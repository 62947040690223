import { actions } from './sections/actions';
import { errors } from './sections/errors';
import { navigation } from './sections/navigation';

export const en = {
  errors,
  navigation,
  actions,
  'sign_in': 'Sign In',
  'related': 'related',

  'merchants_contracts': 'Merchants Contracts',
  'merchants_contracts_description':
    'Create and monitor manually entered contracts',
  'merchants_contracts_add': 'Create a Contract',
  'merchants_contracts_add_description':
    'Create a new contract that will be processed by the Reguard pipeline',
  'your_cost': 'Your Cost',

  'PRODUCT_NOT_LISTED_HERE': "I don't see my product listed here",
  'FILE_CLAIM': 'File a claim',
  'BLANK_PASSWORD': 'Password is required',
  'CHECK_CLAIM_STATUS': 'Check claim status',
  'GENERAL_QUESTION': 'General question',
  'NO_PRODUCT_PURCHASES': 'No product purchases',
  'USER_NOT_SET': 'User not set',
  'BLANK_ORDER': 'he order number is required',
  'BLANK_REPEAT_PASSWORD': 'Please enter the new password again',
  'NO_MATCHING_PRODUCT': 'No matching product',
  'YES': 'Yes',
  'NO': 'No',
  'GOOD_BYE': 'Good bye!',
  'ERROR_SUBMITTING_CLAIM': 'Error submitting claim',
  'CLAIM_BEFORE_ACTIVATION':
    'Unfortunately, this damage occurred before your protection plan was activated. Please contact customer service if you believe this is an error.',
  'FAQ': 'FAQ',
  'CUSTOMER_SERVICE': 'Customer Service',
  'PROVIDED_NAME_NOT_MATCHES_OUR_RECORDS':
    'The provided name does not match our records.',
  'SORRY_PROVIDED_CODE_NOT_MATCHES_OUR_RECORDS':
    'I’m sorry, the code you provided does not match our records.',
  'PLEASE_ENTER_SIX_DIGIT_CODE_SENT_TO_YOU':
    'Please enter the 6-digit code sent to you.',
  'NOT_SET_BOT_AUTHTOKEN': 'Could not set bot authToken. Error:',
  'BLANK_DAMAGE_DATE': 'The damage date is required',
  'USER_KEY_CANNOT_BE_NULL': 'User key cannot be null',
  'BLANK_DAMAGE_LOCATION': 'The damage location is required',
  'BLANK_DAMAGE_AREA': 'The damaged area is required',
  'ERROR_WHILE_VERIFYING_CODE': 'Error while verifying code:',
  'BLANK_DAMAGE_DESCRIPTION': 'The damage description is required',
  'NOT_RETRIEVE_USER': 'Could not retrieve user:',
  'REACHOUT_TO_CUSTOMER_SERVICE_FOR_MORE_ASSISTANCE':
    'Please reach out to customer service for more assistance.',
  'BLANK_POSTAL_CODE': 'The postal code is required',
  'ERROR_FINDING_USER_BY_EMAIL': 'Error finding user by email. email',
  'EQUAL_PASSWORDS':
    'Something went wrong while changing the password: The new password must be different from the previous one',
  'INVALID_EMAIL_OR_PASSWORD': 'Wrong email or password.',
  'ERROR_FINDING_USER_BY_AUTH0ID_USERID':
    'Error finding user by auth0Id. userId',
  'BLANK_ADDRESS': 'The first line of the address is required',
  'BLANK_TEMPORARY_PASSWORD': 'A temporary password is required',
  'NO_SESSION': 'No session',
  'ERROR_FINDING_USER_BY_EMAIL_OR_PHONE':
    'Error finding user by email or phoneNumber. args ',
  'INVALID_CREDENTIALS': 'Wrong credentials',
  'ERROR_FINDING_CUSTOMER_BY_ID': 'Error finding customer by id. customerId',
  'ERROR_WHILE_RESETTING_VERIFICATION':
    'Error while resetting verification. customerWithVerification?.id',
  'INVALID_USER': 'Cannot find user!',
  'BLANK_NEW_PASSWORD': 'A new password is required',
  'INVALID_USER_ROLE': 'Cannot find user role!',
  'NOT_AUTHENTICATED': 'Not authenticated',
  'SOMETHING_WRONG_WHILE_LOGGING_OUT_USER':
    'Something went wrong when logging out the user:',
  'ERROR_FINDING_CONTRACT': 'Error finding contract. args',
  'INVALID_CONTRACT_INFO': 'Invalid contract information',
  'ERROR_FINDING_USER_BY_CUSTOMERID':
    'Error finding user by customer id. customerId',
  'ERROR_FINDING_ORDER_BY_ORDER_NUMBER':
    'Error finding order by order number. orderNumber',
  'INVALID_CUSTOMER': 'Customer not found',
  'CUSTOMER_EMAIL_EXISTS': 'Customer already has email!',
  'SOMETHING_WRONG_WHEN_SENDING_EMAIL_VERIFICATION_CODE':
    'Something went wrong when sending email verification code:',
  'SOMETHING_WRONG_WHEN_SENDING_SMS_VERIFICATION_CODE':
    'Something went wrong when sending SMS verification code:',
  'EMAIL_SENT': 'Email sent',
  'USER_NOT_EXIST': 'User not found',
  'USER_EMAIL_EXISTS': 'User email already exists',
  'USER_NOT_FOUND_OR_NOT_FIND_USER_ROLE':
    'User not found or cannot find user role',
  'SOMETHING_WRONG_WHILE_LOGGING_IN_MERCHANT':
    'Something went wrong when logging in merchant',
  'SOMETHING_WRONG_LOGGING_OUT_USER':
    'Something went wrong when logging out user',
  'FAILED_GETTING_USER_ROLES': 'Failure to get user roles',
  'UNAUTHORIZED_OR_NO_SESSION': 'Unauthorized or no session',
  'ERROR_FETCHING_USER_BY_EMAIL': 'Error while getting user by email. email:',
  'EMAIL_OR_PHONE_REQUIRED': 'At least email or phoneNumber is needed',
  'SOMETHING_WRONG_WHILE_GETTING_USER_BY_EMAIL_OR_PHONE':
    'Something went wrong when getting user by email or phoneNumber:',
  'ERROR_VALIDATING_PHONE': 'Error while validating phoneNumber. phoneNumber:',
  'ERROR_SENDING_SMS': 'Error while sending sms. phoneNumber:',
  'BLANK_VERIFIACTION_CODE': 'Missing verification code!',
  'ERROR_FINDING_CUSTOMER_BY_EMAIL':
    'Error while finding customer by email. email:',
  'ERROR_REQUESTING_VERIFICATION_BY_EMAIL':
    'Error while requesting verification by email. email:',
  'ERROR_SENDING_VERIFICATION_EMAIL_SMS':
    'Error while sending verification email message. email:',
  'ERROR_FINDING_CUSTOMER_BY_EMAIL_WITH_VERIFICATION':
    'Error while finding customer by email with verification. email:',
  'BLANK_CONTRACT_ID_PARAM': 'Missing contract ID param!',
  'ERROR_FINDING_CONTRACT_BY_CONTRACT_ID':
    'Error while finding contract by contractId. contractId:',
  'BLANK_CLAIM_ID': 'Missing claim ID!',
  'ERROR_FINDING_CLAIM_BY_PCMI_CLAIM_ID':
    'Error while finding claim by PCMI claim ID. claimId:',
  'USERID_REQUIRED': 'User id is mandatory',
  'ERROR_GETTING_CUSTOMER_ADDRESS_BY_USERID':
    'Error while getting customer address by userId. userId:',
  'ERROR_FINDING_CLAIM_BY_ID': 'Error while getting claim by id. claimId:',
  'DRAFT_CLAIM_NOT_FOUND': 'Draft claim with this claim ID not found',
  'FAILED_GETTING_CUSTOMER_PURCHASES_BY_ID':
    'Failed to get customer purchases for customer with id',
  'FAILED_GETTING_CONVERSATIONAL_TOKEN': 'Failed to get conversational token',
  'CUSTOMER_WITH_EMAIL_NOT_FOUND': 'Customer with this email not found',
  'SOMETHING_WRONG_WHILE_GETTING_USER_BY_CUSTOMER_KEY':
    'Something went wrong when finding user by customer key',
  'FAILED_GETTING_CUSTOMER_PURCHASES_FOR_CUSTOMERID':
    'Failed to get customer purchases for this customer id',
  'MISSING_UNCHANGED_OR_INVALID_PHONE':
    'Missing, unchanged or invalid phone number',
  'USER_WITH_PHONE_NOT_EXIST': 'User with this phone number not found',
  'SOMETHING_WRONG_SENDING_SMS_OR_REQUESTING_PHONE_CHANGE_FROM_BOT':
    'Something went wrong when sending SMS or when requesting a phone number change from chat bot',
  'MISSING_OR_UNCHANGED_EMAIL': 'Missing or unchanged email',
  'USER_WITH_EMAIL_NOT_EXIST': 'User with this email not found',
  'SOMETHING_WRONG_SENDING_EMAIL_CODE':
    'Something went wrong when sending email code',
  'MISSING_OR_EXPIRED_CODE': 'Missing or EXPIRED_CODE',
  'USER_NOT_FOUND_OR_USER_WITH_PHONE_NOT_EXIST_OR_CHANGE_REQUEST_NOT_FOUND':
    'User not found or user with this phone number not found or change request not found',
  'SOMETHING_WRONG_WHILE_VERIFYING_PHONE_CODE':
    'Something went wrong when verifying phone code',
  'ERROR_WHILE_FINDING_CLAIM_BY_PCMI_CLAIM_ID':
    'Error while finding claim by PCMI claim ID',
  'CALLER_MUST_BE_MERCHANT': 'Caller must be a merchant!',
  'MISSING_REQUIRED_FIELDS': 'Missing required fields!',
  'ERROR_FETCHING_MERCHANT_BY_PCMI_DEALER_GROUP':
    'Error getting merchant by pcmiDealerGroup. caller:',
  'ERROR_LOADING_CONTRACTS': 'Error loading contracts.',
  'ERROR_GENERATING_ERROR_FILE': 'Error generating error fle.',
  'INVALID_PARAMETERS': 'Invalid parameters',
  'ERROR_GETTING_PURCHASE_BY_ORDER_NUMBER_AND_MERCHANT':
    'Error getting purchase by orderNumber and merchant. info:',
  'INVALID_ORDER': 'Order not found!',
  'SOMETHING_WRONG_CREATING_CONTRACTS_AND_ORDERS':
    'Something went wrong when creating contract and orders',
  'SOMETHING_WRONG_WHILE_FETCHING_CONTRACTS_AND_ORDERS':
    'Something went wrong when fetching contract and orders',
  'SOMETHING_WRONG_WHILE_GETTING_CLAIMS':
    'Something went wrong when getting claims:',
  'CLAIM_NOT_FOUND': 'Claim not found:',
  'ERROR_GETTING_CLAIM_BY_ID_FROM_DB':
    'Error while getting claim by id from DB. ClaimId:',
  'ERROR_WHILE_MAPPING': 'Error while mapping.',
  'ERROR_WHILE_FETCHING_CUSTOMER_BY_ID_FROM_DB':
    'while getting customer by id from db. customerId:',
  'ERROR_WHILE_LOADING_PRODUCT_PURCHASE_FROM_DB':
    'Error while loading productPurchase from DB. productPurchase:',
  'ERROR_WHILE_LOADING_DAMAGE_CAUSE_FROM_DB':
    'Error while loading damageCause from DB. causeOfDamage:',
  'ERROR_WHILE_LOADING_DAMAGE_LOCATION_FROM_DB':
    'Error while loading damageLocation from DB. locationOfDamage:',
  'ERROR_WHILE_LOADING_DAMAGE_AREA_FROM_DB':
    'Error while loading damageArea from DB. damageArea:',
  'ERROR_WHILE_LOADING_ADDRESS_FROM_DB':
    'Error while loading address from DB. address:',
  'ERROR_WHILE_SAVING_CLAIM': 'Error while saving the claim. claim:',
  'ERROR_WHILE_MAPPING_RESPONSE_FIELDS': 'Error while mapping response fields.',
  'CLAIM_NOT_EXIST_UNDER_DRAFT_STATUS': 'Claim is not in Draft status!',
  'DRAFT_CLAIM_IS_INCOMPLETE': 'Draft claim is incomplete!',
  'ERROR_FETCHING_ACTIVE_CLAIMS_COUNTS_FROM_DB':
    'Error while getting active claims counts from db.',
  'ANOTHER_PLAN_EXIST_FOR_SAME_PRODUCT':
    'Another active claim has been found for the same product!',
  'ERROR_WHILE_FETCHING_USER_BY_ID': 'Error while getting user by id. userId:',
  'ERROR_COMMUNICATING_WITH_PCMI_OR_PCRS':
    'Error communicating with PCMI/PCRS. Please contact support.',
  'CLAIM_NOT_FOUND_BY_ID': 'Could not get claim by id. claimId:',
  'ADD_CLAIM_PHOTOS': 'addClaimPhotos - No photos to save',
  'ERROR_FETCHING_CUSTOMER_BY_ID':
    'Error while finding customer by id. UserId:',
  'INVALID_USER_CUSTOMER': 'User Customer not found!',
  'ERROR_FETCHING_DRAFT_CLAIM':
    'Error while getting draft claim claim. ClaimId:',
  'ERROR_SAVING_CLAIM_OR_UPLOADING_OR_SAVING_PHOTOS':
    'Error saving claim or uploading/saving photos',
  'SOMETHING_WRONG_WHEN_SAVING_THE_CLAIM_FOR_THIS_CUSTOMER_ID':
    'Something went wrong when saving the claim for this customer ID and this claim ID',
  'CLAIM_OR_USER_NOT_FOUND': 'Claim or user not found',
  'SOMETHING_WRONG_WHEN_SUBMITTING_CLAIM_FOR_THIS_CUSTOMER_ID':
    'Something went wrong when submitting the claim for this customer ID and this claim ID',
  'ERROR_UPLOADING_OR_SAVING_CLAIM_PHOTOS':
    'Error uploading/saving claim photos',
  'SOMETHING_WRONG_SAVING_CLAIM_PHOTOS_FOR_CUSTOMER_AND_CLAIM_ID':
    'Something went wrong when saving the claim photos for this customer ID and this claim ID',
  'MISSING_CUSTOMER_OR_MERCHANT_ID': 'Missing customer or Merchant IDs',
  'SOMETHING_WRONG_WHILE_GETTING_CLAIM_BY_ID':
    'Something went wrong when getting claim by this ID',
  'DRAFT_WITH_CLAIM_ID_FOR_CUSTOMER_NOT_FOUND_OR_INVALID_CUSTOMER':
    'Draft with this claim ID for this customer not found or customer not found',
  'SOMETHING_WRONG_WHILE_REMOVING_DRAFT_CLAIM_FOR_CLAIM_ID':
    'Something went wrong when removing draft claim for this claim ID',
  'MISSING_CUSTOMER_Id': 'Missing customer ID!',
  'SOMETHING_WRONG_FETCHING_CONTRACTS_FOR_CUSTOMER_Id':
    'Something went wrong when getting contracts for customer ID:',
  'SOMETHING_WRONG_GETTING_CONTRACTS_FOR_CUSTOMER_ID':
    'Something went wrong when getting contracts for this customer ID',
  'DOMAIN_TYPE_REQUIRED': 'Domain Type is mandatory!',
  'DOMAIN_TYPE_NOT_ALLOWED': 'Domain type not allowed. Domains available:',
  'ERROR_GETTING_DAMAGE_CAUSES': 'Error while getting damageCauses',
  'PRODUCT_PURCHASE_ID_OR_DRAFT_CLAIM_ID_REQUIRED_FOR_DAMAGE_CAUSE':
    'Product Purchase ID or Draft Claim ID is mandatory for damage cause!',
  'DOMAIN_TYPE_REQUIRED_OR_NOT_ALLOWED_OR_PRODUCT_PURCHASE_ID_OR_DRAFT_CLAIM_ID_REQUIRED_FOR_DAMAGE_CAUSE':
    'Domain Type is mandatory or not allowed or Product Purchase ID or Draft Claim ID is mandatory for damage cause',
  'FAILED_GETTING_TRANSLATION_FILE': 'Failed to get translation file',
  'FAILED_GETTING_TRANSLATION_FILE_DATA': 'Failed to get translation file data',
  'ERROR_FINDING_MERCHANT_BY_MERCHANT_ID':
    'Error while finding merchant by merchantId. merchantId:',
  'MERCHANT_NOT_EXIST': 'Merchant does not exist',
  'ERROR_SAVING_MERCHANT': 'Error while saving merchant. merchant:',
  'ERROR_FETCHING_MERCHANT': 'Error while finding merchants.',
  'DATABASE_ERROR_WHILE_GETTING_ALL_MERCHANT':
    'Database error when getting all merchants',
  'USER_IS_NOT_LINKED_TO_CUSTOMER': 'The user is not linked to a customer',
  'MERCHANT_ID_REQUIRED': 'MerchantIds is mandatory!',
  'ERROR_WHILE_FETCHING_PLANS_FOR_MERCHANT':
    'Error while getting plans for merchant. merchantIds:',
  'PLAN_NOT_FOUND': 'Plan not found!',
  'ERROR_WHILE_GETTING_PURCHASES_FOR_CUSTOMER':
    'Error while getting purchases for customerId. customerId:',
  'ERROR_WHILE_INITIALIZING_MERCHANT_CONFIGURATION':
    'Error when initializing the merchant configuration:',
  'DB_ERROR_GETTING_REPORTS_CONTRACT_DATA':
    'Database error when getting reports contracts data',
  'INVALID_TOKEN': 'Invalid Token!',
  'NO_MERCHANT_LINKED_TO_THIS_USER': 'No merchants linked to this user',
  'ERROR_FINDING_MERCHANT_BY_ID':
    'Error while finding merchants by ids. merchantIds:',
  'ERROR_FINDING_TALENT_USER_BY_EMAIL':
    'Error while finding talent user by email. email:',
  'SOMETHING_WRONG_CREATING_USER_IN_TALENTLMS':
    'Something went wrong when creating user in TalentLMS:',
  'BAD_REQUEST': 'BAD REQUEST',
  'PRODUCT_HAS_STRUCTURAL_DAMAGE': 'Product has structural damage',
  'PRODUCT_HAS_SURFACE_DAMAGE': 'Product has surface damage',
  'PRODUCT_HAS_COSMETIC_DAMAGE': 'Product has only cosmetic damage',
  'PRODUCT_WORK_SOMETIMES_BECAUSE_OF_DAMAGE':
    'Product only works sometimes because of damage',
  'PRODUCT_HAS_DAMAGE_FROM_ACCUMULATION':
    'Product has damage from accumulation',
  'PRODUCT_HAS_DAMAGE_BECAUSE_OF_PREV_MODIFICATION':
    'Product has damage because of previous modification',
  'USER_WITH_SAME_EMAIL_ALREADY_EXIST':
    'A user with the same email already exists!',
  'MAKE_SURE_ENTERING_SAME_EMAIL':
    'Make sure that you are entering the same email',
  'SOMETHING_WRONG_WHILE_GETTING_USER_BY_EMAIL_OR_PHONE_OR_EMAIL_OR_PHONE_REQUIRED':
    'Something went wrong when getting user by email or phoneNumber: At least email or phoneNumber is needed',
  'ADDRESS_NOT_VALID_FOR_CUSTOMER':
    'The address is not valid for the customer!',
  'MISSING_ADDRESS_ID': 'Missing address ID!',
  'OLD_AND_NEW_PASSWORD_MATCHES': 'Old password and new password are equal!',
  'VENDOR_NOT_FOUND': 'Could not find vendor',
  'USER_NOT_EXISTS': 'User does not exist',
  'VERIFICATION_NOT_REQUESTED': 'Verification not requested!',
  'EXPIRED_CODE': 'EXPIRED_CODE!',
  'SELECT_DIFFERENT_EMAIL_ADDRESS': 'Choose a different email address!',
  'PHONE_NOT_UPDATED': 'Phone number is not changed!',
  'NO_CUSTOMER_FOUND_FOR_THIS_EMAIL': 'No customer found for this email!',
  'ERROR_FETCHING_AUTH0_USER_BY_EMAIL':
    'Error while getting auth0 user by email. email:',
  'USER_NOT_ALLOWED': 'User not allowed',
  'ERROR_WHILE_SENDING_ACCOUNT_CREATION_EMAIL':
    'Error while sending account creation email. dbUser:',
  'ERROR_FETCHING_MERCHANT_BY_PCMI_PRICING_GROUP':
    'Error while getting merchant by PCMI pricing group. vendor:',
  'ERROR_CHECKING_IF_USER_ALREADY_EXIST':
    'Error while checking if user already exists. user:',
  'ERROR_WHILE_FETCHING_USER_FROM_AUTH0':
    'Error while getting user from auth0. email:',
  'ERROR_WHILE_CREATING_MERCHANT_USER':
    'Error on creating merchant user. user:',
  'ERROR_WHILE_SENDING_MERCHANT_ONBOARDING_EMAIL':
    'Error while sending merchant onboarding email. email:',
  'ERROR_WHILE_RESETTING_CUSTOMER_VERIFICATION':
    'Error while resetting customer verification. customerId:',
  'FAILED_TO_CHANGE_PASSWORD': 'Failed to change password',
  'ERROR_RESETTING_VERIFICATION_CODE':
    'Error while resetting verification. email:',
  'USER_ERROR': 'User error',
  'USER_NOT_EXIST_ON_DB': 'Cannot find user on DB!',
  'ERROR_UPDATING_CURRENT_PASSWORD':
    'Error while changing current password. username:',
  'ERROR_WHILE_SETTING_VERIFICATION_CODE':
    'Error while setting verification code. userId:',
  'ERROR_SENDING_RESET_PASSWORD_EMAIL':
    'Error while sending reset password email. email:',
  'ERROR_CHECKING_IF_CUSTOMER_ADDRESS_ALREADY_EXIST':
    'Error while checking if customer address already exists. Params:',
  'ERROR_UPDATING_CUSTOMER_ADDRESS':
    'Error while updating customer address. newAddress:',
  'CUSTOMER_NOT_FOUND_FOR_THIS_USER': 'Cannot find a customer for this user!',
  'ERROR_FETCHING_CUSTOMER_ADDRESS_BY_ID':
    'Error while getting customer address by id. customerID:',
  'ADDRESS_EXISTS': 'Address already added.',
  'ERROR_ADDING_CUSTOMER_ADDRESS':
    'Error while adding customer address. params:',
  'MISSING_NEW_EMAIL': 'Missing new email!',
  'ERROR_FETCHING_USER_BY_ID': 'Error while finding user by id. userId:',
  'ERROR_WHILE_SETTING_VERIFICATION':
    'Error while setting verification code. userId:',
  'EMAIL_NOT_UPDATED': 'Email is not changed!',
  'DB_CHANGE_EMAIL_ERROR': 'Database change email error',
  'MISSING_NEW_PHONE': 'Missing new phone number!',
  'ERROR_SENDING_SMS_PHONE': 'Error while sending SMS. phoneNumber:',
  'ERROR_UPDATING_PHONE_NUMBER': 'Error while updating phoneNumber. data:',
  'ADMIN_ROLE_NOT_CONFIGURED': 'Admin role is not configured.',
  'ADDRESS_NOT_EXIST': 'Address not found',
  'SOMETHING_WRONG_WHILE_FETCHING_ADDRESS_ON_MAPBOX':
    'Something went wrong in finding address on mapbox: for id:',
  'ERROR_FINDING_AUTH0_USER_BY_EMAIL':
    'Error while finding auth0 user by email. email:',
  'ERROR_DELETING_USER_BY_EMAIL': 'Error while deleting user by email. email:',
  'ERROR_DELETING_AUTH0_USER_BY_EMAIL':
    'Error while deleting auth0 user by email. email:',
  'INVALID_CUSTOMER_TOKEN': 'Invalid Customer Token!',
  'ERROR_FETCHING_CUSTOMER_BY_EMAIL_OR_TOKEN':
    'Error while finding customer by email or token. data:',
  'ERROR_SENDING_VERIFICATION_EMAIL':
    'Error while sending email verification email. email:',
  'ERROR_CHECKING_EMAIL_VERIFICATION_TIMING':
    'Error while checking email verification timing. customerId:',
  'ERROR_UPDATING_EMAIL': 'Error while updating email. customerId:',
  'ERROR_SENDING_VERIFICATION_MESSAGE_EMAIL':
    'Error while sending verification message email. email:',
  'SOMETHING_WRONG_GETTING_ALL_USERS_FROM_DB':
    'Something went wrong when getting all users from the database',
  'USER_WITH_EMAIL_EXISTS_OR_VERIFICATION_NOT_REQUESTED':
    'A user email with the same email already exists or verification not requested',
  'CUSTOMER_NOT_FOUND_FOR_EMAIL': 'Customer not found for this email',
  'SOMETHING_WRONG_RESETTING_EMAIL_VERIFICATION_OR_CREATING_USER':
    'Something went wrong when resetting email verification or creating a new user',
  'SOMETHING_WRONG_SETTING_PASSWORD':
    'Something went wrong when setting password',
  'SOMETHING_WRONG_SETTING_MERCHANT_PASSWORD':
    'Something went wrong when setting merchant password',
  'INTERNAL_SERVER_ERROR_SENDING_EMAIL':
    'Internal server error when sending email',
  'SOMETHING_WRONG_CHANGING_PASSWORD':
    'Something went wrong when changing password',
  'INVALID_ADDRESS_FOR_CUSTOMER': 'Invalid address for customer',
  'SOMETHING_WRONG_UPDATING_CUSTOMER_ADDRESS':
    'Something went wrong when updating customer address',
  'CUSTOMER_NOT_FOUND_FOR_USER': 'Customer not found for this user',
  'SOMETHING_WRONG_ADDING_CUSTOMER_ADDRESS':
    'Something went wrong when adding customer address',
  'PREF_LANGUAGE_UPDATED_SUCCESSFULLY':
    'Preferred language updated successfully',
  'SOMETHING_WRONG_ADDING_CUSTOMER_LANGUAGE':
    'Something went wrong when adding customer language',
  'SOMETHING_WRONG_REQUESTING_EMAIL_CHANGE':
    'Something went wrong when requesting email change',
  'VERIFICATION_NOT_REQUESTED_OR_INVALID_EMAIL_OR_EXPIRED_CODE':
    'Verification not requested or invalid email or expired code',
  'INVALID_EMAIL_OR_INVALID_CODE_VERIFICATION_NOT_REQUESTED_OR_EXPIRED_CODE':
    'Invalid email or Invalid code, or verification not requested or expired code',
  'VENDOR_NOT_FOUND_OR_USER_NOT_CREATED':
    'Could not find vendor or user could not be created',
  'SOMETHING_WRONG_CREATING_MERCHANT_USER':
    'Something went wrong when creating the merchant user',
  'MISSING_VERIFICATION_CODE_OR_EMAIL_NOT_CHANGED':
    'Missing verification code or email not changed',
  'DB_EMAIL_CHANGE_ERROR_OR_AUTH0_EMAIL_CHANGE_ERROR':
    'Database email change error or Auth0 change email error',
  'SOMETHING_WRONG_CHANGING_EMAIL': 'Something went wrong changing email',
  'PHONE_NOT_CHANGED_OR_VERIFICATION_CODE_VALIDATION_FAILED':
    'Phone number not changed or verification code validation failed',
  'SOMETHING_WRONG_CHANGING_PHONE_AFTER_VERIFICATION':
    'Something went wrong when changing phone number after verification',
  'SOMETHING_WRONG_UPDATING_USER_ROLE':
    'Something went wrong in updating user role',
  'USER_WITH_ID_EXIST': 'User with this ID found',
  'SOMETHING_WRONG_FINDING_USER_ADDRESS':
    'Something went wrong in finding user addresses',
  'USER_WITH_ID_NOT_EXIST': 'User with this id not found',
  'NOT_PERMITTED_TO_DELETE_USER': 'Not allowed to delete this user',
  'USER_WITH_EMAIL_NOT_EXIST_OR_NO_MERCHANT_RELATED':
    'User with this email not found or no merchant related',
  'SOMETHING_WRONG_DELETING_USER': 'Something went wrong in deleting user',
  'UNAUTHORIZED': 'Unauthorized',
  'MISSING_CLAIM_OR_CLAIM_NOT_IN_DRAFT_STATUS_OR_DRAFT_CLAIM_INCOMPLETE_OR_ANOTHER_ACTIVE_PLAN_FOUND_FOR_SAME_PRODUCT':
    'Missing claim ID or claim is not in the Draft status or draft claim is incomplete or another active claim has been found for the same product',
  'BLANK_FIELDS': 'Missing fields!',
  'INVALID_EXTERNAL_ID': 'Invalid external ID! Must be a UUID string',
  'ERROR_GETTING_CONTRACT_BY_ID':
    'Error while getting contract by id. contractExternalId:',
  'INVALID_CONTRACT': 'Contract not found!',
  'ERROR_UPDATING_CONTRACT_WEBHOOK':
    'Error while updating contract webhook. contractExternalId:',
  'ERROR_TRIGGERING_CONTRACT_NOTIFICATION':
    'Error while triggering contract notifications. contractExternalId:',
  'ERROR_UPDATING_PRODUCT_SEQUENCE':
    'Error while updating product sequence number. contractExternalId:',
  'ERROR_LOADING_FILE_VIA_BASE64':
    'Error while loading file from url base 64. file:',
  'ERROR_GETTING_CLAIM_BY_ID':
    'Error occurred while getting claim by id. claimId:',
  'ERROR_EXECUTING_MANAGE_CLAIM_STATUS_UPDATE':
    'Error occurred while executing manageClaimStatusUpdate. args:',
  'ERROR_EXECUTING_MANAGE_CLAIM_CREATION_FROM_PCMI':
    'Error occurred while executing manageClaimCreationFromPCMI. args:',
  'BLANK_PRODUCT_INFO': 'Missing product info!',
  'BLANK_FAILURE_INFO': 'Missing failure info!',
  'BLANK_ADDRESS_DATA': 'Missing address data!',
  'BLANK_CUSTOMER_IDENTIFICATION_DATA':
    'Missing customer identification data (email or phone)!',
  'CONTACT_NOT_ACTIVE': 'Contract is not active!',
  'PRODUCT_NOT_EXIST': 'Product not found!',
  'DAMAGE_CAUSE_NOT_FOUND': 'Damage Cause not found!',
  'CUSTOMER_NOT_SAME_OF_CONTACT': 'Customer is not the same of contract!',
  'PRODUCT_CONDITION_NOT_FOUND':
    'Product Condition (PCMI Functionality Status) not found!',
  'ERROR_FINDING_PAGINATED_USER': 'Error while finding paginated users.',
  'ERROR_MAPPING_USER_WITH_ROLE': 'Error while mapping user with a role.',
  'ERROR_GETTING_CUSTOMER_BY_EMAIL_WITH_VERIFICATION':
    'Error while getting customer by email with verification. email:',
  'ERROR_CREATING_USER': 'Error while creating user. args:',
  'CREATED_TALENTLMS_USER': 'Created talentLMS user',
  'ERROR_CREATING_TALENT_USER': 'Error while creating talent user. user:',
  'ERROR_GETTING_USER_BY_EMAIL_WITH_VERIFICATION':
    'Error while finding user by email with verification. email:',
  'ERROR_UPDATING_PREF_LANGUAGE': 'Error while updating PrefLanguage. params:',
  'PREFERRED_LANGUAGE_UPDATED_SUCCESSFULLY':
    'Preferred language updated successfully',
  'ERROR_SENDING_VERIFICATION_CODE_EMAIL':
    'Error while sending verification code email. email:',
  'ERROR_FINDING_USER_BY_ID_WITH_VERIFICATION':
    'Error while finding user by id with verification. userId:',
  'AUTH0_CHANGE_ERROR': 'Auth0 change email error',
  'NOT_POSSIBLE_TO_CHANGE_EMAIL': 'It was not possible to change the email:',
  'ERROR_REQUESTING_VERIFICATION':
    'Error while requesting verification. params:',
  'SMS_SERVICE_FAILURE': 'SMS service failure',
  'ERROR_GETTING_AUTH0_ROLES': 'Error while getting auth0 roles.',
  'ERROR_TRYING_ASSIGNING_AUTH0_ROLES':
    'Error while trying to assign auth0 role. data:',
  'ERROR_GETTING_USER_BY_CUSTOMERID':
    'Error while getting user by customerId. customerId:',
  'CUSTOMER_NOT_FOUND_WITH_EMAIL':
    'requestVerifyEmail: Customer not found with email',
  'ERROR_RESETTING_VERIFICATION':
    'Error while resetting verification. customerId:',
  'ERROR_AUTH0_USER_BY_EMAIL': 'Error while find auth0 user by email. email:',
  'MERCHANT_NOT_FOUND_FOR_EMAIL':
    'requestVerifyEmailMerchant: Merchant user not found for email',
  'CONTACT_NOT_FOUND_FOR_CUSTOMER_WITH_ID':
    'sendAccountCreationMessage: Contract not found for customer with id',
  'CONTACT_DOES_NOT_HAVE_PCMI_CONTACT':
    'sendAccountCreationMessage: Contract does not have a PCMI contract number attached',
  'BLANK_PHONE': 'phoneNumber is missing!',
  'ERROR_GETTING_USER_BY_PHONE':
    'Error while getting user by phoneNumber. phoneNumber:',
  'REGUARD_VERIFICATION_CODE': 'Reguard phone verification code:',
  'BLANK_EMAIL': 'Email is missing!',
  'ERROR_FINDING_CUSTOMER_WITH_VERIFICATION':
    'Error while finding customer with verification. customerId:',
  'SOMETHING_WRONG_SENDING_SMS': 'Something went wrong when sending SMS:',
  'GREETINGS_HELP': 'Hi there, How can I help you?',
  'RIGHT_PLACE': 'Ok, you’re in the right place to file a claim!',
  'PRIVACY_SERIOUSLY': 'We take your privacy seriously.',
  'MATCHES_INFORMATION':
    'If your {{KEY_TYPE}} matches the information we have on file for your Reguard purchase, you will receive a verification code.',
  'SELECT_PRODUCT':
    'Please select the product you would like to submit a claim for today.',
  'FILED_ALREADY':
    'It looks like you already have a claim filed for this product. ',
  'ONCE_RESOLVED':
    'You can file a new claim for this product once your current claim has been resolved.',
  'TAP_DRAFT':
    'Please tap below to get started. You can close and save a draft of your claim in your portal at any time.',
  'THANKS_FILLING':
    'Thank you for filing a claim with Reguard. We will carefully review the details and get back to you soon.',
  'ONCE_DECISION':
    'Once a decision has been made, you will receive further instruction by email.',
  'DRAFT_SAVED':
    'A draft of your claim has been saved in your portal. You can log in to finish your claim at any time within 30 days.',
  'DRAFT_DISCARDED': 'Your draft has been discarded.',
  'SORRY_CODE': 'I’m sorry you did not receive a code.',
  'OTHER_AUTHENTICATE': 'Would you like to attempt a new way to authenticate?',
  'FOUND_ACCOUNT': 'Good news, {{USER_NAME}}, I found your account. ',
  'FOUND_ACTIVE_CLAIMS':
    'Good news, {{USER_NAME}}, I found {{ACTIVE_CLAIMS}} active claims in your account. ',
  'START_FILING': 'Start filing your claim',
  'CLAIM_SUBMITTED_TEXT': 'claim submitted',
  'CLAIM_SAVED_DRAT_TEXT': 'draft saved',
  'CLAIM_DISCARDED_DRAFT_TEXT': 'draft discarded',
  'ACCOUNT_NOTREGISTERED':
    'It appears as though you do not have an account registered with Reguard. ',
  'CREATE_PASSWORD': 'Please create a password to file a claim.',
  'TAP_PASSWORD': 'Please tap below to create a password. ',
  'SELECT_DRAFT': 'Please select a draft claim to edit',
  'COMPLETE_DRAFT': 'Complete your draft claim',
  'NEW_PASSWORD': 'Create password',
  'UNABLE_LOCATE_CONTRACT':
    'I’m sorry, I was unable to locate your account using your Contract ID. Your Contract ID is an 8 digit ID with numbers and letters.',
  'UNABLE_LOCATE_EMAIL':
    'I’m sorry, I was unable to locate your account using your email.',
  'UNABLE_LOCATE_PHONE':
    'I’m sorry, I was unable to locate your account using your phone.',
  'NO_CONTRACTID':
    'If you do not have your Contract ID, you can provide the email or phone number registered with your Reguard protection plan.',
  'NO_EMAIL':
    'If you do not have your email, you can provide the Contract ID or phone number registered with your Reguard protection plan.',
  'NO_PHONE':
    'If you do not have your phone number, you can provide the Contract ID or email registered with your Reguard protection plan.',
  'NO_PLANS':
    'I’m sorry, you do not currently have any active protection plans with Reguard. ',
  'EXPIRED_PLAN':
    'I’m sorry, your Reguard protection plan {{PCMI_ID}} expired on {{EXPIRED_DATE}}',
  'ANYTHING_HELP': 'Is there anything else I can help you with today?',
  'THANKS_REGUARD':
    'Thank you for choosing Reguard. We’re always here to help. Have a nice day!',
  'VISIT_FAQ':
    'Please visit our FAQ page or contact Customer Service for additional help.',
  'TAB_FAQ': 'Please tap below to view our FAQ page.',
  'TAP_CSERVICE': 'Please tap below to contact Customer Service.',
  'CONTINUE_TEXT': 'Try again',
  'RESTART_TEXT': 'Restart',
  'RESTART_INPROGRESS_TEXT': 'Restarting...',
  'QUIT_TEXT': 'Quit',
  'ERROR_TEXT': 'Something went wrong',
  'ERROR_HANDLER_OPTIONS':
    'Please try again. Choose one of the following options:',
  'RESENT_CODE': 'I resent a code to you. Did you receive it?',
  'UNABLE_FIND_CLAIM': 'I’m sorry I couldn’t find your claim.',
  'FOUND_CLAIM_ID': 'Here is what I found:',
  'RIGHT_PLACE_STATUS':
    'Ok, you’re in the right place to check your claim status!',
  'GET_STARTED_CHATBOT_COPY_PLACEHOLDERS':
    'Enter a contract ID, a number or an email',
  'GET_STARTED_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'To get started, may I have your Contract ID, or the email or phone number you used to register with Reguard?',
  'NOT_RECEIVE_CHATBOT_COPY_PLACEHOLDERS':
    'Enter the 6-digit code we just sent',
  'NOT_RECEIVE_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'If you did not receive a code, please let me know and I will ',
  'MAY_AUTH_CHATBOT_COPY_PLACEHOLDERS':
    'Enter email or Contract ID or phone number to authenticate',
  'MAY_AUTH_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'May I have your email or Contract ID or phone number to authenticate',
  'NAME_REGISTERED_CHATBOT_COPY_PLACEHOLDERS': 'Please provide your full name',
  'NAME_REGISTERED_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'May I have the name registered with your Reguard protection plan?',
  'INVALID_NAMES_CHATBOT_COPY_PLACEHOLDERS': 'Please provide your full name',
  'INVALID_NAMES_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Invalid name. Please enter the first and last names',
  'AUTH_PHONE_CHATBOT_COPY_PLACEHOLDERS': 'Enter the 6-digit code we just sent',
  'AUTH_PHONE_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'May I have your phone number to authenticate?',
  'NOT_UNDERSTOOD_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Enter email or Contract ID or phone number to authenticate',
  'NOT_UNDERSTOOD_CHATBOT_COPY_PLACEHOLDERS':
    'I’m sorry, I’m not sure I understood that. In order to locate your account, I need your email, phone number, or Contract ID.\n\nMay I have your Contract ID, or the email or phone number you used to register with Reguard?',
  'DONT_KNOW_CLAIMID_CHATBOT_COPY_PLACEHOLDERS': 'Enter a claim ID',
  'DONT_KNOW_CLAIMID_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'I don’t know my Claim ID',
  'PLEASE_CODE_CHATBOT_COPY_PLACEHOLDERS':
    'Enter the 6-digit code we just sent',
  'PLEASE_CODE_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Please enter the temporary 6-digit code sent',
  'THANKS_REGARD':
    'Thank you for choosing Reguard. We’re always here to help. Have a nice day!',
  'GET_STARTED_CLAIM_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'To get started, may I please have your Claim ID? Your Claim ID is a [7] digit code starting with [SO]',
  'GET_STARTED_CLAIM_CHATBOT_COPY_PLACEHOLDERS':
    'To get started, may I please have your Claim ID? Your Claim ID is a [7] digit code starting with [SO]',
  'MAY_CONTRACT_EMAIL_PHONE_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Enter a contract ID, a number or an email',
  'MAY_CONTRACT_EMAIL_PHONE_CHATBOT_COPY_PLACEHOLDERS':
    'Ok, may I please have your Contract ID, or the email or phone number you used to register with Reguard?',
  'AUTH_OPTIONS_GENERIC': 'May I have your ',
  'ROLE_NOT_EXIST': 'Role not found',
  'RESTRICTED': 'Restricted',
  'ERROR_FINDING_USER_BY_AUTH0ID': 'Error finding user by auth0id. auth0id',
  'ERROR_FINDING_USER_ROLES': 'Error finding user roles. auth0id',
  'SALE_NOT_FOUND': 'Sale not found',
  'BLANK_TOKEN': 'Missing token',
  'TOKEN_VERIFICATION_FAILED': 'Token verification failed.',
  'VALID_BLANK_TOKEN': 'Valid token missing!',
  'INVALID_CALLER': 'Invalid caller!',
  'INVALID_ENVIRONMENT': 'Invalid environment!',
  'ERROR_DECODING_TOKEN': 'Error decoding token:',
  'ERROR_REQUESTING_VERIFICATION_CUSTOMERID':
    'Error while requesting verification. customerId:',
  'ERROR_WHILE_FINDING_USER_BY_EMAIL':
    'Error while finding user by email. email:',
  'CONTRACT_WITH_PCMI_ID_NOT_FOUND_OR_MISSING_CONTRACT_ID_PARAMS':
    'Contract with this PCMI ID not found or missing contract ID parameter',
  'SOMETHING_WRONG_GETTING_CONTRACT_BY_PCMI_ID':
    'Something went wrong when getting contract by PCMI ID',
  'SOMETHING_WRONG_GETTING_USER_ADDRESSES':
    'Something went wrong when getting user addresses',
  'ERROR_SAVING_STGCONTRACTS': 'Error saving stgContracts. caller:',
  'ERROR_GENERATING_SUCCESS_FILE': 'Error generating success fle.',
  'ERROR_UPLOADING_PCMI_CONTRACT_IMPORT_FILE':
    'Error uploading PCMI contract import file!.',
  'CUSTOMER_ID_REQUIRED': 'Customer id is required',
  'ERROR_GETTING_THE_CLAIM_BY_ID_FROM_DB':
    'Error while getting the claim by id from db. claimId',
  'ERROR_GETTING_CUSTOMER_BY_ID_FROM_DB':
    'Error while getting customer by id from db.',
  'CLAIM_NOT_EXIST': 'Claim not found!',
  'ADDCLAIMPHOTOS_CLAIM_DOES_NOT_EXIST':
    'addClaimPhotos - Claim does not exist',
  'ERROR_DELETING_CLAIM_WITH_ID': 'Error while deleting claim with id:',
  'PRODUCT_PURCHASE_ID_REQUIRED': 'Product purchase id is mandatory',
  'OPENED_CLAIM_FOR_PRODUCTPURCHASEID': 'An opened claim for productPurchaseId',
  'SAVECLAIMPHOTOS_ERROR_UPLOADING_PHOTOS':
    'saveClaimPhotos - Error uploading photos',
  'VERIFICATION_CODE_VALIDATION_FAILED': 'Verification code validation failed!',
  'CODE_WAS_NOT_SENT_PREVIOUSLY': 'Code was not sent previously',
  'CHANGE_REQUEST_NOT_FOUND': 'Change Request not found!',
  'ERROR_GETTING_PLANS_FOR_CUSTOMER':
    'Error while getting plans for customer. customerId:',
  'ERROR_GETTING_PLAN_FOR_MERCHANT':
    'Error while getting plan for merchant. planId:',
  'DATABASE_ERROR_GETTING_REPORTS_SALES_DATA':
    'Database error when getting reports sales data',
  'DATABASE_ERROR_GETTING_REPORTS_CLAIMS_DATA':
    'Database error when getting reports claims data',
  'DATABASE_ERROR_GETTING_UNKNOWN_SALES_DATA':
    'Database error when getting unknown sales data',
  'ERROR_WHEN_GETTING_AZURE_AD_ACCESS_TOKEN':
    'Error when getting azure AD access token',
  'FAILED_TO_SEND_SMS': 'Failed to send the SMS!',
  'PHONE_NUMBER_MUST_BE_ALL_NUMERIC_10_CHARACTERS_LONG_STRING':
    'Phone number must be an all-numeric 10 characters-long string!',
  'CUSTOMER_EXISTS': 'This customer already exists!',
  'CANNOT_FIND_MERCHANT_WITH_CODE': 'Cannot find merchant with code',
  'CANNOT_FIND_STORE_FOR_MERCHANT': 'Cannot find a store for this merchant',
  'CANNOT_FIND_PROTECTION_PLAN_FOR_MERCHANT':
    'Cannot find a protection plan for this merchant',
  'CANNOT_FIND_PRODUCTS_FOR_MERCHANT_PLAN_COMBINATION':
    'Cannot find products for this merchant-plan combination:',
  'FAILED_CREATING_CONTRACT': 'Failed to create contract',
  'CANNOT_SEND_EMAIL': 'Cannot send email -',
  'EMAIL_OR_PHONE_NO_REQUIRED': 'Email or phone number is required!',
  'CUSTOMER_WITH_SAME_EMAIL_ALREADY_EXISTS':
    'A customer with the same email already exist!',
  'FAILED_TO_SEND_INVITATION_EMAIL': 'Failed to send invitation email to',
  'MISSING_FIELDS': 'Missing fields',
  'CONTRACT_NOT_EXIST': 'Contract not found',
  'SOME_DESCRIPTION': 'Some Description',
  'MISSING_OR_INVALID_FIELDS': 'Missing or invalid fields',
  'CONTRACT_NOT_FOUND_OR_NOT_ACTIVE_OR_PRODUCT_NOT_FOUND_OR_DAMAGE_CAUSE_NOT_FOUND':
    'Contract not found or not active or product not found or damage cause not found',
  'SOMETHING_WRONG_WHEN_UPDATING_OR_CREATING_A_CLAIM':
    'Something went wrong when updating or creating a claim',
  'ERROR_GETTING_CONTRACTS': 'Error getting contracts!',
  'MERCHANT_NOT_FOUND': 'Merchant not found!',
  'ERROR_SAVING_MERCHANT_USER': 'Error while saving merchant user',
  'ERROR_GETTING_SUBCATEGORIES': 'Error getting subcategories!',
  'TIER_NOT_EXIST': 'Tier does not exist!',
  'ERROR_GETTING_DEALER_INFO': 'Error getting dealer details!',
  'ERROR_GETTING_STORE_INFO': 'Error getting store details!',
  'CANNOT_FIND_DEFAULT_PRODUCT_SUBCATEGORY_WITH_CODE':
    'Cannot find default product subcategory with code:',
  'CANNOT_GET_ACCESS_TOKEN': 'Cannot get access token!',
  'CANNOT_GET_REFRESH_TOKEN': 'Cannot get refresh token!',
  'SOMETHING_WRONG': 'Something went wrong',
  'FAILURE_DATE_MUST_BE_WITHIN_CONTRACT_EFFECTIVE_AND_EXPIRATION_DATE':
    'The failure date must be within the contract effective and expiration date.',
  'CANNOT_CREATE_CONTRACT': 'Cannot create contract!',
  'CANNOT_ADD_NOTES_TO_CONTRACT': 'Cannot add notes to contract!',
  'CANNOT_UPDATE_PRODUCTS_IN_CONTRACT': 'Cannot update products in contract!',
  'CANNOT_UPDATE_CONTACT_IN_CONTRACT': 'Cannot update contact in contract!',
  'CANNOT_GET_CLAIMS': 'Cannot get claims!',
  'CANNOT_GET_CLAIM_DETAILS': 'Cannot get claim details! claim number:',
  'CANNOT_CREATE_CLAIM': 'Cannot create claim!',
  'CANNOT_ADD_NOTES_TO_CLAIM': 'Cannot add notes to claim!',
  'CANNOT_GET_SUBCATEGORIES': 'Cannot get subcategories!',
  'CANNOT_GET_FAILURE_TYPES': 'Cannot get failure types!',
  'CANNOT_GET_DEALERS': 'Cannot get dealers!',
  'CANNOT_GET_DEALER_DETAILS': 'Cannot get dealer details!',
  'CANNOT_GET_DEALER_STORES': 'Cannot get dealer stores!',
  'CANNOT_GET_DEALER_STORE_INFO': 'Cannot get dealer store details!',
  'INVALID_LOGIN': 'Invalid login',
  'CONTRACT_NOT_FOUND': 'Contract not found!',
  'CONTRACT_NOT_ACTIVE': 'Contract is not active!',
  'PRODUCT_NOT_EXISTS': 'Product not found!',
  'DAMAGE_CAUSE_NOT_EXIST': 'Damage Cause not found!',
  'CUSTOMER_NOT_THE_SAME_OF_CONTRACT': 'Customer is not the same of contract!',
  'INVALID_EXTERNAL_ID_MUST_BE_A_UUID_STRING':
    'Invalid external ID! Must be a UUID string',
  'UPDATING_MS_TOKEN': 'Updating MS access token',
  'ERROR_GETTING_CUSTOMER_BY_MAIL':
    'Error while getting customer by email. email:',
  'ERROR_OCCURRED_PROCESSING_PHOTOS_TO_CREATE':
    'Error occurred while processing photos to create. url:',
  'ERROR_GETTING': 'Error while getting',
  'ERROR_FINDING_USER_BY_EMAIL_WITH_VERIFICATION':
    'Error while finding user by email with verification. email:',
  'ERROR_FINDING_USER_BY_EMAIL_USERID':
    'Error while finding user by email. userId:',
  'CANNOT_FIND_USER_ON_DB': 'Cannot find the user on DB',
  'MERCHANT_WITH_MAIL_NOT_EXIST': 'Merchant with this email not found',
  'SOMETHING_WRONG_IN_REQUESTING_PHONE_NUMBER_CHANGE_OR_WHEN_SENDING_SMS':
    'Something went wrong in requesting phone number change or when sending an SMS',
  // "ADMIN_ROLE_NOT_CONFIGURED":"Admin role is not configured",
  'NO_MERCHANT_RELATED': 'No Merchant Related!',
  'NOT_ALLOWED_TO_DELETE_THIS_USER': 'You are not allowed to delete this user!',
  'FAILED_TO_SEND_MAILS': 'Failed to send emails',
  'MAY_I_HAVE_YOUR_KEY_TO_AUTHENTICATE':
    'May I have your {{keyList}} to authenticate',
  'THIS_DAMAGE_HAPPENED_BEFORE_YOUR_PROTECTION_PLAN':
    'Unfortunately, this damage happened before your protection plan was activated! Please reach out to customer service if you think this is a mistake.',

  // Financial Report UI
  'financial_report': 'Financial Report',
  'top_performing_store_quantity': 'Top Performing Store Quantity',
  'top_performing_store_sales': 'Top Performing Store Sales',
  'lowest_performing_store_quantity': 'Lowest Performing Store Quantity',
  'lowest_performing_store_sales': 'Lowest Performing Store Sales',
  'total_stores_without_sales': 'Total Stores Without Sales',
  'fpp_gross_revenue': 'Gross FPP Revenue',
  'fpp_units_sold': 'FPP Units Sold',
  'fpp_total_sales': 'FPP Total Sales',

  'YES_CREATE_PASSWORD': 'Yes, create a password',
  'NO_FILE_MY_CLAIM_LATER': 'No, file my claim later',
  'START_A_NEW_CLAIM': 'Start a new claim',
  'ERROR_RETRIEVING_CLAIM_LINKED_PRODUCT':
    'Something went wrong retrieving the claim id linked to the product',
  'FINISH_MY_CLAIM': 'Finish my claim',
  'START_NEW_CLAIM': 'Start a new claim',
  'MAY_I_HAVE_YOUR_Contract ID or email or phone number_TO_AUTHENTICATE':
    'May I have your Contract ID or email or phone number to authenticate?',
  'MAY_I_HAVE_YOUR_Contract ID or email_TO_AUTHENTICATE':
    'May I have your Contract ID or email to authenticate?',
  'MAY_I_HAVE_YOUR_Contract ID or phone number_TO_AUTHENTICATE':
    'May I have your Contract ID or phone number to authenticate?',
  'MAY_I_HAVE_YOUR_email or phone number_TO_AUTHENTICATE':
    'May I have your email or phone number to authenticate?',
  'MAY_I_HAVE_YOUR_Contract ID_TO_AUTHENTICATE':
    'May I have your Contract ID to authenticate?',
  'MAY_I_HAVE_YOUR_email_TO_AUTHENTICATE':
    'May I have your email to authenticate?',
  'MAY_I_HAVE_YOUR_phone number_TO_AUTHENTICATE':
    'May I have your phone number to authenticate?',

  // FE
  'welcome': 'Welcome, {{name}}',
  'home': 'Home',
  'my_plans': 'My Plans',
  'my_plan': 'My plan',
  'my_claims': 'My Claims',
  'open_claims': 'Open Claims',
  'inactive_protection_plans': 'Inactive Protection Plans',
  'active_protection_plans': 'Active Protection Plans',
  'expired_protection_plans': 'Expired Protection Plans',
  'file_a_claim': 'File a Claim',
  'account': 'Account',
  'terms_of_use': 'Terms of Use',
  'help': 'Help',
  'account_settings': 'Account Settings',
  'log_out': 'Log Out',
  'draft_claims': 'Draft Claims',
  'why_is_my_plan_inactive': 'Why is my plan inactive?',
  'your_plan_will_be_automatically_activated_once_your_first_item_is_delivered':
    'Your plan will be automatically activated once your first item is delivered.',
  'draft': 'Draft',
  'under_review': 'Under Review',
  'approved': 'Approved',
  'in_progress': 'In Progress',
  'closed': 'Closed',
  'claim_id': 'Claim ID:',
  'last_edited_date': 'Last Edited Date:',
  'view_details': 'View details',
  'finish_claim': 'Finish claim',
  'inactive': 'Inactive',
  'active': 'Active',
  'days_left': ': {{daysUntilExpiration}} Days Left',
  'view_claim': 'View claim',
  'reguard_all_rights_reserved_reguard_the_reguard_logo_the_tag_design_are_trademarks_of_reguard_and_its_affiliated_companies':
    'Reguard. All rights reserved. REGUARD, the REGUARD logo, the tag design are trademarks of Reguard and its affiliated companies.',
  //    login
  'log_in': 'Log in',
  'email': 'Email',
  'business_email': 'Business email',
  'password': 'Password',
  'remember_me': 'Remember me',
  'please_enter_email_with_right_format':
    'Please enter email with right format',
  'email_is_required': 'Email is Required',
  'password_is_required': 'Password is Required',
  'i_forgot_my_password': 'I forgot my password',
  'your_email_or_password_is_incorrect': 'Your email or password is incorrect.',
  'please_try_again': 'Please try again.',
  'please_try_again_in_a_few_minutes_there_was_an_error_logging_you_in':
    'Please try again in a few minutes, there was an error logging you in.',
  "haven't_set_a_password": "Haven't set a password?",
  'set_a_password_now': 'Set a password now',
  'i_did_not_provide_my_email_at_point-of-purchase':
    'I did not provide my email at point-of-purchase.',
  //    profile
  'my_account': 'My Account',
  'profile': 'Profile',
  'name': 'Name',
  'mobile_number': 'Mobile Number',
  'my_addresses': 'My Addresses',
  'add_new_address': '+ Add new address',
  'language': 'Language',
  'select_language': 'Select Language',
  'add_phone_number': 'Add phone number',
  'update_your_email_address': 'Update your email address.',
  'this_address_is_used_to_log_in_your_reguard_account_and_help_service_your_claims':
    'This address is used to log in your Reguard account and help service your claims',
  'your_phone_number': 'your phone number.',
  'this_contact_information_will_be_used_to_help_service_your_claims':
    'This contact information will be used to help service your claims.',
  'update_your_address': 'Update your address.',
  'this_address_will_be_used_to_help_service_your_claim':
    'This address will be used to help service your claim.',
  'select_your_language': 'Select your Language',
  'this_will_be_used_in_navigating_your_website_experience':
    'This will be used in navigating your website experience.',
  'email_verified': 'Email verified',
  'your_email_has_been_updated': 'Your email has been updated.',
  'phone_number_verified': 'Phone number verified',
  'your_phone_number_has_been_updated': 'Your phone number has been updated.',
  'address_saved': 'Address saved!',
  'your_address_has_been_saved': 'Your address has been saved.',
  'language_preference_saved': 'Language preference saved!',
  'your_language_preference_has_been_set_to':
    'Your language preference has been set to {{value}}',
  'update': 'Update',
  'add': 'Add',
  'add_a_new_address': 'Add a new address.',
  'please_enter_the_verification_code_we_just_sent_to':
    'Please enter the verification code we just sent to {{value}}',
  'i_would_like_to_receive_marketing/promotional_communications_from_reguard':
    'I would like to receive marketing/promotional communications from Reguard.',
  'send_email': 'Send email',
  'save_changes': 'Save changes',
  'cancel': 'Cancel',
  'the_postal_code_does_not_match_the_state':
    'The Postal Code does not match the State',
  'please_insert_a_valid_postal_code': 'Please insert a valid Postal code',
  'address_line_1_is_required': 'Address line 1 is required',
  'cannot_be_more_than_50_characters': 'Cannot be more than 50 characters',
  'no_leading_spaces_allowed': 'No leading spaces allowed',
  'city_is_required': 'City is required',
  'postal_code_is_required': 'Postal code is required',
  'postal_code_should_have_5_digits': 'Postal code should have 5 digits',
  'state_is_required': 'State is required',
  'address_line_1': 'Address line 1',
  'enter_an_address': 'Enter an address',
  'apartment_unit_suite_or_floor': 'Apartment, unit, suite, or floor #',
  'optional': 'Optional',
  'city': 'City',
  'state': 'State',
  'postal_code': 'Postal code',
  'save': 'Save',
  'changes': 'changes',
  'address': 'address',
  'current_password': 'Current Password',
  'new_password': 'New Password',
  'confirm_new_password': 'Confirm New Password',
  'your_password_has_been_saved': 'Your password has been saved!',
  'password_must_be_at_least_8_characters_long_and_contain_one_character_from_each_of_the_following':
    'Password must be at least 8 characters long and contain one character from each of the following:',
  'uppercase_(a,_z)': 'Uppercase (A, Z)',
  'lowercase_(a,_z)': 'Lowercase (a, z).',
  'numeric_(0-9)': 'Numeric (0-9).',
  'special_(!,_%,_@,_#,_etc.)': 'Special (!, %, @, #, etc.)',
  'both_passwords_need_to_be_the_same': 'Both passwords need to be the same',
  // my plans
  'my_protection_plans': 'My Protection Plans',
  // my claims
  'claims': 'Claims',
  'closed_claims': 'Closed Claims',
  'do_you_wish_to_delete_the_draft': 'Do you wish to delete the draft?',
  'this_action_cannot_be_undone': 'This action cannot be undone.',
  "why_can't_i_file_a_claim": "Why can't I file a claim?",
  'all_your_protection_plans_are_expired':
    'All your protection plans are expired',
  'all_your_plans_are_expired': 'All your plans are expired.',
  'you_can_file_a_new_claim_once_your_current_ones_are_resolved':
    'You can file a new claim once your current ones are resolved.',
  'delete_claim': 'Delete claim',
  'you_have_no_prior_claims_please_click_"file_a_claim"_to_file':
    'You have no prior claims, please click "File a claim" to file!',
  'loading': 'Loading...',
  'choose_a_product_to_file_a_claim': 'Choose a Product to File a Claim',
  'expired': 'Expired',
  'description': 'Description',
  'merchant': 'Merchant',
  'mattress_plan_icon': 'Mattress Plan Icon',
  'outdoor_plan_icon': 'Outdoor Plan Icon',
  'rug_plan_icon': 'Rug Plan Icon',
  'furniture_plan_icon': 'Furniture Plan Icon',
  'view_terms': 'View terms',
  'active_claim': 'Active Claim',
  'coverage_highlights': 'Coverage Highlights',
  'previous_claim': 'Previous Claim',
  'you_can_file_a_claim_once_your_protection_plan_is_active':
    'You can file a claim once your protection plan is active.',
  'please_refer_to': 'Please refer to',
  'terms_and_conditions': 'terms and conditions',
  'for_full_list_of_coverages': 'for full list of coverages.',
  'damage': 'Damage',
  'verified': 'Verified',
  'unverified': 'Unverified',
  'plan_type': 'Plan type',
  'plan_term': 'Plan term',
  'contract': 'Contract',
  'protection_plan_details': 'Protection Plan Details',
  'plan_details': 'Plan Details',
  'customer_service_will_carefully_review_your_claim':
    'Customer service will carefully review your claim.',
  'if_approved_you_will_receive_further_instruction_by_email':
    'If approved, you will receive further instruction by email.',
  'reguard_will_carefully_review_your_claim_and_get_back_to_you_soon':
    'Reguard will carefully review your claim and get back to you soon!',
  'what_to_expect_next': 'What to expect next?',
  'summary': 'Summary',
  'damage_details': 'Damage Details',
  'you_don’t_have_any_open_claims': 'You don’t have any open claims',
  'upload_photos': 'Upload Photos',
  'review_your_submission': 'Review Your Submission',
  'verify_contact_info': 'Verify Contact Info',
  'delete': 'Delete',
  'save_as_draft': 'Save as draft',
  'discard_updates': 'Discard updates',
  'remove': 'Remove',
  'n_of_n': '{{first}} of {{last}}', //i.e 1 of 4
  'date_of_damage_is_required': '*Date of damage is required!',
  'date_of_damage_cannot_be_in_the_future':
    'Date of damage cannot be in the future',
  'location_of_damage_is_required': '*Location of damage is required!',
  'damage_area_is_required': '*Damage area is required!',
  'cause_of_damage_is_required': '*Cause of damage is required!',
  'description_of_damage_is_required': '*Description of damage is required!',
  'current_password_is_required': 'Current password is required',
  'confirm_password_is_required': 'Confirm password is required',
  'claim_photo_is_required': 'Claim photo is required',
  'address_is_required': 'Address is required',
  'password_must_be_at_least_8_characters':
    'Password must be at least 8 characters',
  'new_password_must_be_at_least_8_characters':
    'New password must be at least 8 characters',
  'confirm_password_must_be_at_least_8_characters':
    'Confirm password must be at least 8 characters',
  'temporary_password_must_be_at_least_6_characters':
    'Temporary password must be at least 6 characters',
  'please_upload_a_minimum_of_5_images': 'Please upload a minimum of 5 images',
  'first_name_must_be_at_least_3_characters':
    'First name must be at least 3 characters',
  'first_name_is_required': 'First name is required',
  'last_name_must_be_at_least_3_characters':
    'Last name must be at least 3 characters',
  'last_name_is_required': 'Last name is required',
  'order_number_is_required': 'Order Number is required',
  'phone_number_is_required': 'Phone number is required',
  'is_not_in_correct_format': 'Is not in correct format',
  'first_name': 'First name',
  'last_name': 'Last name',
  'order_number': 'Order number',
  'phone_number': 'Phone number',
  'continue': 'Continue',
  'new_password_is_required': 'New Password is required',
  "your_password_doesn't_meet_the_requirements":
    "Your password doesn't meet the requirements.",
  'please_repeat_the_new_password': 'Please repeat the New Password',
  'please_make_sure_your_passwords_match':
    'Please make sure your passwords match.',
  'temporary_password_is_required': 'Temporary Password is required',
  'photos_uploaded': '{{length}} photos uploaded',
  'account_not_found_error':
    'Unfortunately, we couldn’t find the account you provided on our record. Please double-check your order information or email us at {{email}}',
  'resend_code_in': 'Resend code in {{time}} seconds.',
  'create_new_password': 'Create new password',
  'enter_a_new_password': 'Enter a new password',
  'temporary_password': 'Temporary password',
  'type_your_password': 'Type your password!',
  'repeat_new_password': 'Repeat new password',
  'repeat_your_password_here': 'Repeat your password here!',
  'save_password': 'Save password',
  'enter_your_email_to_get_started': 'Enter your email to get started',
  'to_set_or_reset_your_password_enter_email':
    'To set or reset your password, enter the email you used to buy the plan and we will send you a code.',
  'Make_sure_that_you_are_entering_the_same_email_that_was_used_when_purchasing_your_Reguard_plan':
    'Make sure that you are entering the same email that was used when purchasing your Reguard plan.',
  'unsubscribe_successful': 'Unsubscribe successful',
  'successfully_unsubscribed':
    'Your email has been successfully unsubscribed from this list.',
  'token_must_have_6_digits': 'The token must has 6 digits',
  'token_must_have_only_numbers': 'The token must has only numbers',
  'token_not_valid': 'The token is not valid',
  'want_to_save_draft': 'Do you want to save a draft?',
  'save_your_draft_and_complete_later': 'Save your draft and complete later.',
  'product_name': 'Product Name',
  'purchase_date': 'Purchase Date',
  'next': 'Next',
  'prev': 'Prev',
  'description_of_damage': 'Description of damage',
  'suggested_topics': 'Suggested topics:',
  'what_happened': 'What happened?',
  'how_did_it_happen': 'How did it happen?',
  'attempts_to_fix_clean_damage':
    'Were there any attempts to fix/clean the damage?',
  'product_serial_number': "What is your product's serial #?",
  'describe_damage_to_product': 'Describe the damage to the product',
  'description_of_damage_required': '*Description of damage',
  'product_condition': 'Product Condition',
  'product_condition_required': '*Product condition is required!',
  'photos_of_damage': 'Photos of damage',
  'suggested_photos': 'Suggested photos:',
  'photo_of_product_label': 'Photo of product label',
  'photo_of_item': 'Photo of item',
  'close_up_photo_of_damage': 'A close-up photo of the damage',
  'photo_of_product_serial_number': 'Photo of product serial #',
  'max_photos_reached': 'You tried to upload more than 12 photos',
  'remove_photo_confirmation': 'Do you wish to remove this photo?',
  'uploading': 'Uploading...',
  'done': 'Done',
  'edit_photos': 'Edit photos',
  'photos': 'PHOTOS',
  'add_5_12_pictures': 'Add 5-12 pictures',
  'date_of_damage': 'Date of damage',
  'select': 'Select...',
  'location_of_damage': 'Location of damage',
  'damage_area': 'Damage area',
  'cause_of_damage': 'Cause of damage',
  'your_service_address': 'Your service address',
  'select_service_address':
    'Select which address Reguard should use to service your claim.',
  'please_select_service_address': '*Please select a service address!',
  'your_contact_information': 'Your contact information',
  'contact_phone_number':
    'Reguard may contact this phone number to help service your claim.',
  'please_add_phone_number': 'Please add a phone number',
  'edit_phone_number': '+ Edit phone number',
  'add_telephone_number': '*Please add a telephone number!',
  'what_do_i_need_to': 'What do I need to',
  'an_accident_happened_in_the_last_30_days':
    'An accident happened in the last 30 days.',
  'an_event_happened_in_the_last_30_days':
    'An event happened in the last 30 days.',
  'you_can_upload_photos_of_the_damage': 'You can upload photos of the damage.',
  'submit': 'Submit',
  'submit_all': 'Submit All',
  'save_and_close': 'Save and close',
  "we_couldn't_find_the_page_that_you_requested":
    "We couldn't find the page that you requested.",
  'there_is_an_error': 'There is an error.',
  'development_in_progress': 'Development in progress...',
  'see_less': 'See less',
  'see_more': 'See more',
  'session_expired': 'Session Expired. Please login.',
  'reguards_customer_service_team_will_review_your_claim_information_and_photos_to_determine_the_best_resolution':
    "Reguard's Customer Service team will review your claim information and photos to determine the best resolution.",
  'a_trusted_local_service_provider_will_reach_out_to_schedule_an_on_site_inspection':
    'A trusted local service provider will reach out to schedule an on-site inspection',
  'after_assessing_your_claim_in_person_they_will_decide_how_to_best_fix_the_damage':
    'After assessing your claim in person, they will decide how to best fix the damage.',
  'the_merchant_you_purchased_the_product_from_will_process_your_reimbursement_in_line_with_the_terms_and_conditions_applicable_to_your_state':
    'The merchant you purchased the product from will process your reimbursement in line with the terms and conditions applicable to your state.',
  'the_merchant_you_purchased_your_product_from_will_ship_you_a_new_product_as_soon_as_possible':
    'The merchant you purchased your product from will ship you a new product as soon as possible.',
  'if_your_original_product_is_no_longer_available_you_will_receive_a_store_credit_for_the_same_purchase_price':
    'If your original product is no longer available, you will receive a store credit for the same purchase price.',
  'please_note_once_reguard_fully_replaces_your_item_your_original_protection_plan_will_be_void':
    'Please note: Once Reguard fully replaces your item, your original protection plan will be void.',
  'included_within_the_kit_are_instructions_for_successful_stain_removal':
    'Included within the kit are instructions for successful stain removal.',
  'you_should_also_refer_to_the_directions_on_how_to_best_take_care_of_the_type_of_product_you_own':
    'You should also refer to the directions on how to best take care of the type of product you own.',
  'status': 'Status:',
  'something_went_wrong': 'Something went wrong when uploading photos',
  'submitted_photos': 'Submitted Photos',
  'no_photos_submitted': 'No photos submitted',
  'load_all': 'Load all',
  'see_claim_details': 'See claim details',
  'choose': 'Choose',
  'for_merchants': 'for Merchants',
  'claim_status': 'Claim Status',
  'claim_details': 'Claim Details',
  'contract_id': 'Contract ID',
  'date_of_submission': 'Date of submission',
  'failure_notes': 'Failure Notes',
  'no_photos_added': 'No photos added',
  'verify_information': 'Please verify your information',
  'enter_information':
    'Please enter the information you provided at point-of-purchase so that we can locate your order.',
  'sorry': 'We are sorry!',
  'back': 'Back',
  'code_sent': 'Code sent!',
  'resend_code': 'Resend code',
  'valid_email_format': 'Please insert a valid format: john.doe@example.com',
  'valid_phone_format': 'Please insert a valid format: 999-999-9999',
  'dashboard': 'Dashboard',
  'warranty_plans': 'Warranty plans',
  'reports': 'Reports',
  'view_all_claims': 'View all claims',
  'back_to_verify_contact_info': 'Back to Verify Contact Info',
  'photos_title': 'Photos',
  'verify_email': 'Verify your email address',
  'reviewing_claim': "We're reviewing your claim and will get back to you soon",
  'claim_approved':
    'Congratulations, your claim has been approved! It is now being processed by the Reguard Customer Service team.',
  'in_home_repair':
    'After carefully reviewing your claim, the Reguard Customer Service team has determined in-home repair as the best resolution.',
  'reimbursement':
    'After carefully reviewing your claim, the Reguard Customer Service team has determined reimbursing you for your item(s) as the best resolution.',
  'replacement':
    'After carefully reviewing your claim, the Reguard Customer Service team has determined replacement of your items as the best resolution.',
  'cleaning_kit':
    'After carefully reviewing your claim, the Reguard Customer Service team has determined sending you a cleaning kit as the best resolution.',
  'is_mandatory': 'is mandatory',
  'add_your_phone_number': 'Add your phone number.',
  'add_your_address': 'Add your address.',
  'back_to_chatbot': 'Back to chatbot',
  'learn_more_about_reguard_privacy_policy':
    'Learn more about Reguard’s Privacy Policy',
  'reguard_chat_recording_and_history':
    'Reguard may record the following chat for quality control and training purposes. We also save your chat history and connect it to purchases.',
  'resend_it': 'resend it.',
  'restart_chat': 'Restart chat.',
  'claim_saved_draft': 'Draft saved',
  'claim_discarded_draft': 'Draft discarded',
  'claim_submitted': 'Claim submitted',
  'password_saved': 'Password saved',
  'plan_highlights': 'Plan highlights',
  'coverage': '{{duration}} {{period}} of coverage',
  'categories': 'Categories',
  'settings': 'Settings',
  'user_management': 'User management',
  'protection_plans': 'Protection plans',
  'training_portal': 'Training portal',
  'active_training_portal': 'Active Training Portal',
  'training_profile_not_found': 'Training profile not found',
  'you_need_to_ask_an_admin_to_activate_your_training_account':
    'You need to ask an admin to activate your training account',
  'user_permissions': 'User Permissions',
  'all_users': 'All Users',
  'search_name_email_role_phone_number':
    'Search name, email, role, phone number...',
  'updated_at': 'Updated at',
  'role': 'Role',
  'no_record_found': 'No record found',
  'back_to_home': 'Back to Home',
  'your_password_has_been_successfully_set':
    'Your password has been successfully set.',
  'password_set': 'Password set!',
  //backend/db/src/seeding/data/damageAreas.ts
  'All over': 'All over',
  'Left arm': 'Left arm',
  'Right arm': 'Right arm',
  'Both arms': 'Both arms',
  'Back cushion': 'Back cushion',
  'Bed post': 'Bed post',
  'Console': 'Console',
  'Crossbar / Stretcher': 'Crossbar / Stretcher',
  'Deck area': 'Deck area',
  'Door': 'Door',
  'Drawer': 'Drawer',
  'Electrical part': 'Electrical part',
  'Footboard / Rest': 'Footboard / Rest',
  'Frame': 'Frame',
  'Headboard / Rest': 'Headboard / Rest',
  'Inside back': 'Inside back',
  'Leg': 'Leg',
  'Lock': 'Lock',
  'Mechanism': 'Mechanism',
  'Motor': 'Motor',
  'Outside back': 'Outside back',
  'Pull handle': 'Pull handle',
  'Remote control': 'Remote control',
  'Seat bench': 'Seat bench',
  'Seat cushion': 'Seat cushion',
  'Shelf': 'Shelf',
  'Side rails': 'Side rails',
  'Springs': 'Springs',
  'Throw pillow': 'Throw pillow',
  'Top': 'Top',
  'Wedge': 'Wedge',
  // backend/db/src/seeding/data/damageLocations.ts
  'Transit': 'Transit',
  'Assembly': 'Assembly',
  'Movement between residences': 'Movement between residences',
  'Redelivery': 'Redelivery',
  'Storage or while in storage': 'Storage or while in storage',
  'Delivery': 'Delivery',
  'Normal residential usage': 'Normal residential usage',
  'Unknown': 'Unknown',
  //backend/db/src/seeding/data/protectionPlanTypes.ts
  'Human and pet bodily fluids': 'Human and pet bodily fluids',
  'Food and beverage': 'Food and beverage',
  'Facial cosmetics': 'Facial cosmetics',
  'Nail polish': 'Nail polish',
  'Grease and oil': 'Grease and oil',
  'Paint and dye': 'Paint and dye',
  'Crayon, ink and marker': 'Crayon, ink and marker',
  'Breakage of mechanisms': 'Breakage of mechanisms',
  'Grass and mud': 'Grass and mud',
  'Dye transfer (color from blue jeans or newspaper)':
    'Dye transfer (color from blue jeans or newspaper)',
  'Household heat marks and burns': 'Household heat marks and burns ',
  'Punctures and rips (excluding seams and pet damage)':
    'Punctures and rips (excluding seams and pet damage)',
  'Fading from the sun': 'Fading from the sun',
  'Failure of motors and associated components':
    'Failure of motors and associated components',
  'Checking, cracking, bubbling, peeling of finish, or lifting of veneers (from a specific incident)':
    'Checking, cracking, bubbling, peeling of finish, or lifting of veneers (from a specific incident)',
  'Mirror loss of slivering or chipping':
    'Mirror loss of slivering or chipping',
  'Glass breakage': 'Glass breakage',
  'Scratch or gouge (penetrating through surface)':
    'Scratch or gouge (penetrating through surface)',
  'Sofa': 'Sofa',
  'Futons': 'Futons',
  'Ottomans': 'Ottomans',
  'Living room sets': 'Living room sets',
  'Love seats': 'Love seats',
  'Sectional sofas': 'Sectional sofas',
  'Reclining furniture': 'Reclining furniture',
  'Recliners': 'Recliners',
  'Coffee tables': 'Coffee tables',
  'End & side tables': 'End & side tables',
  'TV stands': 'TV stands',
  'Sofa & loveseat sets': 'Sofa & loveseat sets',
  'Coffee & end table sets': 'Coffee & end table sets',
  'Accent chairs': 'Accent chairs',
  'Rugs': 'Rugs',
  'Water or beverage marks or rings': 'Water or beverage marks or rings',
  'Rips, tears, punctures, burns and singe marks':
    'Rips, tears, punctures, burns and singe marks',
  'Glass chipping, breakage or cracking':
    'Glass chipping, breakage or cracking',
  "Breakage of mechanisms (upon expiration of the manufacturer's warranty), Including parts and labor":
    "Breakage of mechanisms (upon expiration of the manufacturer's warranty), Including parts and labor",
  'Welds and assembled joints': 'Welds and assembled joints',
  'Outdoor sectionals': 'Outdoor sectionals',
  'Outdoor sofas & Loveseats': 'Outdoor sofas & Loveseats',
  'Patio chairs': 'Patio chairs',
  'Lounge chairs': 'Lounge chairs',
  'Outdoor benches': 'Outdoor benches',
  'Adirondack chairs': 'Adirondack chairs',
  'Outdoor rocking chairs': 'Outdoor rocking chairs',
  'Conversation sets': 'Conversation sets',
  'Outdoor seating sets': 'Outdoor seating sets',
  'Outdoor dining sets': 'Outdoor dining sets',
  'Outdoor bar sets': 'Outdoor bar sets',
  'Outdoor bistro sets': 'Outdoor bistro sets',
  'Outdoor dining chairs': 'Outdoor dining chairs',
  'Outdoor dining tables': 'Outdoor dining tables',
  'Motors': 'Motors',
  'Mechanisms (lndlnlng redlnlng, heating and vibrating)':
    'Mechanisms (lndlnlng redlnlng, heating and vibrating)',
  'Electrical components (Including motors and wiring)':
    'Electrical components (Including motors and wiring) ',
  'Controllers': 'Controllers',
  'Power surge': 'Power surge',
  "Parts and labor included (upon expiration of manufacturer's warranty)":
    "Parts and labor included (upon expiration of manufacturer's warranty)",
  'Twin': 'Twin',
  'Twin XL': 'Twin XL',
  'Full': 'Full',
  'Queen': 'Queen',
  'King': 'King',
  'California king': 'California king',
  'Crayon': 'Ink and marker, Crayon, ink and marker.',
  'Crib': 'Crib',
  'Memory foam': 'Memory foam',
  'Mattress in a box': 'Mattress in a box',
  'Innerspring': 'Innerspring',
  'Hybrid': 'Hybrid',
  'Air mattresses & foldable beds': 'Air mattresses & foldable beds',
  'account_number': 'Account Number',
  'product_sku': 'Product SKU',
  'product_vendor': 'Product Vendor',
  'Checking':
    'Cracking, blistering, finish peeling, or veneer separation (due to a specific incident)',
  'product_price': 'Product Price',
  'protection_plan_price': 'Protection Plan Price',
  'delivery_date': 'Delivery Date',
  'region': 'Region',
  'market': 'Market',
  'store_number': 'Store Number',
  'error_message': 'Error Message',
  'creation_date': 'Creation Date',
  'no_results': 'No Results to show',
  'order_submitted': 'Order Submitted!',

  'account_number_required': 'Account Number is required',
  'warranty_sku_required': 'Warranty SKU is required',
  'warranty_amount_required': 'Warranty amount is required',
  'warranty_amount_greater': 'Warranty amount must be greater than 0',
  'sales_date_required': 'Sales date is required',
  'customer_first_name_required': 'Customer first name is required',
  'customer_last_name_required': 'Customer last name is required',
  'customer_email_required': 'Customer email is required',
  'ship_address_required': 'Ship address is required',
  'ship_city_required': 'Ship city is required',
  'ship_state_required': 'Ship state is required',
  'ship_zip_required': 'Ship zip is required',
  'store_number_required': 'Store number is required',
  'item_id_required': 'Item id is required',
  'item_amount_required': 'Item amount is required',
  'product_name_required': 'Product name is required',
  'product_description_required': 'Product description is required',

  'sales_date': 'Sales date',
  'store_location': 'Store location',
  'store_performance': 'Store Performance',
  'warranty_amount': 'Warranty amount',
  'product_description': 'Product description',
  'item_amount': 'Item amount',
  'quantity': 'Quantity',
  'customer_information': 'Customer information',
  'customer_last_name': 'Customer last name',
  'customer_first_name': 'Customer first name',
  'customer_email': 'Customer email',
  'ship_address': 'Ship address',
  'ship_city': 'Ship city',
  'ship_zip': 'Ship zip',
  'maximum_number_of_photos_reached': 'Maximum number of photos reached',
  'add_more_photos': 'Add more photos',
  'if_you_have_it_please_provide_a_photo_or_copy_of_your_receipt_from_the_merchant':
    'If you have it, please provide a photo or copy of your receipt from the merchant.',
  'your_receipt': 'Your receipt',
  'full_receipt_clear_captured': 'Full receipt, clear captured',
  'file_uppercase': 'FILE',
  'drop_the_file_here': 'Drop the file here',
  'supported_format_doc_jpg_png_pdf': 'Supported format: Doc, JPG, PNG, PDF',
  'plan': 'Protection Plan',
  'anomalies': 'Anomalies',
  'create_order': 'Create Order',
  'plans_quantity': 'Plans quantity',
  'Glass chipping': 'Breaking or cracking, chipping or shattering of glass',
  'INVALID_CODE': 'Invalid verification code',
  'INVALID_CURRENT_PASSWORD':
    'Something went wrong while changing the password: Invalid password',
  'INVALID_DAMAGE_DATE': 'The damage date cannot be in the future',
  'INVALID_DAMAGE_DESCRIPTION':
    'The damage description must contain at least 25 characters',
  'INVALID_EMAIL': 'Please enter your email in the correct format',
  'INVALID_NAME': 'The name must have at least 3 characters',
  'INVALID_NEW_PASSWORD':
    'Your password must be at least 8 characters long and contain one character from each of the following categories: 1. Uppercase (A-Z) 2. Lowercase (a-z) 3. Number (0-9) 4. Special (!, %, @, #, etc.)',
  'please_note_these_are_all_the_products_potentially_covered_by_your_reguard_plan':
    'Please note these are all the products potentially covered by your Reguard plan.',
  'INVALID_PHONE': 'Please enter the phone number in the correct format',
  'INVALID_TEMPORARY_PASSWORD': 'Please enter a valid temporary password',
  'INVALID_ZIP': 'Please enter a valid zip code',
  'LEADING_SPACES_ADDRESS': 'Leading spaces are not allowed',
  'LEADING_SPACES_CITY': 'Leading spaces are not allowed',
  'MISSING_EMAIL':
    'Please enter the same email you used at the time of purchase',
  'PASSWORDS_MISMATCH': 'Please make sure your passwords match',
  'T00_FEW_IMAGES': 'Please upload a minimum of 5 images',
  'T00_MANY_IMAGES': 'You attempted to upload more than 12 images',
  'TOO_FEW_ZIP': 'The zip code must contain 5 digits',
  'TOO_MANY_REQUESTS': 'oo many attempts for this user',
  'WRONG_LOGIN': 'Your email or password is incorrect, please try again',
  'ZIP_STATE_MISMATCH': 'The zip code does not correspond to the state',
  'filter': 'Filter',
  'download_as_csv': 'Download as CSV',
  'gross_revenue': 'Gross Revenue',
  'holding': 'holding',
  'of_plans_sold': 'total # of plans sold',
  'are_you_sure_delete_contracts':
    'Are you sure you would like to delete these contracts?',
  'contract_anomaly_deletion_status_success': 'Contracts successfully removed!',
  'contract_anomaly_deletion_status_error': 'Error deleting contracts!',
  'error_while_filtering_merchants': 'Error while filtering merchants!',
  'sales': 'Sales',
  'sales_sub_header': 'By merchant over time',
  'fpp_sales': 'FPP Sales',
  'eligible_orders': 'Eligible FPP Orders (#)',
  'total_sales': 'Total Sales',
  'merchant_total_sales': 'Merchant Total Sales',
  'attachment_rate': 'FPP Attachment Rate',
  'attachment_rate_description': '(% of Eligible Orders)',
  'existing_claim': 'Existing Claim',
  'cancellation_claim': 'Cancellation Claim',
  'other': 'Other',
  'new_claim': 'New Claim',
  'total_calls': 'Total Calls',
  'total_emails': 'Total Emails',
  'handle_time': 'Avg Handle Time',
  'speed_of_answer': 'Avg Speed of Answer',
  'emails': 'emails',
  'avg_answer_speed': 'Avg Answer Speed',
  'avg_call_duration': 'Avg Call Duration',
  'active_claims': 'Active Claims',
  'acceptance_ratio': 'Acceptance Ratio',
  'oem_handoff': 'OEM Hand-Offs',
  'claims_severity': 'Avg Claim Severity',
  'claims_filed_online': 'Claims Filed Online',
  'interaction_reasons': 'Interaction Reasons',
  'error_filtering_by_merchant': 'Error filtering report by merchant',
  'error_filtering_report_by_date': 'Error filtering report by date.',
  'error_retrieving_report_data': 'Error retrieving report data',
  'units_sold': 'Units Sold',
  'reguard_gross_revenue': 'Reguard Written Revenue',
  'ashley_fpp_sales': 'Ashley FPP Sales',
  'sign_in_to_account': 'Sign in to your account',
  'email_address': 'Email Address',
  'not_valid_email_address': 'Not a valid email address.',
  'forgot_pass': 'Forgot Password?',
  'not_valid_password': 'Not a valid password.',
  'select_a_tab': 'Select a tab',
  'click_an_edit_button': 'Click an EDIT button to start making changes.',
  'results': 'results',
  'if_matching_account':
    'If a matching account was found an email was sent to <1>{{checkEmail}}</1> to allow you to reset your password.',
  'servicing': 'Servicing',
  'combined_report': 'Attachment Rate by Region / Channel / Market',
  'prior_week': 'Prior Week',
  'prior_month': 'Prior Month',
  'fpp_sales_per_week': 'Sales per Week (FPP Units Sold)',
  'cancellation': 'Cancellation',
  'claim_inquiry': 'Claim Inquiry',
  'furniture_purchased_prior_to_support':
    'Furniture Purchased Prior to Support',
  'resolution_type': 'Resolution Type',
  'approved_resolutions': 'Approved Paid Claims Resolution',
  'cleaning_kits': 'Cleaning Kits',
  'not_eligible_for_coverage': 'Not Eligible for Coverage',
  'repair': 'Repair',
  'repair_with_extra_parts': 'Repair with Extra Parts',
  'full_replacement': 'Full Replacement',
  'Ashley Service Technicians, OEM and Reimbursements': 'Ashley',
  'executive_overview': 'Executive Overview',
  'executive_overview_description': 'Sales, Customer Service, and Claims',
  'executive_performance': 'Executive Performance',
  'executive_performance_description': 'Reguard Sales Performance Over Time',
  'sales_description': 'Evaluate sales at any level of the organization',
  'no_results_found': 'No Results Found',
  'accounts': 'Search for and Manage User Accounts',
  'dashboards_description':
    'View metrics and kpi at every level of the Reguard platform',
  'management_description':
    'Perform the tasks necessary to maintain Reguard accounts and contracts',
  'crunching_numbers_title': 'Crunching the Numbers',
  'crunching_numbers_description': 'Come back soon for up to date numbers',
  'KINGSWERE FURNITURE LLC': 'Kingswere',
  'ROCKLEDGE FURNITURE LLC': 'Rockledge',
  'SOUTHWEST FURN OF WI LLC': 'Southwest',
  'STONELEDGE FURNITURE, LLC': 'Stoneledge',
  'reset_your_password': 'Reset your password',
  'enter_your_email_address': 'Enter your email address',
  'page_not_found': 'Page not found',
  'page_not_found_subtitle':
    'Sorry, we couldn’t find the page you’re looking for.',
  'go_back_home': 'Go back Home',
  'customer_address_information': 'Customer Address Information',
  'address_not_available': 'Address Not Available',
  'phone_number_saved': 'Your phone number has been saved',
  'please_enter_primary_phone': 'Please enter your primary phone number',
  'ipa_orders': 'IPA - Anomaly Management',
  'ipa_order_details': 'IPA Order Details',
  'ipa_add_order': 'IPA Add Order',
  'products_insert': 'products insert',
  'import_contract': 'import contract',
  'cancel_search': 'cancel search',
  'addresses_insert': 'addresses insert',
  'duplicate_search': 'duplicate search',
  'check_pcmi_category': 'check pcmi category',
  'ingested': 'ingested',
  'address_validation': 'address validation',
  'manually_updated': 'manually updated',
  'first_occurrence': 'First Occurrence',
  'latest_occurrence': 'Latest Occurrence',
  'customer': 'Customer',
  'store': 'Store',
  'current_step': 'Current Step',
  'item': 'Item',
  'sku': 'Sku',
  'amount': 'Amount',
  'add_manual_occurrence': 'Add Manual Occurrence',
  'merchant_sales': 'Merchant Sales',
  'performance_overview_by_protection_plan_and_location':
    'Performance Overview by Protection Plan and Location',
  'gross_revenue_stat': 'Gross Revenue',
  'store_id': 'Store ID',
  'location': 'Location',
  'by_location_total_sales': 'Total Sales',
  'units_sold_#': 'Units Sold #',
  'protection_plan': 'Protection Plan',
  'protection_plans_overview': 'Protection Plans Overview',
  'pp_total_sales': 'Total Sales',
  '%_of_total': '% of Total',
  'by_quantity': 'By Quantity',
  'by_sales': 'By Sales',
  'top_performer': 'Top Performer',
  'top_individual_performer': 'Top Individual Performer',
  'bottom_performer': 'Bottom Performer',
  'percent_sales': '% Sales',
  't_total_sales': 'Total Sales',
  'total': 'Total',
  'warranty_total': 'Warranty Total',
  'item_total': 'Item Total',
  'activity': 'Activity',
  'no_data_available': 'No Data Available',
  'add_a_comment': 'Add a comment',
  'comment': 'Comment',
  'ipa': 'Processor',
  'SUCCESS': 'success on',
  'FAILED': 'failed on',
  'CREATED': 'created order',
  'no_status': 'No Status',
  'ago': 'ago',
  'manual_update': 'manual update',
  'commented:': 'commented:',
  'sales_by_location': 'Sales by Location',
  'go': 'GO!',
  'merchant_sales_description': 'Metrics and KPI for merchant orders',
  'qty': 'Qty.',
  'warranty_sku': 'Warranty SKU',
  'add_item': 'Add Item',
  'sale_date': 'Sale Date',
  'activity_not_found': 'Activity Not Found',
  'complete': 'Complete',
  'activate_orders': 'Activate Orders',
  'selected_orders': 'Selected Orders',
  'close_panel': 'Close Panel',
  'download_orders': 'Download Orders',
  'selected_orders_by_integration': 'Selected Orders by Integration',
  'of': 'of',
  'my_policies': 'My Policies',
  'my_policy': 'My Policy',
  'lost_package': 'Lost Package',
  'pending_policies': 'Pending Policies',
  'active_policies': 'Active Policies',
  'expired_policies': 'Expired Policies',
  'shipping_status': 'Shipping Status',
  'tracking': 'Tracking',
  'in_transit': 'In Transit',
  'delivered': 'Delivered',
  'policy_status': 'Policy Status',
  'order': 'Order',
  'processing': 'Processing',
  'switch_to': 'Switch to',
  'too_many_requests': 'Too many requests for this user!',
  'please_wait_30_minutes': 'Please wait 30 minutes before trying again.',
  'policy_details': 'Policy Details',
  'please_note_a_policy':
    'Please note a policy is activated either after delivery or on notice of loss.',
  'expires_in': 'Expires in',
  'certificate_of_insurance': 'Certificate of Insurance',
  'coverage_details': 'Coverage Details',
  'days': 'Days',
  'hours': 'Hours',
  'not_yet_assigned': 'Not Yet Assigned',
  'event_details': 'Event Details',
  'date_of_incident': 'Date of Incident',
  'loss_type': 'Loss Type',
  'sheets': {
    overview: 'Overview',
    performance_outliers: 'Performance Outliers',
    protection_plans: 'Protection Plans',
    sales_by_location: 'Sales by Location',
    filenames: {
      all_reports: 'All Reports',
      top_performer: 'Top Performer',
      bottom_performer: 'Bottom Performer',
      protection_plans: 'Protection Plans',
      sales_by_location: 'Sales by Location',
    },
    headers: {
      name: 'Name',
      amount: 'Amount',
      last: 'Last',
      prev: 'Previous',
      change: 'Change',
      percent: 'Percentage',
      group: 'Group',
      value: 'Value',
      location: 'Location',
      salesLocation: 'Sales Location',
      protection_plan: 'Protection Plan',
      protection_plan_id: 'Plan ID',
      quantity: '# of Plans Sold',
      wholesale_value: 'Wholesale Value',
      total_sales_ratio: '% of Plans Sold',
    },
  },
  'attachment_rate_stat': 'Attachment Rate',
  'cancellation_rate_stat': 'Cancellation Rate',
  'balance_of_share_stat': 'Balance of Share',
  'written_sales_stat': 'Written Sales',
  'written_revenue_stat': 'Written Revenue',
  'invoiced_sales_stat': 'Invoiced Sales',
  'invoiced_revenue_stat': 'Invoiced Revenue',
  'merchant_plan_dashboard': 'Merchant Plan Dashboard',
  'receipt': 'Receipt',
  'cancelled_header': 'Cancelled',
  'attach_header': 'Attach',
  'bos_header': 'BoS',
  'sales_summary': 'Sales Summary',
  'rsa_sales_summary': 'RSA Sales Summary',
  'sales_summary_by_plan': 'Sales Summary by Plan',
  'mark_as_delivered': 'Mark as Delivered',
  'select_the_delivery_date': 'Select the Delivery Date',
  'pending_activation': 'Pending Activation',
  'contact_support': 'Contact Support',
  'get_in_touch': 'Get in Touch',
  'contact_support_text': 'Please contact us regarding order #',
  'phone_label': 'Phone',
};
