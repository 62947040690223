import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { buttonSelectors } from '../tests/dataCySelectors';
import { MainPage, MainSection } from '../ui/components';
import ProfileUserInfo from './ProfileUserInfo';
import ProfileUserPassword from './ProfileUserPassword';

interface TabButtonProps {
  children: string;
  active: boolean;
  handleClick: () => void;
}

interface TabMenuProps {
  setActive: (index: number) => void;
  active: number;
}

const TabButton: FC<TabButtonProps> = ({ children, active, handleClick }) => (
  <button
    onClick={handleClick}
    className={`${
      active ? 'bg-reguard-pearl' : ''
    } flex h-[4.625rem] items-center md:pl-6 justify-center md:justify-start text-reguard-indigo rounded flex-1 md:flex-initial`}
  >
    <span className="s2 semibold">{children}</span>
  </button>
);

const TabMenu: FC<TabMenuProps> = ({ setActive, active }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 flex-row md:flex-col md:w-full">
      <TabButton
        handleClick={() => setActive(0)}
        active={active === 0}
        data-cy={buttonSelectors.profileBtn}
      >
        {t('profile')}
      </TabButton>
      <TabButton
        handleClick={() => setActive(1)}
        active={active === 1}
        data-cy={buttonSelectors.passwordBtn}
      >
        {t('password')}
      </TabButton>
    </div>
  );
};

export const Profile = () => {
  const [active, setActive] = useState(0);
  const { t } = useTranslation();
  return (
    <MainPage>
      <MainSection title={t('my_account') as string}>
        <div className="flex gap-6 md:gap-16 flex-wrap">
          <div className="flex flex-1 md:flex-grow-0 basis-[21.375rem]">
            <TabMenu setActive={setActive} active={active} />
          </div>
          <div className="w-full md:max-w-[37.5rem] sm:px-12 md:px-0">
            <div className="s1 text-reguard-indigo mb-8">
              {active === 0 ? t('profile') : t('password')}
            </div>
            {active === 0 ? <ProfileUserInfo /> : <ProfileUserPassword />}
          </div>
        </div>
      </MainSection>
    </MainPage>
  );
};
