import React from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneIcon } from '@cortex/myaccount/views/home/components/phone-icon/phone-icon';

import { Button } from '../../../../ui/components/Button';
import { buttonSelectors } from '../../../../ui/components/tests/dataCySelectors';
import { ContactSupportCard } from '../../components/contact-support-card/contact-support-card';
import { DocumentsFileCopyIcon } from '../../components/documents-file-copy-icon/documents-file-copy-icon';

interface Props {
  handleClose: () => void;
  orderNumber?: string;
}

const handleCopy = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const ContactSupportModalContent = ({
  handleClose,
  orderNumber,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col items-center justify-between `}>
      <div className="h3 text-reguard-indigo text-center mb-3 sm:mb-0 pt-4 sm:pt-[6.75rem]">
        {t('get_in_touch')}
      </div>

      <div className="s4 md:s3 text-reguard-indigo text-center sm:mb-0  sm:pt-[1.85rem]  leading-loose md:leading-normal">
        {t('contact_support_text')} <span className="s4b">{orderNumber}</span>
      </div>

      <ContactSupportCard
        title={t('email')}
        subtitle=" customerservice@reguardprotection.com"
        action={
          <button
            onClick={() => handleCopy('customerservice@reguardprotection.com')}
            data-testid="copy-email-button"
          >
            <DocumentsFileCopyIcon />
          </button>
        }
      />

      <ContactSupportCard
        title={t('phone_label')}
        subtitle="(877) 348-2730"
        action={
          <a href="tel:+18773482730">
            <PhoneIcon />
          </a>
        }
      />

      <div className="flex flex-row justify-center py-10 w-full">
        <Button
          type="submit"
          className="mb-5 sm:static bottom-5 !max-w-[17.938rem]"
          onClick={handleClose}
          data-cy={buttonSelectors.closeBtn}
          data-testid="closeBtn"
        >
          {t('close')}
        </Button>
      </div>
    </div>
  );
};
