export const phoneMask = (rawPhoneNumber: string) => {
  const matchedValues = rawPhoneNumber
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

  if (matchedValues) {
    const maskedValue = !matchedValues[2]
      ? matchedValues[1]
      : `${matchedValues[1]}-${matchedValues[2]}${
          matchedValues[3] ? `-${matchedValues[3]}` : ''
        }`;

    return maskedValue;
  }

  return matchedValues;
};
