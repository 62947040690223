import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomerClaimStatus } from '../../../../common/constants/claims';
import { CardsGrid, MainSection, PlanCard } from '../../../../ui/components';
import useMatchBreakpoint from '../../../../views/util/useMatchBreakpoint';

type Props = {
  purchases: any[];
};

export const ExpiredPlansSection = ({ purchases }: Props) => {
  const isDesktop = useMatchBreakpoint('64rem');
  const navigate = useNavigate();

  const goToClaimDetails = (
    claimId: string | undefined,
    claimStatus: CustomerClaimStatus | undefined,
  ) => {
    if (claimStatus && claimStatus === CustomerClaimStatus.Draft) {
      navigate(`/claims/new?draftClaimId=${claimId}`);
    } else {
      navigate(`/claims/${claimId}`);
    }
  };

  return (
    purchases.length > 0 && (
      <MainSection
        className="flex bg-transparent pt-8 md:pt-0"
        title="Past Protection Plans"
      >
        <CardsGrid className="mb-20">
          {purchases.map((data: any) => (
            <PlanCard
              isMobile={!isDesktop}
              goToClaimDetails={goToClaimDetails}
              goToFileAClaim={() =>
                navigate(
                  `/claims/new?productPurchaseId=${data.productPurchaseId}`,
                )
              }
              goToPlanDetail={() =>
                navigate(`/protection-plan/${data.productPurchaseId}`)
              }
              isExpired={true}
              key={data.productPurchaseId}
              status={data.contract.status}
              {...data}
            />
          ))}
        </CardsGrid>
      </MainSection>
    )
  );
};
