import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  buttonSelectors,
  mainPageSelectors,
} from '../../../..//tests/dataCySelectors';
import { CustomerClaimStatus } from '../../../../common/constants/claims';
import {
  Button,
  CardsGrid,
  MainSection,
  PlanCard,
} from '../../../../ui/components';
import useMatchBreakpoint from '../../../../views/util/useMatchBreakpoint';

type Props = {
  purchases: any[];
  showFileClaimButton: boolean;
  isFetching: boolean;
};
export const ActivePlans = ({
  purchases,
  showFileClaimButton,
  isFetching,
}: Props) => {
  const isDesktop = useMatchBreakpoint('64rem');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToClaimDetails = (
    claimId: string | undefined,
    claimStatus: CustomerClaimStatus | undefined,
  ) => {
    if (claimStatus && claimStatus === CustomerClaimStatus.Draft) {
      navigate(`/claims/new?draftClaimId=${claimId}`);
    }
    navigate(`/claims/${claimId}`);
  };

  return (
    purchases.length > 0 && (
      <MainSection
        className="bg-reguard-pearl-tint mt-4 md:bg-transparent"
        data-cy={mainPageSelectors.activePlansText}
        title={t('active_protection_plans') as string}
        {...(showFileClaimButton
          ? {
              subtitle: t(
                'please_note_these_are_all_the_products_potentially_covered_by_your_reguard_plan',
              ) as string,
              renderRight: (
                <div className="hidden md:flex">
                  <Button
                    isfetching={isFetching}
                    data-cy={buttonSelectors.fileClaimBtn}
                    onClick={() => navigate('/plans/products')}
                    className="relative w-48"
                    disabled={false}
                  >
                    {t('file_a_claim')}
                  </Button>
                </div>
              ),
            }
          : {})}
      >
        <CardsGrid className="mb-20">
          {purchases.map((data: any) => (
            <PlanCard
              isMobile={!isDesktop}
              goToClaimDetails={goToClaimDetails}
              goToFileAClaim={() =>
                navigate(
                  `/claims/new?productPurchaseId=${data.productPurchaseId}`,
                )
              }
              goToPlanDetail={() =>
                navigate(`/protection-plan/${data.productPurchaseId}`)
              }
              daysUntilExpiration={data.daysUntilExpiration}
              isExpired={data.isExpired}
              key={data.productPurchaseId}
              status={data.contract.status}
              {...data}
            />
          ))}
        </CardsGrid>
      </MainSection>
    )
  );
};
