import { AxiosError } from 'axios';

export class SourceError extends Error {
  data: unknown;
  status: number;

  constructor(status: number, message: string, data: unknown) {
    super(message);

    this.status = status;
    this.data = data;

    Object.setPrototypeOf(this, SourceError.prototype);
  }

  public static fromAxiosError(error: AxiosError) {
    return new SourceError(
      error.response?.status || 500,
      error.message,
      error.response?.data,
    );
  }
}

export interface ReportDataSubmissionType {
  name?: string;
  category: string;
  date: {
    start: string;
    end: string;
  };
  comparative_date?: { start: string | null; end: string | null };
  group_by?: string;
  interval?: string | null;
}

export interface FilteredType {
  merchants?: Array<string>;
  region?: string | null;
}

export interface ReportDataSubmissionFilteredType
  extends ReportDataSubmissionType {
  filters: FilteredType;
}

export interface RangeAsDateType extends Range {
  interval?: string;
}

export interface DateRangeAsDateType {
  [key: string]: RangeAsDateType;
}

export type OptionalString = string | null;

export interface PaginationResponse<T> {
  current_page: number;
  total_pages: number;
  total: number;
  size: number;
  content: T[];
}
