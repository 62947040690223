import { useState } from 'react';
import { usePurchasesSearch } from '@cortex/myaccount/network/api/purchases';
import {
  getIngestableOrderById,
  IngestableOrder,
  OccurrenceData,
  patchProcessingStatusReq,
  PostActivityComment,
  postIngestableOrder,
  ProcessingStatus,
} from '@cortex/requests';
import { notify } from '@cortex/shared';

export function useDeliveryUpdate() {
  const [contractID, setContractID] = useState<string | null>(null);
  const [productPurchaseId, setProductPurchaseId] = useState<string>('');
  const [productSku, setProductSku] = useState<string>('');
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<Date>(
    new Date(),
  );

  const { refetch } = usePurchasesSearch();

  const setData = (
    contractId: string,
    productPurchaseId: string,
    productSku: string,
  ) => {
    setContractID(contractId);
    setProductPurchaseId(productPurchaseId);
    setProductSku(productSku);
  };

  const fetchOrderById = async (id: string): Promise<IngestableOrder> => {
    try {
      return await getIngestableOrderById(id);
    } catch (error) {
      notify({
        title: `Failed to fetch order by ID ${id}: ${error}`,
        options: {
          type: 'error',
        },
      });
      throw new Error(`Failed to fetch order by ID ${id}: ${error}`);
    }
  };

  const updateOrderDeliveryDate = (order: IngestableOrder) => {
    return {
      ...order,
      line_items: order.line_items.map(item =>
        item.sku === productSku
          ? { ...item, delivery_date: selectedDeliveryDate.toISOString() }
          : item,
      ),
    };
  };

  const buildPayload = async (
    order: IngestableOrder,
  ): Promise<OccurrenceData> => {
    const comment: PostActivityComment = {
      meta: {
        user_email: order.customer.email,
        user_id: '',
        user_name: '',
        comment: '',
      },
      order_number: order.order_number,
      purchase_id: productPurchaseId,
      event_description: 'Delivery Date',
      merchant_id: order.merchant_id,
      source: 'myaccount',
    };
    const manual_occurrence = order;
    return {
      manual_occurrence,
      comment,
    };
  };

  const updateStatus = async (onSuccess: () => void) => {
    try {
      await patchProcessingStatusReq(
        { contractID },
        { processing_status: ProcessingStatus.PROCESSING },
      );

      const order = await fetchOrderById(productPurchaseId);

      const updatedOrder = updateOrderDeliveryDate(order);

      const payload = await buildPayload(updatedOrder);

      await postIngestableOrder(payload);

      await refetch();
      onSuccess();
    } catch (error) {
      notify({
        title: `Error updating delivery date. ${error}`,
        options: {
          type: 'error',
        },
      });
    }
  };

  return { updateStatus, setData, setSelectedDeliveryDate };
}
