import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatDateAsFullLengthWords } from '@cortex/myaccount/common/utils/usa-dates';
import CubeCheck from '@cortex/myaccount/ui/components/assets/CubeCheck.svg';

import { usePoliciesContext } from '../../context/policies-context';
import { getPurchaseById } from '../../utils/utils';

export function PolicyDetailsSideColumn() {
  const { t } = useTranslation();
  const { policyId } = useParams();
  const { purchases } = usePoliciesContext();
  const purchase = getPurchaseById(policyId, purchases);

  return (
    <div className="text-center lg:text-left mx-0 lg:mx-auto border-r">
      <div>
        <div className="w-32 lg:w-40 h-20 lg:h-40 rounded-md mx-auto flex">
          <div className="w-32 lg:w-40 mb-10">
            <CubeCheck />
          </div>
        </div>

        <div className="my-16 lg:my-0 lg:px-16">
          <div className="lg:my-2">
            <p className="text-sm capitalize">{t('order_number')}</p>
            <p className="font-extrabold text-lg">{purchase?.orderNumber}</p>
          </div>

          <div className="my-2">
            <p className="text-sm">{t('merchant')}</p>
            <p className="font-extrabold text-lg">{purchase?.merchant.name}</p>
          </div>

          <div className="my-2">
            <p className="text-sm">{t('purchase_date')}</p>
            <p className="font-extrabold text-lg">
              {formatDateAsFullLengthWords(purchase?.purchaseDate)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
