import type { FC, ReactNode } from 'react';

interface TooltipProps {
  children?: ReactNode;
  content?: ReactNode;
  className?: string;
  tooltipActive: boolean;
}

// TODO: Apply this component to the FileClaimBar
export const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  className = '',
  tooltipActive,
}) => (
  <div className={`relative group h-fit ${className}`}>
    {children}
    {tooltipActive && (
      <div className="hidden group-hover:md:flex md:hidden relative opacity-100 group-hover:opacity-100 flex-col items-end transition-all duration-400">
        <div className="absolute flex items-end flex-col">
          <div className="small-triangle-top-violet mr-8"></div>
          <div className="bg-reguard-violet w-72 text-white py-2 px-4 rounded text-base">
            {content}
          </div>
        </div>
      </div>
    )}
  </div>
);
