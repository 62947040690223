import React from 'react';

import { CustomerClaimStatus } from '../../../../common/main';
import {
  CardsGrid,
  Claim,
  ClaimCard,
  MainSection,
} from '../../../../ui/components';
import useMatchBreakpoint from '../../../../views/util/useMatchBreakpoint';

type Props = {
  claims: Claim[] | undefined;
  viewAllClaims: boolean;
  setViewAllClaims: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: (path: string) => void;
  t: (key: string) => string;
};

export const Claims = ({
  claims,
  viewAllClaims,
  setViewAllClaims,
  navigate,
  t,
}: Props) => {
  const openClaims = claims?.filter(
    (c: Claim) =>
      c.status !== CustomerClaimStatus.Closed &&
      c.status !== CustomerClaimStatus.Draft,
  );
  const hasClaims = openClaims && openClaims.length > 0;
  const filteredOpenClaims = viewAllClaims
    ? openClaims
    : openClaims?.slice(0, 2);
  const showViewAllClaimsButton =
    !viewAllClaims && (openClaims?.length ?? 0) > 2;
  const isDesktop = useMatchBreakpoint('64rem');

  return (
    <MainSection
      title={hasClaims ? (t('open_claims') as string) : ''}
      className="bg-reguard-pearl-shade bg-opacity-25 rounded-smd sm:bg-transparent"
    >
      {hasClaims ? (
        <CardsGrid className="mb-4 sm:mb-8">
          {filteredOpenClaims?.map((c: Claim) => (
            <ClaimCard
              isMobile={!isDesktop}
              claim={c}
              key={c.id}
              onDetails={() => navigate(`/claims/${c.id}`)}
            />
          ))}
        </CardsGrid>
      ) : (
        <div className="md:hidden flex flex-col items-center sm:items-start">
          <div className="w-full">
            <p className="b1 pt-4 pb-4 sm:pb-8 sm:pt-0 font-normal">
              {t('you_don’t_have_any_open_claims')}
            </p>
          </div>
        </div>
      )}
      {showViewAllClaimsButton && (
        <MainSection>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span
            onClick={() => setViewAllClaims(true)}
            className="b1b pt-4 cursor-pointer flex justify-end"
          >
            {t('view_all_claims')}
          </span>
        </MainSection>
      )}
    </MainSection>
  );
};
