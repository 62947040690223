import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useUpdateUserContractAddress,
  useUpdateUserContractPhone,
} from '@cortex/myaccount/network/api/users';
import { ProgressBar } from '@cortex/myaccount/ui/components/ProgressBar';

import { PlanDetailsBox } from '../../../../ui/components';
import { usePoliciesContext } from '../../context/policies-context';
import { getPurchaseById } from '../../utils/utils';

export function PolicyDetailsStatus() {
  const { t } = useTranslation();
  const { policyId } = useParams();
  const { purchases } = usePoliciesContext();
  const { mutateAsync: updateContractAddress } = useUpdateUserContractAddress();
  const { mutateAsync: updateContractPhoneNumber } =
    useUpdateUserContractPhone();

  const purchase = getPurchaseById(policyId, purchases);

  return (
    <div className="text-center md:text-left mx-auto">
      <div>
        <p className="text-[24px] lg:text-[28px] my-2">{t('policy_details')}</p>
        <p className="text-md">{t('please_note_a_policy')}</p>

        <div className="border-b-[1px] border-t-[1px] border-solid my-10 h-28">
          <p className="relative top-6">
            <span className="font-extrabold">{t('shipping_status')}:</span>{' '}
            {t(purchase?.contract?.shipping_status)}
          </p>
          <ProgressBar step={4} />
        </div>
      </div>

      <PlanDetailsBox
        contract={purchase?.contract}
        plan={{
          ...purchase?.protectionPlan,
          endDate: purchase?.contract.endDate,
          startDate: purchase?.contract.startDate,
          status: purchase?.contract.status,
        }}
        updateContractAddress={updateContractAddress}
        updateContractPhone={updateContractPhoneNumber}
      />
    </div>
  );
}
