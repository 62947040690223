import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { Product } from './models/Product';
import type { Purchase } from './models/Purchase';

interface ProductInfoProps {
  product: Product;
  purchase: Purchase;
}

export const ProductInfo: FC<ProductInfoProps> = (props: ProductInfoProps) => {
  const {
    product: { name, description, merchant, sku },
    purchase: { purchaseDate },
  } = props;
  const pDate = new Date(purchaseDate);
  const { t } = useTranslation();
  const formattedPurchaseDate = () => {
    const month = pDate.toLocaleDateString('en-US', { month: 'long' });
    return `${month} ${pDate.getDate()}, ${pDate.getFullYear()}`;
  };
  return (
    <>
      <div className="md:hidden text-xl text-reguard-indigo md:px-4 py-2">
        {name}
      </div>
      <div className="flex justify-between md:gap-4 md:flex-col py-2 md:px-0 md:py-4">
        <div className="hidden md:flex flex-col">
          <div className="b3b text-reguard-indigo-tint">
            {t('product_name')}
          </div>
          <div className="s3b text-reguard-indigo">{name}</div>
        </div>

        <div className="hidden md:flex flex-col w-1/2 md:w-auto max-w-[17.938rem] gap-1">
          <div className="b3b text-reguard-indigo-tint">{t('description')}</div>
          <div className="b2b text-reguard-indigo">{description}</div>
        </div>

        <div className="hidden md:flex flex-col gap-1">
          <div className="b3b text-reguard-indigo-tint">SKU</div>
          <div className="b2b text-reguard-indigo">{sku}</div>
        </div>

        <div className="flex md:flex flex-col gap-1">
          <div className="b3b text-reguard-indigo-tint">{t('merchant')}</div>
          <div className="b2b text-reguard-indigo">{merchant?.name}</div>
        </div>

        <div className="flex flex-col w-1/2 md:w-auto gap-1">
          <div className="b3b text-reguard-indigo-tint">
            {t('purchase_date')}
          </div>
          <div className="b2b text-reguard-indigo">
            {formattedPurchaseDate()}
          </div>
        </div>
      </div>
    </>
  );
};
