import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@cortex/myaccount/ui/components/Button';

import Calendar from '../../../../assets/FacCalendarIcon.svg';
import Camera from '../../../../assets/FacCameraIcon.svg';
import Receipt from '../../../../assets/Receipt.svg';

interface FileClaimModalProps {
  handleClose: () => void;
}

export const FileClaimModalContent: FC<FileClaimModalProps> = ({
  handleClose,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col items-center justify-between `}>
      <div className="s1 text-reguard-indigo semibold text-center pt-4 sm:pt-[2rem] mb-8">
        {`${t('what_do_i_need_to')} ${t('file_a_claim')}?`}
      </div>

      <div className="flex flex-row justify-center mb-5 w-10 h-10">
        <Calendar />
      </div>

      <div className=" text-reguard-indigo text-center text-2xl semibold w-[17.938rem]  mb-8">
        {t('an_event_happened_in_the_last_30_days')}
      </div>

      <div className="flex flex-row justify-center mb-5 w-10 h-10">
        <Camera />
      </div>
      <div className=" text-reguard-indigo text-center  text-2xl semibold w-[17.938rem] mb-8">
        {t('you_can_upload_photos_of_the_damage')}
      </div>

      <div className="flex flex-row justify-center mb-5 w-10 h-10">
        <Receipt />
      </div>
      <div className=" text-reguard-indigo text-center  text-2xl semibold w-[17.938rem] mb-8">
        {t(
          'if_you_have_it_please_provide_a_photo_or_copy_of_your_receipt_from_the_merchant',
        )}
      </div>

      <Button
        type="submit"
        className="mb-5 absolute sm:static bottom-5 !max-w-[17.938rem]"
        onClick={handleClose}
        data-testid="continueBtn"
      >
        {t('continue')}
      </Button>
    </div>
  );
};
