import { ChangeEvent } from 'react';

import LoaderEllipsisSVG from '../../../../../../ui/components/assets/LoaderEllipsis.svg';
import { AddressBubble } from './AddressBubble';

interface ErrorsType {
  address?: string | undefined;
}

interface ValuesType {
  address?: string | undefined;
}

interface ServiceAddressType {
  isFetching: boolean;
  addresses: any;
  handleChange: (e: ChangeEvent<any>) => void;
  values: ValuesType;
  errors: ErrorsType;
}

export const ServiceAddresses = ({
  isFetching,
  addresses,
  handleChange,
  values,
  errors,
}: ServiceAddressType) => {
  return (
    <>
      {isFetching ? (
        <div className="w-4 h-4">
          <LoaderEllipsisSVG />
        </div>
      ) : (
        addresses.map(address => {
          return (
            <AddressBubble
              key={address.address1}
              address={address}
              handleChange={handleChange}
              values={values}
              errors={errors}
            />
          );
        })
      )}
    </>
  );
};
