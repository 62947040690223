import type { ImageType } from 'react-images-uploading';
import { FileClaimDamageDomain } from '@cortex/myaccount/ui/components';

export type ImageListExtended = ImageType[] &
  {
    file: {
      name: string;
    };
  }[];

export interface StepOneFields {
  dateOfDamage: string;
  locationOfDamage: FileClaimDamageDomain | null;
  kind: string;
}

export interface StepTwoFields {
  address: string | null;
  phoneNumber: string | null;
  kind: string;
}

export interface StepThreeFields {
  claimPhotos: ImageListExtended;
  claimReceipt: File;
  kind: string;
}

export interface StepFourFields {
  merchant: string;
  dateOfDamage: string;
  kind: string;
}

export type FileClaimFields = StepOneFields &
  StepTwoFields &
  StepThreeFields &
  StepFourFields;

export const StepOneInitialState = {
  dateOfDamage: '',
  locationOfDamage: null,
  kind: 'SI',
};

export const StepTwoInitialState = {
  address: '',
  phoneNumber: '',
  kind: 'SI',
};

export const StepThreeInitialState = {
  claimPhotos: [] as ImageListExtended,
  claimReceipt: [] as ImageListExtended,
  kind: 'SI',
};

export const StepFourInitialState = {
  merchant: '',
  dateOfDamage: '',
  kind: 'SI',
};
