import { FC, useState } from 'react';

import type { CustomerClaimImage } from './models/CustomerClaimImage';
import { ImageViewer } from './ImageViewer';
import { PreviewImage } from './PreviewImage';

interface ClaimSummaryPhotosProps {
  pictures: CustomerClaimImage[];
}

export const ClaimSummaryPhotos: FC<ClaimSummaryPhotosProps> = (
  props: ClaimSummaryPhotosProps,
) => {
  const { pictures } = props;

  // manage image viewer
  const [showViewer, setShowViewer] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const openViewer = (idx: number) => {
    setSelectedIndex(idx);
    setShowViewer(true);
  };

  return (
    <div>
      {showViewer && (
        <ImageViewer
          pictures={pictures}
          selectedIndex={selectedIndex}
          onDismiss={() => setShowViewer(false)}
        />
      )}

      <div className="flex gap-y-4 flex-wrap w-full pt-2 pb-4 md:pl-0 h-full">
        {pictures.map((p, i) => (
          <PreviewImage
            className="!mr-2 md:!mr-4 w-[4.625rem] h-[4.625rem] md:h-[7.813rem] md:w-[7.813rem]"
            key={p.id}
            imageUrl={p.url}
            onClick={() => openViewer(i)}
          />
        ))}
      </div>
    </div>
  );
};
