/**
 * @param prodsToClaim Product purchases with active contract
 * @param draftClaims Draft claim list
 * @param openClaims Claim with status in [CustomerClaimStatus.UnderReview, CustomerClaimStatus.InProgress, CustomerClaimStatus.Approved]
 * @returns reason why it's not possible to click on the file a claim button
 */
export const getReasonFileClaim = (
  activePPurchases,
  draftClaims,
  openClaims,
): string => {
  const hasProductsToClaim = !!activePPurchases.length;
  if (!hasProductsToClaim) return 'all_your_plans_are_expired';
  else if (activePPurchases.length <= draftClaims.length + openClaims.length)
    return 'you_can_file_a_new_claim_once_your_current_ones_are_resolved';
  return '';
};
