import { ScreenOverlay } from '@cortex/myaccount/ui/components/ScreenOverlay';

import CloseSVG from '../../../../assets/Close.svg';
import SafeShipLogoLight from '../../../../assets/SafeShipLogoLight.svg';
import { FileClaimModalContent } from './FileClaimModalContent';

interface FileClaimModalProps {
  handleClose: () => void;
}

export function FileClaimModal({ handleClose }: FileClaimModalProps) {
  return (
    <ScreenOverlay className="sm:flex items-center justify-center h-[98vh]">
      <div className="w-full h-full lg:max-w-[37.5rem] lg:max-h-[50rem] bg-white rounded-lg relative">
        <div className="sm:block lg:max-w-[37.5rem] lg:mx-auto">
          <div className="flex justify-center items-center p-4 bg-reguard-indigo relative">
            <span className="w-32">
              <SafeShipLogoLight />
            </span>
            <div className="tw-cst-pf bg-transparent absolute top-0 bottom-0 right-5 flex justify-center items-center">
              <button onClick={handleClose} data-testid="closeBtn">
                <div className="h-[0.9375rem] w-[0.9375rem]">
                  <CloseSVG />
                </div>
              </button>
            </div>
          </div>
        </div>

        <FileClaimModalContent handleClose={handleClose} />
      </div>
    </ScreenOverlay>
  );
}
