import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import LoaderEllipsisSVG from '../../../../../../ui/components/assets/LoaderEllipsis.svg';

interface ErrorsType {
  phoneNumber?: string | undefined;
}

interface ValuesType {
  phoneNumber?: string | undefined;
}

interface AddTelephoneNumberType {
  isFetching: boolean;
  handleChange: (e: ChangeEvent<any>) => void;
  values: ValuesType;
  errors: ErrorsType;
  openPhoneModal: () => void;
}

export const AddTelephoneNumber = ({
  isFetching,
  handleChange,
  values,
  errors,
  openPhoneModal,
}: AddTelephoneNumberType) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-row justify-between sm:justify-center items-baseline">
        <div className="text-lg semibold sm:mr-10">
          {values.phoneNumber || t('please_add_phone_number')}
        </div>
        <button
          onClick={() => {
            openPhoneModal();
          }}
          type="button"
          className="tw-cst-pf bg-transparent flex b3 text-reguard-wintergreen-shade mt-4 mb-6 cqsm:mt-8 cqsm:mb-8 cqmd:mt-11"
        >
          {values.phoneNumber
            ? t('edit_phone_number')
            : '+ ' + t('add_phone_number')}
        </button>

        {isFetching && (
          <div className="w-14 h-14">
            <LoaderEllipsisSVG />
          </div>
        )}

        {values.phoneNumber && (
          <input
            type="hidden"
            value={values.phoneNumber}
            name="phoneNumber"
            onChange={handleChange}
          />
        )}
      </div>
      {errors.phoneNumber && (
        <div className="text-cqsm text-reguard-error semibold mt-1">
          {t('add_telephone_number')}
        </div>
      )}
    </>
  );
};
