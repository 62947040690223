import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CardsGrid,
  MainSection,
  PlanCard,
  PlansTooltip,
} from '../../../../ui/components';
import useMatchBreakpoint from '../../../../views/util/useMatchBreakpoint';

type Props = {
  purchases: any[];
};

export const InactivePlans = ({ purchases }: Props) => {
  const isDesktop = useMatchBreakpoint('64rem');
  const navigate = useNavigate();

  return (
    purchases.length > 0 && (
      <MainSection
        className="bg-reguard-pearl-tint mt-4 md:bg-transparent md:mt-16"
        title="Inactive Protection Plans"
        renderRight={<PlansTooltip />}
      >
        <CardsGrid className="mb-4 md:mb-16">
          {purchases.map((data: any) => (
            <PlanCard
              isMobile={!isDesktop}
              goToClaimDetails={() =>
                navigate(`/claims/${data.productPurchaseId}`)
              }
              goToFileAClaim={() =>
                navigate(
                  `/claims/new?productPurchaseId=${data.productPurchaseId}`,
                )
              }
              goToPlanDetail={() =>
                navigate(`/protection-plan/${data.productPurchaseId}`)
              }
              isExpired={true}
              key={data.productPurchaseId}
              status={data.contract.status}
              {...data}
            />
          ))}
        </CardsGrid>
      </MainSection>
    )
  );
};
