import type { ComponentPropsWithoutRef, FC } from 'react';

import ArrowDownSVG from './assets/ArrowDown.svg';
import ArrowUpSVG from './assets/ArrowUp.svg';
import ArrowLeftSVG from './assets/LeftArrow.svg';
import LoaderEllipsisSVG from './assets/LoaderEllipsis.svg';
import ArrowRightSVG from './assets/RightArrow.svg';

interface ButtonWithArrowProps extends ComponentPropsWithoutRef<'button'> {
  direction: 'up' | 'down' | 'left' | 'right';
  isFetching?: number;
}

export const ButtonWithArrow: FC<ButtonWithArrowProps> = ({
  isFetching = 0,
  ...props
}: ButtonWithArrowProps) => {
  const btnChildren = isFetching ? (
    <div className="flex flex-row justify-center">
      <LoaderEllipsisSVG data-testid="loader-ellipsis" />
    </div>
  ) : (
    props.children
  );

  const getSVG = () => {
    switch (props.direction) {
      case 'up':
        return ArrowUpSVG;
      case 'down':
        return ArrowDownSVG;
      case 'left':
        return ArrowLeftSVG;
      case 'right':
        return ArrowRightSVG;
    }
  };

  const Component = getSVG();

  return (
    <button
      {...props}
      className={`text-sm text-reguard-violet flex gap-2 items-center hover:border-reguard-violet
      focus:border-reguard-violet focus-visible:border-reguard-violet focus:outline-reguard-violet
      focus-visible:outline-reguard-violet`}
    >
      {btnChildren}
      <div
        className="w2 h-2"
        data-testid={
          props.direction === 'up'
            ? 'arrow-up'
            : props.direction === 'down'
              ? 'arrow-down'
              : props.direction === 'left'
                ? 'arrow-left'
                : 'arrow-other'
        }
      >
        <Component />
      </div>
    </button>
  );
};
