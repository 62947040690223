import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoundedButton } from '@cortex/myaccount/ui/components/RoundedButton';

interface MyPolicyButtonProps {
  productPurchaseId: number;
}

export function MyPolicyButton({ productPurchaseId }: MyPolicyButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <RoundedButton
      className="py-1.5 md:py-2 min-w-[6rem] md:min-w-[8rem] text-xs md:text-sm"
      onClick={() => navigate(`/safeship/details/${productPurchaseId}`)}
      kind="white"
    >
      {t('my_policy')}
    </RoundedButton>
  );
}
