import type { FC } from 'react';

import { LoaderGlobal } from './LoaderGlobal';

interface ContentLoaderProps {
  className?: string;
}

export const ContentLoader: FC<ContentLoaderProps> = ({ className = '' }) => {
  return (
    <div
      className={`w-full h-full flex flex-1 items-center justify-center ${className}`}
    >
      <LoaderGlobal isLoading={true} />
    </div>
  );
};
