import { PolicyDetailsActions } from '../components/Policy/PolicyDetailsActions';
import { PolicyDetailsSideColumn } from '../components/Policy/PolicyDetailsSideColumn';
import { PolicyDetailsStatus } from '../components/Policy/PolicyDetailsStatus';
import { PoliciesContextProvider } from '../context/policies-context';

export function PolicyDetails() {
  return (
    <PoliciesContextProvider>
      <div className="grid grid-cols-1 lg:grid-cols-3 bg-[#f7f9fc] pb-44">
        <PolicyDetailsSideColumn />
        <PolicyDetailsStatus />
        <PolicyDetailsActions />
      </div>
    </PoliciesContextProvider>
  );
}
