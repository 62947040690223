import { FC, useState } from 'react';

import LoaderEllipsisSVG from './assets/LoaderEllipsis.svg';

export interface SquareImageContainerProps {
  alt?: string;
  url?: string;
  fallbackUrl?: string;
  className?: string;
  containerClassName?: string;
  imageClassName?: string;
  children?: React.ReactNode;
}

export const SquareImageContainer: FC<SquareImageContainerProps> = ({
  alt,
  url,
  fallbackUrl,
  className,
  containerClassName = '',
  imageClassName = '',
  children,
}) => {
  const [loadingImage, setLoading] = useState(false);

  return (
    <div className={`relative md:pt-[100%] w-full h-full ${className || ''}`}>
      <div
        className={`md:flex items-center justify-center w-full h-full md:absolute left-0 top-0 ${containerClassName}`}
      >
        {children}

        {url && (
          <picture className="flex flex-1 h-full">
            <source srcSet={url} type="image/webp" />
            <source srcSet={fallbackUrl} type="image/png" />

            <img
              onLoadStart={() => setLoading(true)} // eslint-disable-line react/no-unknown-property
              onLoad={() => setLoading(false)}
              loading="lazy"
              src={fallbackUrl}
              alt={alt}
              className={`w-full h-full ${imageClassName}`}
            />
          </picture>
        )}
      </div>
      {loadingImage && (
        <div className="w-full h-full absolute left-0 top-0 flex justify-center items-center">
          <div className="w-full h-full absolute left-0 top-0 bg-reguard-inactive2 opacity-50" />
          <LoaderEllipsisSVG />
        </div>
      )}
    </div>
  );
};
