import { useTranslation } from 'react-i18next';

import { CollapsibleSection } from '../../../../ui/components/CollapsibleSection';
import type { Product } from '../../../../ui/components/models/Product';
import type { Purchase } from '../../../../ui/components/models/Purchase';

interface Props {
  product: Product;
  purchase: Purchase;
}

const labelStyle = 'b3b font-normal text-reguard-indigo-tint mb-1';
const infoStyle = 'text-reguard-indigo b2b';

export const ProductDetailsDescription = (props: Props) => {
  const {
    product: { name, description, merchant, sku },
    purchase: { purchaseDate },
  } = props;
  const pDate = new Date(purchaseDate);
  const { t } = useTranslation();

  const formattedDate = date => {
    const month = date.toLocaleDateString('en-US', { month: 'long' });
    return `${month} ${date.getDate()}, ${date.getFullYear()}`;
  };

  return (
    <div className="w-full md:max-w-[17.938rem]">
      <div className="flex flex-col md:hidden">
        <CollapsibleSection
          title={
            <div className="flex flex-col w-full text-left">
              <div className={labelStyle}>{t('product_name')}</div>
              <div className="s3b text-reguard-indigo sm:b2b">{name}</div>
            </div>
          }
          rootClassName="md:visible"
          className="pl-0 pr-0"
          contentContainerClassName="flex gap-4 flex-col"
        >
          <div className="flex flex-col w-full md:w-auto">
            <div className={labelStyle}>{t('description')}</div>
            <div className={infoStyle}>{description}</div>
          </div>
          <div className="flex flex-col w-full">
            <div className={labelStyle}>SKU</div>
            <div className={infoStyle}>{sku}</div>
          </div>
        </CollapsibleSection>
        <div className="mb-4 flex w-full flex-wrap gap-y-4 sm:mb-0">
          <div className="flex w-1/2 flex-col md:w-full">
            <div className={labelStyle}>{t('merchant')}</div>
            <div className={infoStyle}>{merchant?.name}</div>
          </div>

          <div className="flex flex-col w-1/2 md:w-full">
            <div className={labelStyle}>{t('purchase_date')}</div>
            <div className={infoStyle}>{formattedDate(pDate)}</div>
          </div>
        </div>
      </div>

      <div className="hidden md:flex gap-4 flex-col w-full">
        <div className="flex flex-col w-full">
          <div className={labelStyle}>{t('product_name')}</div>
          <div className="s3b break-words">{name}</div>
        </div>

        <div className="flex flex-col w-full md:w-auto">
          <div className={labelStyle}>{t('description')}</div>
          <div className={infoStyle}>{description}</div>
        </div>

        <div className="flex flex-col w-full">
          <div className={labelStyle}>SKU</div>
          <div className={infoStyle}>{sku}</div>
        </div>

        <div className="flex w-full flex-wrap gap-y-4">
          <div className="flex w-1/2 flex-col md:w-full">
            <div className={labelStyle}>{t('merchant')}</div>
            <div className={infoStyle}>{merchant?.name}</div>
          </div>

          <div className="flex flex-col w-1/2 md:w-full">
            <div className={labelStyle}>{t('purchase_date')}</div>
            <div className={infoStyle}>{formattedDate(pDate)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
