import type { ComponentPropsWithoutRef, FC } from 'react';

export interface TitledCardInfoProps extends ComponentPropsWithoutRef<'div'> {
  title: string;
  removeIcon?: any;
}

const truncatedStyle = 'overflow-hidden whitespace-nowrap text-ellipsis';

export const TitledCardInfo: FC<TitledCardInfoProps> = ({
  title,
  removeIcon,
  children,
  className = '',
}) => (
  <div className={`min-w-0 w-full flexflex-col max-h-[5.125rem] ${className}`}>
    <div className="flex min-w-0">
      <div className={`min-w-0 flex ${removeIcon && 'flex-1'}`}>
        <h3
          className={`tw-cst-pf ${truncatedStyle} mb-1 md:mb-2 text-reguard-indigo semibold text-base leading-4 md:s2 md:leading-6`}
        >
          {title}
        </h3>
      </div>
      {removeIcon && removeIcon}
    </div>
    {children}
  </div>
);
