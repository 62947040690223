import { FC, useState } from 'react';
import { Address } from '@cortex/myaccount/ui/components/models/Address';

import config from '../config';
import initEndpoints from '../endpoints';
import { useAddUserAddress, useUpdateUserAddress } from '../network/api/users';
import { ProfileAddressFormFields } from '../ui/components';

const endpoints = initEndpoints();
const { getMapBoxAddress } = endpoints.userEndpoints();
const apiURL = config.api.url;
const baseURL = `${apiURL}${getMapBoxAddress.path}?`;

interface ProfileAddressFormprops {
  handleClose: () => void;
  addressId?: string;
  handleSubmitSuccess: () => void;
}

const ProfileAddressForm: FC<ProfileAddressFormprops> = ({
  handleClose,
  addressId,
  handleSubmitSuccess,
}) => {
  const [fillManually, setFillManually] = useState(false);
  const [error, setError] = useState('');

  const { mutateAsync: updateAddress, isLoading: updatingAddress } =
    useUpdateUserAddress();
  const { mutateAsync: addAddress, isLoading: addingAddress } =
    useAddUserAddress();
  const isLoading = updatingAddress || addingAddress;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = async ({ state, ...otherFields }: any) => {
    const address = {
      ...otherFields,
      state: state?.value,
    } as unknown as Address;
    try {
      if (!addressId) {
        await addAddress(address);
      } else {
        await updateAddress({ ...address, id: addressId });
      }

      handleSubmitSuccess();
    } catch (e: any) {
      setError(e?.message);
    }
  };

  return (
    <ProfileAddressFormFields
      addressId={addressId}
      baseURL={baseURL}
      error={error}
      setError={setError}
      onSubmit={onSubmit}
      fillManually={fillManually}
      setFillManually={setFillManually}
      isLoading={isLoading}
      onClose={handleClose}
    />
  );
};

export default ProfileAddressForm;
