import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import ROUTES from '../common/constants/routes';
import { useLogin } from '../network/api/users';
import { Login as LoginForm, useGoogleAnalytics } from '../ui/components';

export const Login = () => {
  const { mutateAsync: login, status } = useLogin();
  const { loginEvent } = useGoogleAnalytics();
  const [error, setError] = useState(false);
  const { search } = useLocation();
  const authError = search.includes('?authError=true');
  const isFetching = status === 'loading';
  const { t } = useTranslation();
  // const { isSafeShip } = useCompanyContext();

  const handleSubmit = async (data: any) => {
    const { email, password, remember } = data;
    try {
      const userData = await login({
        email,
        password,
        remember,
      });
      loginEvent({ user_id: userData.user.id, user_email: email });
    } catch (err) {
      setError(true);
    }
  };

  return (
    <div className="relative grid m-auto max-w-[16rem] pb-[12rem]">
      <LoginForm
        handleSubmit={handleSubmit}
        hasError={error || authError}
        isFetching={isFetching}
        forgotPassword={
          <Link
            to="/forgot-password"
            className="c1 mb-4 cursor-pointer underline"
          >
            {t('i_forgot_my_password')}
          </Link>
        }
        clearApiError={() => error && setError(false)}
        apiErrorContent={
          <>
            {error && (
              <span className="error-text c1 mt-8 mb-3  text-reguard-error">
                {t('your_email_or_password_is_incorrect')} <br />
                {t('please_try_again')}
              </span>
            )}
            {authError && (
              <span className="error-text c1 mt-8 mb-3 text-reguard-error">
                {t(
                  'please_try_again_in_a_few_minutes_there_was_an_error_logging_you_in',
                )}
              </span>
            )}
          </>
        }
      />
      <div className="Set-Pass c1 mt-3">
        {"Haven't set a password? "}
        <Link to={ROUTES.FORGOT} className="c1 cursor-pointer underline">
          {t('set_a_password_now')}
        </Link>
      </div>
      <Link
        to={ROUTES.VERIFY_INFORMATION}
        className="c1 cursor-pointer mt-3 underline"
      >
        {t('i_did_not_provide_my_email_at_point-of-purchase')}
      </Link>

      {/* TODO: Enable when feature flags are implemented */}
      {/*<LoginCTA isSafeShip={isSafeShip} />*/}
    </div>
  );
};
