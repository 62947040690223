import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PurchasePlanDetails } from '@cortex/myaccount/network/api/purchases';
import { buttonSelectors } from '@cortex/myaccount/tests/dataCySelectors';
import { ProcessingStatus } from '@cortex/requests';

import { ContractStatus } from '../../../../common/main';
import {
  Button,
  Contract,
  ProtectionPlan,
  Tooltip,
} from '../../../../ui/components';

interface Props {
  canFileClaim: boolean;
  contract: Contract;
  plan: PurchasePlanDetails;
  protectionPlan: ProtectionPlan;
  handleMarkAsDelivered: () => void;
  handleOpenContactSupportModal: () => void;
  isTimeLimitExceeded: boolean;
}

export function ControlsSection({
  canFileClaim,
  contract,
  plan,
  protectionPlan,
  handleMarkAsDelivered,
  handleOpenContactSupportModal,
  isTimeLimitExceeded,
}: Props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const ToolTipContent = () => {
    return (
      <>
        <div className="semibold p-2">{t("why_can't_i_file_a_claim")}</div>
        <div className="p-2 pt-0 font-light">
          {t(
            contract.status === ContractStatus.Inactive
              ? 'you_can_file_a_claim_once_your_protection_plan_is_active'
              : 'you_can_file_a_new_claim_once_your_current_ones_are_resolved',
          )}
        </div>
      </>
    );
  };

  const ViewTermsButton = () => {
    return (
      <a href={protectionPlan.termsConditions} target="_blank" rel="noreferrer">
        <Button variant="secondary" data-cy={buttonSelectors.viewTermsBtn}>
          {t('view_terms')}
        </Button>
      </a>
    );
  };

  const MarkAsDeliveredButton = () => {
    return (
      <Button
        variant="secondary"
        className="mt-3"
        kind="green"
        onClick={handleMarkAsDelivered}
      >
        {t('mark_as_delivered')}
      </Button>
    );
  };

  const FileClaimButton = () => {
    return (
      <Button
        onClick={() =>
          navigate(`/claims/new?productPurchaseId=${plan.productPurchaseId}`)
        }
        disabled={!canFileClaim}
        data-cy={buttonSelectors.fileClaimBtn}
      >
        {t('file_a_claim')}
      </Button>
    );
  };

  const ContactSupportButton = () => {
    return (
      <Button
        variant="secondary"
        className="mt-3"
        kind="orange"
        onClick={handleOpenContactSupportModal}
      >
        {t('contact_support')}
      </Button>
    );
  };

  return (
    <>
      <Tooltip
        tooltipActive={!canFileClaim}
        content={ToolTipContent()}
        className="mb-3"
      >
        <FileClaimButton />
      </Tooltip>

      <ViewTermsButton />

      {!contract.processing_status && <MarkAsDeliveredButton />}

      {contract.processing_status === ProcessingStatus.PROCESSING &&
        isTimeLimitExceeded && <ContactSupportButton />}
    </>
  );
}
