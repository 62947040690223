import { usePoliciesContext } from '../../context/policies-context';
import { Policies } from './Policies';

export function PendingPolicies() {
  const { purchases } = usePoliciesContext();

  if (purchases?.pending?.length === 0) return;

  return (
    <Policies
      title="pending_policies"
      subtitle=""
      purchases={purchases.pending}
    />
  );
}
