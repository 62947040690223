import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { buttonSelectors } from '@cortex/myaccount/ui/components/tests/dataCySelectors';
import dayjs from 'dayjs';

import { Button, SimpleSelectDateForwardRef } from '../../../../ui/components';

interface Props {
  handleClose: () => void;
  onSubmit: (handleClose: () => void) => void;
  setSelectedDeliveryDate: (date: Date) => void;
}

export const SetDateModalContent = ({
  handleClose,
  onSubmit,
  setSelectedDeliveryDate,
}: Props) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  return (
    <>
      {/* Body */}
      <div className={`flex flex-col items-center justify-between `}>
        <div className="s1b text-reguard-indigo text-center mb-11 sm:mb-0 pt-4 sm:pt-[6.75rem]">
          {t('select_the_delivery_date')}
        </div>

        <div className="flex flex-row justify-center mb-5  py-5">
          <SimpleSelectDateForwardRef
            name="dateOfDelivery"
            placeholder={t('select date') as string}
            className="shrink-0 font-normal"
            onChange={(date: Date) => {
              if (!date) return;

              if (setSelectedDate) {
                setSelectedDate(date);
                setSelectedDeliveryDate(date);
              }
            }}
            selected={dayjs(selectedDate).toDate()}
            value={dayjs(selectedDate).toString()}
            maxDate={new Date()}
          />
        </div>

        <div className="flex flex-row justify-center py-5 w-full">
          <Button
            type="submit"
            className="mb-5 sm:static bottom-5 !max-w-[17.938rem]"
            onClick={() => {
              onSubmit(handleClose);
            }}
            data-cy={buttonSelectors.continueBtn}
            data-testid="continueBtn"
          >
            {t('submit')}
          </Button>
        </div>
      </div>
    </>
  );
};
