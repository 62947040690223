import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Languages } from '../common/main';
import { buttonSelectors } from '../tests/dataCySelectors';
import { BrandedDialog, Button, User } from '../ui/components';
import ProfileAddressForm from './ProfileAddressForm';
import ProfileResetForm from './ProfileResetForm';
import ProfileTokenForm from './ProfileTokenForm';

enum Step {
  DATA = 'D',
  TOKEN = 'T',
  SUCCESS = 'S',
}

export interface ProfileModalProps {
  formType: 'email' | 'phoneNumber' | 'address' | 'language';
  userData: User;
  handleClose: () => void;
  addressId?: string;
  hasPhoneNumber: boolean;
  onProcessSuccess?: () => void;
  successCTALabel?: string;
}

const SUCCESS_COPY: Record<
  ProfileModalProps['formType'],
  { title: string; subtitle: string }
> = {
  email: {
    title: 'email_verified',
    subtitle: 'your_email_has_been_updated',
  },
  phoneNumber: {
    title: 'phone_number_verified',
    subtitle: 'your_phone_number_has_been_updated',
  },
  address: {
    title: 'address_saved',
    subtitle: 'your_address_has_been_saved',
  },
  language: {
    title: 'language_preference_saved',
    subtitle: '',
  },
};

const FORM_COPY: Record<
  ProfileModalProps['formType'],
  { title: string; subtitle: string }
> = {
  email: {
    title: 'update_your_email_address',
    subtitle:
      'this_address_is_used_to_log_in_your_reguard_account_and_help_service_your_claims',
  },
  phoneNumber: {
    title: 'your_phone_number',
    subtitle:
      'this_contact_information_will_be_used_to_help_service_your_claims',
  },
  address: {
    title: 'update_your_address',
    subtitle: 'this_address_will_be_used_to_help_service_your_claim',
  },
  language: {
    title: 'select_your_language',
    subtitle: 'this_will_be_used_in_navigating_your_website_experience',
  },
};

const secureEmailDisplay = (email: string) => {
  const splitEmail = email.split('@');
  const user = splitEmail[0];
  return `${user[0]}*****${user[user.length - 1]}@${splitEmail[1]}`;
};

const ProfileModal: FC<ProfileModalProps> = ({
  formType,
  handleClose,
  addressId,
  hasPhoneNumber,
  userData,
  onProcessSuccess,
  successCTALabel,
}) => {
  const [fieldValue, setFieldValue] = useState<string>('');
  const [step, setStep] = useState(Step.DATA);
  const [timer, setTimer] = useState<number>(0);
  const { t } = useTranslation();
  const handleFormType = () => {
    if (formType === 'address') {
      return (
        <ProfileAddressForm
          addressId={addressId}
          handleClose={handleClose}
          handleSubmitSuccess={() => {
            setStep(Step.SUCCESS);
          }}
        />
      );
    }

    return (
      <ProfileResetForm
        handleClose={handleClose}
        userData={userData}
        formType={formType}
        handleSubmitSuccess={(value, response) => {
          if (formType === 'language') {
            setStep(Step.SUCCESS);
            setFieldValue(value);
          } else {
            setFieldValue(value);
            setStep(Step.TOKEN);
            setTimer(response.timer);
          }
        }}
      />
    );
  };

  const handleStep = () => {
    switch (step) {
      case Step.DATA:
        return handleFormType();
      case Step.TOKEN:
        return (
          <ProfileTokenForm
            formType={formType}
            fieldValue={fieldValue}
            handleClose={handleClose}
            onSubmitSucccess={() => setStep(Step.SUCCESS)}
            timer={timer}
          />
        );
      case Step.SUCCESS:
        return (
          <div className="flex justify-center items-center mt-16 w-[17.938rem]">
            <Button
              type="button"
              data-cy={buttonSelectors.backToProfileBtn}
              onClick={() => {
                onProcessSuccess && onProcessSuccess();
                handleClose();
              }}
            >
              {successCTALabel || 'Back to profile'}
            </Button>
          </div>
        );
      default:
        return;
    }
  };
  const getLanguageSuccessSubtitle = (fieldValue: string) => {
    const selectedLanguage = Languages.find(item => item.value === fieldValue);
    return t('your_language_preference_has_been_set_to', {
      value: selectedLanguage?.label,
    });
  };
  const getTitleAndSubtitle = () => {
    const nonAddressTitle =
      formType === 'phoneNumber'
        ? `${t(hasPhoneNumber ? 'Update' : 'Add')} ${t(
            FORM_COPY[formType].title,
          )}`
        : t(FORM_COPY[formType].title);

    switch (step) {
      case Step.DATA:
        return {
          title:
            formType === 'address' && !addressId
              ? t('add_a_new_address')
              : nonAddressTitle,
          subtitle: t(FORM_COPY[formType].subtitle),
        };
      case Step.TOKEN:
        return {
          title: t(FORM_COPY[formType].title),
          subtitle: t('please_enter_the_verification_code_we_just_sent_to', {
            value:
              formType === 'email'
                ? secureEmailDisplay(fieldValue)
                : fieldValue,
          }),
        };
      case Step.SUCCESS:
        return {
          title: t(SUCCESS_COPY[formType].title),
          subtitle:
            formType === 'language'
              ? getLanguageSuccessSubtitle(fieldValue)
              : t(SUCCESS_COPY[formType].subtitle),
        };
      default:
        return {
          title: t(FORM_COPY[formType].title),
          subtitle: t(FORM_COPY[formType].subtitle),
        };
    }
  };

  const { title, subtitle } = getTitleAndSubtitle();

  return (
    <BrandedDialog
      success={step === Step.SUCCESS}
      handleClose={handleClose}
      title={title}
      subtitle={subtitle}
      contentClass={formType === 'address' ? 'sm:pt-[2.0rem]' : ''}
      handleBack={step === Step.TOKEN ? () => setStep(Step.DATA) : undefined}
    >
      {handleStep()}
    </BrandedDialog>
  );
};

export default ProfileModal;
