import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ROUTES from '../common/constants/routes';
import { useSaveClaimStep, useSubmitClaim } from '../network/api/claims';
import { usePurchasesSearch } from '../network/api/purchases';
import { useMyUser } from '../network/api/users';
import {
  DraftClaim,
  FileClaimDamageDomainData,
  FileClaimFields,
  FileClaimStep,
  FileClaimStepFive,
  FileClaimStepFour,
  FileClaimStepOne,
  FileClaimStepsContainer,
  FileClaimStepThree,
  FileClaimStepTwo,
  Purchase,
  useGoogleAnalytics,
  useSaveOrSubmitClaim,
} from '../ui/components';
import { FileClaimBlocker } from './FileClaimBlocker';
import { FileClaimUserInfoStep } from './FileClaimUserInfoStep';

enum GTAGClaimCategory {
  NEW_CLAIM = 'NEW_CLAIM',
  DRAFT = 'DRAFT',
}

export type SubmittedClaimData =
  | { submissionDate: Date; pcmiClaimNumber?: string; claimId: string }
  | undefined;

interface FileClaimFormProps {
  onSubmissionSuccess: (claimData: SubmittedClaimData) => void;
  domainData: {
    damageArea: FileClaimDamageDomainData;
    damageCause: FileClaimDamageDomainData;
    damageLocation: FileClaimDamageDomainData;
  };
  productPurchaseId: string;
  draftClaim?: DraftClaim;
}

export const FileClaimSteps: FC<FileClaimFormProps> = ({
  productPurchaseId,
  domainData,
  onSubmissionSuccess,
  draftClaim = {} as DraftClaim,
}) => {
  const navigate = useNavigate();
  const { step, claimId: draftClaimId, ...draftClaimFormData } = draftClaim;
  const { damageArea, damageCause, damageLocation } = domainData;
  const draftClaimStep = step && +step;

  const [formStep, setFormStep] = useState<FileClaimStep>(
    draftClaimStep || FileClaimStep.STEP_ONE,
  );
  const [requestError, setRequestError] = useState('');
  const [shouldBlockNavigation, setNavigationBlocking] = useState(true);
  // USER DATA
  const { data: userData, isFetching: isFetchingUser } = useMyUser({
    refetchOnMount: false,
  });
  const { data: purchases, isFetching: isFetchingPurchase } =
    usePurchasesSearch();

  // SUBMIT OR SAVE CLAIM
  const { mutateAsync: saveClaimStep, isLoading: isSavingStep } =
    useSaveClaimStep();
  const { mutateAsync: submitClaim, isLoading: isSubmitting } =
    useSubmitClaim();
  const { customEvent } = useGoogleAnalytics();
  const { handleSaveClaim, handleSubmitClaim } = useSaveOrSubmitClaim({
    draftClaimId,
    productPurchaseId,
    formStep,
    saveClaim: saveClaimStep,
    submitClaim,
    onSubmissionSuccess,
  });

  const isFetching =
    isFetchingPurchase || isFetchingUser || isSubmitting || isSavingStep;

  const prodToFileClaimAgainst = purchases.all.find(
    (purchase: Purchase) => purchase.productPurchaseId === productPurchaseId,
  );

  const prevFormStep = () =>
    setFormStep((currentStep: number) => currentStep - 1);

  const nextFormStep = () => {
    setRequestError('');
    setFormStep((currentStep: number) => currentStep + 1);
  };

  const onSaveStep = async (valuesForSave: FileClaimFields) => {
    setRequestError('');
    try {
      const response = await handleSaveClaim(valuesForSave);
      customEvent({
        name: 'save_claim',
        options: {
          item_id: response.claimId,
          item_category: draftClaimId
            ? GTAGClaimCategory.DRAFT
            : GTAGClaimCategory.NEW_CLAIM,
        },
      });
    } catch (e: any) {
      setNavigationBlocking(true);
      setRequestError(e.message);
      throw e;
    }
  };

  const onSubmitClaim = async (formValues: FileClaimFields) => {
    setRequestError('');
    try {
      const response = await handleSubmitClaim(formValues);
      const { updatedAt, pcmiClaimId, id } = response;
      customEvent({
        name: 'submit_claim',
        options: {
          item_id: id,
          pcmi_id: pcmiClaimId,
        },
      });
      onSubmissionSuccess({
        submissionDate: updatedAt,
        pcmiClaimNumber: pcmiClaimId,
        claimId: id,
      });
    } catch (e: any) {
      setRequestError(e.message);
    }
  };

  const initialValues = draftClaimId
    ? {
        phoneNumber: userData.phoneNumber,
        address: prodToFileClaimAgainst?.address?.id,
        ...draftClaimFormData,
      }
    : {
        address: prodToFileClaimAgainst?.address?.id,
        phoneNumber: userData.phoneNumber,
      };

  return (
    <div className="w-full flex flex-col">
      <FileClaimStepsContainer
        handleSubmit={async (values, { setSubmitting }) => {
          if (formStep < FileClaimStep.STEP_FIVE) {
            nextFormStep();
            setSubmitting(false);
            return;
          }

          await onSubmitClaim(values);
        }}
        handleSave={async (values: FileClaimFields) => {
          setNavigationBlocking(false);
          await onSaveStep(values);
          navigate(ROUTES.MY_CLAIMS);
        }}
        formStep={formStep}
        goToNextStep={nextFormStep}
        goToPrevStep={prevFormStep}
        contractNumber={prodToFileClaimAgainst?.contract?.pcmiContractNumber}
        productName={prodToFileClaimAgainst?.product?.name}
        initialValues={initialValues as FileClaimFields}
        requestError={requestError}
        isFetching={isFetching}
      >
        {formStep === FileClaimStep.STEP_ONE && (
          <FileClaimStepOne
            damageArea={damageArea}
            damageCause={damageCause}
            damageLocation={damageLocation}
          />
        )}

        {formStep === FileClaimStep.STEP_TWO && <FileClaimStepTwo />}

        {formStep === FileClaimStep.STEP_THREE && (
          <FileClaimStepThree isDraft={Boolean(draftClaimId)} />
        )}

        {formStep === FileClaimStep.STEP_FOUR && (
          <FileClaimUserInfoStep
            userData={userData}
            renderStep={({
              addresses,
              openAddressModal,
              openPhoneModal,
              loadingData,
            }) => (
              <FileClaimStepFour
                addresses={[
                  prodToFileClaimAgainst?.address,
                  ...(addresses.length > 0
                    ? addresses.filter(
                        ad => prodToFileClaimAgainst?.address.id !== ad.id,
                      )
                    : []),
                ]}
                phoneNumber={userData.phoneNumber}
                openAddressModal={openAddressModal}
                openPhoneModal={openPhoneModal}
                draftClaim={draftClaim}
                isFetching={isFetching || loadingData}
              />
            )}
          />
        )}

        {formStep === FileClaimStep.STEP_FIVE && (
          <FileClaimStepFive
            merchantName={prodToFileClaimAgainst?.merchant?.friendlyName}
            purchaseDate={prodToFileClaimAgainst?.purchaseDate}
            setFormStep={setFormStep}
          />
        )}
        {shouldBlockNavigation && (
          <FileClaimBlocker
            isFetching={isFetching}
            onSaveStep={onSaveStep}
            formStep={formStep}
          />
        )}
      </FileClaimStepsContainer>
    </div>
  );
};
