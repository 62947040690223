import { type ComponentPropsWithoutRef, type FC, ReactElement } from 'react';

interface MainSectionProps extends ComponentPropsWithoutRef<'main'> {
  title?: string;
  subtitle?: string;
  renderRight?: ReactElement;
}

export const MainSection: FC<MainSectionProps> = ({
  className,
  renderRight,
  ...props
}: MainSectionProps) => (
  <main className={`flex justify-center ${className}`} {...props}>
    <div className="w-full max-w-[90rem] px-6 sm:px-16 lg:px-[6.25rem] justify-between items-center">
      {props.title && (
        <div
          className={`flex justify-between items-end md:items-center ${
            props.subtitle ? 'mb-2' : 'mb-4 md:mb-10'
          }`}
        >
          <h3 className="tw-cst-pf b1 md:s2 md:text-4xl text-reguard-indigo pt-4">
            {props.title}
          </h3>
          <div>{renderRight}</div>
        </div>
      )}
      {props.subtitle && (
        <div className="flex justify-between items-end md:items-center mb-4 md:mb-10">
          <p className="tw-cst-pf b text-xl text-reguard-indigo">
            {props.subtitle}
          </p>
        </div>
      )}
      <div>{props.children}</div>
    </div>
  </main>
);
