import { useTranslation } from 'react-i18next';

export const YourContactInformationText = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="semibold text-base mb-3 cqsm:mb-4 cqsm:text-xl">
        {t('your_contact_information')}
      </div>
      <div className="font-normal text-cqsm mb-1 cqsm:text-base">
        {t('contact_phone_number')}
      </div>
    </>
  );
};
