import { Params } from '../../base';
import { synapse } from '../base';

export interface PatchProcessingStatusData {
  processing_status: ProcessingStatus;
}

export enum ProcessingStatus {
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
}

export interface RespondMessage {
  message: string;
}
export const patchProcessingStatusReq = async (
  params: Params,
  data: PatchProcessingStatusData,
): Promise<RespondMessage> => {
  const args = {
    path: `contract/${params.contractID}/processing-status`,
    method: 'PATCH',
    body: {
      ...data,
    },
  };

  try {
    return await synapse(args);
  } catch (err) {
    return Promise.reject(err);
  }
};
