import { useNavigate, useParams } from 'react-router-dom';
import { config } from '@cortex/myaccount/config/config';

import { ContractStatus, CustomerClaimStatus } from '../../../common/main';
import { useClaimsSearch } from '../../../network/api/claims';
import { usePurchasesSearch } from '../../../network/api/purchases';
import { Claim, Contract, ProtectionPlan } from '../../../ui/components';
import useMatchBreakpoint from '../../util/useMatchBreakpoint';

interface ParamTypes {
  [productId: string]: string;
}

export function useProtectionPlanDetails() {
  const { data: claims, isFetching: fetchingClaims } = useClaimsSearch();
  const { data: purchases, isFetching: fetchingPurchases } =
    usePurchasesSearch();
  const { productPurchaseId } = useParams<ParamTypes>();

  const navigate = useNavigate();

  const isDesktop = useMatchBreakpoint('64rem'); // min-width:1024px

  const plan =
    Array.isArray(purchases?.all) &&
    purchases.all.find(
      (p: typeof purchases.all) => p.productPurchaseId === productPurchaseId,
    );

  if (!plan) {
    return {
      claims,
      purchases,
      productPurchaseId,
      canFileClaim: false,
      activeClaim: null,
      inactiveClaim: null,
      plan: null,
      protectionPlan: null,
      product: null,
      isDesktop,
      fetchingPurchases,
      fetchingClaims,
      contract: null,
    };
  }

  const protectionPlan: ProtectionPlan = plan.protectionPlan;
  const contract: Contract = plan.contract;
  const activeClaim =
    Array.isArray(claims) &&
    claims.find(
      (c: Claim) =>
        c.productPurchase.contract?.id === contract.id &&
        c.productPurchase.id === productPurchaseId &&
        c.status !== CustomerClaimStatus.Closed,
    );

  const inactiveClaim =
    Array.isArray(claims) &&
    claims.find(
      (c: Claim) =>
        c.productPurchase.contract?.id === contract.id &&
        c.productPurchase.id === productPurchaseId &&
        c.status === CustomerClaimStatus.Closed,
    );

  const product = plan.product;
  product.merchant = plan.merchant;
  const canFileClaim =
    !activeClaim && !inactiveClaim && contract.status === ContractStatus.Active;

  function isProcessingTimeLimitExceeded(timestamp: string): boolean {
    const timeLimitInMs = config.processingTimeLimitInMinutes * 60 * 1000;
    return Date.now() - new Date(timestamp).getTime() > timeLimitInMs;
  }

  const navigateToClaims = (activeClaim: false | Claim | undefined) => {
    if (!activeClaim) {
      return;
    }

    if (activeClaim.status === CustomerClaimStatus.Draft) {
      navigate(`/claims/new?draftClaimId=${activeClaim.id}`);
      return;
    }

    navigate(`/claims/${activeClaim.id}`);
  };

  return {
    claims,
    purchases,
    productPurchaseId,
    canFileClaim,
    activeClaim,
    inactiveClaim,
    plan,
    protectionPlan,
    product,
    isDesktop,
    fetchingPurchases,
    fetchingClaims,
    contract,
    isProcessingTimeLimitExceeded,
    navigateToClaims,
  };
}
