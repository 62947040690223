import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGoogleAnalytics } from '@cortex/myaccount/ui/components/hooks/useGoogleAnalytics';
import { RoundedButton } from '@cortex/myaccount/ui/components/RoundedButton';

import { CustomerClaimStatus } from '../../../common/constants/claims';
import { getClaimActionButtonText } from '../utils/utils';

interface ClaimActionButtonProps {
  data: any;
  isDisabled: boolean;
  fileClaim: () => void;
  setShowModal: (value: boolean) => void;
}

export function ClaimActionButton({
  data,
  isDisabled,
  setShowModal,
}: ClaimActionButtonProps) {
  const { t } = useTranslation();
  const { fileAClaimCTAEvent } = useGoogleAnalytics();
  const navigate = useNavigate();
  const handleClaimAction = (
    productPurchaseId: string | undefined,
    title: string,
  ) => {
    if (title === 'view_claim') {
      return navigate(`/safeship/claims/${customerClaim.id}`);
    }

    setShowModal(true);
    navigate(`/safeship/claims/new?productPurchaseId=${productPurchaseId}`);
  };

  const customerClaim =
    data.customerClaims.find(c =>
      [
        CustomerClaimStatus.UnderReview,
        CustomerClaimStatus.InProgress,
        CustomerClaimStatus.Approved,
      ].includes(c.status),
    ) || data.customerClaims.find(c => c.status === CustomerClaimStatus.Draft);

  const buttonInfo = getClaimActionButtonText(customerClaim, data.contract);
  const { title, color } = buttonInfo;
  const isInTransit = title === 'lost_package';
  const isButtonDisabled = !isInTransit && isDisabled;

  return (
    <RoundedButton
      kind={color}
      className={`mx-2 py-1.5 md:py-2 min-w-[6rem] md:min-w-[8rem] text-xs md:text-sm ${
        isButtonDisabled && 'cursor-not-allowed opacity-50 disabled'
      }`}
      onClick={event => {
        if (isButtonDisabled) return event.stopPropagation();
        event.stopPropagation();

        fileAClaimCTAEvent({
          productSKU: data.product.sku,
          name: data.product.name,
        });
        handleClaimAction(data.productPurchaseId, title);
      }}
    >
      {t(title)}
    </RoundedButton>
  );
}
