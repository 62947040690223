import dayjs from 'dayjs';
import type { TFunction } from 'i18next';
import * as yup from 'yup';

export enum FileClaimStep {
  STEP_ONE = 1,
  STEP_TWO = 2,
  STEP_THREE = 3,
}

const validationSchema = (t: TFunction<'translation', undefined>) => {
  const facStepOneSchema = yup.object().shape({
    dateOfDamage: yup
      .string()
      .required(t('date_of_incident_is_required') as string)
      .when('dateOfIncident', {
        is: (value: string) => value?.length,
        then: yup
          .string()
          .test(
            'required',
            t('date_of_incident_cannot_be_in_the_future') as string,
            value => {
              return dayjs(value).isBefore(new Date());
            },
          ),
      }),
    lossType: yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable()
      .required(t('loss_type_is_required') as string),
  });

  const facStepTwoSchema = yup.object().shape({
    address: yup.string().required(t('address_is_required') as string),
    phoneNumber: yup.string().required(t('phone_number_is_required') as string),
  });

  const facStepThreeSchema = yup.object().shape({
    claimPhotos: yup.array().of(
      yup.object().shape({
        dataURL: yup.string(),
        file: yup.object().nullable(),
        name: yup.string(),
      }),
    ),
  });

  return {
    [FileClaimStep.STEP_ONE]: facStepOneSchema,
    [FileClaimStep.STEP_TWO]: facStepTwoSchema,
    [FileClaimStep.STEP_THREE]: facStepThreeSchema,
  };
};

export default validationSchema;
