import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import SuccessSVG from './assets/Success.svg';
import { buttonSelectors } from './tests/dataCySelectors';
import { CustomerClaimStatus } from '../../common/constants/claims';
import { BulletPointItem } from './BulletPointItem';
import { Button } from './Button';
import { ClaimStatusProgressBar } from './ClaimStatusProgressBar';

export interface FileClaimConfirmationProps {
  claimId: string;
  submissionDate: Date;
  status: CustomerClaimStatus;
  nextSteps: string[];
  viewClaim: () => void;
}

export const FileClaimConfirmation: FC<FileClaimConfirmationProps> = ({
  claimId,
  submissionDate,
  status,
  nextSteps,
  viewClaim,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col items-center text-left sm:text-center pb-6 sm:pb-10">
      <div className="flex w-full flex-col sm:items-center">
        <h3 className="tw-cst-pf h3 pb-4">Success!</h3>
        <div className="s3b mb-4 sm:mb-[4.5rem] font-normal">
          {t(
            'reguard_will_carefully_review_your_claim_and_get_back_to_you_soon',
          )}
        </div>
      </div>

      <div className="flex w-full flex-col sm:items-center">
        <div className="mb-2">
          <span className="b2b sm:s2 semibold">{t('claim_id')} </span>
          <span className="b2 sm:s3b !font-normal">{claimId}</span>
        </div>

        <div className="sm:mb-14 mb-9">
          <span className="b2b sm:s2 semibold">
            {t('date_of_submission')}:{' '}
          </span>
          <span className="b2 sm:s3b !font-normal">
            {' '}
            {dayjs(submissionDate).format('MMM DD, YYYY')}
          </span>
        </div>
      </div>

      <div className="flex justify-center items-center pb-8">
        <div className="flex justify-center items-center w-44 h-44">
          <SuccessSVG />
        </div>
      </div>

      <div className="mb-6 sm:mb-[5.625rem] w-full max-w-[37.5rem]">
        <ClaimStatusProgressBar status={status} />
      </div>

      <div className="mb-10 sm:mb-14">
        <div className="b1 sm:s1 mb-2 sm:mb-8">{t('what_to_expect_next')}</div>
        <div>
          {nextSteps.map((text, index) => (
            <BulletPointItem
              key={`file-claim-bullet-${index}`}
              textClassName="sm:s3b !font-normal"
              className="sm:mb-6"
            >
              {text}
            </BulletPointItem>
          ))}
        </div>
      </div>

      <Button
        type="button"
        className="mb-10 sm:mb-[7.5rem]"
        onClick={viewClaim}
        data-cy={buttonSelectors.viewClaimBtn}
      >
        {t('view_claim')}
      </Button>
    </div>
  );
};
