import { RoundedButtonProps } from '@cortex/myaccount/ui/components';
import dayjs from 'dayjs';

import { CustomerClaimStatus } from '../../../common/constants/claims';

export function getClaimActionButtonText(
  customerClaim: { status: CustomerClaimStatus; id: string | null },
  contract: { shipping_status: string },
): {
  title: string;
  color: RoundedButtonProps['kind'];
} {
  if (contract.shipping_status === 'in transit') {
    return { title: 'lost_package', color: 'purple' };
  }

  if (customerClaim?.status === CustomerClaimStatus.Draft) {
    return { title: 'finish_claim', color: 'orange' };
  }

  if (customerClaim && customerClaim?.id) {
    return { title: 'view_claim', color: 'green' };
  }

  return { title: 'file_a_claim', color: 'purple' };
}

export function getPurchaseById(id, purchases) {
  return purchases.all.find(purchase => purchase.productPurchaseId === id);
}

export function getTimeDifferenceForPurchase(purchase) {
  const today = dayjs();
  const purchaseDate = dayjs(purchase?.delivery_date);
  return purchaseDate.diff(today, 'hour');
}
