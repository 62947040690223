import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FileClaimDamageDomain } from '@cortex/myaccount/ui/components';
import { SimpleSelectForwardRef } from '@cortex/myaccount/ui/components/SimpleSelect';
import { SimpleSelectDateForwardRef } from '@cortex/myaccount/ui/components/SimpleSelectDate';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';

import type { StepOneFields } from '../../../helpers/facFieldsInitialValues';
import { useDebounce } from '../../../hooks/useDebounce';

export type FileClaimDamageDomainData = FileClaimDamageDomain[];

interface FileClaimStepOneProps {
  damageArea: FileClaimDamageDomainData;
  damageCause: FileClaimDamageDomainData;
  damageLocation: FileClaimDamageDomainData;
  isChatBot?: boolean;
}

const DEBOUNCE_TIMEOUT = 100;

export const FileClaimStepOne: FC<FileClaimStepOneProps> = ({
  damageLocation,
  isChatBot,
}: FileClaimStepOneProps) => {
  const { values, errors, validateField, setFieldValue } =
    useFormikContext<StepOneFields>();
  const debounce = useDebounce(DEBOUNCE_TIMEOUT);
  const { t } = useTranslation();
  return (
    <div
      className={`step-one grid grid-cols-1 gap-x-6 gap-y-3  ${
        isChatBot
          ? ''
          : 'cqsm:gap-y-6 cqsm:grid-cols-2 cqmd:grid-cols-2  sm:gap-y-6 sm:grid-cols-2  md:grid-cols-2 '
      }`}
    >
      <SimpleSelectDateForwardRef
        label={t('date_of_incident') as string}
        labelHtmlFor="dateOfIncident"
        name="dateOfDamage"
        placeholder={t('select') as string}
        className="shrink-0 font-normal"
        error={errors?.dateOfDamage}
        onChange={(date: Date) => {
          if (!date) {
            return;
          }
          setFieldValue('dateOfDamage', dayjs(date).format('YYYY-MM-DD'));
          debounce(() => validateField('dateOfDamage'));
        }}
        selected={
          values.dateOfDamage ? dayjs(values.dateOfDamage).toDate() : undefined
        }
        value={values.dateOfDamage}
      />

      <SimpleSelectForwardRef
        options={damageLocation}
        label={t('loss_type') as string}
        placeholder={t('lost_package') + '...'}
        name="lossType"
        className="shrink-0 font-normal"
        error={errors?.locationOfDamage}
        errorExclamationIconVisible={false}
        onChange={opt => {
          setFieldValue('locationOfDamage', opt);
          validateField('locationOfDamage');
          debounce(() => validateField('locationOfDamage'));
        }}
        value={values.locationOfDamage}
      />
    </div>
  );
};
