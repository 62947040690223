import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@cortex/myaccount/common/constants/routes';
import { useGoogleAnalytics } from '@cortex/myaccount/ui/components/hooks/useGoogleAnalytics';
import { useSaveOrSubmitClaim } from '@cortex/myaccount/ui/components/hooks/useSaveOrSubmitClaim';
import { FileClaimStep } from '@cortex/myaccount/ui/components/models/FileClaimForm';
import { Purchase } from '@cortex/myaccount/ui/components/models/Purchase';
import { FileClaimUserInfoStep } from '@cortex/myaccount/views/FileClaimUserInfoStep';

import { useFileClaimContext } from '../../../context/file-claim-context';
import { FileClaimFields } from '../../../helpers/facFieldsInitialValues';
import {
  getInitialValues,
  getIsFetching,
  handleOnSaveStep,
  handleOnSubmitClaim,
} from '../../../helpers/fileClaimFuncs';
import { FileClaimFormProps } from '../../../types/fileClaimTypes';
import { DraftClaim } from '../DraftClaim/DraftClaim';
import { FileClaimBlocker } from '../FileClaimBlocker/FileClaimBlocker';
import { FileClaimStepFour } from '../FileClaimStepFour/FileClaimStepFour';
import { FileClaimStepOne } from '../FileClaimStepOne/FileClaimStepOne';
import { FileClaimStepThree } from '../FileClaimStepThree/FileClaimStepThree';
import { FileClaimStepTwo } from '../FileClaimStepTwo/FileClaimStepTwo';
import { FileClaimStepsContainer } from './FileClaimStepsContainer';

export const FileClaimSteps: FC<FileClaimFormProps> = ({
  productPurchaseId,
  domainData,
  onSubmissionSuccess,
  draftClaim = {} as DraftClaim,
}) => {
  const navigate = useNavigate();
  const { customEvent } = useGoogleAnalytics();
  const {
    userData,
    isFetchingUser,
    purchases,
    isFetchingPurchase,
    saveClaimStep,
    isSavingStep,
    submitClaim,
    isSubmitting,
    requestError,
    setRequestError,
    shouldBlockNavigation,
    setNavigationBlocking,
  } = useFileClaimContext();

  const { step, claimId: draftClaimId, ...draftClaimFormData } = draftClaim;

  const draftClaimStep = step && +step;
  const { damageArea, damageCause, damageLocation } = domainData;

  const [formStep, setFormStep] = useState<FileClaimStep>(
    draftClaimStep || FileClaimStep.STEP_ONE,
  );

  const { handleSaveClaim, handleSubmitClaim } = useSaveOrSubmitClaim({
    draftClaimId,
    productPurchaseId,
    formStep,
    saveClaim: saveClaimStep,
    submitClaim,
    onSubmissionSuccess,
  });

  const isFetching = getIsFetching(
    isFetchingPurchase,
    isFetchingUser,
    isSubmitting,
    isSavingStep,
  );

  const prodToFileClaimAgainst = purchases.all.find(
    (purchase: Purchase) => purchase.productPurchaseId === productPurchaseId,
  );

  const prevFormStep = () =>
    setFormStep((currentStep: number) => currentStep - 1);

  const nextFormStep = () => {
    setRequestError('');
    setFormStep((currentStep: number) => currentStep + 1);
  };

  const saveStepObj = {
    setRequestError,
    handleSaveClaim,
    customEvent,
    draftClaimId,
    setNavigationBlocking,
  };

  const onSaveStep = async (valuesForSave: FileClaimFields) => {
    await handleOnSaveStep(valuesForSave, saveStepObj);
  };

  const submitClaimObj = {
    setRequestError,
    handleSubmitClaim,
    customEvent,
    onSubmissionSuccess,
  };

  const onSubmitClaim = async (formValues: FileClaimFields) => {
    await handleOnSubmitClaim(formValues, submitClaimObj);
  };

  const initialValues = getInitialValues(
    draftClaimId,
    userData,
    prodToFileClaimAgainst,
    draftClaimFormData,
  );

  return (
    <div className="w-full flex flex-col">
      <FileClaimStepsContainer
        handleSubmit={async values => {
          if (formStep < FileClaimStep.STEP_FOUR) {
            nextFormStep();
            return;
          }

          await onSubmitClaim(values);
        }}
        handleSave={async (values: FileClaimFields) => {
          setNavigationBlocking(false);
          await onSaveStep(values);
          navigate(ROUTES.MY_CLAIMS);
        }}
        formStep={formStep}
        goToNextStep={nextFormStep}
        goToPrevStep={prevFormStep}
        contractNumber={prodToFileClaimAgainst?.contract?.pcmiContractNumber}
        productName={prodToFileClaimAgainst?.product?.name}
        initialValues={initialValues as FileClaimFields}
        requestError={requestError}
        isFetching={isFetching}
      >
        {/* Date of Incident & Loss Type Selects */}
        {formStep === FileClaimStep.STEP_ONE && (
          <FileClaimStepOne
            damageArea={damageArea}
            damageCause={damageCause}
            damageLocation={damageLocation}
          />
        )}

        {/* Address and Phone Information */}
        {formStep === FileClaimStep.STEP_TWO && (
          <FileClaimUserInfoStep
            userData={userData}
            renderStep={({
              addresses,
              openAddressModal,
              openPhoneModal,
              loadingData,
            }) => (
              <FileClaimStepTwo
                addresses={[
                  prodToFileClaimAgainst?.address,
                  ...(addresses.length > 0
                    ? addresses.filter(
                        ad => prodToFileClaimAgainst?.address.id !== ad.id,
                      )
                    : []),
                ]}
                phoneNumber={userData.phoneNumber}
                openAddressModal={openAddressModal}
                openPhoneModal={openPhoneModal}
                draftClaim={draftClaim}
                isFetching={isFetching || loadingData}
              />
            )}
          />
        )}

        {/* Photo and Receipt Uploads */}
        {formStep === FileClaimStep.STEP_THREE && (
          <FileClaimStepThree isDraft={Boolean(draftClaimId)} />
        )}

        {/* Review Your Submission */}
        {formStep === FileClaimStep.STEP_FOUR && (
          <FileClaimStepFour
            merchantName={prodToFileClaimAgainst?.merchant?.friendlyName}
            purchaseDate={prodToFileClaimAgainst?.purchaseDate}
            setFormStep={setFormStep}
          />
        )}

        {shouldBlockNavigation && (
          <FileClaimBlocker
            isFetching={isFetching}
            onSaveStep={onSaveStep}
            formStep={formStep}
          />
        )}
      </FileClaimStepsContainer>
    </div>
  );
};
