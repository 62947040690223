import type { FC } from 'react';
import React from 'react';
import type { OptionProps } from 'react-select';

import LocationSVG from '../assets/Location.svg';

const CustomOption: FC<OptionProps> = ({ innerProps, isDisabled, label }) => {
  const { className, ...otherProps } = innerProps;
  const splitLabel = label.split(',');

  return !isDisabled ? (
    <div
      className={`b2 cursor-pointer w-full flex gap-1 items-center py-4 border-b border-b-reguard-pearl-shade min-w-0 ${className}`}
      {...otherProps}
    >
      <div className="flex flex-shrink-0">
        <div className="w-6 h-6">
          <LocationSVG />
        </div>
      </div>
      <div className="overflow-hidden whitespace-nowrap text-ellipsis">
        <span className="b2 font-bold">{splitLabel[0]}</span>
        {splitLabel.splice(1, splitLabel.length - 1).join(',')}
      </div>
    </div>
  ) : null;
};

export default CustomOption;
