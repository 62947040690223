import type { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { buttonSelectors } from './tests/dataCySelectors';
import { Button } from './Button';

interface FileClaimBarProps {
  className?: string;
  onButtonClick: MouseEventHandler;
  loading?: boolean;
  disabled?: boolean;
  reason?: string;
}

export const FileClaimBar: FC<FileClaimBarProps> = (
  props: FileClaimBarProps,
) => {
  const {
    className,
    onButtonClick,
    loading = false,
    disabled = false,
    reason = 'all_your_protection_plans_are_expired',
  } = props;
  const { t } = useTranslation();

  return (
    <div className="w-full max-w-[90rem] mx-auto hidden md:flex justify-end">
      <div className="absolute py-4 pr-6 sm:pr-16 lg:pr-[6.25rem]">
        <div className="group flex flex-col items-end">
          <Button
            isfetching={loading}
            onClick={onButtonClick}
            className={className}
            disabled={disabled || loading}
            data-cy={buttonSelectors.fileClaimBtn}
            data-testid="fileClaimBtn"
          >
            {t('file_a_claim')}
          </Button>
          {!loading && disabled && (
            <div className="group-hover:flex hidden opacity-0 group-hover:opacity-100 flex-col items-end transition-all duration-400">
              <div className="small-triangle-top-violet mr-8"></div>
              <div className="bg-reguard-violet w-72 text-white py-2 px-4 rounded text-base">
                <div className="semibold p-2">
                  {t("why_can't_i_file_a_claim")}
                </div>
                <div className="p-2 pt-0 font-light">{t(reason)}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
