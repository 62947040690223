import type { FC, MouseEventHandler } from 'react';

import FurnitureSVG from './assets/Fbfurniture.svg';
import FurniturePaddedSVG from './assets/FbFurniturePadded.svg';
import MattressSVG from './assets/FbMattress.svg';
import MattressPaddedSVG from './assets/FbMattressPadded.svg';
import OutdoorSVG from './assets/FbOutdoor.svg';
import OutdoorPaddedSVG from './assets/FbOutdoorPadded.svg';
import PreviewSVG from './assets/FbPreview.svg';
import RugSVG from './assets/FbRug.svg';
import RugPaddedSVG from './assets/FbRugPadded.svg';

interface PreviewImageProps {
  imageUrl?: string;
  fallbackType?: string;
  onClick?: MouseEventHandler;
  className?: string;
}

export const renderSvgFallback = (
  type: string,
  className?: string,
  padded?: boolean,
) => {
  if (type.includes('RUG')) {
    return padded ? (
      <div className={className}>
        <RugPaddedSVG />
      </div>
    ) : (
      <div className={className}>
        <RugSVG />
      </div>
    );
  }
  if (type.includes('FURN')) {
    return padded ? (
      <div className={className}>
        <FurniturePaddedSVG />
      </div>
    ) : (
      <div className={className} data-testid="furn-svg">
        <FurnitureSVG />
      </div>
    );
  }
  if (type.includes('ADJ')) {
    return padded ? (
      <div className={className}>
        <MattressPaddedSVG />
      </div>
    ) : (
      <div className={className}>
        <MattressSVG />
      </div>
    );
  }
  if (type.includes('OUTDRF')) {
    return padded ? (
      <div className={className}>
        <OutdoorPaddedSVG />
      </div>
    ) : (
      <div className={className}>
        <OutdoorSVG />
      </div>
    );
  }
  return (
    <div className={className}>
      <PreviewSVG />
    </div>
  );
};

const Fallback = ({
  type,
  className,
}: {
  type?: string;
  className?: string;
}) => (
  <div
    className={`min-w-[5rem] h-[5rem] mr-4
    md:w-full md:max-w-[9.375rem] md:h-[9.375rem] md:mr-6
    bg-reguard-pearl-tint shadow-preview-image-placeholder ${className}`}
  >
    <div
      className={`rounded-md w-full h-full flex justify-center items-center ${
        !type ? 'bg-reguard-pearl-shade' : ''
      }`}
    >
      {renderSvgFallback(
        type || '',
        'w-[68%] h-[68%] max-w-[6.375rem] max-h-[6.375rem]',
      )}
    </div>
  </div>
);

export const PreviewImage: FC<PreviewImageProps> = (
  props: PreviewImageProps,
) =>
  !props.imageUrl ? (
    <Fallback type={props.fallbackType} className={props.className} />
  ) : (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      style={{ backgroundImage: `url('${props.imageUrl}')` }}
      className={`mr-4 md:mr-6 rounded-md min-w-[5rem] min-h-[5rem] md:min-w-[9.375rem] md:min-h-[9.375rem] overflow-hidden bg-cover bg-left-top
        ${props.onClick ? 'cursor-pointer' : ''}
        ${props.className || ''}
        `}
      onClick={props.onClick}
      role="img"
    ></div>
  );
