import { useTranslation } from 'react-i18next';

import FacRadioStepTwoChecked from './assets/FacRadioStepTwoChecked.svg';
import FacRadioStepTwoUnChecked from './assets/FacRadioStepTwoUnChecked.svg';
import { Languages } from '../../common/constants/language';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { TextField, TextFieldProps } from './TextField';

type ProfileResetProps = {
  isDisabled: boolean;
  onSubmit: () => void;
  isLoading: boolean;
  onChange: (e: any) => void;
  error: string;
  backendError: string;
  fieldValue: string;
  onClose: () => void;
  textFieldProps: TextFieldProps;
  formType: 'address' | 'phoneNumber' | 'email' | 'language';
  newsletter: boolean;
  setNewsletter: (arg: boolean) => void;
  onPaste: (arg: any) => void;
};

export const ProfileResetFormFields = ({
  isDisabled,
  onSubmit,
  isLoading,
  onChange,
  error,
  backendError,
  fieldValue,
  onClose,
  textFieldProps,
  formType,
  newsletter,
  setNewsletter,
  onPaste,
}: ProfileResetProps) => {
  const { t } = useTranslation();
  return (
    <form
      id="profileresetform"
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
      className="w-full mb-16"
    >
      {formType === 'language' ? (
        <>
          {Languages.map(pc => {
            return (
              <div
                key={pc.value}
                className="pc-radio-group_row grid items-center grid-cols-12 odd:bg-reguard-pearl"
              >
                <div className="pc-radio-group_column col-span-10">
                  <label htmlFor={pc.value} className="cursor-pointer">
                    <div className="break-normal py-2 pl-2 font-normal">{`${pc.label}`}</div>
                  </label>
                </div>

                <div className="pc-radio-group_column col-span-2 text-right">
                  <label
                    className="flex flex-row justify-end items-center break-normal py-2 pr-5 cursor-pointer"
                    htmlFor={pc.value}
                  >
                    <input
                      className="peer tw-cst-pf"
                      name="productCondition"
                      id={pc.value}
                      type="radio"
                      value={pc.value}
                      onChange={() => {
                        onChange(pc.value);
                      }}
                      checked={pc.value === fieldValue}
                    />
                    <div className="h-5 w-5 p-0 inline-block cursor-pointer peer-checked:p-0 peer-checked:h-5 peer-checked:w-[1.625rem]">
                      {pc.value === fieldValue ? (
                        <div
                          className="pl-[0.125rem] h-6
                          w-6
                          p-0
                          inline-block
                          cursor-pointer
                          peer-checked:p-0
                          peer-checked:h-5
                          peer-checked:w-[1.625rem]"
                        >
                          <FacRadioStepTwoChecked />
                        </div>
                      ) : (
                        <div className="w-4 h-4">
                          <FacRadioStepTwoUnChecked />
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="mt-12">
          <TextField
            value={fieldValue}
            onChange={e => {
              onChange(e.target.value);
            }}
            onPaste={onPaste}
            error={!!error}
            errorMessage={error}
            errorExclamationIconVisible
            {...textFieldProps}
          />
        </div>
      )}

      {formType !== 'language' && (
        <div className="mt-[0.875rem]">
          <Checkbox
            name="newsletter"
            label={t(
              'i_would_like_to_receive_marketing/promotional_communications_from_reguard',
            )}
            onChange={() => setNewsletter(!newsletter)}
            checked={newsletter}
          />
        </div>
      )}
      <div className="mt-[0.875rem] pt-4">
        {backendError && (
          <div className="c1 text-reguard-error mb-3">{backendError}</div>
        )}
        <Button
          isfetching={isLoading}
          disabled={isDisabled}
          type="submit"
          form="profileresetform"
        >
          {t(formType === 'email' ? 'send_email' : 'save_changes')}
        </Button>
        <button
          onClick={onClose}
          className="tw-cst-pf bg-transparent flex w-full justify-center b2 text-reguard-wintergreen-shade mt-4"
        >
          {t('cancel')}
        </button>
      </div>
    </form>
  );
};
