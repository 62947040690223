import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProcessingStatus } from '@cortex/requests';

import { CustomerClaimStatus } from '../../../../common/main';
import { buttonSelectors } from '../../../../tests/dataCySelectors';
import {
  Button,
  Contract,
  HorizontalSeparator,
  Product,
  ProtectionPlan,
  Purchase,
  SquareImageContainer,
} from '../../../../ui/components';
import ProtectionPlanSofa from '../../../assets/ProtectionPlanSofa.svg';
import { ClaimSection } from '../claims-section/claims-section';
import { ProductDetailsDescription } from '../product-details-description/product-details-description';

interface Props {
  canFileClaim: boolean;
  activeClaim: any;
  plan: any;
  contract: Contract;
  protectionPlan: ProtectionPlan;
  product: any;
  isDesktop: boolean;
  handleMarkAsDelivered: () => void;
  handleOpenContactSupportModal: () => void;
  isTimeLimitExceeded: boolean;
}

export function ProductDetailsSection({
  canFileClaim,
  activeClaim,
  plan,
  contract,
  protectionPlan,
  product,
  isDesktop,
  handleMarkAsDelivered,
  handleOpenContactSupportModal,
  isTimeLimitExceeded,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const MarkAsDeliveredButton = () => {
    return (
      <Button
        variant="secondary"
        className="mt-3"
        kind="green"
        onClick={handleMarkAsDelivered}
      >
        {t('mark_as_delivered')}
      </Button>
    );
  };

  const ContactSupportButton = () => {
    return (
      <Button
        variant="secondary"
        className="mt-3"
        kind="orange"
        onClick={handleOpenContactSupportModal}
      >
        {t('contact_support')}
      </Button>
    );
  };

  const FileClaimButton = () => {
    return (
      <Button
        className="!w-full !max-w-[100%] !mb-4"
        onClick={() =>
          navigate(`/claims/new?productPurchaseId=${plan.productPurchaseId}`)
        }
        disabled={!canFileClaim}
        data-cy={buttonSelectors.fileClaimBtn}
      >
        {t('file_a_claim')}
      </Button>
    );
  };

  const ViewTermsButton = () => {
    return (
      <a href={protectionPlan.termsConditions} target="_blank" rel="noreferrer">
        <Button
          className="!w-full !max-w-[100%]"
          variant="secondary"
          data-cy={buttonSelectors.viewTermsBtn}
        >
          {t('view_terms')}
        </Button>
      </a>
    );
  };

  return (
    <>
      {/* small screens only section */}
      {!isDesktop && (
        <div className="px-0 py-3">
          <div className="flex flex-row">
            <div className="left-box flex flex-1 max-w-2/5">
              <SquareImageContainer className="shadow-md rounded-lg w-full min-w-[7rem] min-h-[7rem] max-h-[18rem] max-w-[18rem]">
                <ProtectionPlanSofa />
              </SquareImageContainer>
            </div>
            <div className="right-box w-3/5 ml-4">
              <FileClaimButton />

              <ViewTermsButton />

              {!contract.processing_status && <MarkAsDeliveredButton />}

              {contract.processing_status === ProcessingStatus.PROCESSING &&
                isTimeLimitExceeded && <ContactSupportButton />}
            </div>
          </div>

          {activeClaim ? (
            <div className="mt-8 mb-5">
              <ClaimSection
                claim={activeClaim}
                plan={plan}
                title={t(
                  activeClaim.status === CustomerClaimStatus.Draft
                    ? 'draft'
                    : 'active_claim',
                )}
                isMobile={!isDesktop}
                goToDetails={() => {
                  if (activeClaim.status === CustomerClaimStatus.Draft) {
                    navigate(`/claims/new?draftClaimId=${activeClaim.id}`);
                  } else {
                    navigate(`/claims/${activeClaim.id}`);
                  }
                }}
              />
            </div>
          ) : null}
        </div>
      )}

      {/* product type image */}
      <div className="hidden md:block mb-8">
        <SquareImageContainer className="shadow-md rounded-lg  w-full min-w-[7rem] max-h-[18rem] max-w-[18rem]">
          <ProtectionPlanSofa />
        </SquareImageContainer>
      </div>

      {/* product info */}
      <ProductDetailsDescription
        product={product || ({} as Product)}
        purchase={plan || ({} as Purchase)}
      />
      <HorizontalSeparator className="my-0 !w-full pr-0 sm:my-4 max-w-[unset] md:hidden" />
    </>
  );
}
