import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { usePoliciesContext } from '../../context/policies-context';
import {
  getPurchaseById,
  getTimeDifferenceForPurchase,
} from '../../utils/utils';
import { FileClaimModal } from '../FileClaim/FileClaimModal/FileClaimModal';

export function PolicyDetailsActions() {
  const { t } = useTranslation();
  const { policyId } = useParams();
  const { purchases } = usePoliciesContext();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);

  const purchase = getPurchaseById(policyId, purchases);
  const difference = getTimeDifferenceForPurchase(purchase);

  return (
    <div className="flex flex-col mx-auto lg:mx-32 my-2">
      <button
        className="bg-[#04C796] text-white rounded-md w-56 h-9 hover:bg-reguard-green"
        onClick={() => {
          setShowModal(true);
          navigate(`/safeship/claims/new?productPurchaseId=${policyId}`);
        }}
      >
        {t('file_a_claim')}
      </button>

      <a
        href={purchase?.protectionPlan.termsConditions}
        target="_blank"
        rel="noreferrer"
      >
        <button className="w-56 h-9 border-gray-600 border-2 rounded-md my-2">
          {t('certificate_of_insurance')}
        </button>
      </a>

      <div className="my-10 w-72">
        <p>
          {t('policy_status')}: {purchase?.contract?.status}
        </p>
        {difference > 0 && (
          <div className="flex">
            <div className="border-2 border-gray-500 border-solid w-[110px] rounded-full text-[#7368ED] text-center text-sm capitalize pr-4">
              {t('expires_in')}:
            </div>
            <div className="border-2 border-gray-500 border-solid w-[132px] rounded-full bg-[#7368ED] text-white text-center relative right-4 text-sm">
              {Math.floor(difference / 24)} {t('days')} {difference % 24}{' '}
              {t('hours')}
            </div>
          </div>
        )}
      </div>
      {showModal && <FileClaimModal handleClose={() => setShowModal(false)} />}
    </div>
  );
}
