import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getReasonFileClaim } from './util/fileClaimUtils';
import useMatchBreakpoint from './util/useMatchBreakpoint';
import { CustomerClaimStatus } from '../common/main';
import { useClaimsSearch } from '../network/api/claims';
import { usePurchasesSearch } from '../network/api/purchases';
import {
  CardsGrid,
  Claim,
  CollapsibleSection,
  FileClaimBar,
  MainPage,
  MainSection,
  PlanCard,
  PlansTooltip,
  useGoogleAnalytics,
} from '../ui/components';

export const MyPlans = () => {
  const { data: purchases, isFetching: isPurchasesFetching } =
    usePurchasesSearch();
  const { data: claims, isFetching: isClaimsFetching } = useClaimsSearch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDesktop = useMatchBreakpoint('64rem'); // min-width:1024px
  const prodsToClaim = purchases.active.filter(
    (data: typeof purchases.active) => data.canFileAClaim,
  );
  const { fileAClaimCTAEvent } = useGoogleAnalytics();

  const isFetching = isPurchasesFetching || isClaimsFetching;
  const goToClaimDetails = (
    claimId: string | undefined,
    claimStatus: CustomerClaimStatus | undefined,
  ) => {
    if (claimStatus && claimStatus === CustomerClaimStatus.Draft) {
      navigate(`/claims/new?draftClaimId=${claimId}`);
    } else {
      navigate(`/claims/${claimId}`);
    }
  };
  const goToFileAClaim = (productPurchaseId: string | undefined) => {
    navigate(`/claims/new?productPurchaseId=${productPurchaseId}`);
  };

  let draftClaims: Claim[] = [];
  let openClaims: Claim[] = [];

  if (claims?.length > 0) {
    draftClaims = claims.filter(
      (c: Claim) => c.status === CustomerClaimStatus.Draft,
    );
    openClaims = claims.filter((c: Claim) =>
      [
        CustomerClaimStatus.UnderReview,
        CustomerClaimStatus.InProgress,
        CustomerClaimStatus.Approved,
      ].includes(c.status),
    );
  }

  const reason = getReasonFileClaim(purchases.active, draftClaims, openClaims);

  return (
    <MainPage title={t('my_protection_plans') as string}>
      {purchases.inactive.length > 0 && (
        <MainSection
          title={t('inactive_protection_plans') as string}
          renderRight={<PlansTooltip />}
          className="!px:0 border bg-reguard-inactive2 mb-4"
        >
          <CardsGrid className="mb-4">
            {purchases.inactive.map(data => (
              <PlanCard
                isMobile={!isDesktop}
                goToClaimDetails={goToClaimDetails}
                goToFileAClaim={() => goToFileAClaim(data.productPurchaseId)}
                goToPlanDetail={() =>
                  navigate(`/protection-plan/${data.productPurchaseId}`)
                }
                isExpired={true}
                key={data.productPurchaseId}
                status={data.contract.status}
                {...data}
              />
            ))}
          </CardsGrid>
        </MainSection>
      )}

      {purchases.active.length > 0 && (
        <>
          <FileClaimBar
            onButtonClick={() => navigate('/plans/products')}
            disabled={isFetching || !prodsToClaim.length}
            loading={isFetching}
            reason={reason}
          />
          <MainSection
            title={t('active_protection_plans') as string}
            subtitle={
              t(
                'please_note_these_are_all_the_products_potentially_covered_by_your_reguard_plan',
              ) as string
            }
          >
            <CardsGrid className="mb-8 md:mb-20">
              {purchases.active.map(data => (
                <PlanCard
                  isMobile={!isDesktop}
                  goToClaimDetails={goToClaimDetails}
                  goToFileAClaim={() => {
                    fileAClaimCTAEvent({
                      productSKU: data.product.sku,
                      name: data.product.name,
                    });
                    goToFileAClaim(data.productPurchaseId);
                  }}
                  goToPlanDetail={() =>
                    navigate(`/protection-plan/${data.productPurchaseId}`)
                  }
                  isExpired={data.isExpired}
                  key={data.productPurchaseId}
                  status={data.contract.status}
                  {...data}
                />
              ))}
            </CardsGrid>
          </MainSection>
        </>
      )}

      {purchases.expired.length > 0 && (
        <div>
          {/* mobile */}
          <CollapsibleSection
            contentContainerClassName="bg-reguard-darkgray"
            rootClassName="sm:hidden"
            className="bg-reguard-darkgray"
            titleFormatted
            title={t('expired') as string}
          >
            <CardsGrid className="bg-reguard-darkgray px-6 mb-20">
              {purchases.expired.map(data => (
                <PlanCard
                  isMobile={!isDesktop}
                  goToClaimDetails={goToClaimDetails}
                  goToFileAClaim={() => goToFileAClaim(data.productPurchaseId)}
                  goToPlanDetail={() =>
                    navigate(`/protection-plan/${data.productPurchaseId}`)
                  }
                  isExpired={true}
                  key={data.productPurchaseId}
                  status={data.contract.status}
                  {...data}
                />
              ))}
            </CardsGrid>
          </CollapsibleSection>

          {/* desktop */}
          <MainSection
            title={t('expired_protection_plans') as string}
            className="!px:0 border bg-reguard-inactive2 mb-4 hidden sm:flex"
          >
            <CardsGrid className="mb-4">
              {purchases.expired.map(data => (
                <PlanCard
                  isMobile={!isDesktop}
                  goToClaimDetails={goToClaimDetails}
                  goToFileAClaim={() => goToFileAClaim(data.productPurchaseId)}
                  goToPlanDetail={() =>
                    navigate(`/protection-plan/${data.productPurchaseId}`)
                  }
                  isExpired={true}
                  key={data.productPurchaseId}
                  status={data.contract.status}
                  {...data}
                />
              ))}
            </CardsGrid>
          </MainSection>
        </div>
      )}
    </MainPage>
  );
};
