import { usePoliciesContext } from '../../context/policies-context';
import { Policies } from './Policies';

export function ExpiredPolicies() {
  const { purchases } = usePoliciesContext();

  if (purchases?.inactive?.length === 0) return;

  return (
    <Policies
      title="expired_policies"
      subtitle=""
      purchases={purchases.inactive}
    />
  );
}
