import { ocular } from './ocular/base';
import { AuthDevice } from './synapse/auth/device';
import { synapse } from './synapse/base';

export async function loginDevice(): Promise<[unknown, string]> {
  const { code: otp } = await ocular<{ code: string }>({
    path: '/otp',
    method: 'get',
  });

  const { device_id } = await ocular<{ device_id: string }>({
    path: '/config',
    method: 'get',
  });

  const authResult = await synapse<AuthDevice>({
    path: '/auth/device',
    method: 'post',
    body: { otp, device_id },
  });

  const {
    user: {
      config: { LANDING_URL },
    },
  } = authResult;

  const result = {
    user: authResult.user,
    token: authResult.token,
  };

  return [result, LANDING_URL];
}

export function logoutHandler() {
  try {
    localStorage.clear();
  } catch (err) {
    console.error('logout error', err);
  }

  window.location.assign('/');
}
