import type { ComponentPropsWithoutRef, ReactNode } from 'react';

import CheckCircleSVG from './assets/CheckCircle.svg';
import ErrorSignSVG from './assets/ErrorSign.svg';
import LoaderEllipsisSVG from './assets/LoaderEllipsis.svg';
import classes from './TextField.module.css';

export interface TextFieldProps extends ComponentPropsWithoutRef<'input'> {
  label: ReactNode;
  error?: boolean;
  valid?: boolean;
  containerClassName?: string;
  errorExclamationIconVisible?: boolean;
  successIconVisible?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
}

export function TextField({
  valid,
  error,
  errorMessage,
  label,
  errorExclamationIconVisible,
  successIconVisible,
  containerClassName = '',
  isLoading,
  ...props
}: TextFieldProps) {
  const getBorderColor = () =>
    (error && 'border-reguard-error') ||
    (valid && 'border-reguard-wintergreen') ||
    'border-reguard-slate';
  return (
    <label
      className={`flex justify-center select-none flex-col-reverse ${
        error && errorMessage ? '' : 'mb-4'
      } ${containerClassName}`}
    >
      {error && errorMessage && (
        <span className="c2 semibold text-reguard-error mt-1 mb-4">
          {errorMessage} <br />
        </span>
      )}

      <div
        className={`${
          props.type === 'number' ? classes.numericInput : ''
        } relative font-normal`}
      >
        <input
          {...props}
          className={`tw-cst-pf h-12 text-reguard-indigo px-4 border-2 ${getBorderColor()} focus-visible:border-reguard-violet focus:outline-none rounded w-full flex-auto
            hover:bg-reguard-pearl-tint ${
              valid
                ? 'focus-visible:border-reguard-wintergreen'
                : 'focus-visible:border-reguard-violet'
            } ${
              error
                ? 'focus-visible:border-reguard-error hover:border-reguard-error'
                : ''
            } disabled:bg-reguard-inactive1 disabled:text-reguard-inactive2
            ${
              (error
                ? 'placeholder:text-reguard-error text-reguard-error'
                : 'placeholder:text-reguard-inactive1') ||
              'placeholder:border-reguard-indigo'
            } hover:border-reguard-violet focus:border-reguard-violet ${
              props.className
            }`}
          onChange={props.onChange}
        />

        {isLoading && (
          <div className="absolute right-0 top-0">
            <LoaderEllipsisSVG data-testid="loader-ellipsis" />
          </div>
        )}

        {(error || valid) && (
          <div className="absolute right-2 bottom-0 top-0 flex justify-center items-center">
            <div>
              {error && errorExclamationIconVisible && (
                <ErrorSignSVG data-testid="error-icon" />
              )}
              {valid && successIconVisible && (
                <CheckCircleSVG data-testid="success-icon" />
              )}
            </div>
          </div>
        )}
      </div>

      <span className={`b2 ${error && 'text-reguard-error'} mb-2`}>
        {label}
      </span>
    </label>
  );
}
