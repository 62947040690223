import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CustomerClaimStatus } from '../common/main';
import { useAddClaimPhotos, useGetClaimById } from '../network/api/claims';
import {
  BreadCrumbs,
  ClaimInfoTable,
  ClaimPhotos,
  ClaimPhotosModal,
  ClaimStatusCard,
  ClaimStatusProgressBar,
  ContentLoader,
  HorizontalSeparator,
  MainPage,
  MainSection,
  ProductInfo,
  renderSvgFallback,
  VerticalSeparator,
} from '../ui/components';

interface ParamTypes {
  [claimId: string]: string;
}

export const ClaimDetails = () => {
  const { claimId } = useParams<ParamTypes>();
  const { data: claim, isFetching } = useGetClaimById(claimId);
  const navigate = useNavigate();
  const [showAddPhotosModal, setShowAddPhotosModal] = useState(false);
  const { mutateAsync: addPhotos, isLoading } = useAddClaimPhotos();
  const { t } = useTranslation();

  // const [showCancelDialog, setShowCancelDialog] = useState(false);

  if (isFetching) {
    return <ContentLoader />;
  }

  if (!claim) {
    throw new Error('Claim not found');
  }

  // cancel claim
  // const canCancelClaim = claim.status === CustomerClaimStatus.Draft;
  // const toggleDialog = () => setShowCancelDialog((s) => !s);

  // product info
  const [firstImage] = claim.customerClaimImages;
  const prod = {
    ...claim?.productPurchase?.product,
  };
  const purch = {
    ...claim?.productPurchase?.purchase,
  };

  const type = claim.productPurchase.protectionPlan.sku;

  return (
    <MainPage>
      <MainSection>
        {/* {showCancelDialog && (
          <SimpleDialog title="Are you sure?" subtitle="You are about to cancel your claim.">
            <RoundedButton kind="dark" className="w-36 mx-auto" onClick={toggleDialog}>
              Cancel claim
            </RoundedButton>
            <RoundedButton kind="white" className="w-36 mx-auto" onClick={toggleDialog}>
              Go Back
            </RoundedButton>
          </SimpleDialog>
        )} */}
        <div className="flex flex-col md:flex-row">
          {/* product info */}
          <div className="flex flex-col md:p-2 md:pl-0 md:pr-2 min-w-fit">
            {/* mobile only back */}
            <BreadCrumbs
              className="md:hidden"
              onBack={() => {
                navigate('/claims');
              }}
              crumbs={[t('my_claims'), t('claim_status')]}
            />

            {/* desktop only first image */}
            <div className="hidden md:block max-h-[17.9375rem] mb-4">
              {firstImage?.url ? (
                <img
                  className="shadow-md rounded-lg max-h-[17.9375rem] max-w-[17.9375rem]"
                  src={firstImage.url}
                  alt={prod.name}
                ></img>
              ) : (
                <div className="shadow-md rounded-lg max-h-[17.9375rem] max-w-[17.9375rem] bg-reguard-pearl-tint">
                  {renderSvgFallback(type, '', true)}
                </div>
              )}
            </div>

            {/* product info */}
            <ProductInfo product={prod} purchase={purch} />
          </div>

          <VerticalSeparator className="hidden md:block md:pl-4 md:pr-10 md:mb-14" />
          <HorizontalSeparator className="md:hidden" />

          {/* claim info */}
          <div className="w-full flex flex-col basis-3/4">
            {/* claim status title - desktop only */}
            <h3 className="tw-cst-pf hidden md:block text-3xl semibold text-reguard-indigo pb-4 md:pl-0">
              <span>{t('claim_status')}</span>
              <span className="font-normal text-reguard-indigo-tint px-2">
                {' '}
                |{' '}
              </span>
              <span
                className={
                  claim.status === CustomerClaimStatus.Closed
                    ? ''
                    : 'text-reguard-violet'
                }
              >
                {claim.status}
              </span>
            </h3>

            {/* claim status card */}
            <div className="py-4 pr-4 max-w-[37.5rem]">
              <ClaimStatusCard
                status={claim.status}
                isCancelled={claim.isCancelled}
                isDenied={claim.isDenied}
                resolutionType={claim.solution}
              />
            </div>

            {/* claim status progress bar */}
            <ClaimStatusProgressBar status={claim.status} />

            {/* claim info table */}
            <ClaimInfoTable
              onDetailsClick={() => {
                if (claim?.status === CustomerClaimStatus.Draft) {
                  navigate(`/claims/new?draftClaimId=${claim?.id}`);
                } else {
                  navigate(`/claims/summary/${claim?.id || ''}`);
                }
              }}
              claimID={claim?.pcmiClaimId || ''}
              damageCause={claim?.damageCause?.name}
            />

            <HorizontalSeparator className="pr-4 my-2 max-w-[38.125rem]" />

            {/* photos */}
            <ClaimPhotos
              pictures={claim.customerClaimImages}
              onClick={() => setShowAddPhotosModal(true)}
            />
          </div>

          {/* cancel claim */}
          {/*TODO: replace button explicit button width, button should be revised*/}
          <div className="flex items-start justify-center md:pt-[3.125rem] md:pr-0 mb-10 mt-8 md:mt-4 w-full basis-24 lg:basis-64 shrink">
            {/* {canCancelClaim && (
              <Button variant="secondary" onClick={toggleDialog} className="w-full max-w-[12.5rem]">
                Cancel Claim
              </Button>
            )} */}
          </div>
        </div>
      </MainSection>

      {showAddPhotosModal && (
        <ClaimPhotosModal
          photos={claim.claimPhotos}
          handleClose={() => setShowAddPhotosModal(false)}
          claimId={claimId as string}
          onSubmit={addPhotos}
          isFetching={isLoading}
        />
      )}
    </MainPage>
  );
};
