import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import USStates from './constants/USStates';
import type { Address } from './models/Address';
import { buttonSelectors, textInputFields } from './tests/dataCySelectors';
import { NOLEADING_SPACES, WS_TEXT } from '../../common/main';
import { AddressInput } from './AddressInput';
import { Button } from './Button';
import { SimpleSelectForwardRef } from './SimpleSelect';
import { TextField } from './TextField';

export type ProfileAddressFormFieldsProps = {
  fillManually: boolean;
  onSubmit: (arg: any) => Promise<void>;
  setError: Dispatch<SetStateAction<string>>;
  setFillManually: (arg: boolean) => void;
  baseURL: string;
  error: string;
  isLoading: boolean;
  addressId?: string;
  onClose: () => void;
};

export type AddressFields = {
  addressSearch: string;
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  state: { value: string; label: string };
};

export const checkZipCode = async (
  zipCode: string,
  state: string,
  validate: () => void,
  baseURL: string,
  setIsValidating: (arg: boolean) => void,
  setZipCodeMatchError: (arg: any) => void,
  t: (arg: string) => any,
) => {
  const config = {
    method: 'GET',
    credentials: 'include',
  } as RequestInit;
  setIsValidating(true);
  const response = await window.fetch(
    `${baseURL}searchText=${encodeURIComponent(zipCode)}`,
    config,
  );
  const data = await response.json();

  const postalCodeResult =
    Array.isArray(data?.features) &&
    data?.features.find(feature => feature?.place_type?.includes('postcode'));

  if (postalCodeResult) {
    const context = postalCodeResult.context;
    const regionIndex = context.findIndex(ctx => ctx.id.includes('region'));
    const stateShortCode =
      context[regionIndex] && context[regionIndex].short_code?.split('-')[1];
    setZipCodeMatchError(
      stateShortCode !== state
        ? (t('the_postal_code_does_not_match_the_state') as string)
        : undefined,
    );
  } else {
    setZipCodeMatchError(t('please_insert_a_valid_postal_code') as string);
  }
  validate();
  setIsValidating(false);
};

export const ProfileAddressFormFields = ({
  fillManually,
  onSubmit,
  setFillManually,
  baseURL,
  error,
  isLoading,
  addressId,
  onClose,
}: ProfileAddressFormFieldsProps) => {
  const [zipCodeMatchError, setZipCodeMatchError] = useState<
    undefined | string
  >(undefined);
  const [isValidating, setIsValidating] = useState(false);
  const { t } = useTranslation();

  const isFormFilled = useCallback(
    (values: AddressFields): boolean => {
      if (fillManually) {
        const notFilledField = Object.keys(values).find(
          key => key !== 'addressSearch' && key !== 'address2' && !values[key],
        );

        return notFilledField === undefined;
      }

      return Boolean(values.addressSearch);
    },
    [fillManually],
  );

  return (
    <Formik
      initialValues={{
        addressSearch: '',
        address1: '',
        address2: '',
        city: '',
        zipCode: '',
        state: { value: '', label: '' },
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        address1: Yup.string()
          .required(t('address_line_1_is_required') as string)
          .max(50, t('cannot_be_more_than_50_characters') as string)
          .test('ws1', t(WS_TEXT) as string, val =>
            NOLEADING_SPACES.test(val as string),
          ),
        address2: Yup.string().notRequired(),
        city: fillManually
          ? Yup.string()
              .required(t('city_is_required') as string)
              .max(50, t('cannot_be_more_than_50_characters') as string)
              .test('ws3', t(WS_TEXT) as string, val =>
                NOLEADING_SPACES.test(val as string),
              )
          : Yup.string(),
        zipCode: fillManually
          ? Yup.string()
              .required(t('postal_code_is_required') as string)
              .test('ws4', t(WS_TEXT) as string, val =>
                NOLEADING_SPACES.test(val as string),
              )
              .length(5, t('postal_code_should_have_5_digits') as string)
          : Yup.string(),
        state: fillManually
          ? Yup.object().shape({
              label: Yup.string(),
              value: Yup.string().required(t('state_is_required') as string),
            })
          : Yup.object(),
      })}
      onSubmit={({ addressSearch: _, ...values }: AddressFields) => {
        onSubmit(values);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        dirty,
        isValid,
        setFieldValue,
        setValues,
        setFieldError,
        validateField,
      }) => (
        <form className={fillManually ? 'h-full' : ''} onSubmit={handleSubmit}>
          {fillManually ? (
            <TextField
              autoComplete="shipping address-line1"
              label={t('address_line_1') as string}
              name="address1"
              data-cy={textInputFields.address1InputField}
              error={!!errors.address1}
              errorMessage={errors.address1}
              maxLength={60}
              onChange={e => {
                handleChange(e);
                errors.address1 && setFieldError('address1', undefined);
              }}
              onBlur={() => validateField('address1')}
              value={values.address1}
              placeholder={t('enter_an_address') as string}
              errorExclamationIconVisible
            />
          ) : (
            <AddressInput
              name="addressSearch"
              data-cy={textInputFields.address1InputField}
              errorExclamationIconVisible
              setError={message => setFieldError('addressSearch', message)}
              label={t('address_line_1') as string}
              enterManually={() => {
                setFieldError('addressSearch', undefined);
                setFillManually(true);
              }}
              baseURL={baseURL}
              error={!!errors.addressSearch}
              errorMessage={errors.addressSearch}
              placeholder={t('enter_an_address') as string}
              handleChange={(vals: Address) => {
                const searchHasBeenClean = Object.keys(vals).length === 0;
                const { state, shortCode, ...otherFields } = vals;

                searchHasBeenClean &&
                  setFieldError(
                    'addressSearch',
                    t('address_line_1_is_required') as string,
                  );
                !!errors.addressSearch &&
                  !searchHasBeenClean &&
                  setFieldError('addressSearch', undefined);
                setValues({
                  ...otherFields,
                  address2: values.address2,
                  state: { value: shortCode || state, label: state },
                  addressSearch: searchHasBeenClean ? '' : 'filled',
                });
              }}
              requiredFields={['city', 'state', 'zipCode']}
            />
          )}

          <TextField
            label={t('apartment_unit_suite_or_floor') as string}
            name="address2"
            data-cy={textInputFields.address2InputField}
            maxLength={60}
            containerClassName={fillManually ? 'mb-4' : 'mt-4'}
            onBlur={() => validateField('address2')}
            onChange={e => {
              handleChange(e);
            }}
            value={values.address2.trimStart()}
            placeholder={t('optional') as string}
          />

          {fillManually && (
            <div>
              <TextField
                onChange={e => {
                  handleChange(e);
                  validateField('address1');
                  errors.city && setFieldError('city', undefined);
                }}
                onBlur={() => validateField('city')}
                maxLength={50}
                containerClassName={errors.city ? '' : 'mb-4'}
                label={t('city') as string}
                name="city"
                data-cy={textInputFields.cityInputField}
                error={!!errors.city}
                errorMessage={errors.city}
                value={values.city}
                placeholder="New York"
                errorExclamationIconVisible
              />

              <div className="flex gap-7 mb-0 items-start w-full">
                <SimpleSelectForwardRef
                  options={USStates}
                  labelHtmlFor="state"
                  label={t('state') as string}
                  containerClassName="flex flex-1 shrink-0"
                  className="w-full"
                  name="state"
                  data-cy={textInputFields.stateInputField}
                  error={errors.state?.value}
                  value={values.state.value ? values.state : undefined}
                  placeholder="NY"
                  onChange={async (data: { label: string; value: string }) => {
                    setFieldValue('state', data);
                    validateField('city');

                    if (values?.zipCode?.length === 5) {
                      checkZipCode(
                        values.zipCode,
                        data.value,
                        () => validateField('zipCode'),
                        baseURL,
                        setIsValidating,
                        setZipCodeMatchError,
                        t,
                      );
                    }
                  }}
                  errorExclamationIconVisible
                />
                <TextField
                  label={t('postal_code') as string}
                  maxLength={5}
                  name="zipCode"
                  data-cy={textInputFields.postalCodeInputField}
                  placeholder="99999"
                  error={!!errors.zipCode || !!zipCodeMatchError}
                  errorMessage={errors.zipCode || zipCodeMatchError}
                  value={values.zipCode}
                  containerClassName="flex flex-1 shrink-0"
                  errorExclamationIconVisible
                  onChange={e => {
                    if (e.target.value.length > 5) return;
                    handleChange(e);

                    if (e.target.value.length < 5) {
                      setFieldError(
                        'zipCode',
                        t('postal_code_should_have_5_digits') as string,
                      );
                    }

                    if (e.target.value.length === 5) {
                      checkZipCode(
                        e.target.value,
                        values.state.value,
                        () => validateField('zipCode'),
                        baseURL,
                        setIsValidating,
                        setZipCodeMatchError,
                        t,
                      );
                    }
                  }}
                  onBlur={() => validateField('zipCode')}
                />
              </div>
            </div>
          )}
          <div className="mt-[0.875rem] pt-4">
            {error && <div className="c1 text-reguard-error">{error}</div>}
            <Button
              isfetching={isSubmitting || isLoading || isValidating}
              disabled={
                isSubmitting ||
                !dirty ||
                !isValid ||
                isLoading ||
                !isFormFilled(values) ||
                isValidating ||
                !!zipCodeMatchError
              }
              type="submit"
              className="max-w-[100%]"
              data-cy={
                addressId
                  ? buttonSelectors.saveChangesBtn
                  : buttonSelectors.saveAddressBtn
              }
            >
              {t('save')} {t(addressId ? 'changes' : 'address')}
            </Button>
            <button
              onClick={onClose}
              className="tw-cst-pf bg-transparent lex w-full justify-center b2 text-reguard-wintergreen-shade mt-4 mb-20"
              data-cy={buttonSelectors.cancelBtn}
            >
              {t('cancel')}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};
