import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ROUTES from '../common/constants/routes';
import { phoneMask } from '../common/phone-number/phone-utils';
import { useCheckUserBySalesId } from '../network/api/users';
import { buttonSelectors } from '../tests/dataCySelectors';
import {
  Button,
  phoneNumberRules,
  TextField,
  useDebounce,
} from '../ui/components';

const DEBOUNCE_TIMEOUT = 400;

export const VerifyUserInformation = () => {
  const debounced = useDebounce(DEBOUNCE_TIMEOUT);
  const navigate = useNavigate();
  const { mutateAsync: checkData, status } = useCheckUserBySalesId();
  const { t } = useTranslation();

  const isFetching = status === 'loading';

  return (
    <div className="flex flex-col items-center">
      <div className="max-w-[17.938rem] mb-8 mt-[6.75rem]">
        <h4 className="tw-cst-pf text-reguard-indigo mx-0 mb-8">
          {t('verify_information')}
        </h4>
        <span className="b2">{t('enter_information')}</span>
      </div>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          firstName: '',
          lastName: '',
          orderNumber: '',
          phoneNumber: '',
        }}
        onSubmit={async ({ phoneNumber, firstName, lastName, orderNumber }) => {
          try {
            const { customerToken } = await checkData({
              phoneNumber,
              firstName,
              lastName,
              orderNumber,
            });
            navigate(`${ROUTES.SET_EMAIL}/${customerToken}`);
          } catch (e: any) {
            navigate(ROUTES.VERIFY_INFORMATION_FAILED);
          }
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .min(3, t('first_name_must_be_at_least_3_characters') as string)
            .required(t('first_name_is_required') as string),
          lastName: Yup.string()
            .min(3, t('last_name_must_be_at_least_3_characters') as string)
            .required(t('last_name_is_required') as string),
          orderNumber: Yup.string().required(
            t('order_number_is_required') as string,
          ),
          phoneNumber: Yup.string()
            .required(t('phone_number_is_required') as string)
            .matches(phoneNumberRules, t('is_not_in_correct_format') as string),
        })}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          dirty,
          isValid,
          validateField,
          values,
          setFieldError,
          setFieldValue,
        }) => (
          <form className="max-w-[17.938rem]" onSubmit={handleSubmit}>
            <div className="flex gap-7 items-start w-full">
              <TextField
                label={t('first_name') as string}
                maxLength={21}
                name="firstName"
                placeholder="Jane"
                error={!!errors.firstName}
                errorMessage={errors.firstName}
                containerClassName="flex flex-1 shrink-0"
                errorExclamationIconVisible
                onBlur={() => validateField('firstName')}
                onPaste={() => {
                  debounced(() => validateField('firstName'));
                }}
                onChange={e => {
                  handleChange(e);
                  errors.firstName && setFieldError('firstName', undefined);
                  debounced(() => {
                    validateField('firstName');
                  });
                }}
              />
              <TextField
                label={t('last_name') as string}
                maxLength={21}
                name="lastName"
                placeholder="Doe"
                error={!!errors.lastName}
                errorMessage={errors.lastName}
                containerClassName="flex flex-1 shrink-0"
                errorExclamationIconVisible
                onBlur={() => validateField('lastName')}
                onPaste={() => {
                  validateField('firstName');
                  debounced(() => validateField('lastName'));
                }}
                onChange={e => {
                  handleChange(e);
                  errors.lastName && setFieldError('lastName', undefined);
                  validateField('firstName');
                  debounced(() => validateField('lastName'));
                }}
              />
            </div>

            <TextField
              label={t('order_number') as string}
              maxLength={20}
              name="orderNumber"
              placeholder="9999999ABCD"
              error={!!errors.orderNumber}
              errorMessage={errors.orderNumber}
              errorExclamationIconVisible
              onBlur={() => validateField('orderNumber')}
              onPaste={() => {
                validateField('lastName');
                debounced(() => validateField('orderNumber'));
              }}
              onChange={e => {
                handleChange(e);
                errors.orderNumber && setFieldError('orderNumber', undefined);
                validateField('lastName');
                debounced(() => validateField('orderNumber'));
              }}
            />
            <TextField
              onPaste={e => {
                e.preventDefault();

                if (!e.clipboardData) return;

                const value = e.clipboardData.getData('Text');
                setFieldValue('phoneNumber', phoneMask(value));
                validateField('orderNumber');
                debounced(() => validateField('phoneNumber'));
              }}
              label={t('phone_number') as string}
              maxLength={12}
              value={phoneMask(values.phoneNumber) || ''}
              name="phoneNumber"
              placeholder="999-999-9999"
              error={!!errors.phoneNumber}
              errorMessage={errors.phoneNumber}
              errorExclamationIconVisible
              onBlur={() => validateField('phoneNumber')}
              onChange={e => {
                handleChange(e);
                errors.phoneNumber && setFieldError('phoneNumber', undefined);
                validateField('orderNumber');
                debounced(() => validateField('phoneNumber'));
              }}
            />
            <div className="mt-[0.875rem] pt-4">
              <Button
                isfetching={isSubmitting || isFetching}
                disabled={
                  isSubmitting ||
                  !dirty ||
                  !isValid ||
                  isFetching ||
                  Boolean(
                    Object.keys(values).find(
                      key => (values as Record<string, string>)[key] === '',
                    ),
                  )
                }
                type="submit"
                data-cy={buttonSelectors.continueBtn}
              >
                {t('continue')}
              </Button>
              <button
                onClick={() => navigate('/')}
                className="flex w-full justify-center b2 text-reguard-wintergreen-shade mt-4 mb-[2.375rem]"
                data-cy={buttonSelectors.cancelBtn}
              >
                {t('cancel')}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
