interface CheckboxProps {
  label: string;
  name?: string;
  id?: string;
  checked?: boolean;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const stateStyle = 'checked:bg-reguard-wintergreen-shade checkbox-input';

export function Checkbox({
  label,
  className,
  checked,
  ...props
}: CheckboxProps) {
  return (
    <label className="flex items-center select-none gap-x-[1.125rem] gap-y-3">
      <div className="relative h-6">
        <input
          className={`tw-cst-pf appearance-none border-[1px] border-solid border-reguard-pearl-shade rounded-[1px] w-6 h-6 bg-reguard-pearl
          focus:border-reguard-violet focus-visible:border-reguard-violet
          focus:outline-reguard-violet focus-visible:outline-reguard-violet
          ${stateStyle} ${className}`}
          type="checkbox"
          checked={checked}
          data-testid="check"
          {...props}
        ></input>
      </div>

      <span className="c1">{label}</span>
    </label>
  );
}
