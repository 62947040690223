import { useState } from 'react';
import dayjs from 'dayjs';

import { stringHelper } from '..';
import type { FileClaimFields } from '../constants/facFieldsInitialValues';
import type { Claim } from '../models/Claim';
import type { FileClaimStep } from '../models/FileClaimForm';

export interface DraftClaim extends Claim {
  claimId: string;
}

type HandleValuesToSaveParam = FileClaimFields & {
  step: FileClaimStep;
  productPurchaseId: string;
  draftClaimId?: string;
  lastClaimIdSaved?: string;
  id?: string;
};

type HandleValuesToSave = (
  values: HandleValuesToSaveParam,
) => Promise<FormData>;

export type HandleSaveClaim = (
  formValues: FileClaimFields,
) => Promise<Claim | any>;
export type HandleSubmitClaim = (
  formValues: FileClaimFields,
) => Promise<Claim | any>;

type UseSaveOrSubmitClaimOptions = {
  saveClaim: (formData: FormData) => Promise<DraftClaim | any>;
  submitClaim?: (param: { claimId: string }) => Promise<Claim | any>;
  formStep: FileClaimStep;
  productPurchaseId: string;
  draftClaimId?: string;
  lastClaimIdSaved?: string;
  onSubmissionSuccess?: (param: {
    submissionDate: Date;
    pcmiClaimNumber: string;
    claimId: string;
  }) => void;
};

type UseSaveOrSubmitClaim = (opts: UseSaveOrSubmitClaimOptions) => {
  handleSubmitClaim: HandleSubmitClaim;
  handleSaveClaim: HandleSaveClaim;
};

export const removeDuplicateImages = (imageList: Record<any, any>[]) => {
  const uniqueImageList = imageList.filter(
    (value, index, self) =>
      index ===
      self.findIndex(imageObj => {
        return (
          stringHelper.sanitizeFilename(imageObj.dataURL) ===
          stringHelper.sanitizeFilename(value.dataURL)
        );
      }),
  );

  return uniqueImageList;
};

const handleValuesToSave: HandleValuesToSave = async values => {
  const formDataForSave = new FormData();

  // If the claim exists... we pass the claimId to update it
  if (values.draftClaimId) {
    formDataForSave.set('claimId', values.draftClaimId);
  }

  Object.keys(values).forEach(key => {
    if (!values[key]) return;

    const specialValues = ['causeOfDamage', 'locationOfDamage', 'damageArea'];
    const formValue = specialValues.includes(key)
      ? values[key].value
      : values[key];

    if (key === 'claimPhotos') return;

    if (key === 'dateOfDamage') {
      if (!values[key]) {
        values[key] = dayjs().format('YYYY-MM-DD');
      }
    }

    formDataForSave.set(key, formValue);
  });

  if (values.claimPhotos) {
    const claimPhotos = removeDuplicateImages(values.claimPhotos);
    const photosArray = await Promise.all(
      claimPhotos.map(async (photo: any) => {
        const res = await fetch(photo.dataURL);
        const blob = await res.blob();
        return { file: blob, name: `${photo.file.name}` };
      }),
    );

    for (const photo of photosArray) {
      formDataForSave.append('claimPhotos', photo.file, photo.name);
    }
  }

  /**  Avoid 409 after a PCMI error when submitting a never-saved claim.
   *   Also, avoid the same conditions when the user goes back to some previous step and clicks on the save button
   **/
  if (values.lastClaimIdSaved) {
    formDataForSave.set('id', values.lastClaimIdSaved);
    formDataForSave.set('claimId', values.lastClaimIdSaved);
  }

  return formDataForSave;
};

export const fileClaimBuildErrorMessage = (error: any) =>
  ((error?.message as string).includes('this damage happened before') &&
    (error?.message as string).split(':')[2]) ||
  'Error submitting claim!';

export const useSaveOrSubmitClaim: UseSaveOrSubmitClaim = ({
  saveClaim,
  productPurchaseId,
  formStep,
  draftClaimId,
  submitClaim,
  onSubmissionSuccess,
}) => {
  const [lastClaimIdSaved, setLastClaimIdSaved] = useState('');

  const handleSaveClaim: HandleSaveClaim = async formValues => {
    try {
      const response = await saveClaim(
        await handleValuesToSave({
          ...formValues,
          productPurchaseId,
          draftClaimId,
          id: draftClaimId,
          step: formStep,
          lastClaimIdSaved,
        }),
      );
      return response;
    } catch (e: any) {
      throw new Error(fileClaimBuildErrorMessage(e));
    }
  };

  const handleSubmitClaim: HandleSubmitClaim = async formValues => {
    if (!submitClaim || !onSubmissionSuccess) {
      return;
    }

    try {
      const savedClaim = await handleSaveClaim(formValues);
      setLastClaimIdSaved(savedClaim.claimId);
      const response = await submitClaim({ claimId: savedClaim.claimId });

      const { updatedAt, pcmiClaimId, id } = response;

      onSubmissionSuccess({
        submissionDate: updatedAt,
        pcmiClaimNumber: pcmiClaimId,
        claimId: id,
      });
    } catch (e: any) {
      // The error will be caught inside the FileClaimStepsContainer component
      throw new Error(fileClaimBuildErrorMessage(e));
    }
  };

  return { handleSubmitClaim, handleSaveClaim };
};
