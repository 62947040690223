import React from 'react';

interface Props {
  title: string;
  subtitle: string;
  action: React.ReactNode;
}

export const ContactSupportCard = ({ title, subtitle, action }: Props) => {
  return (
    <div className="w-5/6 flex">
      <div className="flex flex-col w-10/12 bg-reguard-gray text-center py-2  mt-5 rounded-tl-lg rounded-bl-lg">
        <div className="font-normal text-sm leading-[1.0313rem] md:s4b text-reguard-indigo text-left ml-5 mt-5">
          {title}
        </div>
        <div className="font-normal text-sm leading-[1.0313rem] md:s4b tw-cst-pf text-left ml-5 mb-5 mt-2">
          {subtitle}
        </div>
      </div>
      <div className="flex flex-col w-2/12 bg-gray-200 text-center py-2  mt-5 rounded-tr-lg rounded-br-lg hover:bg-reguard-darkgray transition-all duration-200 ease-in cursor-pointer">
        <div
          role="presentation"
          className="s4 text-reguard-indigo flex justify-center items-center h-full"
        >
          {action}
        </div>
      </div>
    </div>
  );
};
