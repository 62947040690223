import { useTranslation } from 'react-i18next';

import { ProtectionPlan } from '../../../../ui/components/models/ProtectionPlan';
import { ProtectionPlanDetailsTable } from '../protection-plan-details-table/protection-plan-details-table';
import { ProtectionPlanDetailsTabs } from '../protection-plan-details-tabs/protection-plan-details-tabs';

interface Props {
  protectionPlan: ProtectionPlan;
}
export const ProtectionPlanCoverageHighlights = (props: Props) => {
  const { protectionPlan } = props;
  const covered = protectionPlan.coveredDamages.filter(cd => cd.isCovered);
  const [firstDamage] = covered;
  const coverageTableLabels = firstDamage?.tableConfig
    ? Object.keys(firstDamage.tableConfig)
    : [];
  const { t } = useTranslation();
  return (
    <>
      <div className="visible md:hidden">
        <ProtectionPlanDetailsTabs
          tables={coverageTableLabels.map((cl, index) => (
            <ProtectionPlanDetailsTable
              key={index}
              coveredDamages={covered}
              mainHeading={t('damage')}
              columns={[cl]}
            />
          ))}
          tabNames={coverageTableLabels}
        />
      </div>

      <div className="hidden md:block w-full">
        <ProtectionPlanDetailsTable
          coveredDamages={covered}
          mainHeading={t('damage')}
          columns={coverageTableLabels}
        />
      </div>
    </>
  );
};
