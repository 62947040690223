import { useQuery } from 'react-query';
import { Address } from '@cortex/myaccount/ui/components/models/Address';
import { ProcessingStatus } from '@cortex/requests';
import { Merchant } from '@cortex/shared';

import { ContractStatus, CustomerClaimStatus } from '../../common/main';
import { useCompanyContext } from '../../context/company-context';
import initEndpoints from '../../endpoints';
import {
  Claim,
  Contract,
  getDaysUntilExpiration,
  Product,
  ProductPurchase,
  ProtectionPlan,
  Purchase,
} from '../../ui/components';
import { Client, useClient } from './client';

const { getPurchases } = initEndpoints().purchaseEndpoints();

export type PurchasePlanDetails = {
  productPurchaseId: string | undefined;
  processingStatus: ProcessingStatus;
  invoice: string;
  invoiceDate: Date;
  daysUntilExpiration: number;
  isExpired: boolean;
  isActive: boolean;
  isInactive: boolean;
  isPending: boolean | undefined;
  merchant: Merchant | undefined;
  address: Address;
  product: Product;
  protectionPlan: ProtectionPlan;
  contract: Contract;
  customerClaims: Claim[] | undefined;
  showDetails: boolean;
  purchaseDate: Date;
  delivery_date: Date;
  canFileAClaim: boolean;
  orderNumber: number | undefined;
};

const isContractExpired = (status: ContractStatus) =>
  [
    ContractStatus.Cancelled,
    ContractStatus.Expired,
    ContractStatus.Void,
  ].includes(status);

const isContractPending = contract => {
  const inactive = contract.status === ContractStatus.Inactive;

  // Shipping Insurance Specific
  if (
    (inactive && contract.shipping_status === 'processing') ||
    (inactive && contract.shipping_status === 'in transit')
  ) {
    return true;
  }
};

const getContractTypes = (contract, daysUntilExpiration: number) => {
  const isExpired =
    daysUntilExpiration <= 0 || isContractExpired(contract.status);

  const isPending = isContractPending(contract);

  let contractType: string;

  switch (contract.status) {
    case ContractStatus.Active:
      contractType = 'active';
      break;
    case isPending:
      contractType = 'pending';
      break;
    case ContractStatus.Inactive:
      contractType = 'inactive';
      break;
    default:
      contractType = isExpired ? 'expired' : 'inactive';
      break;
  }

  return {
    isActive: contract.status === ContractStatus.Active,
    isExpired,
    isInactive: contract.status === ContractStatus.Inactive,
    isPending,
    contractType,
  };
};

const getPurchaseSearchConfig = (client: Client, kind: string) => ({
  queryKey: ['purchaseSearch'],
  queryFn: () =>
    client(getPurchases.path.replace(':kind', kind), {}).then(
      (data: any) => data,
    ),
});

function usePurchasesSearch() {
  const client = useClient();
  const { kind } = useCompanyContext();
  const result = useQuery<Purchase[]>(getPurchaseSearchConfig(client, kind));
  const data: any = {
    active: [],
    expired: [],
    inactive: [],
    pending: [],
    all: [],
  };

  if (!result.data) {
    return {
      ...result,
      data,
    };
  }

  result.data?.forEach(purchase => {
    purchase.productPurchases?.forEach((productPurchase: ProductPurchase) => {
      const daysUntilExpiration = getDaysUntilExpiration(
        productPurchase.contract.endDate as string,
      );

      const contractStatus = productPurchase.contract.status;

      const contractTypes = getContractTypes(
        productPurchase.contract,
        daysUntilExpiration,
      );
      const { isActive, isExpired, isInactive, isPending, contractType } =
        contractTypes;

      const shouldFilterFE =
        // filter cancelled or ineligible products
        !!productPurchase.ineligible ||
        !!productPurchase.deletedAt ||
        ((contractStatus === ContractStatus.Void ||
          contractStatus === ContractStatus.Cancelled) &&
          productPurchase.contract.startDate === null);

      if (
        (isActive || isExpired || isInactive || isPending) &&
        !shouldFilterFE
      ) {
        const type = isPending ? 'pending' : contractType;

        const obj: PurchasePlanDetails = {
          productPurchaseId: productPurchase.id,
          processingStatus: productPurchase.contract.processing_status,
          invoice: productPurchase.invoice,
          invoiceDate: productPurchase.invoiceDate,
          daysUntilExpiration,
          isExpired,
          isActive,
          isInactive,
          isPending,
          merchant: purchase.merchant,
          address: productPurchase.address,
          product: productPurchase.product,
          protectionPlan: productPurchase.protectionPlan,
          contract: productPurchase.contract,
          customerClaims: productPurchase.customerClaims,
          showDetails: productPurchase.showDetails || isInactive,
          purchaseDate: purchase.purchaseDate,
          delivery_date: productPurchase.deliveryDate,
          canFileAClaim:
            !productPurchase.customerClaims ||
            !productPurchase.customerClaims.length ||
            (!productPurchase.customerClaims.some((cc: Claim) =>
              [
                CustomerClaimStatus.InProgress,
                CustomerClaimStatus.UnderReview,
                CustomerClaimStatus.Approved,
                CustomerClaimStatus.Draft,
                CustomerClaimStatus.Pending,
              ].includes(cc.status),
            ) &&
              isActive),
          orderNumber: purchase.orderNumber,
        };

        data[type].push(obj);
        data.all.push(obj);
      }
    });
  });

  return {
    ...result,
    data,
    refetch: result.refetch,
  };
}

export { usePurchasesSearch };
