import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useRequestEmailChange,
  useRequestPhoneChange,
} from '../network/api/users';
import { useUpdateLanguage } from '../network/api/users';
import {
  emailRules,
  phoneNumberRules,
  ProfileResetFormFields,
} from '../ui/components';
import { TextFieldProps, useDebounce, User } from '../ui/components';
import type { ProfileModalProps } from './ProfileModal';

interface FieldsConfig {
  name: string;
  type: TextFieldProps['type'];
  label: string;
  placeholder: string;
  pattern?: string;
}

interface ProfileResetFormProps {
  formType: ProfileModalProps['formType'];
  handleSubmitSuccess: (value: string, response: { timer: number }) => void;
  handleClose: () => void;
  userData: User;
}

const FIELD_CONFIG: Record<string, FieldsConfig> = {
  email: {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'john.doe@email.com',
  },
  phoneNumber: {
    name: 'phoneNumber',
    type: 'tel',
    label: 'Phone number',
    placeholder: '999-999-9999',
  },
};

const DEBOUNCE_TIMEOUT = 400;

const ProfileResetForm: FC<ProfileResetFormProps> = ({
  handleSubmitSuccess,
  formType,
  handleClose,
  userData,
}) => {
  const [fieldValue, setFieldValue] = useState('');
  const [newsletter, setNewsletter] = useState(userData.newsletter);
  const [error, setError] = useState('');
  const [backendError, setBackendError] = useState('');
  const [isValid, setValid] = useState(false);
  const {
    mutateAsync: requestLanguageChange,
    isLoading: requestingLanguageChange,
  } = useUpdateLanguage();
  const { mutateAsync: requestEmailChange, isLoading: requestingEmailChange } =
    useRequestEmailChange();
  const { mutateAsync: requestPhoneChange, isLoading: requestingPhoneChange } =
    useRequestPhoneChange();
  const isLoading =
    requestingEmailChange || requestingPhoneChange || requestingLanguageChange;
  const debounced = useDebounce(DEBOUNCE_TIMEOUT);
  const { t } = useTranslation();
  const handlePhoneMask = (value: string) => {
    const matchedValues = value
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    if (matchedValues) {
      const maskedValue = !matchedValues[2]
        ? matchedValues[1]
        : `${matchedValues[1]}-${matchedValues[2]}${
            matchedValues[3] ? `-${matchedValues[3]}` : ''
          }`;

      return maskedValue;
    }

    return matchedValues;
  };

  const validateField = (value?: string) => {
    if (!value) {
      setError(`${FIELD_CONFIG[formType].label} is mandatory`);
      setValid(false);
      return;
    }
    const skipFieldTest = formType === 'language';
    const isValid =
      formType === 'email'
        ? emailRules.test(value)
        : phoneNumberRules.test(value);

    if (!isValid && !skipFieldTest) {
      const errorMessage =
        formType === 'email' ? 'valid_email_format' : 'valid_phone_format';
      setError(t(errorMessage) as string);
      setValid(false);
      return;
    }

    setError('');
    setBackendError('');
    setValid(true);
  };

  const handleSubmit = async () => {
    let response: { timer: number } = { timer: 0 };
    try {
      if (formType === 'language') {
        localStorage.setItem('language', fieldValue);
        await requestLanguageChange({ languagePref: fieldValue });
      } else if (formType === 'email') {
        response = await requestEmailChange({ email: fieldValue, newsletter });
      } else {
        response = await requestPhoneChange({
          phoneNumber: `+1${fieldValue.replace(/\D/g, '')}`,
          newsletter,
        });
      }
      handleSubmitSuccess(fieldValue, response);
    } catch (e: any) {
      setBackendError(e?.message);
    }
  };

  const handleChange = (currentValue: string) => {
    let value = currentValue;

    if (formType === 'phoneNumber' && value) {
      value = handlePhoneMask(currentValue) || '';
    }

    setFieldValue(value);
    debounced(() => validateField(value));
  };

  const onPaste = (e: any) => {
    e.preventDefault();
    const value = e.clipboardData.getData('Text');
    const handledValue =
      formType === 'phoneNumber' ? handlePhoneMask(value) : value;

    setFieldValue(handledValue || '');

    debounced(() => validateField(handledValue || ''));
  };

  const isDisabled =
    !isValid || !!error || !!backendError || !fieldValue || isLoading;

  return (
    <ProfileResetFormFields
      setNewsletter={setNewsletter}
      newsletter={newsletter}
      formType={formType}
      isDisabled={isDisabled}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onChange={handleChange}
      onPaste={onPaste}
      error={error}
      backendError={backendError}
      fieldValue={fieldValue}
      onClose={handleClose}
      textFieldProps={FIELD_CONFIG[formType]}
    />
  );
};

export default ProfileResetForm;
