import type { ComponentPropsWithoutRef } from 'react';

export const VerticalSeparator = (props: ComponentPropsWithoutRef<'div'>) => (
  <div
    {...props}
    className={`h-auto mx-2 pt-2 ${props.className}`}
    role="separator"
  >
    <div className="w-px h-full bg-reguard-inactive2 rounded"></div>
  </div>
);
