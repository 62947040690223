import type { FC, ReactNode } from 'react';

import PurpleDotSVG from './assets/PurpleDot.svg';

interface BulletPointItemProps {
  children?: ReactNode;
  textClassName?: string;
  className?: string;
}

export const BulletPointItem: FC<BulletPointItemProps> = ({
  children,
  textClassName = '',
  className = '',
}) => (
  <div className={`flex gap-2 items-center my-2 ${className}`}>
    <div className="w-3 h-3">
      <PurpleDotSVG />
    </div>
    <div className={`b3b ${textClassName}`}>{children}</div>
  </div>
);
