import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toI18nKey } from '@cortex/myaccount/common/utils/utils';
import { ProcessingStatus } from '@cortex/requests';
import parsePhoneNumberFromString from 'libphonenumber-js';

import FacPencil from './assets/FacPencil.svg';
import type {
  Contract,
  ContractAddress,
  ContractPhoneNumber,
} from './models/Contract';
import type { ProtectionPlan } from './models/ProtectionPlan';
import { ContractStatus } from '../../common/constants/contracts';
import { getDateString } from '../../common/utils/usa-dates';
import { CollapsibleSection } from './CollapsibleSection';
import { UpdatePhoneNumber } from './UpdatePhoneNumber';
import { UpdateProtectionPlanAddress } from './UpdateProtectionPlanAddress';

interface AddressDataType {
  address: ContractAddress;
  contractId: string;
}

interface PhoneNumberDataType {
  phoneNumber: ContractPhoneNumber;
  contractId: string;
}

interface PlanDetailsProps {
  contract: Contract;
  plan: ProtectionPlan & {
    endDate?: string;
    startDate?: string;
    status: ContractStatus;
  };
  updateContractAddress: (address: AddressDataType) => void;
  updateContractPhone: (phoneNumber: PhoneNumberDataType) => void;
}

export type AddressInputsType = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip_code: string;
};

const labelStyle =
  'w-full max-w-[10rem] mb-1 text-lg leading-[1.25rem] text-reguard-indigo-tint text-left';
const infoStyle =
  'text-base leading-[1.125rem] semibold text-reguard-indigo text-left';

export const PlanDetailsBox: FC<PlanDetailsProps> = (
  props: PlanDetailsProps,
) => {
  const { contract, plan, updateContractAddress, updateContractPhone } = props;
  const planTermYears = `${plan.duration} ${plan.period}`;
  const { t } = useTranslation();

  const endDate = plan.endDate && getDateString(plan.endDate);
  const startDate = plan.startDate && getDateString(plan.startDate);
  const showDates = endDate && startDate;
  const planTerm = showDates
    ? `${planTermYears} (${startDate} – ${endDate})`
    : planTermYears;
  const [showModifyAddressModal, setShowModifyAddressModal] =
    useState<boolean>(false);
  const [address, setAddress] = useState<ContractAddress>(contract.address);
  const [showPhoneNumberModal, setShowPhoneNumberModal] =
    useState<boolean>(false);
  const [phone, setPhone] = useState({
    primary: contract.phoneNumber?.primary,
    secondary: contract.phoneNumber?.secondary,
  });
  const primaryPhone = parsePhoneNumberFromString(phone?.primary || '', 'US');
  const secondaryPhone = parsePhoneNumberFromString(
    phone?.secondary || '',
    'US',
  );

  if (showModifyAddressModal) {
    return (
      <UpdateProtectionPlanAddress
        contract={contract}
        updateContractAddress={updateContractAddress}
        setShowModifyAddressModal={setShowModifyAddressModal}
        address={address}
        setAddress={setAddress}
      />
    );
  }

  if (showPhoneNumberModal) {
    return (
      <UpdatePhoneNumber
        contract={contract}
        updateContractPhone={updateContractPhone}
        setShowPhoneNumberModal={setShowPhoneNumberModal}
        phoneNumber={phone}
        setPhoneNumber={setPhone}
      />
    );
  }

  return (
    <>
      <div className="flex w-full md:hidden">
        <CollapsibleSection
          title={
            <div className="flex flex-col md:flex-row">
              <div className={labelStyle}>{t('plan_type')}</div>
              <div className={infoStyle}>{plan.description}</div>
            </div>
          }
          className="pl-0 pr-0"
          contentContainerClassName="flex gap-4 flex-col"
          rootClassName="w-full md:hidden"
        >
          <div className="flex flex-col md:flex-row">
            <div className={labelStyle}>{t('plan_term')}</div>
            <div className={infoStyle}>{planTerm}</div>
          </div>

          <div className="flex flex-col md:flex-row  min-w-0">
            <div className={labelStyle}>{t('contract')} #</div>
            <div className={infoStyle}>
              {plan.status === ContractStatus.Inactive
                ? 'Not yet Assigned'
                : contract.pcmiContractNumber
                  ? contract.pcmiContractNumber
                  : contract.id}
            </div>
          </div>
        </CollapsibleSection>
      </div>

      <div className="hidden md:flex gap-4 flex-col py-2">
        <div className="flex flex-col md:flex-row">
          <div className={labelStyle}>Plan type</div>
          <div className={infoStyle}>{plan.description}</div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className={labelStyle}>Plan Status</div>
          <div className={infoStyle}>
            {contract.processing_status === ProcessingStatus.PROCESSING
              ? t('pending_activation')
              : t(toI18nKey(contract.status))}
          </div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className={labelStyle}>Plan term</div>
          <div className={infoStyle}>{planTerm}</div>
        </div>

        <div className="flex flex-col md:flex-row min-w-0">
          <div className={labelStyle}>Contract #</div>
          <div className={infoStyle}>
            {plan.status === ContractStatus.Inactive
              ? 'Not yet Assigned'
              : contract.pcmiContractNumber
                ? contract.pcmiContractNumber
                : contract.id}
          </div>
        </div>
        <div className="flex flex-col md:flex-row min-w-0">
          <div className={labelStyle}>Address</div>
          {plan.status === ContractStatus.Inactive ? (
            <div className={infoStyle}>Not yet Assigned</div>
          ) : (
            <div className={infoStyle}>
              {address
                ? address.address1 +
                  ', ' +
                  address.address2 +
                  ' ' +
                  address.city +
                  ', ' +
                  address.state +
                  ', ' +
                  address.zip_code
                : 'Not yet Assigned'}
              <button
                type="button"
                className="tw-cst-pf bg-transparent ml-8"
                onClick={() => setShowModifyAddressModal(true)}
              >
                <div className="w-4 h-4 relative top-0.5 text-black">
                  <FacPencil />
                </div>
              </button>
            </div>
          )}
        </div>

        <div className="flex flex-col md:flex-row min-w-0">
          <div className={labelStyle}>Phone</div>
          {plan.status === ContractStatus.Inactive ? (
            <div className={infoStyle}>Not yet Assigned</div>
          ) : (
            <div className={infoStyle}>
              <span>
                {primaryPhone
                  ? primaryPhone.formatNational()
                  : 'Primary Phone - Not yet Assigned'}
              </span>

              <br />

              <span>
                {secondaryPhone
                  ? secondaryPhone.formatNational()
                  : 'Secondary Phone - Not yet Assigned'}
              </span>
              <button
                type="button"
                className="tw-cst-pf bg-transparent ml-8"
                onClick={() => setShowPhoneNumberModal(true)}
              >
                <div className="w-4 h-4 relative top-0.5 text-black">
                  <FacPencil />
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
