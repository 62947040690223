import type { ComponentPropsWithoutRef, FC } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

const childrenStyle =
  'max-h-24 flex w-full md:max-w-[37.75rem] items-center sm:max-h-[12.375rem] sm:w-[calc(50%_-_0.5rem)] lg:w-[calc(50%_-_1rem)]';

export const CardsGrid: FC<ComponentPropsWithoutRef<'div'>> = ({
  className,
  children,
  ...props
}) => (
  <div
    className={`flex flex-1 flex-wrap gap-y-2 sm:gap-4 md:gap-4 lg:gap-8 ${className}`}
    {...props}
    role="presentation"
  >
    {Children.map(children, child => {
      return (
        isValidElement(child) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cloneElement(
          child as React.ReactElement<ComponentPropsWithoutRef<any>>,
          { className: `${child.props.className || ''} ${childrenStyle}` },
          null,
        )
      );
    })}
  </div>
);
