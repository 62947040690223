import {
  StepFourInitialState,
  StepOneInitialState,
  StepThreeInitialState,
  StepTwoInitialState,
} from '../helpers/facFieldsInitialValues';

export const FIELDS_INITIAL_STATE = {
  ...StepOneInitialState,
  ...StepTwoInitialState,
  ...StepThreeInitialState,
  ...StepFourInitialState,
};
