import type { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { buttonSelectors } from './tests/dataCySelectors';
import { ButtonWithArrow } from './ButtonWithArrow';

interface ClaimInfoTableProps {
  claimID: string;
  damageCause: string;
  onDetailsClick: MouseEventHandler;
}

export const ClaimInfoTable: FC<ClaimInfoTableProps> = (
  props: ClaimInfoTableProps,
) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col md:flex-row justify-between py-4 pr-4 gap-6 max-w-[600px]">
      <div className="flex flex-wrap gap-8 md:gap-12 items-start w-full">
        <TableCell label="Claim ID" value={props.claimID} />
        <div className="flex items-end flex-1 justify-between ml-4 md:ml-0">
          <TableCell label="Damage Cause" value={props.damageCause} />
          <div className="flex items-center self-start min-w-fit">
            <ButtonWithArrow
              direction="right"
              onClick={props.onDetailsClick}
              data-cy={buttonSelectors.seeClaimDetailsBtn}
            >
              <span className="b3">{t('see_claim_details')}</span>
            </ButtonWithArrow>
          </div>
        </div>
      </div>
    </div>
  );
};

interface TableCellProps {
  label: string;
  value: string;
}

const TableCell: FC<TableCellProps> = (props: TableCellProps) => (
  <div className="flex flex-col gap-1">
    <div className="b3b text-reguard-indigo-tint">{props.label}</div>
    <div className="b2b text-reguard-indigo">{props.value}</div>
  </div>
);
