import { useMutation, useQuery, useQueryClient } from 'react-query';

import initEndpoints from '../../endpoints';
import type { Claim } from '../../ui/components';
import { Client, useClient } from './client';
import getParamsString from './getParamsString';

const {
  getClaims,
  getClaimById,
  getClaimForFormById,
  saveClaimStep,
  submitClaim,
  removeDraftClaim,
  addClaimPhotos,
} = initEndpoints().claimEndpoints();
const { getDomainValues } = initEndpoints().domainEndpoints();

const getClaimsSearchConfig = (client: Client) => ({
  queryKey: ['claimsSearch'],
  queryFn: () => client(getClaims.path, {}).then((data: any) => data),
});

function useClaimsSearch() {
  const client = useClient();
  const result = useQuery(getClaimsSearchConfig(client));

  return {
    ...result,
    data: result.data as Claim[],
  };
}

const getClaimByIdConfig = (client: Client, id: string) => ({
  queryKey: ['claimDetail', id],
  queryFn: () =>
    client(getClaimById.path.replace(':id', id), {}).then((data: any) => data),
});

function useGetClaimById(id) {
  const client = useClient();
  const result = useQuery(getClaimByIdConfig(client, id));

  return {
    ...result,
    data: result.data as Claim,
  };
}

const defaultMutationOptions = (options = {}) => ({
  onError: (_err: string, _variables: any, recover: unknown) =>
    typeof recover === 'function' ? recover() : null,
  ...options,
});

function useSaveClaimStep(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      client(saveClaimStep.path, {
        method: saveClaimStep.method,
        data,
        customConfig: {
          body: data,
          credentials: 'include',
          headers: {},
        },
      }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.refetchQueries('claimsSearch'),
    },
  );
}

function useSubmitClaim(options = {}, isSI = false) {
  const client = useClient();

  return useMutation(
    data => {
      const modifiedData = { ...data, isSI };

      return client(submitClaim.path, {
        method: submitClaim.method,
        data: modifiedData,
      });
    },
    {
      ...defaultMutationOptions(options),
    },
  );
}

function useAddClaimPhotos(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      client(addClaimPhotos.path, {
        method: addClaimPhotos.method,
        data,
        customConfig: {
          body: data,
          credentials: 'include',
          headers: {},
        },
      }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('claimDetail'),
    },
  );
}

function useGetClaimForFormById({ claimId }: { claimId: string }) {
  const client = useClient();
  const result = useQuery(
    ['getClaimForFormById', claimId],
    () => client(getClaimForFormById.path.replace(':id', claimId), {}),
    { enabled: !!claimId },
  );

  return { ...result, data: result.data ?? [] };
}

function useGetDomainValues(params: any) {
  const client = useClient();
  const result = useQuery<Record<string, any>, Error>(
    ['getDomainValues', {}],
    () =>
      client(
        `${getDomainValues.path.replace(':domainType', 'all')}${getParamsString(
          params,
        )}`,
        {},
      ),
  );

  return { ...result, data: result.data ?? [] };
}

function useRmDraftClaim() {
  const client = useClient();
  const queryClient = useQueryClient();
  const result = useMutation(
    data =>
      client(removeDraftClaim.path.replace(':id', data.claimId), {
        method: removeDraftClaim.method,
        data,
      }),
    {
      ...defaultMutationOptions({ enabled: false }),
      onSuccess: () => {
        queryClient.invalidateQueries('claimsSearch');
        queryClient.invalidateQueries('purchaseSearch');
      },
      onMutate: async changedClaim => {
        await queryClient.cancelQueries({ queryKey: ['claimsSearch'] });

        queryClient.setQueryData(['claimsSearch'], (previousClaims: any) => {
          const optimisticData = previousClaims
            .map(oldClaim => {
              if (oldClaim.id === changedClaim.claimId) {
                return null;
              }

              return {
                ...oldClaim,
              };
            })
            .filter(claim => claim !== null);

          return optimisticData;
        });

        return {};
      },
    },
  );

  return { ...result, data: result.data ?? [] };
}

export {
  useClaimsSearch,
  useGetClaimForFormById,
  useGetDomainValues,
  useSaveClaimStep,
  useSubmitClaim,
  useRmDraftClaim,
  useAddClaimPhotos,
  useGetClaimById,
};
