import React, { createContext, ReactNode, useContext, useState } from 'react';
import {
  useSaveClaimStep,
  useSubmitClaim,
} from '@cortex/myaccount/network/api/claims';
import { usePurchasesSearch } from '@cortex/myaccount/network/api/purchases';
import { useMyUser } from '@cortex/myaccount/network/api/users';

import { FileClaimContextValues } from '../types/fileClaimTypes';

export interface FileClaimContextProviderProps {
  children: ReactNode;
}

const FileClaimContext = createContext<FileClaimContextValues>(
  {} as FileClaimContextValues,
);

export function FileClaimContextProvider({
  children,
}: FileClaimContextProviderProps) {
  const { data: userData, isFetching: isFetchingUser } = useMyUser({
    refetchOnMount: false,
  });
  const { data: purchases, isFetching: isFetchingPurchase } =
    usePurchasesSearch();

  const { mutateAsync: saveClaimStep, isLoading: isSavingStep } =
    useSaveClaimStep();
  const { mutateAsync: submitClaim, isLoading: isSubmitting } = useSubmitClaim(
    {},
    true,
  );

  const [requestError, setRequestError] = useState('');
  const [shouldBlockNavigation, setNavigationBlocking] = useState(true);

  return (
    <FileClaimContext.Provider
      value={{
        userData,
        isFetchingUser,
        children,
        purchases,
        isFetchingPurchase,
        saveClaimStep,
        isSavingStep,
        submitClaim,
        isSubmitting,
        requestError,
        setRequestError,
        shouldBlockNavigation,
        setNavigationBlocking,
      }}
    >
      {children}
    </FileClaimContext.Provider>
  );
}

export function useFileClaimContext() {
  return useContext(FileClaimContext);
}
