import type { ComponentPropsWithoutRef, ReactNode } from 'react';

interface CardProps extends ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  className?: string;
  handleClick?: () => void;
}

export function Card({
  children,
  className,
  handleClick,
  ...props
}: CardProps) {
  return (
    <div
      className={`shadow-card p-2 md:p-6 bg-white rounded-lg ${
        className || ''
      }`}
      {...props}
    >
      {handleClick ? (
        <button
          className="w-full h-full flex items-center focus:border-reguard-violet focus-visible:border-reguard-violet focus:outline-reguard-violet focus-visible:outline-reguard-violet"
          onClick={handleClick}
        >
          {children}
        </button>
      ) : (
        children
      )}
    </div>
  );
}
