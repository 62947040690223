import { useEffect } from 'react';
import React from 'react';
import CookieBot from 'react-cookiebot';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom';
import { FlagsmithProvider } from '@cortex/shared';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { ErrorPage } from './ui/components/ErrorPage';
import App from './App';
import config from './config';
import { store } from './store';

import './ui/components/Theme.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if ((error as any).status === 404 || (error as any).status === 401)
          return false;
        else if (failureCount < 2) return true;
        else return false;
      },
    },
    mutations: {},
  },
});

const { dsn, tracesSampleRate, environment, version } = config.sentry;
try {
  Sentry.init({
    release: version,
    environment: environment,
    dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    // TODO:  adjust the tracesSampleRate in production
    tracesSampleRate: tracesSampleRate,
  });
} catch (error: any) {
  console.error('Sentry config error in the customer portal: ', error);
}

// TODO: improve to use shared error codes and messages
const FallBack = () => {
  const navigate = useNavigate();

  return (
    <ErrorPage
      message="Something went wrong! Unfortunately we could not find this page"
      status={404}
      callToAction={() => {
        navigate('/');
        window.location.reload();
      }}
      ctaLabel="Back to Home"
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <FlagsmithProvider {...config.flagsmith}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <Sentry.ErrorBoundary showDialog fallback={<FallBack />}>
            <CookieBot domainGroupId={config.cookieBot.domainId} />
            <App />
          </Sentry.ErrorBoundary>
        </Provider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </FlagsmithProvider>,
);
