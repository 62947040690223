import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { useBlocker } from './util/useBlockTransition';
import {
  checkClaimFormFilled,
  FileClaimFields,
  FileClaimStep,
  SmallDialog,
  SmallDialogButtonMapType,
} from '../ui/components';

interface FileClaimBlockerProps {
  onSaveStep: (formValues: FileClaimFields) => Promise<any>;
  isFetching?: boolean;
  formStep: FileClaimStep;
}

export const FileClaimBlocker: FC<FileClaimBlockerProps> = ({
  isFetching,
  onSaveStep,
  formStep,
}) => {
  const [isDraftModalVisible, showDraftModal] = useState(false);
  const [isDiscardClicked, setIsDiscardClicked] = useState(false);
  const { values, validateForm, isValid } = useFormikContext<FileClaimFields>();
  const { t } = useTranslation();
  const callBack = useCallback(
    (tx: { retry: () => void }) => {
      if (!isDraftModalVisible && !isDiscardClicked) {
        showDraftModal(true);
        return;
      }

      tx.retry();
    },
    [isDraftModalVisible, isDiscardClicked],
  );

  const { continueNavigation } = useBlocker(callBack);

  if (!isDraftModalVisible) {
    return null;
  }

  return (
    <SmallDialog
      title={t('want_to_save_draft')}
      subtitle={t('save_your_draft_and_complete_later')}
      onClose={() => {
        showDraftModal(false);
      }}
      buttonsConfig={[
        {
          type: SmallDialogButtonMapType.SAVE_DRAFT,
          handleClick: async () => {
            if (!isValid || !checkClaimFormFilled(values, formStep)) {
              validateForm();
              showDraftModal(false);
              return;
            }
            try {
              await onSaveStep(values);
              continueNavigation();
            } catch (e: any) {
              showDraftModal(false);
            }
          },
          isFetching,
        },
        {
          type: SmallDialogButtonMapType.DISCARD_UPDATES,
          handleClick: () => {
            setIsDiscardClicked(true);
            showDraftModal(false);
            continueNavigation();
          },
          disabled: isFetching,
        },
      ]}
    />
  );
};
