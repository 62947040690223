import type { SimpleSelectOption } from '../types/types';

export const Languages: SimpleSelectOption[] = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
];
