const getParamsString = (params: Record<string, string>) => {
  const entries = Object.entries(params);

  if (entries.length === 0) {
    return '';
  }

  return entries.reduce<string>((acc, [key, value], index) => {
    return `${acc}${key}=${value}${index < entries.length - 1 ? '&' : ''}`;
  }, '?');
};

export default getParamsString;
