import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useUpdateUserContractAddress,
  useUpdateUserContractPhone,
} from '../../../../network/api/users';
import {
  Contract,
  PlanDetailsBox,
  ProtectionPlan,
} from '../../../../ui/components';

interface Props {
  contract: Contract;
  protectionPlan: ProtectionPlan;
}
export function ProtectionPlanDetails({ contract, protectionPlan }: Props) {
  const { t } = useTranslation();
  const { mutateAsync: updateContractAddress } = useUpdateUserContractAddress();
  const { mutateAsync: updateContractPhoneNumber } =
    useUpdateUserContractPhone();

  return (
    <>
      <h3 className="tw-cst-pf hidden md:block text-4xl semibold text-reguard-indigo pb-2">
        {t('protection_plan_details')}
      </h3>
      <div className="flex justify-between items-end md:items-center pb-4">
        <p className="tw-cst-pf b text-xl text-reguard-indigo">
          {t(
            'please_note_these_are_all_the_products_potentially_covered_by_your_reguard_plan',
          )}
        </p>
      </div>
      <PlanDetailsBox
        contract={contract}
        plan={{
          ...protectionPlan,
          endDate: contract.endDate,
          startDate: contract.startDate,
          status: contract.status,
        }}
        updateContractAddress={updateContractAddress}
        updateContractPhone={updateContractPhoneNumber}
      />
    </>
  );
}
