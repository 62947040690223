//eslint-disable-next-line
const NON_ASCII_REGX = /[^\x00-\x7F]/g;

export const removeNonAscii = (value?: string) => {
  if (!value) return '';
  return value.replace(NON_ASCII_REGX, '');
};

export const sanitizeFilename = (filename?: string) => {
  return removeNonAscii(filename).replace(/\s+/g, '');
};
