import type { ComponentPropsWithoutRef, FC, MouseEventHandler } from 'react';

import ArrowSVG from './assets/LeftArrow.svg';

interface BreadCrumbsProps extends ComponentPropsWithoutRef<'div'> {
  crumbs: string[];
  onBack: MouseEventHandler;
}

export const BreadCrumbs: FC<BreadCrumbsProps> = ({
  crumbs,
  onBack,
  ...divProps
}) => {
  const isLast = (idx: number) => idx === crumbs.length - 1;

  const { className, ...restDivProps } = divProps;

  return (
    <div
      {...restDivProps}
      className={`flex gap-4 items-center md:px-6 px-3 pb-4 pt-2 mb-2 ${className}`}
    >
      <button onClick={onBack}>
        <ArrowSVG />
      </button>

      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className="cursor-pointer" onClick={onBack}>
        {crumbs.map((crumb, i) => (
          <span key={i} className={`text-xs ${isLast(i) ? 'semibold' : ''}`}>
            {crumb}
            {!isLast(i) ? ' / ' : ''}
          </span>
        ))}
      </div>
    </div>
  );
};
