import { GTAGClaimCategory } from '../enums/enums';
import { FileClaimFields } from './facFieldsInitialValues';

export const getIsFetching = (
  isFetchingPurchase,
  isFetchingUser,
  isSubmitting,
  isSavingStep,
) => {
  return isFetchingPurchase || isFetchingUser || isSubmitting || isSavingStep;
};

export const getInitialValues = (
  draftClaimId,
  userData,
  prodToFileClaimAgainst,
  draftClaimFormData,
) => {
  return draftClaimId
    ? {
        phoneNumber: userData.phoneNumber,
        address: prodToFileClaimAgainst?.address?.id,
        ...draftClaimFormData,
        dateOfDamage: '',
        locationOfDamage: null,
      }
    : {
        address: prodToFileClaimAgainst?.address?.id,
        phoneNumber: userData.phoneNumber,
        dateOfDamage: '',
        locationOfDamage: null,
      };
};

export const handleOnSaveStep = async (
  valuesForSave: FileClaimFields,
  saveStepObj,
) => {
  const {
    setRequestError,
    handleSaveClaim,
    customEvent,
    draftClaimId,
    setNavigationBlocking,
  } = saveStepObj;
  setRequestError('');
  try {
    const response = await handleSaveClaim(valuesForSave);
    customEvent({
      name: 'save_claim',
      options: {
        item_id: response.claimId,
        item_category: draftClaimId
          ? GTAGClaimCategory.DRAFT
          : GTAGClaimCategory.NEW_CLAIM,
      },
    });
  } catch (e: any) {
    setNavigationBlocking(true);
    setRequestError(e.message);
    throw e;
  }
};

export const handleOnSubmitClaim = async (
  formValues: FileClaimFields,
  submitClaimObj,
) => {
  const {
    setRequestError,
    handleSubmitClaim,
    customEvent,
    onSubmissionSuccess,
  } = submitClaimObj;

  setRequestError('');
  try {
    const response = await handleSubmitClaim(formValues);
    const { updatedAt, pcmiClaimId, id } = response;
    customEvent({
      name: 'submit_claim',
      options: {
        item_id: id,
        pcmi_id: pcmiClaimId,
      },
    });
    onSubmissionSuccess({
      submissionDate: updatedAt,
      pcmiClaimNumber: pcmiClaimId,
      claimId: id,
    });
  } catch (e: any) {
    setRequestError(e.message);
  }
};
