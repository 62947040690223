import axios, { AxiosError, AxiosResponse } from 'axios';

import { config } from './config';
import { logoutHandler } from './handlers';
import { SourceError } from './models';

export type Params = Record<string, unknown>;

export interface ParamsType {
  path: string;
  method: string;
  host?: string;
  pathParams?: Params;
  params?: Params;
  body?: unknown;
}

export function newBase(url: string) {
  return async function req<Data>(params: ParamsType) {
    const host = url;
    const timeout = 60000;

    const instance = axios.create({
      withCredentials: true,
      baseURL: params.host || host,
      timeout,
    });

    instance.interceptors.response.use(undefined, async (error: AxiosError) => {
      if (isAuthError(error)) logoutHandler();
      return Promise.reject(error);
    });

    if (params.pathParams) {
      params.path = compileUrl(params.path, params.pathParams);
    }

    try {
      const result: AxiosResponse<Data> = await instance({
        url: params.path,
        method: params.method,
        params: params.params,
        data: params.body,
      });

      return result.data as Data;
    } catch (error) {
      throw SourceError.fromAxiosError(error as AxiosError);
    }
  };
}

function isAuthError(error: AxiosError) {
  return error?.response?.status === 401;
}

function compileUrl(template: string, params: Params) {
  return template.replace(/:([a-zA-Z0-9_]+)/g, (_, key) => `${params[key]}`);
}

export const req = newBase(config.api.url);
