import type { FC } from 'react';
import { components, MenuListProps } from 'react-select';

interface MenuListCustomProps extends MenuListProps {
  enterManually: () => void;
}

const MenuList: FC<MenuListCustomProps> = ({
  children,
  className,
  enterManually,
  ...props
}) => {
  return (
    <components.MenuList
      className={`px-4 pb-2 border-b border-b-reguard-pearl-shade flex flex-col min-w-0 w-full max-w-[17.938rem] h-full max-h-[19rem] ${className}`}
      {...props}
    >
      {children}
      <div className="w-full">
        <button
          onClick={enterManually}
          className="tw-cst-pf bg-transparent flex w-full b2 text-reguard-wintergreen-shade mt-3 mb-4"
        >
          I’ll enter my address manually.
        </button>
        <a
          href="https://www.mapbox.com/search-service"
          rel="noopener noreferrer"
          target="_blank"
          className="tw-cst-pf text-reguard-pearl-shade"
        >
          Powered by MapBox
        </a>
      </div>
    </components.MenuList>
  );
};

export default MenuList;
