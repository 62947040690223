import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import parsePhoneNumberFromString from 'libphonenumber-js';
import * as yup from 'yup';

import type { Contract, ContractPhoneNumber } from './models/Contract';
import { BrandedDialog } from './BrandedDialog';
import { Button } from './Button';
import { notify } from './Toast';

interface PhoneNumberDataType {
  phoneNumber: ContractPhoneNumber;
  contractId: string;
}

interface UpdateProtectionPlanPhonePropsType {
  contract: Contract;
  updateContractPhone: (phoneNumber: PhoneNumberDataType) => void;
  setShowPhoneNumberModal: (setShowPhoneNumberModal: boolean) => void;
  phoneNumber: ContractPhoneNumber;
  setPhoneNumber: (phoneNumber: ContractPhoneNumber) => void;
}

const parseNumber = (phoneNumber: string) => {
  return parsePhoneNumberFromString(phoneNumber, 'US');
};

export const UpdatePhoneNumber = ({
  contract,
  updateContractPhone,
  setShowPhoneNumberModal,
  phoneNumber,
  setPhoneNumber,
}: UpdateProtectionPlanPhonePropsType) => {
  const { t } = useTranslation();
  const primaryPhone = parseNumber(phoneNumber?.primary || '');
  const secondaryPhone = parseNumber(phoneNumber?.secondary || '');

  const schema = yup
    .object()
    .shape({
      primary: yup
        .string()
        .required('Primary phone is required')
        .default('')
        .min(10, 'You must enter a primary phone'),
      secondary: yup.string().optional().default(''),
    })
    .required();

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<ContractPhoneNumber>({
    defaultValues: {
      primary: primaryPhone ? primaryPhone.formatNational() : '',
      secondary: secondaryPhone ? secondaryPhone.formatNational() : '',
    },
    criteriaMode: 'all',
    // This one is a known issue - https://github.com/react-hook-form/resolvers/issues/245
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const handlePhoneChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: 'primary' | 'secondary',
  ) => {
    const phoneNumber = parseNumber(event?.target?.value || '');

    if (phoneNumber) {
      return setValue(field, phoneNumber.formatNational());
    }
    return setValue(field, event.target.value);
  };

  const handlePhoneSubmission = (data: any) => {
    const primaryPhone = parseNumber(data?.primary || '');
    const secondaryPhone = parseNumber(data?.secondary || '');

    const phoneData = {
      phoneNumber: {
        primary: primaryPhone ? primaryPhone?.nationalNumber : '',
        secondary: secondaryPhone ? secondaryPhone?.nationalNumber : '',
      },
      contractId: contract.id,
    };

    updateContractPhone(phoneData);
    setPhoneNumber(data);
    setShowPhoneNumberModal(false);
    notify({
      title: t('phone_number_saved'),
      options: {
        type: 'success',
      },
    });
  };

  return (
    <BrandedDialog
      title="Edit Phone Number"
      subtitle={t(
        'this_contact_information_will_be_used_to_help_service_your_claims',
      )}
      contentClass="max-w-[100rem] !pt-32"
      handleClose={() => {
        setShowPhoneNumberModal(false);
      }}
    >
      <form
        className="bg-white sm:rounded-xl md:col-span-2"
        onSubmit={handleSubmit(handlePhoneSubmission)}
      >
        <div>
          <div className="mt-4 mb-4">
            <div>
              <label htmlFor="primary-phone">Primary Phone</label>
            </div>
            <input
              className="border-solid border-2 border-black rounded-md w-full"
              {...register('primary', {
                required: t('please_enter_primary_phone') as string,
              })}
              onChange={e => handlePhoneChange(e, 'primary')}
              maxLength={10}
            />
            <div className="text-red-600">{errors?.primary?.message}</div>
          </div>

          <div className="mb-4">
            <div>
              <label htmlFor="secondary-phone">Secondary Phone</label>
            </div>
            <input
              className="border-solid border-2 border-black rounded-md w-full"
              {...register('secondary')}
              onChange={e => handlePhoneChange(e, 'secondary')}
              maxLength={10}
            />
            <div className="text-red-600 mb-2">
              {errors?.secondary?.message}
            </div>
          </div>

          <Button title="Save" type="submit" className="max-w-[100%]">
            Submit
          </Button>
        </div>
      </form>
    </BrandedDialog>
  );
};
