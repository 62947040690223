import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomerClaimStatus } from '@cortex/myaccount/common/main';
import {
  useClaimsSearch,
  useRmDraftClaim,
} from '@cortex/myaccount/network/api/claims';
import { usePurchasesSearch } from '@cortex/myaccount/network/api/purchases';
import { ClaimsContainer } from '@cortex/myaccount/ui/components/ClaimsContainer';
import { MainPage } from '@cortex/myaccount/ui/components/MainPage';
import { MainSection } from '@cortex/myaccount/ui/components/MainSection';
import { Claim } from '@cortex/myaccount/ui/components/models/Claim';
import { SmallDialog } from '@cortex/myaccount/ui/components/SmallDialog';
import { SmallDialogButtonMapType } from '@cortex/myaccount/ui/components/SmallDialog';

export const SIMyClaims = () => {
  const { data: claims } = useClaimsSearch();
  const { data: purchases } = usePurchasesSearch();
  const [modalOpen, setModalOpen] = useState(false);
  const [claimId, setClaim] = useState('');
  const { mutateAsync: removeDraft } = useRmDraftClaim();
  const hasProductsToClaim = !!purchases.active.filter(
    (data: typeof purchases.active) => data.canFileAClaim,
  ).length;

  const { t } = useTranslation();

  const navigate = useNavigate();
  const goToDetails = (id: string) => {
    navigate(`/safeship/claims/${id}`);
  };
  const goToFileAClaim = (id: string) => {
    navigate(`/safeship/claims/new?productPurchaseId=${id}`);
  };

  const closeModalHandler = () => {
    setModalOpen(false);
  };

  const removeHandler = async () => {
    setModalOpen(false);

    await removeDraft({ claimId });
  };

  const removeDraftHandler = (claimId: string) => {
    setClaim(claimId);
    setModalOpen(!modalOpen);
  };
  const emptyMessage: string = hasProductsToClaim
    ? 'you_have_no_prior_claims_please_click_"file_a_claim"_to_file'
    : 'all_your_plans_are_expired';

  // manage sections visibility
  let draftClaims: Claim[] = [];
  let openClaims: Claim[] = [];
  let closedClaims: Claim[] = [];

  const siClaims =
    (claims &&
      claims.length > 0 &&
      claims?.filter(claim => claim.kind === 'SI')) ||
    [];

  if (siClaims?.length > 0) {
    draftClaims = siClaims.filter(
      (c: Claim) => c.status === CustomerClaimStatus.Draft,
    );
    openClaims = siClaims.filter((c: Claim) =>
      [
        CustomerClaimStatus.UnderReview,
        CustomerClaimStatus.InProgress,
        CustomerClaimStatus.Approved,
      ].includes(c.status),
    );
    closedClaims = siClaims.filter(
      (c: Claim) => c.status === CustomerClaimStatus.Closed,
    );
  }

  const hasDraft = !!draftClaims.length;
  const hasOpen = !!openClaims.length;
  const hasClosed = !!closedClaims.length;
  const hasAny = hasDraft || hasOpen || hasClosed;

  return (
    <MainPage title={t('claims') as string}>
      {modalOpen && (
        <SmallDialog
          title={t('do_you_wish_to_delete_the_draft') as string}
          subtitle={t('this_action_cannot_be_undone') as string}
          buttonsConfig={[
            {
              type: SmallDialogButtonMapType.DELETE,
              handleClick: removeHandler,
            },
            {
              type: SmallDialogButtonMapType.CANCEL,
              handleClick: closeModalHandler,
            },
          ]}
        />
      )}
      {/* draft */}
      {hasDraft && (
        <MainSection title={t('draft_claims') as string}>
          <ClaimsContainer
            claims={draftClaims}
            onDetails={(id: string) => {
              goToFileAClaim(id);
            }}
            onRemoveDraft={removeDraftHandler}
          />
        </MainSection>
      )}

      {/* open */}
      {hasOpen && (
        <MainSection title={t('open_claims') as string}>
          <ClaimsContainer
            claims={openClaims}
            onDetails={(id: string) => {
              goToDetails(id);
            }}
          />
        </MainSection>
      )}

      {/* closed */}
      {hasClosed && (
        <MainSection title={t('closed_claims') as string}>
          <ClaimsContainer
            claims={closedClaims}
            onDetails={(id: string) => {
              goToDetails(id);
            }}
          />
        </MainSection>
      )}

      {/* no claims */}
      {!hasAny && (
        <MainSection title={t('my_claims') as string}>
          <div className="text-sm py-8 px-6 mb-20">{t(emptyMessage)}</div>
        </MainSection>
      )}
    </MainPage>
  );
};
