import { FC, useState } from 'react';
import {
  components,
  DropdownIndicatorProps,
  GroupBase,
  MenuListProps,
  OptionsOrGroups,
  PlaceholderProps,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import * as Sentry from '@sentry/react';

import { colors } from '../../../theme';
import debounce from '../helper/debounce';
import type { Address } from '../models/Address';
import ReactSelectIndicator from '../ReactSelectIndicator';
import CustomOption from './CustomOption';
import LoadingMessage from './LoadingMessage';
import MenuList from './MenuList';
import {
  AddressInputProps,
  MapBoxAddressContext,
  MapBoxAddressResults,
  SelectOption,
} from './types';

const extractAddressObj = (mapBoxCtx: MapBoxAddressContext[]) => {
  return mapBoxCtx.reduce((acc, { id, text, short_code }) => {
    if (id.includes('postcode')) {
      return { ...acc, zipCode: text };
    }

    if (id.includes('place')) {
      return { ...acc, city: text };
    }

    if (id.includes('region')) {
      return { ...acc, state: text, shortCode: short_code?.split('-')[1] };
    }

    return acc;
  }, {});
};

const handleLocationResults = (
  results: MapBoxAddressResults,
  withCoordinates?: boolean,
) => {
  return results.features.map(
    ({ text, place_name, context, address }): SelectOption => {
      const option = {
        highlight: withCoordinates ? '' : results.query.join(' '),
        address1: address ? `${address} ${text}` : place_name,
        completeAddress: place_name,
        ...extractAddressObj(context),
      };

      return option as SelectOption;
    },
  );
};

const getAddressOptions = (
  searchText: string,
  baseURL: string,
  coordinates?: { longitude: number; latitude: number },
) => {
  const config = {
    method: 'GET',
    credentials: 'include',
  } as RequestInit;

  const esc = encodeURIComponent;
  const params: any = {
    searchText,
  };

  if (coordinates && coordinates.longitude && coordinates.latitude) {
    params.longitude = coordinates.longitude;
    params.latitude = coordinates.latitude;
    if (!searchText) {
      params.searchText = `${coordinates.longitude},${coordinates.latitude}`;
    }
  }

  const query = Object.keys(params)
    .map(k => `${esc(k)}=${esc(params[k])}`)
    .join('&');

  try {
    return window.fetch(baseURL + query, config);
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag('address-search-text', searchText);
      scope.setTag('address-config', JSON.stringify(config));
      scope.setTag('address-baseURL', baseURL);
      scope.setTag('address-query', query);
      Sentry.captureException(error);
    });

    throw error;
  }
};

const Placeholder = ({
  className,
  error,
  ...props
}: PlaceholderProps & { error?: boolean }) => {
  return (
    <components.Placeholder
      className={`${className} b2 ${
        error ? '!text-reguard-error' : '!text-reguard-inactive1'
      }`}
      {...props}
    />
  );
};

export const AddressInput: FC<AddressInputProps> = ({
  handleChange,
  enterManually,
  baseURL,
  error,
  valid,
  className,
  label,
  setError,
  name,
  errorMessage,
  placeholder,
  errorExclamationIconVisible,
}) => {
  const [defaultOptions, setDefaultOptions] = useState<
    SelectOption[] | undefined
  >();
  const [isLoading, setLoading] = useState(false);

  const getBorderColor = () =>
    (error && 'reguard-error') ||
    (valid && 'reguard-wintergreen') ||
    'reguard-slate';

  const handleFocus = () => {
    setLoading(true);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          try {
            const response = await getAddressOptions('', baseURL, {
              longitude: position.coords.longitude,
              latitude: position.coords.latitude,
            });
            const data = await response.json();

            if (response.ok) {
              setDefaultOptions(handleLocationResults(data, true));
              setLoading(false);
            } else {
              setDefaultOptions(undefined);
              setLoading(false);
              throw data;
            }
          } catch {
            setError('Something went wrong with geolocation');
          } finally {
            setLoading(false);
          }
        },
        () => {
          setLoading(false);
        },
      );
    } else {
      setLoading(false);
    }
  };

  const getLocations = (
    searchText: string,
    callBack: (
      options: OptionsOrGroups<SelectOption, GroupBase<SelectOption>>,
    ) => void,
  ) => {
    getAddressOptions(searchText, baseURL)
      .then(async response => {
        const data = await response.json();
        setLoading(true);
        if (response.ok) {
          callBack(
            handleLocationResults(
              data as MapBoxAddressResults,
              false,
            ) as unknown as OptionsOrGroups<
              SelectOption,
              GroupBase<SelectOption>
            >,
          );
          setLoading(false);
        } else {
          callBack([]);
          setError('Something went wrong with geolocation');
          setLoading(false);
          Promise.reject(data);
        }
      })
      .catch(() => {
        setError('Something went wrong with geolocation');
        setLoading(false);
      });
  };

  return (
    <label className="tw-cst-pf flex justify-center select-none gap-2 flex-col-reverse">
      {error && errorMessage && (
        <span className="b3 semibold text-reguard-error">
          {errorMessage} <br />
        </span>
      )}
      <AsyncSelect
        name={name}
        placeholder={placeholder}
        onChange={val => {
          if (!val) {
            setError(`${label} is required`);
            handleChange({} as Address);
            return;
          }
          const value = val as Address;
          const newValue = {
            address1: value.address1,
            city: value.city,
            zipCode: value.zipCode,
            state: value.state,
            shortCode: value.shortCode,
          };

          handleChange(newValue as Address);
        }}
        isLoading={isLoading}
        isClearable
        defaultOptions={defaultOptions}
        onMenuOpen={handleFocus}
        loadOptions={debounce(getLocations, 500)}
        getOptionLabel={option => {
          const addressOption = option as SelectOption;
          return `${addressOption?.address1}, ${addressOption?.city}, ${addressOption?.state}`;
        }}
        components={{
          LoadingMessage,
          Placeholder: (props: PlaceholderProps) => (
            <Placeholder error={error} {...props} />
          ),
          MenuList: (props: MenuListProps) => (
            <MenuList enterManually={enterManually} {...props} />
          ),
          Option: CustomOption,
          DropdownIndicator: (passedProps: DropdownIndicatorProps) => (
            <ReactSelectIndicator
              {...passedProps}
              errorExclamationIconVisible={errorExclamationIconVisible}
              error={error}
              valid={valid}
            />
          ),
        }}
        className={`h-12 text-reguard-indigo border-2 rounded w-full flex-auto
          hover:bg-reguard-pearl-tint disabled:bg-reguard-inactive1 disabled:text-reguard-inactive2
          hover:border-reguard-violet focus:border-reguard-violet
          ${!error ? 'placeholder:border-reguard-indigo' : ''} ${className}`}
        styles={{
          dropdownIndicator: (provided, state) => ({
            ...provided,
            display: state.isFocused ? 'none' : 'flex',
          }),
          container: provided => ({
            ...provided,
            'borderColor': colors[getBorderColor()],
            ':focus-within': {
              borderColor: error
                ? colors['reguard-error']
                : colors['reguard-wintergreen'],
            },
          }),
          control: (provided, state) => ({
            ...provided,
            'border': 'none',
            'height': '100%',
            'outline': 'none',
            'boxShadow': 'none',
            'backgroundColor': state.hasValue
              ? colors['reguard-pearl-tint']
              : 'transparent',
            ':hover': {
              backgroundColor: colors['reguard-pearl-tint'],
            },
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
        }}
      />
      <span className={`b2 ${error ? 'text-reguard-error' : ''}`}>{label}</span>
    </label>
  );
};
