import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getReasonFileClaim } from './util/fileClaimUtils';
import { CustomerClaimStatus } from '../common/main';
import { useClaimsSearch, useRmDraftClaim } from '../network/api/claims';
import { usePurchasesSearch } from '../network/api/purchases';
import {
  Claim,
  ClaimsContainer,
  FileClaimBar,
  MainPage,
  MainSection,
  // RemoveDraftModal,
  SmallDialog,
  SmallDialogButtonMapType,
} from '../ui/components';

export const MyClaims = () => {
  const { data: claims, isFetching: isClaimsFetching } = useClaimsSearch();
  const { data: purchases, isFetching: isPurchasesFetching } =
    usePurchasesSearch();
  const [modalOpen, setModalOpen] = useState(false);
  const [claimId, setClaim] = useState('');
  const { mutateAsync: removeDraft } = useRmDraftClaim();
  const hasProductsToClaim = !!purchases.active.filter(
    (data: typeof purchases.active) => data.canFileAClaim,
  ).length;

  const { t } = useTranslation();

  const isFetching = isClaimsFetching || isPurchasesFetching;
  // actions
  const navigate = useNavigate();
  const goToDetails = (id: string) => {
    navigate(`/claims/${id}`);
  };
  const goToNewClaim = () => {
    navigate(`/plans/products`);
  };
  const goToFileAClaim = (id: string) => {
    navigate(`/claims/new?draftClaimId=${id}`);
  };

  const closeModalHandler = () => {
    setModalOpen(false);
  };

  const removeHandler = async () => {
    setModalOpen(false);

    await removeDraft({ claimId });
  };

  const removeDraftHandler = (claimId: string) => {
    setClaim(claimId);
    setModalOpen(!modalOpen);
  };
  // todo: disabled should be guided by active contract state
  const emptyMessage: string = hasProductsToClaim
    ? 'you_have_no_prior_claims_please_click_"file_a_claim"_to_file'
    : 'all_your_plans_are_expired';

  // manage sections visibility
  let draftClaims: Claim[] = [];
  let openClaims: Claim[] = [];
  let closedClaims: Claim[] = [];

  if (claims?.length > 0) {
    draftClaims = claims.filter(
      (c: Claim) => c.status === CustomerClaimStatus.Draft,
    );
    openClaims = claims.filter((c: Claim) =>
      [
        CustomerClaimStatus.UnderReview,
        CustomerClaimStatus.InProgress,
        CustomerClaimStatus.Approved,
      ].includes(c.status),
    );
    closedClaims = claims.filter(
      (c: Claim) => c.status === CustomerClaimStatus.Closed,
    );
  }

  const hasDraft = !!draftClaims.length;
  const hasOpen = !!openClaims.length;
  const hasClosed = !!closedClaims.length;
  const hasAny = hasDraft || hasOpen || hasClosed;

  const reason =
    (isFetching && t('loading')) ||
    getReasonFileClaim(purchases.active, draftClaims, openClaims);
  return (
    <MainPage title={t('claims') as string}>
      {modalOpen && (
        <SmallDialog
          title={t('do_you_wish_to_delete_the_draft') as string}
          subtitle={t('this_action_cannot_be_undone') as string}
          buttonsConfig={[
            {
              type: SmallDialogButtonMapType.DELETE,
              handleClick: removeHandler,
            },
            {
              type: SmallDialogButtonMapType.CANCEL,
              handleClick: closeModalHandler,
            },
          ]}
        />
      )}
      <FileClaimBar
        loading={isFetching}
        disabled={!hasProductsToClaim}
        onButtonClick={goToNewClaim}
        reason={reason}
      />
      {/* draft */}
      {hasDraft && (
        <MainSection title={t('draft_claims') as string}>
          <ClaimsContainer
            claims={draftClaims}
            onDetails={(id: string) => {
              goToFileAClaim(id);
            }}
            onRemoveDraft={removeDraftHandler}
          />
        </MainSection>
      )}

      {/* open */}
      {hasOpen && (
        <MainSection title={t('open_claims') as string}>
          <ClaimsContainer
            claims={openClaims}
            onDetails={(id: string) => {
              goToDetails(id);
            }}
          />
        </MainSection>
      )}

      {/* closed */}
      {hasClosed && (
        <MainSection title={t('closed_claims') as string}>
          <ClaimsContainer
            claims={closedClaims}
            onDetails={(id: string) => {
              goToDetails(id);
            }}
          />
        </MainSection>
      )}

      {/* no claims */}
      {!hasAny && (
        <MainSection title={t('my_claims') as string}>
          <div className="text-sm py-8 px-6 mb-20">{t(emptyMessage)}</div>
        </MainSection>
      )}
    </MainPage>
  );
};
