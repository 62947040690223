import { ImageType } from 'react-images-uploading';
import { ImageListExtended } from '@cortex/myaccount/views/safeship/helpers/facFieldsInitialValues';
import { sanitizeFilename } from '@cortex/myaccount/views/safeship/helpers/string';
import * as Sentry from '@sentry/react';
import heic2any from 'heic2any';
import { v4 as uuidv4 } from 'uuid';

export const removeDuplicateImages = (imageList: ImageListExtended) => {
  const extensionRegex = /(.png|.jpeg|.jpg|.gif|.heic|.heif)/;
  const iosImageName = 'image';

  const nonGenericIosNameList = imageList.map(imageObj =>
    imageObj.file?.name.replace(extensionRegex, '') === iosImageName
      ? {
          ...imageObj,
          file: {
            ...imageObj.file,
            name: imageObj.file.name.replace(
              iosImageName,
              `${iosImageName}${uuidv4()}`,
            ),
          },
        }
      : imageObj,
  );

  const uniqueImageList = nonGenericIosNameList.filter(
    (value, index, self) =>
      index ===
      self.findIndex(imageObj => {
        return (
          sanitizeFilename(imageObj.file?.name.replace(extensionRegex, '')) ===
          sanitizeFilename(value.file?.name.replace(extensionRegex, ''))
        );
      }),
  );

  return uniqueImageList;
};

export const convertToPNG = async (
  file: File,
): Promise<ImageType | undefined> => {
  try {
    const result = await heic2any({
      blob: file,
      toType: 'image/png',
    });

    const blobResult = result as Blob;
    const urlCreator = window.URL || window.webkitURL;

    return {
      dataURL: urlCreator.createObjectURL(blobResult),
      file: new File([blobResult], file.name.replace(/.heic|.heif/i, '.png'), {
        type: blobResult.type,
      }),
    };
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag('si-error-converting-to-png', file.name);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const fetchBlob = async (image: ImageType) => {
  try {
    const res = await fetch(image.dataURL as string);

    const blob = await res.blob();
    const file = image.file as File;

    return {
      file: new File([blob], file.name, { type: blob.type }),
      name: file.name,
      dataURL: image.dataURL,
    };
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag('si-error-fetching-blob', image.dataURL);
      Sentry.captureException(error);
    });
    throw error;
  }
};
