import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CloseSVG from './assets/Close.svg';
import Calendar from './assets/FacCalendarIcon.svg';
import Camera from './assets/FacCameraIcon.svg';
import Receipt from './assets/Receipt.svg';
import { buttonSelectors } from './tests/dataCySelectors';
import { Button } from './Button';
import { Logo } from './Logo';
import { ScreenOverlay } from './ScreenOverlay';

interface FileClaimModalProps {
  handleClose: () => void;
}

export const FileClaimModalText = ({ handleClose }: FileClaimModalProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center sm:justify-between">
      <div className="s1b text-reguard-indigo text-center mb-11 sm:mb-0 pt-4 sm:pt-[6.75rem]">
        {t('what_do_i_need_to')} <br /> {t('file_a_claim')}?
      </div>

      <div className="mb-11 sm:mb-0">
        <div className="flex flex-row justify-center mb-5 w-10 h-10">
          <Calendar />
        </div>

        <div className=" text-reguard-indigo text-center text-2xl semibold w-[17.938rem]">
          {t('an_accident_happened_in_the_last_30_days')}
        </div>
      </div>

      <div className="mb-11 sm:mb-0">
        <div className="flex flex-row justify-center mb-5 w-10 h-10">
          <Camera />
        </div>
        <div className=" text-reguard-indigo text-center text-2xl semibold w-[17.938rem]">
          {t('you_can_upload_photos_of_the_damage')}
        </div>
      </div>

      <div className="mb-11 sm:mb-0">
        <div className="flex flex-row justify-center mb-5 w-10 h-10">
          <Receipt />
        </div>
        <div className=" text-reguard-indigo text-center text-2xl semibold w-[17.938rem]">
          {t(
            'if_you_have_it_please_provide_a_photo_or_copy_of_your_receipt_from_the_merchant',
          )}
        </div>
      </div>

      <Button
        type="submit"
        className="!max-w-[17.938rem] mb-24"
        onClick={handleClose}
        data-cy={buttonSelectors.continueBtn}
        data-testid="continueBtn"
      >
        {t('continue')}
      </Button>
    </div>
  );
};

export const FileClaimModal: FC<FileClaimModalProps> = ({ handleClose }) => {
  return (
    <ScreenOverlay className="hidden sm:flex items-center justify-center h-[98vh]">
      <div className="w-full h-5/6 sm:max-w-[37.5rem] sm:max-h-[50rem] bg-white rounded-lg overflow-hidden relative">
        <div className="hidden sm:block lg:max-w-[37.5rem] lg:mx-auto">
          <div className="flex justify-center items-center p-4 bg-reguard-indigo lg:max-h-[3.6rem] rounded-t-lg relative">
            <Logo />
            <div className="tw-cst-pf bg-transparent absolute top-0 bottom-0 right-5 flex justify-center items-center">
              <button
                onClick={handleClose}
                data-cy={buttonSelectors.closeBtn}
                data-testid="closeBtn"
              >
                <div className="h-[0.9375rem] w-[0.9375rem]">
                  <CloseSVG />
                </div>
              </button>
            </div>
          </div>
        </div>

        <FileClaimModalContent handleClose={handleClose} />
      </div>
    </ScreenOverlay>
  );
};

export const FileClaimModalContent: FC<FileClaimModalProps> = ({
  handleClose,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Body */}
      <div className={`flex flex-col items-center justify-between `}>
        <div className="s1 text-reguard-indigo semibold text-center pt-4 sm:pt-[2rem] mb-8">
          {`${t('what_do_i_need_to')} ${t('file_a_claim')}?`}
        </div>

        <div className="flex flex-row justify-center mb-5 w-10 h-10">
          <Calendar />
        </div>

        <div className=" text-reguard-indigo text-center text-2xl semibold w-[17.938rem]  mb-8">
          {t('an_accident_happened_in_the_last_30_days')}
        </div>

        <div className="flex flex-row justify-center mb-5 w-10 h-10">
          <Camera />
        </div>
        <div className=" text-reguard-indigo text-center  text-2xl semibold w-[17.938rem] mb-8">
          {t('you_can_upload_photos_of_the_damage')}
        </div>

        <div className="flex flex-row justify-center mb-5 w-10 h-10">
          <Receipt />
        </div>
        <div className=" text-reguard-indigo text-center  text-2xl semibold w-[17.938rem] mb-8">
          {t(
            'if_you_have_it_please_provide_a_photo_or_copy_of_your_receipt_from_the_merchant',
          )}
        </div>

        <Button
          type="submit"
          className="mb-5 absolute sm:static bottom-5 !max-w-[17.938rem]"
          onClick={handleClose}
          data-cy={buttonSelectors.continueBtn}
          data-testid="continueBtn"
        >
          {t('continue')}
        </Button>
      </div>
    </>
  );
};
