import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerClaimStatus } from '@cortex/myaccount/common/main';
import {
  useAddClaimPhotos,
  useGetClaimById,
} from '@cortex/myaccount/network/api/claims';
import { BreadCrumbs } from '@cortex/myaccount/ui/components/BreadCrumbs';
import { ClaimInfoTable } from '@cortex/myaccount/ui/components/ClaimInfoTable';
import { ClaimPhotos } from '@cortex/myaccount/ui/components/ClaimPhotos';
import { ClaimPhotosModal } from '@cortex/myaccount/ui/components/ClaimPhotosModal';
import { ClaimStatusCard } from '@cortex/myaccount/ui/components/ClaimStatusCard';
import { ClaimStatusProgressBar } from '@cortex/myaccount/ui/components/ClaimStatusProgressBar';
import { ContentLoader } from '@cortex/myaccount/ui/components/ContentLoader';
import { HorizontalSeparator } from '@cortex/myaccount/ui/components/HorizontalSeparator';
import { MainPage } from '@cortex/myaccount/ui/components/MainPage';
import { MainSection } from '@cortex/myaccount/ui/components/MainSection';
import { renderSvgFallback } from '@cortex/myaccount/ui/components/PreviewImage';
import { ProductInfo } from '@cortex/myaccount/ui/components/ProductInfo';
import { VerticalSeparator } from '@cortex/myaccount/ui/components/VerticalSeparator';

interface ParamTypes {
  [claimId: string]: string;
}

export const SIClaimDetails = () => {
  const { claimId } = useParams<ParamTypes>();
  const { data: claim, isFetching } = useGetClaimById(claimId);
  const navigate = useNavigate();
  const [showAddPhotosModal, setShowAddPhotosModal] = useState(false);
  const { mutateAsync: addPhotos, isLoading } = useAddClaimPhotos();
  const { t } = useTranslation();

  if (isFetching) {
    return <ContentLoader />;
  }

  if (!claim) {
    throw new Error('Claim not found');
  }

  const [firstImage] = claim.customerClaimImages;
  const prod = {
    ...claim?.productPurchase?.product,
  };
  const purch = {
    ...claim?.productPurchase?.purchase,
  };

  const type = claim.productPurchase.protectionPlan.sku;

  return (
    <MainPage>
      <MainSection>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col md:p-2 md:pl-0 md:pr-2 min-w-fit">
            <BreadCrumbs
              className="md:hidden"
              onBack={() => {
                navigate('/claims');
              }}
              crumbs={[t('my_claims'), t('claim_status')]}
            />

            <div className="hidden md:block max-h-[17.9375rem] mb-4">
              {firstImage?.url ? (
                <img
                  className="shadow-md rounded-lg max-h-[17.9375rem] max-w-[17.9375rem]"
                  src={firstImage.url}
                  alt={prod.name}
                ></img>
              ) : (
                <div className="shadow-md rounded-lg max-h-[17.9375rem] max-w-[17.9375rem] bg-reguard-pearl-tint">
                  {renderSvgFallback(type, '', true)}
                </div>
              )}
            </div>

            <ProductInfo product={prod} purchase={purch} />
          </div>

          <VerticalSeparator className="hidden md:block md:pl-4 md:pr-10 md:mb-14" />
          <HorizontalSeparator className="md:hidden" />

          <div className="w-full flex flex-col basis-3/4">
            <h3 className="tw-cst-pf hidden md:block text-3xl semibold text-reguard-indigo pb-4 md:pl-0">
              <span>{t('claim_status')}</span>
              <span className="font-normal text-reguard-indigo-tint px-2">
                {' '}
                |{' '}
              </span>
              <span
                className={
                  claim.status === CustomerClaimStatus.Closed
                    ? ''
                    : 'text-reguard-violet'
                }
              >
                {claim.status}
              </span>
            </h3>

            <div className="py-4 pr-4 max-w-[37.5rem]">
              <ClaimStatusCard
                status={claim.status}
                isCancelled={claim.isCancelled}
                isDenied={claim.isDenied}
                resolutionType={claim.solution}
              />
            </div>

            <ClaimStatusProgressBar status={claim.status} />

            <ClaimInfoTable
              onDetailsClick={() => {
                if (claim?.status === CustomerClaimStatus.Draft) {
                  return navigate(
                    `/safeship/claims/new?productPurchaseId=${claim?.productPurchase.id}`,
                  );
                }
                return navigate(`/safeship/claims/summary/${claim?.id || ''}`);
              }}
              claimID={claim?.pcmiClaimId || ''}
              damageCause={claim?.damageCause?.name}
            />

            <HorizontalSeparator className="pr-4 my-2 max-w-[38.125rem]" />

            <ClaimPhotos
              pictures={claim.customerClaimImages}
              onClick={() => setShowAddPhotosModal(true)}
            />
          </div>

          <div className="flex items-start justify-center md:pt-[3.125rem] md:pr-0 mb-10 mt-8 md:mt-4 w-full basis-24 lg:basis-64 shrink"></div>
        </div>
      </MainSection>

      {showAddPhotosModal && (
        <ClaimPhotosModal
          photos={claim.claimPhotos}
          handleClose={() => setShowAddPhotosModal(false)}
          claimId={claimId as string}
          onSubmit={addPhotos}
          isFetching={isLoading}
        />
      )}
    </MainPage>
  );
};
