import { useTranslation } from 'react-i18next';
import { ProcessingStatus } from '@cortex/requests';

import type { Claim } from './models/Claim';
import type { Contract } from './models/Contract';
import type { Product } from './models/Product';
import type { ProtectionPlan } from './models/ProtectionPlan';
import { buttonSelectors, cardsSelectors } from './tests/dataCySelectors';
import { CustomerClaimStatus } from '../../common/constants/claims';
import { toI18nKey } from '../../common/utils/utils';
import { MobileArrow } from './MobileArrow';
import { PreviewImage } from './PreviewImage';
import { TitledCardInfo } from './TitledCardInfo';
import { Card, RoundedButton } from './';

export interface PlanCardProps extends Contract {
  className?: string;
  isExpired: boolean;
  isInactive: boolean;
  daysUntilExpiration: number;
  customerClaims: Claim[];
  product: Product;
  protectionPlan: ProtectionPlan;
  showDetails: boolean;
  goToPlanDetail?: () => void;
  goToFileAClaim: () => void;
  goToClaimDetails: (
    id: string | undefined,
    status: CustomerClaimStatus | undefined,
  ) => void;
  isMobile?: boolean;
  processingStatus: string;
}

const truncatedStyle = 'overflow-hidden whitespace-nowrap text-ellipsis';
const activeSVGStyle = 'stroke-reguard-wintergreen';

export const PlanCard = ({
  goToPlanDetail,
  product,
  protectionPlan,
  merchant,
  className,
  isExpired,
  isInactive,
  daysUntilExpiration,
  customerClaims,
  goToClaimDetails,
  goToFileAClaim,
  isMobile,
  showDetails,
  status,
  processingStatus,
}: PlanCardProps) => {
  const customerClaim =
    customerClaims.find(c =>
      [
        CustomerClaimStatus.UnderReview,
        CustomerClaimStatus.InProgress,
        CustomerClaimStatus.Approved,
      ].includes(c.status),
    ) || customerClaims.find(c => c.status === CustomerClaimStatus.Draft);

  const { t } = useTranslation();

  const isDraft = customerClaim?.status === CustomerClaimStatus.Draft;

  return (
    <Card
      handleClick={isMobile ? goToPlanDetail : undefined}
      className={className}
      data-cy={cardsSelectors.planCard}
    >
      <PreviewImage fallbackType={protectionPlan?.sku} />

      <div className="min-w-0 md:min-w-[16.563rem] w-full flex flex-col justify-center">
        {/* TODO: this margin bottom is hacky, the upper wrapper div should take 100% height */}
        <TitledCardInfo
          title={product.name}
          className="md:mb-[1.875rem] text-left"
        >
          <p
            className={`${truncatedStyle} semibold text-[0.875rem] pt-1 md:pt-2 leading-4 md:text-[1.125rem] md:leading-[1.25rem] text-reguard-indigo-tint`}
          >
            {merchant.name}
          </p>
          <p
            className={`${truncatedStyle} text-[0.75rem] leading-[0.875rem] md:text-[1.125rem] md:leading-[1.25rem] pt-1 md:pt-2 text-reguard-indigo-tint`}
          >
            <span className="semibold">
              {processingStatus === ProcessingStatus.PROCESSING &&
              status !== 'Active'
                ? t('pending_activation')
                : t(toI18nKey(status))}
            </span>

            {!isExpired && !isInactive && (
              <span>{t('days_left', { daysUntilExpiration })} </span>
            )}
          </p>
        </TitledCardInfo>

        <div className="hidden w-full md:flex justify-end items-center">
          {showDetails && (
            <RoundedButton
              onClick={goToPlanDetail}
              kind="white"
              className="ml-2 px-4"
              data-cy={
                isExpired || isInactive
                  ? buttonSelectors.viewDetailsBtn
                  : buttonSelectors.myPlanBtn
              }
            >
              {t(isExpired || isInactive ? 'view_details' : 'my_plan')}
            </RoundedButton>
          )}

          {!isExpired && !isInactive && (
            <RoundedButton
              kind={isDraft ? 'orange' : customerClaim?.id ? 'violet' : 'green'}
              className="ml-2 px-4"
              onClick={
                customerClaim?.id
                  ? () => {
                      goToClaimDetails(
                        customerClaim?.id,
                        customerClaim?.status,
                      );
                    }
                  : () => {
                      goToFileAClaim();
                    }
              }
              data-cy={
                isDraft
                  ? buttonSelectors.finishClaimBtn
                  : customerClaim?.id
                    ? buttonSelectors.viewClaimBtn
                    : buttonSelectors.fileClaimBtn
              }
            >
              {t(
                isDraft
                  ? 'finish_claim'
                  : customerClaim?.id
                    ? 'view_claim'
                    : 'file_a_claim',
              )}
            </RoundedButton>
          )}
        </div>
      </div>

      <MobileArrow
        onClick={goToPlanDetail}
        className="mr-5 ml-4 flex self-center w-4 h-4"
        svgClassName={!isExpired ? activeSVGStyle : ''}
      />
    </Card>
  );
};

export default PlanCard;
