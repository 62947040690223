import type { FC, ReactNode } from 'react';

import Arrow from './assets/RightArrow.svg';

interface ProfileItemProps {
  name?: string;
  children?: string | ReactNode;
  handleClick?: () => void;
  contentSize?: 'small' | 'regular';
  className?: string;
  disabled?: boolean;
}

export const ProfileItem: FC<ProfileItemProps> = ({
  name,
  children,
  handleClick,
  contentSize = 'regular',
  className = '',
  disabled = false,
}) => {
  const contentMaxWidth =
    contentSize === 'regular' ? 'sm:max-w-[26.625rem]' : 'sm:max-w-[16.25rem]';

  const onClick = () => {
    if (!disabled && handleClick) handleClick();
  };

  return (
    <div
      role="button"
      data-testid="edit-profile-button"
      tabIndex={0}
      onClick={onClick}
      title={name}
      className={`flex w-full items-center pr-4 py-4 md:py-8 border-b border-reguard-inactive2 min-w-0 ${className}`}
    >
      <div className="flex flex-1 items-center gap-y-4 md:gap-y-0 flex-wrap">
        {name && (
          <div className="flex flex-1 min-w-full md:min-w-[9.5rem]">
            <span className="b2b text-reguard-wintergreen-shade">{name}</span>
          </div>
        )}
        <div className="flex flex-[4]">
          <span
            className={`b1 semibold text-reguard-indigo max-w-[12.875rem] ${contentMaxWidth} break-words`}
          >
            {children}
          </span>
        </div>
      </div>
      {handleClick && (
        <div className="flex items-center">
          <button disabled={disabled} onClick={handleClick}>
            <div className="stroke-reguard-wintergreen fill-reguard-wintergreen w-2.5 h-2.5">
              <Arrow />
            </div>
          </button>
        </div>
      )}
    </div>
  );
};
