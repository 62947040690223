import { Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export function initSentry(dsn: string) {
  Sentry.init({
    dsn,
    integrations: [],
  });
}

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      {children}
    </Sentry.ErrorBoundary>
  );
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
