import { synapse } from '../../base';
import { IngestableOrder } from './types';

export const getIngestableOrderById = async (
  id: string,
): Promise<IngestableOrder> => {
  const args = {
    path: `ingestable/orders/${id}`,
    method: 'GET',
  };

  return synapse(args);
};
