import {
  FileClaimFields,
  StepOneInitialState,
  StepThreeInitialState,
  StepTwoInitialState,
} from '../helpers/facFieldsInitialValues';
import { FileClaimStep } from './FileClaimStep';

const MAPPED_INITIAL_STATE = {
  [FileClaimStep.STEP_ONE]: StepOneInitialState,
  [FileClaimStep.STEP_TWO]: StepTwoInitialState,
  [FileClaimStep.STEP_THREE]: StepThreeInitialState,
};

export const checkClaimFormFilled = (
  values: FileClaimFields,
  formStep: FileClaimStep,
) => {
  let filled = true;

  if (MAPPED_INITIAL_STATE[formStep]) {
    for (const key of Object.keys(MAPPED_INITIAL_STATE[formStep])) {
      if (!values[key]) {
        filled = false;
        break;
      }
    }
  }

  return filled;
};

export const checkStepValid = (errors, formStep) => {
  if (MAPPED_INITIAL_STATE[formStep]) {
    for (const key of Object.keys(MAPPED_INITIAL_STATE[formStep])) {
      if (errors[key]) {
        return false;
      }
    }
  }

  return true;
};
