import { useDebouncedCallback } from 'use-debounce';

const useDebounce = (timeout: number) => {
  const debounced = useDebouncedCallback(
    // function
    func => {
      return func();
    },
    timeout,
  );

  return debounced;
};

export default useDebounce;
