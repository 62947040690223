import { useTranslation } from 'react-i18next';

import { PolicyCard } from '../../../../ui/components';
import { MainPolicySection } from './MainPolicySection';

interface PoliciesProps {
  title: string;
  subtitle: string;
  purchases: any;
}

export function Policies({ title, subtitle, purchases }: PoliciesProps) {
  const { t } = useTranslation();
  const areActivePolicies = title.indexOf('active_policies') > -1;

  if (!purchases?.length) return;

  return (
    <div className={areActivePolicies ? `bg-[#f7f9fc]` : 'bg-[#dbe0ea]'}>
      <MainPolicySection
        title={t(title) as string}
        subtitle={t(subtitle) as string}
      >
        <div className="mb-8 md:mb-16 grid grid-cols-1 lg:grid-cols-2 lg:min-w-[945px] gap-x-4 gap-y-2">
          {purchases?.map(data => (
            <PolicyCard
              key={data.productPurchaseId}
              isDisabled={!areActivePolicies}
              data={data}
              status={data.contract.status}
              {...data}
            />
          ))}
        </div>
      </MainPolicySection>
    </div>
  );
}
