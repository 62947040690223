import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';

import useDebounce from './hooks/useDebounce';
import { buttonSelectors, textInputFields } from './tests/dataCySelectors';
import { Button, Checkbox, TextField } from '.';

const DEBOUNCE_TIMEOUT = 400;

interface LoginFormProps {
  handleSubmit: (args: {
    email: string;
    password: string;
    remember: boolean;
  }) => Promise<any>;
  isMerchant?: boolean;
  hasError?: boolean;
  isFetching?: boolean;
  apiErrorContent?: ReactNode;
  clearApiError?: () => void;
  forgotPassword?: ReactNode;
}

export const Login = ({
  handleSubmit,
  isMerchant,
  hasError,
  isFetching,
  apiErrorContent,
  clearApiError,
  forgotPassword,
}: LoginFormProps) => {
  const [remember, setRemember] = useState(false);
  const { t } = useTranslation();
  const onChangeRemember = () => {
    setRemember(state => !state);
  };

  const submit = async (data: { email: string; password: string }) => {
    await handleSubmit({ ...data, remember });
  };

  const debounced = useDebounce(DEBOUNCE_TIMEOUT);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={submit}
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('please_enter_email_with_right_format') as string)
          .required(t('email_is_required') as string),
        password: Yup.string().required(t('password_is_required') as string),
      })}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        validateField,
        dirty,
        isValid,
      }) => (
        <form className={classNames('grid')} onSubmit={handleSubmit}>
          <h4 className="tw-cst-pf text-reguard-indigo mt-14 mx-0 mb-8">
            {t('log_in')}
          </h4>
          <TextField
            name="email"
            data-cy={textInputFields.emailInputField}
            label={t(isMerchant ? 'business_email' : 'email')}
            onChange={evt => {
              clearApiError?.();
              handleChange(evt);
              debounced(() => {
                validateField('email');
              });
            }}
            placeholder="john.doe@email.com"
            errorExclamationIconVisible
            error={!!hasError || !!errors?.email}
            errorMessage={errors?.email}
            autoCapitalize="off"
            valid={isValid && dirty}
          />

          <TextField
            name="password"
            data-cy={textInputFields.passwordInputField}
            label={t('password')}
            onBlur={() => validateField('password')}
            onChange={evt => {
              clearApiError?.();
              handleChange(evt);
            }}
            type="password"
            error={!!hasError || !!errors?.password}
            errorMessage={errors?.password}
            errorExclamationIconVisible
            placeholder="Password"
            containerClassName="mb-3"
            valid={isValid && dirty}
          />

          {forgotPassword}

          <Checkbox
            name="remember"
            label={t('remember_me')}
            onChange={onChangeRemember}
            checked={remember}
          />

          {apiErrorContent}

          <Button
            disabled={
              isSubmitting ||
              isFetching ||
              !dirty ||
              !isValid ||
              Boolean(
                Object.keys(values).find(
                  key => (values as Record<string, string>)[key] === '',
                ),
              ) ||
              hasError
            }
            variant="primary"
            type="submit"
            data-cy={buttonSelectors.loginButton}
            className={hasError ? '' : 'mt-8'}
            isfetching={isFetching || isSubmitting}
          >
            {t('log_in')}
          </Button>
        </form>
      )}
    </Formik>
  );
};
