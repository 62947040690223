import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import CloseSVG from './assets/Close.svg';
import FILE_CLAIM_VALIDATION_SCHEMAS from './constants/facValidationRules';
import { removeDuplicateImages } from './hooks/useSaveOrSubmitClaim';
import { buttonSelectors } from './tests/dataCySelectors';
import { Button } from './Button';
import { FileClaimStepThree } from './FileClaimStepThree';
import { Logo } from './Logo';
import { ScreenOverlay } from './ScreenOverlay';

type Props = {
  handleClose: () => void;
  onSubmit: (photos: any) => Promise<void>;
  isFetching: boolean;
  claimId: string;
  photos: any;
};

export const ClaimPhotosModal = ({
  handleClose,
  onSubmit,
  isFetching,
  claimId,
  photos,
}: Props) => {
  const { t } = useTranslation();
  const handleAddPhotos = async ({ photosToAdd, id, setError }) => {
    const formDataForSave = new FormData();

    formDataForSave.set('id', id);

    if (photosToAdd) {
      const claimPhotos = removeDuplicateImages(photosToAdd);
      const photosArray = await Promise.all(
        claimPhotos.map(async (photo: any) => {
          const res = await fetch(photo.dataURL);
          const blob = await res.blob();
          return { file: blob, name: `${photo.file.name}` };
        }),
      );

      for (const photo of photosArray) {
        formDataForSave.append('claimPhotos', photo.file, photo.name);
      }
    }

    try {
      await onSubmit(formDataForSave);
      handleClose();
    } catch (error) {
      // todo: map BE and FE error
      setError(t('something_went_wrong'));
    }
  };

  return (
    <ScreenOverlay className="flex items-center justify-center bg-reguard-pearl-tint">
      <div className="w-full h-full sm:max-w-[37.5rem] sm:max-h-[50rem] bg-white rounded-lg overflow-hidden">
        <div className="lg:mx-auto">
          <div className="flex justify-center items-center p-4 bg-reguard-indigo h-[4.44rem] rounded-t-lg relative">
            <Logo />
            <div className="absolute top-4 right-5 flex justify-center items-center">
              <button
                onClick={handleClose}
                className="tw-cst-pf bg-transparent"
                data-cy={buttonSelectors.closeBtn}
                data-testid="close-button"
              >
                <div className="h-4 w-4">
                  <CloseSVG />
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="dialog-content flex flex-col h-full w-full px-[4.375rem] overflow-y-auto showScrollbar">
          <div className="md:mt-10">
            <div className="flex flex-1 flex-col">
              <Formik
                initialValues={{ claimPhotos: photos }}
                validationSchema={FILE_CLAIM_VALIDATION_SCHEMAS(t)[3]}
                onSubmit={onSubmit}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({ errors, values, setFieldError }) => (
                  <FileClaimStepThree
                    disableViewer
                    isDraft={false}
                    renderButton={() => (
                      <div className="flex justify-center mt-2 mb-20 h-screen">
                        <Button
                          isfetching={isFetching}
                          disabled={
                            !!errors.claimPhotos ||
                            values.claimPhotos.length < 5
                          }
                          className="!max-w-[100%] sm:!max-w-[17.938rem]"
                          variant="secondary"
                          type="button"
                          data-cy={buttonSelectors.saveAndCloseBtn}
                          data-testid="save_and_close"
                          onClick={() =>
                            handleAddPhotos({
                              photosToAdd: values.claimPhotos,
                              id: claimId,
                              setError: message =>
                                setFieldError('claimPhotos', message),
                            })
                          }
                        >
                          {t('save_and_close')}
                        </Button>
                      </div>
                    )}
                  />
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </ScreenOverlay>
  );
};
