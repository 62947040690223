import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ApprovedIconSVG from './assets/claimstatus/Approved.svg';
import CancelledIconSVG from './assets/claimstatus/Cancelled.svg';
import ClosedIconSVG from './assets/claimstatus/Closed.svg';
import DeniedIconSVG from './assets/claimstatus/Denied.svg';
import InProgressSVG from './assets/claimstatus/InProgress.svg';
import ReviewIconSVG from './assets/claimstatus/UnderReview.svg';
import {
  CustomerClaimSolution,
  CustomerClaimStatus,
} from '../../common/constants/claims';
import { statusDescription } from '../../common/utils/claim-status';
import { BulletPointItem } from './BulletPointItem';
import { CollapsibleParagraph } from './CollapsibleParagraph';

interface ClaimStatusCardProps {
  status: CustomerClaimStatus;
  isDenied?: boolean;
  isCancelled?: boolean;
  resolutionType?: CustomerClaimSolution;
}

export const getStatusTextColor = (status, resolutionType) => {
  switch (status) {
    case CustomerClaimStatus.UnderReview:
      return 'text-reguard-orange';
    case CustomerClaimStatus.InProgress:
    case CustomerClaimStatus.Approved:
      return 'text-reguard-violet-shade';
    case CustomerClaimStatus.Closed:
      return resolutionType === CustomerClaimSolution.Resolved
        ? 'text-reguard-wintergreen'
        : 'text-reguard-indigo';
    default:
      return 'text-reguard-indigo';
  }
};
export const ClaimStatusCard: FC<ClaimStatusCardProps> = (
  props: ClaimStatusCardProps,
) => {
  const { status, resolutionType } = props;

  const isUnderReview = status === CustomerClaimStatus.UnderReview;
  const isInProgress = status === CustomerClaimStatus.InProgress;
  const isClosed = status === CustomerClaimStatus.Closed;
  const isApproved = status === CustomerClaimStatus.Approved;

  const displayStatus =
    isInProgress && !!resolutionType ? `${status} - ${resolutionType}` : status;

  const hasStatusDesc = isApproved || isInProgress || isUnderReview;
  const { t } = useTranslation();

  const nextSteps = () => {
    if (isUnderReview) {
      return [
        'customer_service_will_carefully_review_your_claim',
        'if_approved_you_will_receive_further_instruction_by_email',
      ];
    }
    if (isApproved) {
      return [
        'reguards_customer_service_team_will_review_your_claim_information_and_photos_to_determine_the_best_resolution',
      ];
    }

    if (isInProgress) {
      if (resolutionType === CustomerClaimSolution.Repair) {
        return [
          'a_trusted_local_service_provider_will_reach_out_to_schedule_an_on_site_inspection',
          'after_assessing_your_claim_in_person_they_will_decide_how_to_best_fix_the_damage',
        ];
      }
      if (resolutionType === CustomerClaimSolution.Reimbursement) {
        return [
          'the_merchant_you_purchased_the_product_from_will_process_your_reimbursement_in_line_with_the_terms_and_conditions_applicable_to_your_state',
        ];
      }
      if (resolutionType === CustomerClaimSolution.Replacement) {
        return [
          'the_merchant_you_purchased_your_product_from_will_ship_you_a_new_product_as_soon_as_possible',
          'if_your_original_product_is_no_longer_available_you_will_receive_a_store_credit_for_the_same_purchase_price',
          'please_note_once_reguard_fully_replaces_your_item_your_original_protection_plan_will_be_void',
        ];
      }
      if (resolutionType === CustomerClaimSolution.CleaningKit) {
        return [
          'included_within_the_kit_are_instructions_for_successful_stain_removal',
          'you_should_also_refer_to_the_directions_on_how_to_best_take_care_of_the_type_of_product_you_own',
        ];
      }
    }

    return [];
  };

  const nextStepsContent = (
    <div className="pt-2">
      <div className="semibold text-lg">{t('what_to_expect_next')}</div>

      {nextSteps().map((ns, i) => (
        <BulletPointItem key={i}>{t(ns)}</BulletPointItem>
      ))}
    </div>
  );

  return (
    <div className="p-2 rounded-lg ring-1 ring-reguard-violet text-reguard-indigo bg-white">
      <div className="flex gap-2">
        {/* desktop only - big icon */}
        <div className="hidden md:flex p-4 pr-0">
          <StatusIcon {...props} small={false} />
        </div>

        <div className="flex flex-col p-4">
          {/* first row */}
          <div className="flex gap-4 items-center">
            {/* small icon: mobile only */}
            <div className="md:hidden">
              <StatusIcon {...props} small={true} />
            </div>
            {/* status title */}
            <div className="s3b">
              <span className="semibold"> {t('status')} </span>
              <span
                className={`${getStatusTextColor(
                  status,
                  resolutionType,
                )} font-normal`}
              >
                {displayStatus}
              </span>
            </div>
          </div>

          {/* sub status */}
          {isClosed && resolutionType && (
            <div className="semibold text-lg pt-2">{resolutionType}</div>
          )}

          {/* status description */}
          {hasStatusDesc && (
            <div className="b2 pt-2">
              {t(statusDescription(status, resolutionType))}
            </div>
          )}

          {/* next steps */}
          {hasStatusDesc && (
            <div>
              <CollapsibleParagraph className="md:hidden">
                {nextStepsContent}
              </CollapsibleParagraph>
              <div className="hidden md:block">{nextStepsContent}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface ClaimStatusIconProps extends ClaimStatusCardProps {
  small: boolean;
}
export const StatusIcon: FC<ClaimStatusIconProps> = (
  props: ClaimStatusIconProps,
) => {
  const { status, small, isDenied, resolutionType } = props;
  const size = small ? 'h-6 w-6' : 'h-12 w-12';
  const isResolved = resolutionType === CustomerClaimSolution.Resolved;
  const getSVG = () => {
    if (status === CustomerClaimStatus.Closed) {
      if (isResolved) {
        return ClosedIconSVG;
      }
      if (isDenied) {
        return DeniedIconSVG;
      }
      return CancelledIconSVG;
    }
    if (status === CustomerClaimStatus.UnderReview) {
      return ReviewIconSVG;
    }
    if (status === CustomerClaimStatus.InProgress) {
      return InProgressSVG;
    }

    if (status === CustomerClaimStatus.Approved) {
      return ApprovedIconSVG;
    }

    return ClosedIconSVG;
  };
  const Component = getSVG();
  return (
    <span className={`${size}`}>
      <Component />
    </span>
  );
};
