import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useBlocker(
  blocker: (tx: { retry: () => void }) => void,
  when = true,
): { continueNavigation: () => void } {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setIsBlocking] = useState<boolean>(false);
  const continueNavigation = useRef<() => void>(() => {});

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (when) {
        event.preventDefault();
        event.returnValue = ''; // Modern browsers require this to show a prompt
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when]);

  useEffect(() => {
    if (!when) return;

    return () => {
      setIsBlocking(false);
    };
  }, [location, navigate, blocker, when]);

  return { continueNavigation: continueNavigation.current };
}
