import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import FacRadioStepTwoChecked from './assets/FacRadioStepTwoChecked.svg';
import FacRadioStepTwoUnChecked from './assets/FacRadioStepTwoUnChecked.svg';
import type { StepTwoFields } from './constants/facFieldsInitialValues';
import { productConditions } from './constants/facRadioBtn';
import { textAreaSelectors } from './tests/dataCySelectors';
import { TextAreaForwardRef } from './';

import './FileClaimStep.module.css';

export const FileClaimStepTwo: FC = () => {
  const { values, errors, setFieldValue, setFieldError, validateField } =
    useFormikContext<StepTwoFields>();
  const { t } = useTranslation();
  const formatError = ({
    fieldName,
    fieldTitle,
    fieldError,
  }: {
    fieldName: string;
    fieldTitle: string;
    fieldError?: string;
  }) => {
    return fieldError && fieldError.replace(fieldName, fieldTitle);
  };

  return (
    <>
      <div className="step-two grid grid-cols-1 gap-x-6 gap-y-6 mb-6">
        <div>
          <div className="font-normal text-base mb-3 cqsm:mb-4 cqsm:text-xl sm:text-xl">
            {t('description_of_damage')}
          </div>
          <div className="font-normal text-cqsm mb-1 cqsm:text-base sm:text-base">
            {t('suggested_topics')}
          </div>
          <ul className="b3b list-dict font-normal pl-6 text-cqsm list-disc cqsm:text-base">
            <li>{t('what_happened')}</li>
            <li>{t('how_did_it_happen')}</li>
            <li>{t('attempts_to_fix_clean_damage')}</li>
            <li>{t('product_serial_number')}</li>
          </ul>
        </div>

        <TextAreaForwardRef
          placeholder={t('describe_damage_to_product') as string}
          labelHtmlFor="descriptionOfDamage"
          error={
            errors?.descriptionOfDamage
              ? formatError({
                  fieldName: 'descriptionOfDamage',
                  fieldTitle: t('description_of_damage_required'),
                  fieldError: errors.descriptionOfDamage,
                })
              : ''
          }
          className="w-full h-52 p-2 font-normal"
          containerClassName="cqsm:mt-6 sm:mt-6"
          name="descriptionOfDamage"
          data-cy={textAreaSelectors.textArea}
          onChange={(e: any) => {
            const value = e.target.value.replace(/\s{2,}/g, ' ');
            const hasOnlySpaces = /^\s*$/g.test(value);
            if (hasOnlySpaces && value !== '') {
              setFieldError(
                'descriptionOfDamage',
                t('description_of_damage_is_required') as string,
              );
              return;
            }

            setFieldValue('descriptionOfDamage', value);
            validateField('descriptionOfDamage');
          }}
          value={values.descriptionOfDamage}
        />
      </div>

      <div className="mb-12 cqsm:mb-20 cqmd:mb-20 sm:mb-20 md:mb-20">
        <div className="font-normal text-base mb-3 cqsm:mb-4 cqsm:text-xl sm:mb-4 sm:text-xl">
          {t('product_condition')}
        </div>
        {productConditions?.map(pc => {
          return (
            <div
              key={pc.id}
              className="pc-radio-group_row grid items-center grid-cols-12 even:bg-reguard-pearl"
            >
              <div className="pc-radio-group_column col-span-10">
                <label htmlFor={pc.id} className="cursor-pointer">
                  <div className="break-normal py-2 pl-2 font-normal">
                    {t(pc.text)}
                  </div>
                </label>
              </div>

              <div className="pc-radio-group_column col-span-2 text-right">
                <label
                  className="flex flex-row justify-end items-center break-normal py-2 pr-5 cursor-pointer"
                  htmlFor={pc.id}
                >
                  <input
                    className="peer tw-cst-pf"
                    name="productCondition"
                    id={pc.id}
                    type="radio"
                    value={pc.value}
                    onChange={e => {
                      setFieldValue('productCondition', e.target.value, true);
                      validateField('productCondition');
                    }}
                    checked={values.productCondition === pc.value}
                  />
                  <div
                    className="
                          h-5
                          w-5
                          p-0
                          inline-block
                          cursor-pointer
                          peer-checked:p-0
                          peer-checked:h-5
                          peer-checked:w-[1.625rem]
                          "
                  >
                    {values.productCondition === pc.value ? (
                      <span
                        className="pl-[0.125rem] h-6
                          w-6
                          p-0
                          inline-block
                          cursor-pointer
                          peer-checked:p-0
                          peer-checked:h-5
                          peer-checked:w-[1.625rem]"
                      >
                        <FacRadioStepTwoChecked />
                      </span>
                    ) : (
                      <FacRadioStepTwoUnChecked />
                    )}
                  </div>
                </label>
              </div>
            </div>
          );
        })}
        {errors.productCondition && (
          <div className="text-cqsm text-reguard-error semibold mt-1 cqsm:absolute sm:absolute">
            {t('product_condition_required')}
          </div>
        )}
      </div>
    </>
  );
};
