/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, MouseEventHandler, useState } from 'react';

import BigLeftArrowSVG from './assets/BigLeftArrow.svg';
import BigRightArrowSVG from './assets/BigRightArrow.svg';
import CloseSVG from './assets/Close.svg';
import type { CustomerClaimImage } from './models/CustomerClaimImage';
import { buttonSelectors } from './tests/dataCySelectors';
import classes from './BrandedDialog.module.css';
import { ScreenOverlay } from './ScreenOverlay';
import { SquareImageContainer } from './SquareImageContainer';
import { PreviewImage } from '.';

interface ImageViewerProps {
  pictures: CustomerClaimImage[];
  selectedIndex?: number;
  onDismiss: MouseEventHandler;
}

export const ImageViewer: FC<ImageViewerProps> = (props: ImageViewerProps) => {
  const { pictures, selectedIndex = 0, onDismiss } = props;
  const [showIndex, setShowIndex] = useState(selectedIndex);
  const selectedPicture = () => pictures[showIndex].url;

  const selectPicture = (idx: number) => {
    setShowIndex(idx);
  };

  const next = () => {
    setShowIndex(prevIndex => {
      if (prevIndex < pictures.length - 1) {
        return prevIndex + 1;
      }
      return 0;
    });
  };

  const previous = () => {
    setShowIndex(prevIndex => {
      if (prevIndex !== 0) {
        return prevIndex - 1;
      }
      return pictures.length - 1;
    });
  };

  const isShown = (i: number) => i === showIndex;

  return (
    <ScreenOverlay>
      <div
        className="flex flex-col items-center justify-center h-full"
        data-testid="image-viewer"
      >
        <div className="w-full">
          <div className="flex justify-center relative px-[25%] pt-16">
            <div className="w-1/12 flex items-center justify-center absolute top-16 bottom-0 left-9 md:left-[15%]">
              <button
                className="w-full tw-cst-pf bg-transparent"
                onClick={previous}
                data-cy={buttonSelectors.arrowLeftBtn}
                data-testid="arrow-left-button"
              >
                <div className="mx-auto w-4 h-4">
                  <BigLeftArrowSVG />
                </div>
              </button>
            </div>

            <div className="w-full max-w-[43.75rem] max-h-[43.75rem] min-w-[12.5rem]">
              <SquareImageContainer
                className="shadow-md rounded-lg w-full min-w-[7rem] min-h-[7rem] max-h-[18rem] max-w-[18rem]"
                alt="Selected Image"
                url={selectedPicture()}
              />
            </div>

            <div className="w-1/12 flex items-center justify-center absolute top-16 bottom-0 right-9 md:right-[15%]">
              <button
                className="w-full tw-cst-pf bg-transparent"
                onClick={next}
                data-cy={buttonSelectors.arrowRightBtn}
                data-testid="arrow-right-button"
              >
                <div className="mx-auto w-4 h-4">
                  <BigRightArrowSVG />
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center mt-24 md:mt-12">
          <div
            className={`flex p-4 items-center overflow-x-scroll h-full ${classes.noScrollbar}`}
          >
            {pictures.map((p, i) => (
              <PreviewImage
                imageUrl={p.url}
                key={i}
                onClick={() => selectPicture(i)}
                className={`mr-2 md:mr-4 !max-w-[5rem] !max-h-[5rem] ${
                  isShown(i)
                    ? 'ring-2 ring-white'
                    : 'opacity-80 hover:opacity-100'
                }`}
              />
            ))}
            {/* some spacing */}
            <div className="pr-1"></div>
          </div>
        </div>

        <div className="absolute flex justify-center items-center right-7 top-32 md:right-14 md:top-24">
          <button
            onClick={onDismiss}
            className="tw-cst-pf bg-transparent text-6xl text-white"
            data-cy={buttonSelectors.closeBtn}
            data-testid="close-button"
          >
            <div className="mx-auto w-[2.18rem] h-[2.18rem]">
              <CloseSVG />
            </div>
          </button>
        </div>
      </div>
    </ScreenOverlay>
  );
};
