/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { FC, ReactNode } from 'react';

import { ScreenOverlay } from './ScreenOverlay';

interface DialogProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
}
export const SimpleDialog: FC<DialogProps> = (props: DialogProps) => (
  <ScreenOverlay className="flex items-center justify-center">
    <div className="bg-white rounded p-8 pt-4 flex flex-col">
      <div className="text-lg md:text-xl text-reguard-indigo semibold p-2 text-center">
        {props.title}
      </div>
      <div className="text-xs md:text-sm text-reguard-indigo-tint p-2 pt-0 mb-2">
        {props.subtitle}
      </div>

      <div className="flex flex-col gap-2">{props.children}</div>
    </div>
  </ScreenOverlay>
);
