export function DocumentsFileCopyIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5018 22.6358V12.6718C17.5018 12.1762 17.3049 11.7009 16.9544 11.3506L14.3143 8.71048C13.964 8.35993 13.4886 8.16309 12.9931 8.16309H6.53053C5.49917 8.16309 4.66309 8.99917 4.66309 10.0305V22.6358C4.66309 23.6671 5.49917 24.5032 6.53053 24.5032H15.6343C16.6657 24.5032 17.5018 23.6671 17.5018 22.6358V22.6358Z"
        stroke="#4B506D"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5019 19.8362H21.4703C22.5016 19.8362 23.3377 19.0002 23.3377 17.9688V5.8304C23.3377 4.5412 22.2926 3.49609 21.0034 3.49609H12.3665C11.3351 3.49609 10.499 4.33218 10.499 5.36354V8.16471"
        stroke="#4B506D"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5016 12.8317H13.7667C13.251 12.8317 12.833 12.4137 12.833 11.898V8.16309"
        stroke="#4B506D"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
