import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ROUTES from '@cortex/myaccount/common/constants/routes';

import ArrowLeft from './assets/ArrowLeft.svg';
import CloseSVG from './assets/Close.svg';
import SuccessSVG from './assets/Success.svg';
import { buttonSelectors } from './tests/dataCySelectors';
import SafeShipLogoLight from '../../ui/components/assets/SafeShipLogoLight.svg';
import { Logo } from './Logo';
import { ScreenOverlay } from './ScreenOverlay';

interface BrandedDialogProps {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  children?: ReactNode;
  handleClose?: () => void;
  success?: boolean;
  contentClass?: string;
  handleBack?: () => void;
  isMerchant?: boolean;
  isChatbot?: boolean;
}

export const BrandedDialog: FC<BrandedDialogProps> = ({
  success,
  title,
  subtitle,
  children,
  handleClose,
  contentClass,
  handleBack,
  isMerchant,
  isChatbot,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isSafeShip = location.pathname.indexOf(ROUTES.SAFESHIP) > -1;

  return (
    <ScreenOverlay
      isChatbot={isChatbot}
      className="flex items-center justify-center"
    >
      <div className="w-full h-full sm:max-w-[37.5rem] sm:max-h-[50rem] bg-white rounded-lg overflow-hidden">
        <div className="lg:max-w-[37.5rem] lg:mx-auto">
          <div className="flex justify-center items-center p-4 bg-reguard-indigo lg:max-h-[3.6rem] rounded-t-lg relative">
            {isSafeShip ? (
              <span className="w-28">
                <SafeShipLogoLight />
              </span>
            ) : (
              <Logo />
            )}
            {isMerchant && (
              <span className="b2b text-reguard-pearl">
                {t('for_merchants')}
              </span>
            )}
            {handleClose && (
              <div className="absolute top-0 bottom-0 right-5 flex justify-center items-center">
                <button
                  onClick={handleClose}
                  className="tw-cst-pf bg-transparent"
                  data-cy={buttonSelectors.closeBtn}
                  data-testid="closeBtn"
                >
                  <div className="h-[0.9375rem] w-[0.9375rem]">
                    <CloseSVG />
                  </div>
                </button>
              </div>
            )}

            {handleBack && (
              <div className="tw-cst-pf bg-transparent absolute top-0 bottom-0 left-6 flex justify-center items-center">
                <button
                  onClick={handleBack}
                  data-cy={buttonSelectors.arrowLeftBtn}
                  data-testid="arrowLeftBtn"
                >
                  <ArrowLeft />
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="dialog-content flex flex-col h-full w-full items-center overflow-y-auto showScrollbar">
          <div
            className={`max-w-[19rem] h-full pt-16 sm:pt-[6.75rem] ${contentClass}`}
          >
            {title && (
              <div className="s1 text-reguard-indigo mb-8">{title}</div>
            )}

            {subtitle && (
              <div className="b2 text-reguard-indigo mb-4">{subtitle}</div>
            )}
            {success && (
              <div className="flex justify-center items-center mt-12">
                <div className="flex justify-center items-center w-44 h-44">
                  <SuccessSVG />
                </div>
              </div>
            )}
            <div className="flex flex-1 flex-col items-center">{children}</div>
          </div>
        </div>
      </div>
    </ScreenOverlay>
  );
};
