import type { FC } from 'react';

import RightArrowSVG from './assets/RightArrow.svg';

export interface MobileArrowProps {
  svgClassName?: string;
  className?: string;
  onClick?: () => void;
}

export const MobileArrow: FC<MobileArrowProps> = props => (
  <div
    className={`tw-cst-pf w-4 flex flex-col justify-center items-center md:hidden ${props.className}`}
    data-testid="mobile-arrow-div"
  >
    <button onClick={props.onClick}>
      <div className={`${props.svgClassName || ''}`} data-testid="arrow-svg">
        <RightArrowSVG />
      </div>
    </button>
  </div>
);
