import type { FC, ReactNode } from 'react';

interface PublicRoutesContainerProps {
  children?: ReactNode;
}

export const PublicRoutesContainer: FC<PublicRoutesContainerProps> = ({
  children,
}) => (
  <div className="lg:bg-reguard-pearl-tint flex flex-1">
    <div className="h-full w-full">{children}</div>
  </div>
);
