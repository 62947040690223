import { ReactNode } from 'react';

import { Logo } from '../../../../ui/components/Logo';
import { ScreenOverlay } from '../../../../ui/components/ScreenOverlay';
import { buttonSelectors } from '../../../../ui/components/tests/dataCySelectors';
import { CloseIcon } from '../close-icon/close-icon';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface Props {
  handleClose: () => void;
  children?: ReactNode;
}

export const ModalBase = ({ handleClose, children }: Props) => {
  return (
    <ScreenOverlay className="flex items-center justify-center h-[98vh]">
      <div
        className="w-full h-5/6 sm:max-w-[37.5rem] sm:max-h-[50rem] bg-white rounded-lg overflow-hidden relative"
        role="dialog"
      >
        <div className="hidden sm:block lg:max-w-[37.5rem] lg:mx-auto">
          <div className="flex justify-center items-center p-4 bg-reguard-indigo lg:max-h-[3.6rem] rounded-t-lg relative">
            <Logo />
            <div className="tw-cst-pf bg-transparent absolute top-0 bottom-0 right-5 flex justify-center items-center">
              <button
                onClick={handleClose}
                data-cy={buttonSelectors.closeBtn}
                data-testid="closeBtn"
              >
                <div className="h-[0.9375rem] w-[0.9375rem]">
                  <CloseIcon />
                </div>
              </button>
            </div>
          </div>
        </div>
        {children}
      </div>
    </ScreenOverlay>
  );
};
