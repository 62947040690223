import { useState } from 'react';
import { CustomerClaimStatus } from '@cortex/myaccount/common/constants/claims';
import { useClaimsSearch } from '@cortex/myaccount/network/api/claims';
import { usePurchasesSearch } from '@cortex/myaccount/network/api/purchases';
import { Claim, Purchase } from '@cortex/myaccount/ui/components';

export type OrderData = {
  claims: Claim[];
  purchases: {
    active: Purchase[];
    expired: Purchase[];
    inactive: Purchase[];
  };
};

export function useHomeView() {
  const [viewAllClaims, setViewAllClaims] = useState(false);

  const { data: claims, isFetching: isFetchingClaims } = useClaimsSearch();

  const { data: purchases, isFetching: isFetchingPurchases } =
    usePurchasesSearch();

  const openClaims = claims?.filter(
    (c: Claim) =>
      c.status !== CustomerClaimStatus.Closed &&
      c.status !== CustomerClaimStatus.Draft,
  );

  const isFetching = isFetchingClaims || isFetchingPurchases;
  const showViewAllClaimsButton = !viewAllClaims && openClaims?.length > 2;

  const orderData: OrderData = {
    claims: claims || [],
    purchases: {
      active: purchases?.active || [],
      expired: purchases?.expired || [],
      inactive: purchases?.inactive || [],
    },
  };

  return {
    orderData,
    viewAllClaims,
    setViewAllClaims,
    isFetching,
    showViewAllClaimsButton,
  };
}
