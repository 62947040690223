import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WarningSign from './assets/WarningSign.svg';
import ROUTES from '../common/constants/routes';
import { buttonSelectors } from '../tests/dataCySelectors';
import { Button } from '../ui/components';

export const VerifyUserFailed = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center overflow-y-auto text-center">
      <div className="max-w-[17.938rem] mb-16">
        <h4 className="tw-cst-pf text-reguard-indigo mt-[3.875rem] mx-0 mb-8">
          {t('sorry')}
        </h4>
        <span className="b2">
          {t('account_not_found_error', {
            email: 'customerservice@reguardprotection.com',
          })}
        </span>
      </div>
      <div className="w-32">
        <WarningSign />
      </div>

      <Button
        className="mt-16 mb-20"
        onClick={() => navigate(ROUTES.VERIFY_INFORMATION)}
        type="button"
        data-cy={buttonSelectors.backBtn}
      >
        {t('back')}
      </Button>
    </div>
  );
};
