// https://github.com/vitejs/vite/issues/1149#issuecomment-857686209
export const config = {
  app: {
    url: process.env.VITE_APP_URL || 'http://localhost:4200',
  },
  api: {
    url: process.env.VITE_API_URL || 'http://localhost:3500',
  },
  ocular: {
    url: process.env.VITE_OCULAR_URL || 'http://localhost:3502',
  },
  synapse: {
    url: process.env.VITE_SYNAPSE_URL || 'http://localhost:3501',
  },
};
