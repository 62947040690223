import React, { createContext, ReactNode, useContext } from 'react';

import { usePurchasesSearch } from '../../../network/api/purchases';
import { PoliciesContextValues } from '../types/policyTypes';

export interface PoliciesContextProviderProps {
  children: ReactNode;
}

const PoliciesContext = createContext<PoliciesContextValues>(
  {} as PoliciesContextValues,
);

export function PoliciesContextProvider({
  children,
}: PoliciesContextProviderProps) {
  const { data: purchases, isFetching: isFetchingPurchases } =
    usePurchasesSearch();

  return (
    <PoliciesContext.Provider
      value={{
        purchases,
        isFetchingPurchases,
        children,
      }}
    >
      {children}
    </PoliciesContext.Provider>
  );
}

export function usePoliciesContext() {
  return useContext(PoliciesContext);
}
