import { FC, useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import ImageUploading, {
  ImageListType,
  ImageType,
} from 'react-images-uploading';
import { FileClaimImageGrid } from '@cortex/myaccount/ui/components/FileClaimImageGrid';
import { ImageViewer } from '@cortex/myaccount/ui/components/ImageViewer';
import { RoundedButton } from '@cortex/myaccount/ui/components/RoundedButton';
import { SimpleDialog } from '@cortex/myaccount/ui/components/SimpleDialog';
import {
  SmallDialog,
  SmallDialogButtonMapType,
} from '@cortex/myaccount/ui/components/SmallDialog';
import { useFormikContext } from 'formik';

import {
  convertToPNG,
  fetchBlob,
  removeDuplicateImages,
} from './helpers/helpers';
import FacDocumentsFileCopy from '../../../../../ui/components/assets/FacDocumentsFileCopy.svg';
import FacImageUploadIconSVG from '../../../../../ui/components/assets/FacImageUploadIcon.svg';
import LoaderEllipsisSVG from '../../../../../ui/components/assets/LoaderEllipsis.svg';
import {
  ImageListExtended,
  StepThreeFields,
} from '../../../helpers/facFieldsInitialValues';

interface FileClaimStepThreeProps {
  isChatBot?: boolean;
  isDraft?: boolean;
  renderButton?: () => JSX.Element;
  disableViewer?: boolean;
}

const ACCEPTABLE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'heic', 'heif'];
const MAX_NUMBER_OF_PHOTOS = 12;

export const FileClaimStepThree: FC<FileClaimStepThreeProps> = ({
  isChatBot,
  renderButton,
  disableViewer,
  isDraft,
}: FileClaimStepThreeProps) => {
  const [loadingImages, setLoadImages] = useState(false);
  const [error, setError] = useState('');
  const { values, errors, setFieldValue, setFieldError } =
    useFormikContext<StepThreeFields>();
  const photos = values.claimPhotos;
  const receipt = values.claimReceipt;
  const { t } = useTranslation();

  const hasPhotos = Array.isArray(photos) && photos.length;

  const setErrors = (message: string) => {
    setFieldError('claimPhotos', message);
  };
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      setFieldValue('claimReceipt', acceptedFiles[0]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      onDrop,
      accept: {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          ['.docx'],
      },
      multiple: false,
    });

  const [editingImages, setEditingImages] = useState(false);

  // manage image viewer
  const [showViewer, setShowViewer] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const openViewer = (idx: number) => {
    setSelectedIndex(idx);
    setShowViewer(true);
  };

  const [removeDialogVisible, showRemoveDialog] = useState({
    showDialog: false,
    index: 0,
  });

  const refDialog = useRef({
    showDialog: false,
  });

  const hasEditablePhotos = photos.some(photo => !!photo.file?.lastModified);

  const onChange = async (imageList: ImageListType) => {
    const heImage = imageList.findIndex(
      image =>
        image.file?.type === 'image/heic' || image.file?.type === 'image/heif',
    );

    if (heImage >= 0) {
      try {
        setLoadImages(true);
        const images = removeDuplicateImages(imageList as ImageListExtended);
        const filteredImages = images.reduce<Promise<ImageType | undefined>[]>(
          (acc, image) => {
            if (
              image.file?.type === 'image/heic' ||
              image.file?.type === 'image/heif'
            ) {
              return [...acc, convertToPNG(image.file as File)];
            }

            return [...acc, fetchBlob(image as ImageType)];
          },
          [],
        );

        const convertedImages = await Promise.all(filteredImages);

        setFieldValue('claimPhotos', convertedImages, true);
      } catch (e: any) {
        console.warn(e);
      } finally {
        setLoadImages(false);
      }
    } else {
      setFieldValue(
        'claimPhotos',
        removeDuplicateImages(imageList as ImageListExtended),
        true,
      );
    }

    if (imageList.length >= 5) {
      setErrors('');
      setError('');
    }
  };

  return (
    <>
      {refDialog.current.showDialog && (
        <SimpleDialog
          title={t('remove_photo_confirmation')}
          subtitle={t('this_action_cannot_be_undone') as string}
        >
          <RoundedButton kind="dark" className="w-36 mx-auto">
            {t('remove')}
          </RoundedButton>
          <RoundedButton kind="white" className="w-36 mx-auto">
            {t('cancel')}
          </RoundedButton>
        </SimpleDialog>
      )}

      <div className="step-three grid grid-cols-1 gap-x-6 gap-y-6 mb-6 relative">
        <div>
          <div className="s3 mb-3 cqsm:mb-4 cqsm:text-xl">
            {t('photos_of_damage')}
          </div>
          <div className="font-normal text-cqsm mb-1 cqsm:text-base">
            {t('suggested_photos')}
          </div>
          <ul className="b3b list-dict font-normal pl-6 text-cqsm list-disc cqsm:text-base">
            <li>{t('photo_of_product_label')}</li>
            <li>{t('photo_of_item')}</li>
            <li>{t('close_up_photo_of_damage')}</li>
            <li>{t('photo_of_product_serial_number')}</li>
          </ul>
        </div>

        <ImageUploading
          multiple
          value={photos}
          onError={errors => {
            if (errors?.maxNumber) {
              setError(t('max_photos_reached') as string);
            }
          }}
          onChange={onChange}
          maxNumber={MAX_NUMBER_OF_PHOTOS}
          acceptType={ACCEPTABLE_TYPES}
          allowNonImageType
        >
          {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
            <>
              {showViewer && (
                <ImageViewer
                  pictures={imageList.map((image, i) => ({
                    id: `${i}`,
                    url: image.dataURL as string,
                  }))}
                  selectedIndex={selectedIndex}
                  onDismiss={() => setShowViewer(false)}
                />
              )}

              {removeDialogVisible.showDialog && (
                <SmallDialog
                  title={t('remove_photo_confirmation')}
                  subtitle={t('this_action_cannot_be_undone')}
                  buttonsConfig={[
                    {
                      type: SmallDialogButtonMapType.REMOVE,
                      handleClick: () => {
                        onImageRemove(removeDialogVisible.index);
                        showRemoveDialog({ showDialog: false, index: 0 });
                      },
                    },
                    {
                      type: SmallDialogButtonMapType.CANCEL,
                      handleClick: () => {
                        showRemoveDialog({ showDialog: false, index: 0 });
                      },
                    },
                  ]}
                />
              )}

              <div className="uploadImageContainer flex flex-col">
                {hasPhotos ? (
                  <div className="flex flex-row w-full justify-between items-center mb-4">
                    <div className="text-base semibold">
                      {loadingImages
                        ? t('uploading')
                        : t('photos_uploaded', { length: imageList.length })}
                    </div>

                    {hasEditablePhotos ? (
                      <RoundedButton
                        kind={editingImages ? 'violet' : 'white'}
                        className="font-semibold font-proximaNovaLt text-sm leading-[1.0313rem] !rounded-[0.938rem] !px-2 !py-1 !min-w-fit"
                        onClick={e => {
                          e.preventDefault();
                          setEditingImages(prev => !prev);
                        }}
                      >
                        {t(editingImages ? 'done' : 'edit_photos')}
                      </RoundedButton>
                    ) : null}
                  </div>
                ) : null}

                {/* image list */}
                <div className="flex w-full">
                  {!loadingImages && hasPhotos ? (
                    <FileClaimImageGrid
                      imageList={photos}
                      editable
                      className={isChatBot ? 'sm:!px-0' : ''}
                      isEditing={editingImages}
                      handleImageClick={index => {
                        if (editingImages) {
                          if (isDraft) {
                            showRemoveDialog({ showDialog: true, index });
                            return;
                          }
                          onImageRemove(index);
                        } else {
                          !disableViewer && openViewer(index);
                        }

                        if (photos.length === 1) setEditingImages(false);
                      }}
                      gridWrapperClassName={isChatBot ? '!gap-2 !mb-6' : ''}
                    />
                  ) : null}
                  {loadingImages && (
                    <div className="relative w-full h-[4.625rem] sm:h-[7.813rem]">
                      <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                        <LoaderEllipsisSVG />
                      </div>
                    </div>
                  )}
                </div>

                {/* image button */}
                <button
                  type="button"
                  disabled={photos.length === 12}
                  className={`flex flex-col w-full self-center max-w-[17.938rem] sm:max-w-[100%] justify-center items-center ${
                    photos.length > 0
                      ? 'h-[6.875rem] sm:h-[11.688rem]'
                      : 'h-[14.813rem]'
                  } border border-dashed border-reguard-inactive1 rounded-lg bg-white`}
                  onClick={e => {
                    e.preventDefault();
                    onImageUpload();
                  }}
                  {...dragProps}
                >
                  <span className="w-11 h-11">
                    <FacImageUploadIconSVG />
                  </span>
                  <div className="text-base text-reguard-violet font-normal mt-3">
                    {photos.length > 5 ? (
                      <div>
                        {t(
                          photos.length === 12
                            ? 'maximum_number_of_photos_reached'
                            : 'add_more_photos',
                        )}
                      </div>
                    ) : (
                      <>
                        <div>{t('photos')}</div>
                        <div>({t('add_5_12_pictures')})</div>
                      </>
                    )}
                  </div>
                </button>
              </div>
            </>
          )}
        </ImageUploading>

        {(errors.claimPhotos || error) && (
          <div className="text-cqsm text-reguard-error semibold mt-1">
            {(errors as any)?.claimPhotos || error}
          </div>
        )}
        <div>
          <div className="s3 mb-3 cqsm:mb-4 cqsm:text-xl">
            {t('your_receipt')}
          </div>
          <div className="font-normal text-cqsm mb-1 cqsm:text-base">
            {t('full_receipt_clear_captured')}
          </div>
        </div>

        {receipt && <p className="mb-2">{receipt.name}</p>}

        <div {...getRootProps()} className="uploadImageContainer flex flex-col">
          <button
            type="button"
            className="flex flex-col w-full self-center max-w-[17.938rem] sm:max-w-[100%] justify-center items-center h-[14.813rem] border border-dashed border-reguard-inactive1 rounded-lg bg-white"
          >
            <input {...getInputProps()} />
            <span className="w-11 h-11">
              <FacDocumentsFileCopy />
            </span>
            <div className="text-base text-reguard-violet font-normal mt-3">
              <div>{t('file_uppercase')}</div>
              {isDragActive && isDragAccept ? (
                <p>{t('drop_the_file_here')} ...</p>
              ) : (
                <div>{t('supported_format_doc_jpg_png_pdf')}</div>
              )}
            </div>
          </button>
        </div>

        {renderButton ? renderButton() : null}
      </div>
    </>
  );
};
