interface BadgeProps {
  color: keyof typeof colors;
  text: string;
}
const colors = {
  green: { ring: 'ring-green-200', fill: 'fill-green-500' },
  red: { ring: 'ring-red-200', fill: 'fill-red-500' },
};
export function Badge({ color = 'green', text }: BadgeProps) {
  const colorConfig = colors[color];
  return (
    <div
      className={`flex items-center gap-x-1.5 rounded-full inset-y-2 mx-2 my-2 px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ${colorConfig.ring}`}
    >
      <svg
        className={`h-1.5 w-1.5 ${colorConfig.fill}`}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx={3} cy={3} r={3} />
      </svg>
      {text}
    </div>
  );
}
