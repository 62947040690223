import { Address } from '@cortex/myaccount/ui/components/models/Address';

export function AddressText({
  address1,
  address2,
  city,
  state,
  zipCode,
}: Address) {
  const cityStateZip =
    city && state && zipCode ? `${city}, ${state}, ${zipCode}` : '';

  return (
    <>
      {address1 && <span>{address1}</span>}

      {address2 && <span>{address2}</span>}

      {cityStateZip && (
        <>
          <br />
          {cityStateZip}
        </>
      )}
    </>
  );
}
