import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

// DUPLICATED HOOK IN COMPONENTS MODULE
import useMatchBreakpoint from './util/useMatchBreakpoint';
import ROUTES from '../common/constants/routes';
import { CustomerClaimStatus } from '../common/main';
import {
  useGetClaimForFormById,
  useGetDomainValues,
} from '../network/api/claims';
import {
  FileClaimConfirmation,
  FileClaimDamageDomainData,
  FileClaimModal,
  FileClaimModalText,
  MainPage,
  MainSection,
} from '../ui/components';
import { FileClaimSteps, SubmittedClaimData } from './FileClaimSteps';

interface DomainType {
  damageArea: FileClaimDamageDomainData;
  damageCause: FileClaimDamageDomainData;
  damageLocation: FileClaimDamageDomainData;
}

export const FileClaim: FC = () => {
  const navigate = useNavigate();
  const [facModal, setFacModal] = useState(true);
  const [newClaimData, setNewClaimData] = useState<SubmittedClaimData>();
  const { t } = useTranslation();
  //SEARCH PARAMS
  const [searchParams] = useSearchParams();
  const productPurchaseId = searchParams.get('productPurchaseId') || '';
  const draftClaimId = searchParams.get('draftClaimId') || '';

  const { data: draftClaim = {}, isFetching: isFetchingDraft } =
    useGetClaimForFormById({
      claimId: draftClaimId,
    });

  const { data, isFetching: isFetchingDomain } = useGetDomainValues(
    productPurchaseId ? { productPurchaseId } : { draftClaimId },
  );

  const domainData = data as DomainType;

  const isTabletAndAbove = useMatchBreakpoint('48rem');

  const isFetching = isFetchingDraft || isFetchingDomain;

  const handleContent = () => {
    if (isFetching) return null;

    if (newClaimData) {
      return (
        <FileClaimConfirmation
          viewClaim={() => {
            navigate(
              `${ROUTES.MY_CLAIMS}/${newClaimData.claimId || draftClaimId}`,
            );
          }}
          claimId={newClaimData?.pcmiClaimNumber || ''}
          submissionDate={newClaimData.submissionDate}
          status={CustomerClaimStatus.UnderReview}
          nextSteps={[
            t('customer_service_will_carefully_review_your_claim'),
            t('if_approved_you_will_receive_further_instruction_by_email'),
          ]}
        />
      );
    }

    return (
      <FileClaimSteps
        draftClaim={draftClaim}
        productPurchaseId={productPurchaseId || draftClaim?.productPurchase?.id}
        onSubmissionSuccess={setNewClaimData}
        domainData={domainData}
      />
    );
  };

  return (
    <MainPage>
      <MainSection>
        {/* TODO:
            - Avoid to render the FileClaimModalText above the modal;
            - Reuse the FileClaimModalText inside of the modal;
            - Handle the isTabletAndAbove with media or container queries
        */}
        {facModal && !draftClaimId && (
          <>
            <FileClaimModal
              handleClose={() => {
                setFacModal(false);
              }}
            />

            {!isTabletAndAbove && (
              <div className="h-[90vh] overflow-y-auto">
                <FileClaimModalText
                  handleClose={() => {
                    setFacModal(false);
                  }}
                />
              </div>
            )}
          </>
        )}
        {handleContent()}
      </MainSection>
    </MainPage>
  );
};
