import dayjs from 'dayjs';

export const getDateString = (date: string | null) => {
  // mm/dd/yyyy
  return dayjs(date).format('MM/DD/YYYY');
};

export const addDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

export const formatDateAsFullLengthWords = (inputDate: string) => {
  const date = dayjs(inputDate);
  const month = date.format('MMMM');
  const day = date.format('D');
  const year = date.format('YYYY');
  const dayWithSuffix = addDaySuffix(parseInt(day));

  // Example: September 5th 2023
  return `${month} ${dayWithSuffix} ${year}`;
};
