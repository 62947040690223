import type { FC, ReactElement, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { UnmountClosed } from 'react-collapse';

import ArrowUpSVG from './assets/ArrowUp.svg';

export const CollapsibleSection: FC<{
  title: ReactNode;
  titleFormatted?: boolean;
  children: ReactNode | ReactElement;
  className?: string;
  rootClassName?: string;
  open?: boolean;
  contentContainerClassName?: string;
}> = ({
  title,
  titleFormatted,
  children,
  className,
  rootClassName,
  open,
  contentContainerClassName,
}) => {
  const [isOpened, setOpened] = useState(false);

  const titleText = titleFormatted ? (
    <h3 className="tw-cst-pf b1 text-reguard-indigo py-4 px-0 leading-[1.25rem] md:text-3xl md:leading-[1.875rem]">
      {title}
    </h3>
  ) : (
    title
  );

  useEffect(() => {
    if (open) {
      setOpened(true);
    }
  }, [open]);

  return (
    <div className={rootClassName || 'md:hidden'}>
      <button
        onClick={() => setOpened(!isOpened)}
        className={`flex w-full px-6 py-3 justify-between items-center ${
          className || ''
        }`}
      >
        {titleText}
        <div className={isOpened ? 'w-4 h-4 mx-4' : 'w-4 h-4 mx-4 rotate-180'}>
          <ArrowUpSVG />
        </div>
      </button>

      <UnmountClosed isOpened={isOpened}>
        <div className={`pb-3 ${contentContainerClassName || ''}`}>
          {children}
        </div>
      </UnmountClosed>
    </div>
  );
};
