import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteSVG from './assets/Delete.svg';
import type { Claim } from './models/Claim';
import { buttonSelectors } from './tests/dataCySelectors';
import { CustomerClaimStatus } from '../../common/constants/claims';
import { toI18nKey } from '../../common/utils/utils';
import { Card } from './Card';
import { ClaimStatusIndicator } from './ClaimStatusIndicator';
import { MobileArrow } from './MobileArrow';
import { PreviewImage } from './PreviewImage';
import { RoundedButton } from './RoundedButton';
import { TitledCardInfo } from './TitledCardInfo';

interface ClaimCardProps {
  claim: Claim;
  onDetails: () => void;
  onRemoveDraft?: (claimId: string) => void;
  className?: string;
  isMobile?: boolean;
  disabled?: boolean;
}
export const ClaimCard: FC<ClaimCardProps> = (props: ClaimCardProps) => {
  const { t } = useTranslation();
  const {
    claim: {
      pcmiClaimId,
      customerClaimImages: pictures,
      productPurchase: { product },
      status,
      updatedAt,
    },
    className,
    isMobile,
    disabled,
  } = props;
  // take first pic
  const firstImage = pictures && pictures[0];
  const activeSVGStyle = 'stroke-reguard-wintergreen';

  // button colors
  const buttonStyle = () => {
    switch (status) {
      case CustomerClaimStatus.Draft:
        return 'orange';
      case CustomerClaimStatus.UnderReview:
      case CustomerClaimStatus.InProgress:
      case CustomerClaimStatus.Approved:
        return 'violet';
      case CustomerClaimStatus.Closed:
      default:
        return 'white';
    }
  };

  const parsedDate = updatedAt?.toString() as string;
  const title = pcmiClaimId ? 'claim_id' : 'last_edited_date';
  const value = pcmiClaimId
    ? pcmiClaimId
    : new Date(parsedDate).toLocaleDateString('en-us', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });

  const removeIcon = status === CustomerClaimStatus.Draft &&
    !!props.onRemoveDraft && (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        title={t('delete_claim') as string}
        className="flex-none hidden sm:block sm:mr-[1rem] cursor-pointer w-5 h-5"
        data-cy={buttonSelectors.removeBtn}
        data-testid="remove-draft-button"
        onClick={() => {
          props.onRemoveDraft && props.onRemoveDraft(props.claim.id);
        }}
      >
        <DeleteSVG />
      </div>
    );
  const isDraft = status === CustomerClaimStatus.Draft;
  return (
    <Card
      handleClick={isMobile && !disabled ? props.onDetails : undefined}
      className={`ring-inset ${className}`}
      data-testid="claim-card"
    >
      <div className="flex items-start w-full text-reguard-indigo h-full">
        {/* picture */}
        <PreviewImage
          className={`mr-2 p-2 ${
            isDraft ? 'h-[3rem] min-w-[3rem] !mr-4 !p-0' : ''
          }`}
          imageUrl={isDraft ? undefined : firstImage?.url}
        />

        {/* claim data */}
        <div className="min-w-0 w-full h-full flex flex-col justify-center">
          <TitledCardInfo title={product.name} removeIcon={removeIcon}>
            <div
              className={`b3b md:b1b pt-1 md:pt-2  text-reguard-indigo-tint text-left ${
                isDraft ? 'hidden md:block' : ''
              }`}
            >
              <span className="b1 mr-2">{t(title)}</span>
              <span>{value}</span>
            </div>
            <div className="md:pt-2 pt-1">
              <ClaimStatusIndicator status={t(toI18nKey(status))} />
            </div>
          </TitledCardInfo>

          <div
            className="hidden w-full h-full md:flex justify-end items-end"
            title={
              t(toI18nKey(isDraft ? 'Finish claim' : 'View details')) as string
            }
          >
            <RoundedButton
              disabled={disabled}
              kind={buttonStyle()}
              onClick={!isMobile ? props.onDetails : undefined}
              data-cy={
                isDraft
                  ? buttonSelectors.finishClaimBtn
                  : buttonSelectors.viewDetailsBtn
              }
              data-testid="details-button"
            >
              {t(toI18nKey(isDraft ? 'Finish claim' : 'View details'))}
            </RoundedButton>
          </div>
        </div>
        {/* mobile arrow */}
        <MobileArrow
          onClick={!disabled ? props.onDetails : undefined}
          className="mr-5 ml-4 flex self-center"
          svgClassName={!isDraft ? activeSVGStyle : ''}
        />
      </div>
    </Card>
  );
};
