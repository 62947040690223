import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Languages } from '../common/main';
import { useMyUser } from '../network/api/users';
import LoaderEllipsisSVG from '../ui/components/assets/LoaderEllipsis.svg';
import { Badge } from '../ui/components/Badge';
import { ProfileItem } from '../ui/components/ProfileItem';
import ProfileModal, { ProfileModalProps } from './ProfileModal';

const Placeholder = ({ children }: { children: string }) => (
  <div className="b1 text-reguard-inactive1">{children}</div>
);

const ProfileUserInfo = () => {
  const [modalOpen, showOrHideModal] = useState<
    ProfileModalProps['formType'] | null
  >(null);
  const [addressId, setAddressId] = useState('');
  const { data: userData, isRefetching } = useMyUser();
  const { t } = useTranslation();
  const selectedLanguage = Languages.find(
    item => item.value === userData.languagePref,
  );

  return (
    <>
      {modalOpen && (
        <ProfileModal
          hasPhoneNumber={!!userData.phoneNumber}
          addressId={addressId}
          userData={userData}
          formType={modalOpen}
          handleClose={() => {
            setAddressId('');
            showOrHideModal(null);
          }}
        />
      )}
      <div className="w-full pb-3 md:pb-6">
        <ProfileItem name={t('name') as string}>{userData.name}</ProfileItem>

        <ProfileItem
          handleClick={() => !isRefetching && showOrHideModal('email')}
          name={t('email') as string}
        >
          {isRefetching ? (
            <div className="w-14 h-14">
              <LoaderEllipsisSVG />
            </div>
          ) : (
            userData.email
          )}
        </ProfileItem>

        <ProfileItem
          handleClick={() => !isRefetching && showOrHideModal('phoneNumber')}
          name={t('mobile_number') as string}
        >
          {isRefetching ? (
            <div className="w-14 h-14">
              <LoaderEllipsisSVG />
            </div>
          ) : (
            (
              <div className="flex items-center">
                {userData.phoneNumber}{' '}
                <Badge
                  color={userData.is_verified ? 'green' : 'red'}
                  text={userData.is_verified ? t('verified') : t('unverified')}
                />
              </div>
            ) || <Placeholder>{t('add_phone_number')}</Placeholder>
          )}
        </ProfileItem>

        <ProfileItem
          handleClick={() => !isRefetching && showOrHideModal('language')}
          name={t('language') as string}
        >
          {isRefetching ? (
            <div className="w-14 h-14">
              <LoaderEllipsisSVG />
            </div>
          ) : (
            selectedLanguage?.label || (
              <Placeholder>{t('select_language')}</Placeholder>
            )
          )}
        </ProfileItem>
      </div>
    </>
  );
};

export default ProfileUserInfo;
