import type { MouseEventHandler } from 'react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { ReguardHeader } from '@cortex/myaccount/views/shared/ReguardHeader/ReguardHeader';
import { SafeShipHeader } from '@cortex/myaccount/views/shared/SafeShipHeader/SafeShipHeader';

import ROUTES from '../../common/constants/routes';
import { navigationLinksSelectors } from '../../tests/dataCySelectors';
import { MobileMenu } from './MobileMenu';

interface HeaderProps {
  userName: string;
  onHamburgerClick?: MouseEventHandler;
  onLogout: MouseEventHandler;
}

export const MainHeader: FC<HeaderProps> = (props: HeaderProps) => {
  const [showMobileMenu, toggleMobileMenu] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const [bodyElement] = document.getElementsByTagName('body');

    if (showMobileMenu) {
      bodyElement.classList.add('overflow-hidden');
    }

    return () => {
      bodyElement.classList.remove('overflow-hidden');
    };
  }, [showMobileMenu]);

  return (
    <>
      <MobileMenu
        isMenuVisible={showMobileMenu}
        onDismiss={() => toggleMobileMenu(false)}
        onLogout={props.onLogout}
      />
      <header className="flex flex-col items-center bg-reguard-pearl-tint">
        {/* top header */}
        <div className="md:w-full bg-white hidden md:flex justify-center">
          <div className="md:flex justify-between p-4 font-light text-xs text-reguard-indigo-tint bg-white md:w-full md:max-w-[90rem]">
            <div>{t('welcome', { name: props.userName })}</div>
            <div className="flex gap-4 mr-4">
              <NavLink
                to={ROUTES.ACCOUNT}
                className={({ isActive }) =>
                  isActive
                    ? 'text-reguard-green c2'
                    : 'text-reguard-indigo-tint hover:underline c2'
                }
              >
                {t('account_settings')}
              </NavLink>
              <Link
                to="#"
                onClick={props.onLogout}
                data-cy={navigationLinksSelectors.logoutLink}
                className="tw-cst-pf text-reguard-indigo-tint hover:underline c2 cursor-pointer"
              >
                {t('log_out')}
              </Link>
            </div>
          </div>
        </div>

        {/* Logos + Links */}
        <div className="w-full flex md:w-full md:max-w-[90rem]">
          <ReguardHeader toggleMobileMenu={toggleMobileMenu} />
          <SafeShipHeader />
        </div>
      </header>
    </>
  );
};
