import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import LogoBlackSVG from './assets/LogoBlack.svg';
import { Button } from './Button';

interface ErrorPageProps {
  status?: number | string;
  message?: string;
  children?: ReactNode;
  logo?: ReactNode;
  callToAction?: () => void;
  ctaLabel?: string;
}

export const ErrorPage: FC<ErrorPageProps> = ({
  status,
  message,
  callToAction,
  logo,
  children,
  ctaLabel,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-screen w-full bg-reguard-pearl-tint items-center justify-center">
      {logo || (
        <span className="w-[19.375rem] h-[5rem] mb-24">
          <LogoBlackSVG />
        </span>
      )}
      <div className="text-3xl leading-[1.875rem] text-center max-w-[25rem] mb-24">
        <div className="pb-4">
          <span className="s1b mr-4">{status || 404}.</span>
          <span className="s1 font-normal">{t('there_is_an_error')}</span>
        </div>
        <span className="s1b">
          {message || t("we_couldn't_find_the_page_that_you_requested")}
        </span>
      </div>
      {callToAction && <Button onClick={callToAction}>{ctaLabel}</Button>}
      {children}
    </div>
  );
};
