import type { FC, ReactNode } from 'react';

import FacRemoveImageCrossSVG from './assets/FacRemoveImageCross.svg';
import type { ImageListExtended } from './constants/facFieldsInitialValues';
import { SquareImageContainer } from './SquareImageContainer';

interface FileClaimImageGridProps {
  editable?: boolean;
  handleImageClick?: (index: number) => void;
  imageList: ImageListExtended;
  isEditing?: boolean;
  className?: string;
  gridWrapperClassName?: string;
}

interface RemovableImageProps {
  handleImageClick: () => void;
  editingImages?: boolean;
  children: ReactNode;
}

const RemovableImage: FC<RemovableImageProps> = ({
  handleImageClick,
  editingImages,
  children,
}) => (
  <button
    className={`${
      editingImages ? 'cursor-pointer' : ''
    } h-full w-full rounded-lg relative bg-transparent`}
    onClick={e => {
      e.preventDefault();
      handleImageClick();
    }}
  >
    {children}
    {editingImages && (
      <div
        className="flex flex-1 h-full rounded-lg absolute top-0 right-0 justify-center items-center left-0"
        style={{
          background: `linear-gradient(0deg, rgba(30, 37, 71, 0.5), rgba(30, 37, 71, 0.5))`,
        }}
      >
        <span className="sm:h-7 sm:w-7">
          <FacRemoveImageCrossSVG />
        </span>
      </div>
    )}
  </button>
);

export const FileClaimImageGrid: FC<FileClaimImageGridProps> = ({
  editable = false,
  imageList,
  handleImageClick,
  isEditing,
  className = '',
  gridWrapperClassName = '',
}) => (
  <div className={`flex sm:px-4 w-fit ${className}`}>
    <div
      className={`grid grid-cols-[repeat(4,_minmax(4.625rem,_7.813rem))] grid-rows-[repeat(auto-fit,_minmax(4.625rem,_1fr))] gap-2 mb-6 sm:mb-8 sm:gap-6 ${gridWrapperClassName}`}
    >
      {imageList.map((image, index) => (
        <div
          key={`${image.name}-${index}`}
          className="image-item flex flex-1 h-full min-w-[4.625rem] max-w-[7.813rem] min-h-[4.625rem] max-h-[7.813rem]"
        >
          {editable &&
          // was not already uploaded
          image.file?.lastModified ? (
            <RemovableImage
              handleImageClick={() =>
                handleImageClick && handleImageClick(index)
              }
              editingImages={isEditing}
            >
              <SquareImageContainer
                className="shadow-md rounded-lg w-full min-w-[7rem] min-h-[7rem] max-h-[18rem] max-w-[18rem]"
                alt={image.name}
                url={image.dataURL}
                imageClassName="object-cover rounded-lg"
              />
            </RemovableImage>
          ) : (
            <SquareImageContainer
              className="shadow-md rounded-lg w-full min-w-[7rem] min-h-[7rem] max-h-[18rem] max-w-[18rem]"
              alt={image.name}
              url={image.dataURL}
              imageClassName="object-cover rounded-lg"
            />
          )}
        </div>
      ))}
    </div>
  </div>
);
