export enum ContractStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Void = 'Void',
  Expired = 'Expired',
  Inactive = 'Inactive',
  Pending = 'Pending',
  Recreated = 'Recreated',
}

export enum ContractSource {
  Api = 'Api',
  Recreated = 'Recreated',
  File = 'File',
  Merchant = 'Merchant',
}
