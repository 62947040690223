import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActivePlans } from './components/active-plans/active-plans';
import { Claims } from './components/claims/claims';
import { ExpiredPlansSection } from './components/expired-plans/expired-plans';
import { InactivePlans } from './components/inactive-plans/inactive-claims';
import { useHomeView } from './helpers/use-home-view';
import { MainPage, MainSection } from '../../ui/components';

export const Home = () => {
  const {
    orderData,
    viewAllClaims,
    setViewAllClaims,
    isFetching,
    showViewAllClaimsButton,
  } = useHomeView();

  const { t } = useTranslation();
  const navigate = useNavigate();

  if (isFetching) return <div className="h-[18.75rem]"></div>;

  const handleShowFileClaimButton = orderData.purchases.active.some(
    p => p.canFileAClaim,
  );

  return (
    <div>
      <div className="relative flex flex-col w-full flex-1">
        <MainPage title="Home">
          <Claims
            claims={orderData.claims}
            viewAllClaims={viewAllClaims}
            setViewAllClaims={setViewAllClaims}
            navigate={navigate}
            t={t}
          />

          {showViewAllClaimsButton && (
            <MainSection>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => setViewAllClaims(true)}
                className="b1b pt-4 cursor-pointer flex justify-end"
              >
                {t('view_all_claims')}
              </span>
            </MainSection>
          )}

          <InactivePlans purchases={orderData.purchases.inactive} />

          <ActivePlans
            purchases={orderData.purchases.active}
            isFetching={isFetching}
            showFileClaimButton={handleShowFileClaimButton}
          />

          <ExpiredPlansSection purchases={orderData.purchases.expired} />
        </MainPage>
      </div>
    </div>
  );
};
