import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Address } from '@cortex/myaccount/ui/components/models/Address';

import { useAllUserAddresses } from '../network/api/users';
import type { User } from '../ui/components';
import ProfileModal, { ProfileModalProps } from './ProfileModal';

interface FileClaimUserInfoStepProps {
  userData: User;
  onProcessSuccess?: () => void;
  renderStep: ({
    addresses,
    openAddressModal,
    openPhoneModal,
    loadingData,
  }: {
    addresses: Address[];
    openAddressModal: () => void;
    openPhoneModal: () => void;
    loadingData: boolean;
  }) => ReactNode;
}

export const FileClaimUserInfoStep: FC<FileClaimUserInfoStepProps> = ({
  renderStep,
  userData,
  onProcessSuccess,
}) => {
  const { data: addresses, isFetching } = useAllUserAddresses();
  const { t } = useTranslation();
  const [modalOpen, showOrHideModal] = useState<
    ProfileModalProps['formType'] | null
  >(null);
  const [addressId, setAddressId] = useState('');

  const openAddressModal = () => {
    showOrHideModal('address');
  };

  const openPhoneModal = () => {
    showOrHideModal('phoneNumber');
  };

  return (
    <>
      {modalOpen && (
        <ProfileModal
          addressId={addressId}
          formType={modalOpen}
          hasPhoneNumber={!!userData.phoneNumber}
          userData={userData}
          handleClose={() => {
            setAddressId('');
            showOrHideModal(null);
          }}
          onProcessSuccess={onProcessSuccess}
          successCTALabel={t('back_to_verify_contact_info') as string}
        />
      )}
      {renderStep({
        addresses,
        openAddressModal,
        openPhoneModal,
        loadingData: isFetching,
      })}
    </>
  );
};
