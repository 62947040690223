import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormStepInfoIconSVG from './assets/FormStepInfoIcon.svg';
import classes from './PlansTooltip.module.css';
import { Tooltip } from './Tooltip';
import { SmallDialog, SmallDialogButtonMapType } from '.';
import { useScreenSize } from '.';

const title = 'why_is_my_plan_inactive';
const subtitle =
  'your_plan_will_be_automatically_activated_once_your_first_item_is_delivered';

export const PlansTooltip = () => {
  const [showModal, setShowModal] = useState(false);
  const { isTabletOrBelow } = useScreenSize();
  const { t } = useTranslation();
  useEffect(() => {
    // close mobile modal when stretching to desktop
    if (!isTabletOrBelow && showModal) {
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabletOrBelow]);

  return (
    <>
      <Tooltip
        tooltipActive={true}
        content={
          <>
            <div className="semibold p-2">{t(title)}</div>
            <div className="p-2 pt-0 font-light">{t(subtitle)}</div>
          </>
        }
      >
        <button
          className={`flex items-center tw-cst-pf bg-transparent md:pr-[1.875rem] ${classes['info-icon-button-inactive']}`}
          type="button"
          onClick={() => isTabletOrBelow && setShowModal(true)}
        >
          <FormStepInfoIconSVG />
        </button>
      </Tooltip>

      {isTabletOrBelow && showModal ? (
        <div className="flex md:hidden">
          <SmallDialog
            title={title}
            subtitle={subtitle}
            onClose={() => {
              setShowModal(false);
            }}
            buttonsConfig={[
              {
                type: SmallDialogButtonMapType.CANCEL,
                handleClick: async () => {
                  setShowModal(false);
                },
              },
            ]}
          />
        </div>
      ) : null}
    </>
  );
};
