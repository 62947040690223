import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useScreenSize } from './hooks/screen-size';
import type { CustomerClaimImage } from './models/CustomerClaimImage';
import { buttonSelectors } from './tests/dataCySelectors';
import { ButtonWithArrow } from './ButtonWithArrow';
import { ImageViewer } from './ImageViewer';
import { PreviewImage } from './PreviewImage';

interface ClaimPhotosProps {
  pictures: CustomerClaimImage[];
  onClick: () => void;
}

const mobileMaxPictures = 3;

export const ClaimPhotos: FC<ClaimPhotosProps> = (props: ClaimPhotosProps) => {
  const { pictures, onClick } = props;
  // manage number of pictures and resize
  const { isMobile } = useScreenSize();
  const [loadedAll, setLoadedAll] = useState(
    pictures.length <= mobileMaxPictures,
  );
  const [picturesToShow, setPicturesToShow] = useState(
    isMobile ? pictures.slice(0, mobileMaxPictures) : pictures,
  );
  const { t } = useTranslation();
  const loadAll = () => {
    setPicturesToShow(pictures);
    setLoadedAll(true);
  };

  useEffect(() => {
    if (!loadedAll) {
      if (!isMobile) {
        setPicturesToShow(pictures);
      } else {
        setPicturesToShow(pictures.slice(0, 3));
      }
    }
  }, [isMobile, loadedAll, pictures]);

  // manage image viewer
  const [showViewer, setShowViewer] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const openViewer = (idx: number) => {
    setSelectedIndex(idx);
    setShowViewer(true);
  };

  return (
    <div className="md:mb-[7.25rem]">
      {showViewer && (
        <ImageViewer
          pictures={pictures}
          selectedIndex={selectedIndex}
          onDismiss={() => setShowViewer(false)}
        />
      )}

      <div className="flex justify-between max-w-[37.5rem] pr-4">
        <h3 className="tw-cst-pf text-reguard-indigo py-4 pr-4 s1">
          {t('submitted_photos')}
        </h3>
        <ButtonWithArrow
          className=""
          direction="right"
          onClick={onClick}
          data-cy={buttonSelectors.uploadPhotosBtn}
        >
          <span className="b3">+ {t('upload_photos')}</span>
        </ButtonWithArrow>{' '}
      </div>

      <div className="flex gap-y-4 flex-wrap w-full pt-2 pb-4 md:pl-0 h-full">
        {picturesToShow.length > 0 ? (
          picturesToShow.map((p, i) => (
            <PreviewImage
              className="!mr-2 md:!mr-4 w-[6.25rem] h-[6.25rem] md:h-[9.375rem] md:w-[9.375rem]"
              key={i}
              imageUrl={p.url}
              onClick={() => openViewer(i)}
            />
          ))
        ) : (
          <span className="text-reguard-indigo">
            {t('no_photos_submitted')}
          </span>
        )}
      </div>
      {isMobile && !loadedAll && (
        <div>
          <button
            className="text-reguard-violet text-sm"
            onClick={loadAll}
            data-cy={buttonSelectors.loadAllBtn}
          >
            {t('load_all')}
          </button>
        </div>
      )}
    </div>
  );
};
