interface ProgressBarType {
  step: number;
}

export function ProgressBar({ step }: ProgressBarType) {
  return (
    <div className="mt-6 w-3/4 mx-auto lg:w-full lg:mx-0">
      <div className="overflow-hidden rounded-full bg-gray-200">
        <div
          className="h-4 rounded-full bg-[#7368ED]"
          style={{ width: `calc((${step} * 2 + 1) / 8 * 100%)` }}
        />
      </div>
      <div className="mt-6 hidden grid-cols-4 font-medium text-gray-600 sm:grid">
        {Array.from({ length: step }, (_, index) => (
          <div
            key={index}
            className={`text-center ${step > index ? 'text-[#7368ED]' : ''}`}
          ></div>
        ))}
      </div>
    </div>
  );
}
