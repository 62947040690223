import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useRequestEmailChange,
  useRequestPhoneChange,
  useUpdatePhoneNumber,
  useUpdateUserEmail,
} from '../network/api/users';
import { buttonSelectors } from '../tests/dataCySelectors';
import { Button, TokenForm } from '../ui/components';
import type { ProfileModalProps } from './ProfileModal';
import ResendClock from './ResendClock';

interface ProfileTokenFormProps {
  onSubmitSucccess: () => void;
  handleClose: () => void;
  formType: ProfileModalProps['formType'];
  fieldValue: string;
  timer?: number;
}

const ProfileTokenForm: FC<ProfileTokenFormProps> = ({
  handleClose,
  onSubmitSucccess,
  formType,
  fieldValue,
  ...props
}) => {
  const [error, setError] = useState('');
  const [sendDisabled, setSendDisabled] = useState(true);
  const [codeSent, setCodeSent] = useState(false);
  const { mutateAsync: updateEmail, isLoading: updatingEmail } =
    useUpdateUserEmail();
  const { mutateAsync: updatePhone, isLoading: updatingPhone } =
    useUpdatePhoneNumber();

  const { mutateAsync: requestEmailChange, isLoading: requestingEmailChange } =
    useRequestEmailChange();
  const { mutateAsync: requestPhoneChange, isLoading: requestingPhoneChange } =
    useRequestPhoneChange();

  const isLoading =
    updatingEmail ||
    updatingPhone ||
    requestingPhoneChange ||
    requestingEmailChange;

  const { t } = useTranslation();
  // TODO: get timer from mutation cached response from the previous request
  // show timer
  const timer = props.timer || 0;
  const resetTimer = () => setSendDisabled(false);
  const handleSendButtonStatus = () => setSendDisabled(true);

  async function handleResendCode() {
    setError('');
    setCodeSent(false);
    try {
      if (formType === 'email') {
        await requestEmailChange({ email: fieldValue });
      } else {
        await requestPhoneChange({ phoneNumber: fieldValue });
      }
      setCodeSent(true);
      handleSendButtonStatus();
    } catch (e: any) {
      setError(e.message);
    }
  }

  return (
    <TokenForm
      error={error}
      setError={setError}
      handleSubmit={async token => {
        try {
          if (formType === 'email') {
            await updateEmail({ verificationCode: token, email: fieldValue });
          } else {
            await updatePhone({
              verificationCode: token,
              phoneNumber: fieldValue,
            });
          }
          onSubmitSucccess();
        } catch (e: any) {
          setError(e?.message);
        }
      }}
    >
      <div className="mt-[0.875rem] pt-4 relative w-full flex flex-col items-center">
        {codeSent && (
          <div className="text-reguard-wintergreen-shade b3">
            {t('code_sent')}
          </div>
        )}
        {/*  */}
        {sendDisabled && (
          <ResendClock timer={timer || 0} resetTimer={resetTimer} />
        )}
        <Button
          disabled={sendDisabled}
          form="profiletokenform"
          type="button"
          isfetching={isLoading}
          onClick={handleResendCode}
          data-cy={buttonSelectors.resendCodeBtn}
        >
          {t('resend_code')}
        </Button>
        <button
          onClick={handleClose}
          className="flex w-full justify-center b2 text-reguard-wintergreen-shade mt-4"
          data-cy={buttonSelectors.cancelBtn}
        >
          {t('cancel')}
        </button>
      </div>
    </TokenForm>
  );
};

export default ProfileTokenForm;
