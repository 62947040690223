import { useTranslation } from 'react-i18next';

export function PolicyCardInfo({ orderNumber, contract }: any) {
  const { t } = useTranslation();

  return (
    <div className="min-w-0 w-full  max-h-[5.125rem] md:mb-[1.875rem] text-left">
      <div className="flex justify-between semibold text-base leading-4 md:s2 md:leading-6 mb-2">
        <span>{t('order')}</span>
        <span>{orderNumber}</span>
      </div>
      <div className="bg-gray-100 flex justify-between mb-1">
        <span>{t('tracking')}</span>
        <span className="text-gray-500">{contract.tracking}</span>
      </div>

      <div className="flex justify-between mb-1">
        <span>{t('shipping_status')}</span>
        <span className="text-gray-500">
          {t(contract.shipping_status || '')}
        </span>
      </div>

      <div className="bg-gray-100 flex justify-between mb-1">
        <span>{t('policy_status')}</span>
        <span className="text-gray-500">{t(contract.status || '')}</span>
      </div>
    </div>
  );
}
