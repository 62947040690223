import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';

import FacDocumentsFileCopySmall from './assets/FacDocumentsFileCopySmall.svg';
import FacPencil from './assets/FacPencil.svg';
import type { FileClaimFields } from './constants/facFieldsInitialValues';
import { productConditions } from './constants/facRadioBtn';
import { buttonSelectors } from './tests/dataCySelectors';
import { FileClaimImageGrid } from './FileClaimImageGrid';
import { FileClaimStep } from '.';

interface FileClaimStepFiveProps {
  merchantName?: string;
  purchaseDate?: string;
  setFormStep: (step: number) => void;
  isChatBot?: boolean;
}

export const FileClaimStepFive: FC<FileClaimStepFiveProps> = ({
  merchantName,
  purchaseDate,
  setFormStep,
  isChatBot,
}: FileClaimStepFiveProps) => {
  const { values } = useFormikContext<FileClaimFields>();
  const productConditionLabel = productConditions.find(
    pc => pc.value === values.productCondition,
  )?.text;
  const { t } = useTranslation();

  return (
    <>
      <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 mt-8 md:mt-0 border-reguard-pearl">
        <div className="left mb-4">
          <div className="text-cqsm">{t('merchant')}</div>
          <div className="text-base semibold">{merchantName}</div>
        </div>
      </div>

      <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 border-reguard-pearl">
        <div className="right mb-4">
          <div className="text-cqsm">{t('purchase_date')}</div>
          <div className="text-base semibold">
            {purchaseDate && dayjs(purchaseDate).format('MMM DD, YYYY')}
          </div>
        </div>
      </div>

      {values.dateOfDamage && (
        <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 border-reguard-pearl">
          <div className="left mb-4">
            <div className="text-cqsm">{t('date_of_damage')}</div>
            <div className="text-base semibold">
              {dayjs(values.dateOfDamage).format('MMM DD, YYYY')}
            </div>
          </div>
          <div className="right mb-4">
            <button
              type="button"
              className="tw-cst-pf bg-transparent"
              onClick={() => setFormStep(FileClaimStep.STEP_ONE)}
              data-cy={buttonSelectors.editStepOneBtn}
              data-testid="editStepOneBtn"
            >
              <FacPencil />
            </button>
          </div>
        </div>
      )}

      {values.causeOfDamage && (
        <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 border-reguard-pearl">
          <div className="left mb-4">
            <div className="text-cqsm">{t('cause_of_damage')}</div>
            <div className="text-base semibold">
              {values.causeOfDamage?.label}
            </div>
          </div>
          <div className="right mb-4">
            <button
              type="button"
              className="tw-cst-pf bg-transparent"
              onClick={() => setFormStep(FileClaimStep.STEP_ONE)}
              data-cy={buttonSelectors.editStepOneBtn}
              data-testid="editStepOneBtn"
            >
              <FacPencil />
            </button>
          </div>
        </div>
      )}
      {values.locationOfDamage && (
        <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 border-reguard-pearl">
          <div className="left mb-4">
            <div className="text-cqsm">{t('location_of_damage')}</div>
            <div className="text-base semibold">
              {values.locationOfDamage?.label}
            </div>
          </div>
          <div className="right mb-4">
            <button
              type="button"
              className="tw-cst-pf bg-transparent"
              onClick={() => setFormStep(FileClaimStep.STEP_ONE)}
              data-cy={buttonSelectors.editStepOneBtn}
              data-testid="editStepOneBtn"
            >
              <FacPencil />
            </button>
          </div>
        </div>
      )}
      {values.damageArea && (
        <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 border-reguard-pearl">
          <div className="left mb-4">
            <div className="text-cqsm">{t('damage_area')}</div>
            <div className="text-base semibold">{values.damageArea?.label}</div>
          </div>
          <div className="right mb-4">
            <button
              type="button"
              className="tw-cst-pf bg-transparent"
              onClick={() => setFormStep(FileClaimStep.STEP_ONE)}
              data-cy={buttonSelectors.editStepOneBtn}
              data-testid="editStepOneBtn"
            >
              <FacPencil />
            </button>
          </div>
        </div>
      )}
      {values.descriptionOfDamage && (
        <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 border-reguard-pearl">
          <div className="left mb-4">
            <div className="text-cqsm">{t('description')}</div>
            <div className="text-base semibold">
              {values.descriptionOfDamage}
            </div>
          </div>
          <div className="right mb-4">
            <button
              type="button"
              className="tw-cst-pf bg-transparent"
              onClick={() => setFormStep(FileClaimStep.STEP_TWO)}
              data-cy={buttonSelectors.editStepTwoBtn}
            >
              <FacPencil />
            </button>
          </div>
        </div>
      )}
      {values.productCondition && (
        <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 border-reguard-pearl">
          <div className="left mb-4">
            <div className="text-cqsm">{t('product_condition')}</div>
            <div className="text-base semibold">
              {t(productConditionLabel || values.productCondition)}
            </div>
          </div>
          <div className="right mb-4">
            <button
              type="button"
              className="tw-cst-pf bg-transparent"
              onClick={() => setFormStep(FileClaimStep.STEP_TWO)}
              data-cy={buttonSelectors.editStepTwoBtn}
            >
              <FacPencil />
            </button>
          </div>
        </div>
      )}

      {values.claimPhotos && (
        <div className="border-solid border-0 border-b mb-4 flex flex-col w-full items-center border-reguard-pearl">
          <div className="purchaseInfo w-full flex flex-row items-center justify-between mb-4 sm:px-4">
            <div className="left mb-4">
              <div className="text-cqsm">{t('photos_title')}</div>
            </div>
            <div className="right mb-4">
              <button
                type="button"
                className="tw-cst-pf bg-transparent"
                onClick={() => setFormStep(FileClaimStep.STEP_THREE)}
                data-cy={buttonSelectors.editStepThreeBtn}
              >
                <FacPencil />
              </button>
            </div>
          </div>

          <FileClaimImageGrid
            gridWrapperClassName={isChatBot ? '!gap-2 !mb-6' : ''}
            className="sm:px-0"
            imageList={values.claimPhotos}
          />
        </div>
      )}
      {values.claimReceipt && (
        <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 cqsm:mb-14 sm:mb-14 border-reguard-pearl">
          <div className="left mb-4">
            <div className="text-cqsm">{t('receipt')}</div>

            <div className="flex items-center">
              <span className="mr-1">
                <FacDocumentsFileCopySmall />
              </span>
              <div className="text-base semibold">
                {values.claimReceipt.name}
              </div>
            </div>
          </div>
          <div className="right mb-4">
            <button
              type="button"
              className="tw-cst-pf bg-transparent"
              onClick={() => setFormStep(FileClaimStep.STEP_THREE)}
              data-cy={buttonSelectors.editStepThreeBtn}
            >
              <FacPencil />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
