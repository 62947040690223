import { Claim, ClaimCard, ProductPurchase } from '../../../../ui/components';

interface Props {
  isMobile: boolean;
  title: string;
  claim: Claim;
  plan: { [index: string]: any };
  goToDetails: () => void;
}

export function ClaimSection({
  claim,
  title,
  plan,
  isMobile,
  goToDetails,
}: Props) {
  return (
    <>
      <h3 className="tw-cst-pf hidden md:block text-4xl semibold text-reguard-indigo pb-4">
        {title}
      </h3>
      <div className="flex px-0 w-full">
        <ClaimCard
          className="w-full"
          isMobile={isMobile}
          claim={{ ...claim, productPurchase: plan as ProductPurchase }}
          onDetails={goToDetails}
        />
      </div>
    </>
  );
}
