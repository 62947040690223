import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePurchasesSearch } from '../network/api/purchases';
import {
  CardsGrid,
  MainPage,
  MainSection,
  ProductCard,
  useGoogleAnalytics,
} from '../ui/components';

export const ChooseProduct = () => {
  const { data: purchases } = usePurchasesSearch();
  const prodsToClaim = purchases.active.filter(
    (data: typeof purchases.active) => data.canFileAClaim,
  );
  const navigate = useNavigate();
  const { fileAClaimCTAEvent } = useGoogleAnalytics();
  const { t } = useTranslation();
  const goToFileAClaim = (productPurchaseId: string | undefined) => {
    navigate(`/claims/new?productPurchaseId=${productPurchaseId}`);
  };

  return (
    <MainPage title={t('choose_a_product_to_file_a_claim') as string}>
      <MainSection
        title={t('choose_a_product_to_file_a_claim') as string}
        subtitle={
          t(
            'please_note_these_are_all_the_products_potentially_covered_by_your_reguard_plan',
          ) as string
        }
      >
        <CardsGrid className="mb-20">
          {prodsToClaim.map((data: typeof purchases.active) => (
            <ProductCard
              daysUntilExpiration={data.daysUntilExpiration}
              isExpired={data.isExpired}
              fileClaim={() => {
                fileAClaimCTAEvent({
                  productSKU: data.product.sku,
                  name: data.product.name,
                });
                goToFileAClaim(data.productPurchaseId);
              }}
              protectionPlanSKU={data.protectionPlan.sku}
              key={data.product.sku}
              {...data}
            />
          ))}
        </CardsGrid>
      </MainSection>
    </MainPage>
  );
};
