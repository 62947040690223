import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ROUTES from '@cortex/myaccount/common/constants/routes';
import { useGetClaimForFormById } from '@cortex/myaccount/network/api/claims';
import { useGetDomainValues } from '@cortex/myaccount/network/api/claims';
import { type FileClaimDamageDomain } from '@cortex/myaccount/ui/components';
import { FileClaimConfirmation } from '@cortex/myaccount/ui/components/FileClaimConfirmation';
import { MainPage } from '@cortex/myaccount/ui/components/MainPage';
import { MainSection } from '@cortex/myaccount/ui/components/MainSection';
import { SubmittedClaimData } from '@cortex/myaccount/views/safeship/types/fileClaimTypes';

import { FileClaimModal } from '../components/FileClaim/FileClaimModal/FileClaimModal';
import { FileClaimSteps } from '../components/FileClaim/FileClaimSteps/FileClaimSteps';
import { FileClaimContextProvider } from '../context/file-claim-context';

export type FileClaimDamageDomainData = FileClaimDamageDomain[];

interface DomainType {
  damageArea: FileClaimDamageDomainData;
  damageCause: FileClaimDamageDomainData;
  damageLocation: FileClaimDamageDomainData;
}

enum CustomerClaimStatus {
  Draft = 'Draft',
  UnderReview = 'Under Review',
  Approved = 'Approved',
  InProgress = 'In Progress',
  Closed = 'Closed',
  Pending = 'Pending',
}

export const SIFileClaim: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [facModal, setFacModal] = useState(true);
  const [newClaimData, setNewClaimData] = useState<SubmittedClaimData>();

  const productPurchaseId = searchParams.get('productPurchaseId') || '';
  const draftClaimId = searchParams.get('draftClaimId') || '';

  const { data: draftClaim = {}, isFetching: isFetchingDraft } =
    useGetClaimForFormById({
      claimId: draftClaimId,
    });

  const { data, isFetching: isFetchingDomain } = useGetDomainValues(
    productPurchaseId ? { productPurchaseId } : { draftClaimId },
  );

  const domainData = data as DomainType;

  const isFetching = isFetchingDraft || isFetchingDomain;

  const handleContent = () => {
    if (isFetching) return null;

    if (newClaimData) {
      return (
        <FileClaimConfirmation
          viewClaim={() => {
            navigate(
              `${ROUTES.MY_CLAIMS}/${newClaimData.claimId || draftClaimId}`,
            );
          }}
          claimId={newClaimData?.pcmiClaimNumber || ''}
          submissionDate={newClaimData.submissionDate}
          status={CustomerClaimStatus.UnderReview}
          nextSteps={[
            t('customer_service_will_carefully_review_your_claim'),
            t('if_approved_you_will_receive_further_instruction_by_email'),
          ]}
        />
      );
    }

    return (
      <FileClaimContextProvider>
        <FileClaimSteps
          draftClaim={draftClaim}
          productPurchaseId={
            productPurchaseId || draftClaim?.productPurchase?.id
          }
          onSubmissionSuccess={setNewClaimData}
          domainData={domainData}
        />
      </FileClaimContextProvider>
    );
  };

  return (
    <MainPage>
      <MainSection>
        {facModal && !draftClaimId && (
          <FileClaimModal
            handleClose={() => {
              setFacModal(false);
            }}
          />
        )}
        {handleContent()}
      </MainSection>
    </MainPage>
  );
};
