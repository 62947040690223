import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useChangeUserPassword } from '../network/api/users';
import { buttonSelectors } from '../tests/dataCySelectors';
import { passwordRules, RoundedButton, TextField } from '../ui/components';

const ProfileUserPassword = () => {
  const { mutateAsync: changeUserPassword } = useChangeUserPassword();
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        currentPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined,
      }}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string().required(
          t('current_password_is_required') as string,
        ),
        newPassword: Yup.string()
          .matches(
            passwordRules,
            t("your_password_doesn't_meet_the_requirements") as string,
          )
          .min(8, t('new_password_must_be_at_least_8_characters') as string)
          .required(t('new_password_is_required') as string),
        confirmPassword: Yup.string()
          .required(t('confirm_password_is_required') as string)
          .matches(
            passwordRules,
            t("your_password_doesn't_meet_the_requirements") as string,
          )
          .min(8, t('confirm_password_must_be_at_least_8_characters') as string)
          .when('newPassword', {
            is: (val: string) => val && val.length > 0,
            then: Yup.string().oneOf(
              [Yup.ref('newPassword')],
              t('both_passwords_need_to_be_the_same') as string,
            ),
          }),
      })}
      onSubmit={async (
        { currentPassword, newPassword },
        { setSubmitting, setStatus },
      ) => {
        try {
          setStatus(undefined);
          setSubmitting(true);

          await changeUserPassword({
            oldPassword: currentPassword,
            newPassword,
          });
          setStatus('success');
        } catch (e) {
          setStatus((e as { message: string }).message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        status,
        isValid,
        setStatus,
      }) => (
        <form
          className="w-full flex flex-col max-w-[25.25rem] gap-4 pb-8 md:pb-20"
          onSubmit={handleSubmit}
        >
          <TextField
            name="currentPassword"
            value={values.currentPassword}
            type="password"
            onChange={e => {
              handleChange(e);
              if (status && status !== 'success') {
                setStatus(undefined);
              }
            }}
            label={t('current_password') as string}
            placeholder="************"
            error={Boolean(errors.currentPassword)}
            valid={values.currentPassword && Boolean(!errors.currentPassword)}
          />

          <TextField
            name="newPassword"
            type="password"
            onChange={e => {
              handleChange(e);
              if (status && status !== 'success') {
                setStatus(undefined);
              }
            }}
            value={values.newPassword}
            label={t('new_password') as string}
            placeholder="************"
            error={Boolean(errors.newPassword)}
            valid={values.newPassword && Boolean(!errors.newPassword)}
          />

          <TextField
            name="confirmPassword"
            type="password"
            onChange={e => {
              handleChange(e);
              if (status && status !== 'success') {
                setStatus(undefined);
              }
            }}
            value={values.confirmPassword}
            label={t('confirm_new_password') as string}
            placeholder="************"
            error={Boolean(errors.confirmPassword)}
            valid={values.confirmPassword && Boolean(!errors.confirmPassword)}
          />

          {status === 'success' ? (
            <span className="text-reguard-wintergreen-shade b1 leading-5 mt-1 mb-16">
              {t('your_password_has_been_saved')}
            </span>
          ) : (
            <div
              className={`text-reguard-indigo font-normal text-[0.75rem] leading-[0.875rem] mt-1 mb-4 h-[4.375rem] ${
                (errors.currentPassword ||
                  errors.confirmPassword ||
                  (status && status !== 'success')) &&
                'text-reguard-error'
              }`}
            >
              {status ? (
                `${status}`
              ) : (
                <>
                  {t(
                    'password_must_be_at_least_8_characters_long_and_contain_one_character_from_each_of_the_following',
                  )}
                  <ul className="tw-cst-pf list-disc ml-4">
                    <li>{t('uppercase_(a,_z)')}</li>
                    <li>{t('lowercase_(a,_z)')}</li>
                    <li>{t('numeric_(0-9)')}</li>
                    <li>{t('special_(!,_%,_@,_#,_etc.)')}</li>
                  </ul>
                </>
              )}
            </div>
          )}

          <RoundedButton
            className="w-32"
            type="submit"
            kind={
              !isValid || isSubmitting || (status && status !== 'success')
                ? 'grey'
                : 'green'
            }
            disabled={
              !isValid || isSubmitting || (status && status !== 'success')
            }
            data-cy={buttonSelectors.saveChangesBtn}
          >
            {t('save_changes')}
          </RoundedButton>
        </form>
      )}
    </Formik>
  );
};

export default ProfileUserPassword;
