import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import USstates from './constants/USStates';
import type { Contract, ContractAddress } from './models/Contract';
import { BrandedDialog } from './BrandedDialog';
import { Button } from './Button';
import type { AddressInputsType } from './PlanDetailsBox';
import { notify } from './Toast';

interface AddressDataType {
  address: ContractAddress;
  contractId: string;
}

interface UpdateProtectionPlanAddressPropsType {
  contract: Contract;
  updateContractAddress: (address: AddressDataType) => void;
  setShowModifyAddressModal: (showModifyAddressModal: boolean) => void;
  address: ContractAddress;
  setAddress: (address: ContractAddress) => void;
}

export const UpdateProtectionPlanAddress = ({
  contract,
  updateContractAddress,
  setShowModifyAddressModal,
  address,
  setAddress,
}: UpdateProtectionPlanAddressPropsType) => {
  const { t } = useTranslation();

  const schema = yup
    .object()
    .shape({
      address1: yup
        .string()
        .required('Address is required')
        .default('')
        .min(1, 'You must enter an address'),
      address2: yup.string().optional().default(''),
      city: yup.string().required('City is required').default(''),
      state: yup.string().required().default(''),
      zip_code: yup
        .string()
        .required('Zip Code is required')
        .default('')
        .min(5, 'You must enter a zip code'),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<AddressInputsType>({
    defaultValues: {
      address1: address?.address1 || '',
      address2: address?.address2 || '',
      city: address?.city || '',
      state: address?.state || '',
      zip_code: address?.zip_code || '',
    },
    criteriaMode: 'all',
    // This one is a known issue - https://github.com/react-hook-form/resolvers/issues/245
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const handleAddressSubmission = (data: any) => {
    const addressData = {
      address: { ...data },
      contractId: contract.id,
    };

    updateContractAddress(addressData);
    setAddress(data);
    setShowModifyAddressModal(false);
    notify({
      title: t('your_address_has_been_saved'),
      options: {
        type: 'success',
      },
    });
  };

  return (
    <BrandedDialog
      title="Edit Contract Address"
      subtitle="This address will be used to help service your claim"
      contentClass="max-w-[100rem] !pt-32"
      handleClose={() => {
        setShowModifyAddressModal(false);
      }}
    >
      <form
        className="bg-white sm:rounded-xl md:col-span-2"
        onSubmit={handleSubmit(handleAddressSubmission)}
      >
        <div>
          <div className="mt-4 mb-4">
            <div>
              <label htmlFor="address-1">Address 1</label>
            </div>
            <input
              className="border-solid border-2 border-black rounded-md w-full"
              {...register('address1', {
                required: 'Please enter your address',
              })}
            />
            <div className="text-red-600">{errors?.address1?.message}</div>
          </div>

          <div className="mb-4">
            <div>
              <label htmlFor="address-2">Address 2</label>
            </div>
            <input
              className="border-solid border-2 border-black rounded-md w-full"
              {...register('address2')}
            />
            <div className="text-red-600 mb-2">{errors?.address2?.message}</div>
          </div>

          <div className="flex flex-row mb-4">
            <div className="w-1/3">
              <div>
                <label htmlFor="city">City</label>
              </div>
              <input
                className="border-solid border-2 border-black rounded-md w-5/6"
                {...register('city', {
                  required: 'Please enter your city',
                })}
              />
              <div className="text-red-600 mb-2">{errors?.city?.message}</div>
            </div>

            <div className="w-1/3">
              <div>
                <label htmlFor="state">State</label>
              </div>
              <select
                className="border-solid border-2 border-black h-11 rounded-md w-5/6 text-center"
                {...register('state', {
                  required: 'Please enter your state',
                })}
              >
                {USstates.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.value}
                  </option>
                ))}
              </select>
              <div className="text-red-600 mb-2">{errors?.state?.message}</div>
            </div>

            <div className="w-1/3">
              <div>
                <label htmlFor="zip">Zip</label>
              </div>
              <input
                className="border-solid border-2 border-black rounded-md w-5/6"
                {...register('zip_code', {
                  required: 'Please enter your zip',
                })}
              />
              <div className="text-red-600 mb-4">
                {errors?.zip_code?.message}
              </div>
            </div>
          </div>

          <Button title="Save" type="submit" className="max-w-[100%]">
            Submit
          </Button>
        </div>
      </form>
    </BrandedDialog>
  );
};
