import { FC, useEffect } from 'react';
import { Address } from '@cortex/myaccount/ui/components/models/Address';
import { useFormikContext } from 'formik';

import { AddNewAddressButton } from './Address/AddNewAddressButton';
import { ServiceAddresses } from './Address/ServiceAddresses';
import { YourServiceAddressText } from './Address/YourServiceAddressText';
import { AddTelephoneNumber } from './Telephone/AddTelephoneNumber';
import { YourContactInformationText } from './Telephone/YourContactInformationText';

export interface StepTwoFields {
  address: string;
  phoneNumber: string;
}

export interface FileClaimStepTwoProps {
  addresses: Address[];
  openAddressModal: () => void;
  openPhoneModal: () => void;
  draftClaim?: Record<string, any>;
  isFetching: boolean;
  phoneNumber?: string;
}

export const FileClaimStepTwo: FC<FileClaimStepTwoProps> = ({
  addresses,
  openAddressModal,
  openPhoneModal,
  isFetching,
  phoneNumber,
}: FileClaimStepTwoProps) => {
  const { values, errors, setFieldValue, handleChange } =
    useFormikContext<StepTwoFields>();

  useEffect(() => {
    if (phoneNumber) {
      setFieldValue('phoneNumber', phoneNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  return (
    <>
      <YourServiceAddressText />

      <div className="mb-12 cqsm:mb-20 cqmd:mb-20">
        <ServiceAddresses
          isFetching={isFetching}
          addresses={addresses}
          values={values}
          errors={errors}
          handleChange={handleChange}
        />
        <AddNewAddressButton openAddressModal={openAddressModal} />
        <YourContactInformationText />

        <AddTelephoneNumber
          isFetching={isFetching}
          handleChange={handleChange}
          values={values}
          errors={errors}
          openPhoneModal={openPhoneModal}
        />
      </div>
    </>
  );
};
