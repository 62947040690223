import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FacDocumentsFileCopySmall from '@cortex/myaccount/ui/components/assets/FacDocumentsFileCopySmall.svg';
import FacPencil from '@cortex/myaccount/ui/components/assets/FacPencil.svg';
import { FileClaimImageGrid } from '@cortex/myaccount/ui/components/FileClaimImageGrid';
import { buttonSelectors } from '@cortex/myaccount/ui/components/tests/dataCySelectors';
import { FileClaimStep } from '@cortex/myaccount/views/safeship/helpers/FileClaimStep';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';

import type { FileClaimFields } from '../../../helpers/facFieldsInitialValues';

interface FileClaimStepFourProps {
  merchantName?: string;
  purchaseDate?: string;
  setFormStep: (step: number) => void;
  isChatBot?: boolean;
}

export const FileClaimStepFour: FC<FileClaimStepFourProps> = ({
  merchantName,
  purchaseDate,
  setFormStep,
  isChatBot,
}: FileClaimStepFourProps) => {
  const { values } = useFormikContext<FileClaimFields>();
  const { t } = useTranslation();

  return (
    <>
      <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 mt-8 md:mt-0 border-reguard-pearl">
        <div className="left mb-4">
          <div className="text-cqsm">{t('merchant')}</div>
          <div className="text-base semibold">{merchantName}</div>
        </div>
      </div>

      <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 border-reguard-pearl">
        <div className="right mb-4">
          <div className="text-cqsm">{t('purchase_date')}</div>
          <div className="text-base semibold">
            {purchaseDate && dayjs(purchaseDate).format('MMM DD, YYYY')}
          </div>
        </div>
      </div>

      {values.dateOfDamage && (
        <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 border-reguard-pearl">
          <div className="left mb-4">
            <div className="text-cqsm">{t('date_of_incident')}</div>
            <div className="text-base semibold">
              {dayjs(values.dateOfDamage).format('MMM DD, YYYY')}
            </div>
          </div>
          <div className="right mb-4">
            <button
              type="button"
              className="tw-cst-pf bg-transparent"
              onClick={() => setFormStep(FileClaimStep.STEP_ONE)}
              data-testid="editStepOneBtn"
            >
              <FacPencil />
            </button>
          </div>
        </div>
      )}

      {values.claimPhotos && (
        <div className="border-solid border-0 border-b mb-4 flex flex-col w-full items-center border-reguard-pearl">
          <div className="purchaseInfo w-full flex flex-row items-center justify-between mb-4 sm:px-4">
            <div className="left mb-4">
              <div className="text-cqsm">{t('photos_title')}</div>
            </div>
            <div className="right mb-4">
              <button
                type="button"
                className="tw-cst-pf bg-transparent"
                onClick={() => setFormStep(FileClaimStep.STEP_THREE)}
                data-cy={buttonSelectors.editStepThreeBtn}
              >
                <FacPencil />
              </button>
            </div>
          </div>

          <FileClaimImageGrid
            gridWrapperClassName={isChatBot ? '!gap-2 !mb-6' : ''}
            className="sm:px-0"
            imageList={values.claimPhotos}
          />
        </div>
      )}
      {values.claimReceipt && (
        <div className="purchaseInfo flex flex-row items-center justify-between border-solid border-0 border-b mb-4 sm:px-4 cqsm:mb-14 sm:mb-14 border-reguard-pearl">
          <div className="left mb-4">
            <div className="text-cqsm">Receipt</div>

            <div className="flex items-center">
              <span className="mr-1">
                <FacDocumentsFileCopySmall />
              </span>
              <div className="text-base semibold">
                {values.claimReceipt.name}
              </div>
            </div>
          </div>
          <div className="right mb-4">
            <button
              type="button"
              className="tw-cst-pf bg-transparent"
              onClick={() => setFormStep(FileClaimStep.STEP_THREE)}
              data-cy={buttonSelectors.editStepThreeBtn}
            >
              <FacPencil />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
