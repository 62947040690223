import dayjs from 'dayjs';

export const getDaysUntilExpiration = (expirationDate_: string) => {
  const expirationDate = new Date(expirationDate_);
  const NOW = new Date();

  const difference = expirationDate.getTime() - NOW.getTime();
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return totalDays;
};

export const dateFormat = (date: string | Date, format = 'YYYY-MM-DD') => {
  return dayjs(date).format(format);
};
