import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MobileArrow } from '@cortex/myaccount/ui/components/MobileArrow';
import type { Contract } from '@cortex/myaccount/ui/components/models/Contract';

import CubeCheck from '../../../assets/CubeCheck.svg';
import { ClaimActionButton } from '../ClaimActionButton';
import { FileClaimModal } from '../FileClaim/FileClaimModal/FileClaimModal';
import { MyPolicyButton } from './MyPolicyButton';
import { PolicyCardInfo } from './PolicyCardInfo';

export interface PolicyCardProps extends Contract {
  data: any;
  isDisabled: boolean;
  fileClaim: () => void;
}

const activeSVGStyle = 'stroke-reguard-wintergreen';

export function PolicyCard({ data, isDisabled, fileClaim }: PolicyCardProps) {
  const navigate = useNavigate();

  const { productPurchaseId, isExpired, contract, orderNumber } = data;
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className="shadow-card p-2 md:p-6 bg-white rounded-lg">
      <div
        role="presentation"
        className="w-full h-full flex items-center focus:border-reguard-violet focus-visible:border-reguard-violet focus:outline-reguard-violet focus-visible:outline-reguard-violet"
      >
        <div className="min-w-[150px] min-h-[200px] md:min-h-[150px] pr-2">
          <CubeCheck />
        </div>

        <div className="min-w-0 md:min-w-[16.563rem] w-full justify-center">
          <PolicyCardInfo orderNumber={orderNumber} contract={contract} />

          <div className="pt-14 lg:pt-5 w-full flex justify-end items-center">
            <MyPolicyButton productPurchaseId={productPurchaseId} />
            <ClaimActionButton
              data={data}
              isDisabled={isDisabled}
              fileClaim={fileClaim}
              setShowModal={setShowModal}
            />
          </div>
        </div>

        <MobileArrow
          onClick={() => navigate(`/protection-plan/${productPurchaseId}`)}
          className="mr-5 ml-4 flex self-center"
          svgClassName={!isExpired ? activeSVGStyle : ''}
        />
      </div>
      {showModal && <FileClaimModal handleClose={() => setShowModal(false)} />}
    </div>
  );
}

export default PolicyCard;
