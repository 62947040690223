import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ControlsSection } from '@cortex/myaccount/views/protection-plan-details/components/controls-section/controls-section';
import { ModalBase } from '@cortex/myaccount/views/protection-plan-details/components/modal-base/modal-base';
import { ProductDetailsSection } from '@cortex/myaccount/views/protection-plan-details/components/product-details-section/product-details-section';

import { ClaimSection } from './components/claims-section/claims-section';
import { ContactSupportModalContent } from './components/contact-support-modal-content/contact-support-modal-content';
import { ProtectionPlanCoverageHighlights } from './components/protection-plan-coverage-highlights/protection-plan-coverage-highlights';
import { ProtectionPlanDetails } from './components/protection-plan-details/protection-plan-details';
import { SetDateModalContent } from './components/set-date-modal-content/set-date-modal-content';
import { useProtectionPlanDetails } from './helpers/use-protection-plan-details';
import { useDeliveryUpdate } from './helpers/use-update-delivery';
import { CustomerClaimStatus } from '../../common/main';
import {
  BreadCrumbs,
  CollapsibleSection,
  ContentLoader,
  HorizontalSeparator,
  MainPage,
  MainSection,
  VerticalSeparator,
} from '../../ui/components';

export const ProtectionPlanDetail = () => {
  const {
    fetchingClaims,
    fetchingPurchases,
    isDesktop,
    plan,
    protectionPlan,
    activeClaim,
    contract,
    inactiveClaim,
    canFileClaim,
    product,
    isProcessingTimeLimitExceeded,
    navigateToClaims,
  } = useProtectionPlanDetails();

  const { updateStatus, setSelectedDeliveryDate, setData } =
    useDeliveryUpdate();

  const navigate = useNavigate();
  const [dateModal, setDateModal] = useState(false);
  const [contactSupportModal, setContactSupportModal] = useState(false);
  const handleCloseDateModal = () => setDateModal(false);
  const handleCloseContactSupportModal = () => setContactSupportModal(false);
  const handleOpenContactSupportModal = () => setContactSupportModal(true);

  const { t } = useTranslation();
  if (fetchingClaims || fetchingPurchases) {
    return <ContentLoader />;
  }

  if (!plan) {
    navigate('/');
    return null;
  }

  if (!protectionPlan) {
    return null;
  }

  const handleMarkAsDelivered = () => {
    setDateModal(true);
    setData(
      contract?.id,
      plan.productPurchaseId,
      plan.product.sku ? plan.product.sku : '',
    );
  };

  const isTimeLimitExceeded = isProcessingTimeLimitExceeded(
    contract?.requested_to_process_at || Date.now().toString(),
  );

  return (
    <div>
      <MainPage>
        <MainSection>
          <div className="flex flex-col md:flex-row pb-28">
            {/* product info section */}
            <div className="flex flex-col p-0 md:w-1/5 md:mr-8">
              {/* mobile only "Back" */}
              <BreadCrumbs
                className="md:hidden"
                onBack={() => {
                  navigate('/plans');
                }}
                crumbs={[t('my_plans'), t('plan_details')]}
              />
              <ProductDetailsSection
                canFileClaim={canFileClaim}
                activeClaim={activeClaim}
                plan={plan}
                contract={contract}
                protectionPlan={protectionPlan}
                product={product}
                isDesktop={isDesktop}
                handleMarkAsDelivered={handleMarkAsDelivered}
                handleOpenContactSupportModal={handleOpenContactSupportModal}
                isTimeLimitExceeded={isTimeLimitExceeded}
              />
            </div>

            <VerticalSeparator className="hidden mr-0 ml-0 md:block" />

            {/* protection plan details section */}
            <div className="w-full md:w-3/5 md:ml-16 md:mr-[4.625rem]">
              {activeClaim ? (
                <div className="hidden md:block mb-12">
                  <ClaimSection
                    claim={activeClaim}
                    plan={plan}
                    title={t(
                      activeClaim.status === CustomerClaimStatus.Draft
                        ? 'draft'
                        : 'active_claim',
                    )}
                    isMobile={!isDesktop}
                    goToDetails={() => {
                      navigateToClaims(activeClaim);
                    }}
                  />
                </div>
              ) : null}
              <ProtectionPlanDetails
                contract={contract}
                protectionPlan={protectionPlan}
              />

              <HorizontalSeparator className="mt-0 sm:max-w-[unset] !w-full pr-0 sm:mt-14" />

              <CollapsibleSection
                title={t('coverage_highlights')}
                titleFormatted
                rootClassName="md:visible"
                className="pl-0 pr-0"
                open={isDesktop}
              >
                <ProtectionPlanCoverageHighlights
                  protectionPlan={protectionPlan}
                />
                <div className="py-2 w-full text-sm">
                  <span>* {t('please_refer_to')} </span>
                  <a
                    className="semibold text-reguard-wintergreen-shade"
                    href={protectionPlan.termsConditions}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('terms_and_conditions')}
                  </a>
                  <span> {t('for_full_list_of_coverages')}</span>
                </div>
              </CollapsibleSection>

              <HorizontalSeparator className="my-0 !w-full pr-0 sm:my-4 sm:max-w-[unset]" />

              {inactiveClaim ? (
                <div className="pt-4 md:mt-16">
                  <h3 className="tw-cst-pf block md:hidden text-reguard-indigo text-xl leading-[1.25rem] pl-0 pt-0 pb-6 font-normal">
                    {t('previous_claim')}
                  </h3>
                  <ClaimSection
                    claim={inactiveClaim}
                    plan={plan}
                    title={t('previous_claim')}
                    isMobile={!isDesktop}
                    goToDetails={() => {
                      navigate(`/claims/${inactiveClaim.id}`);
                    }}
                  />
                  <HorizontalSeparator className="hidden md:block !w-full pr-0 my-8  max-w-[unset]" />
                </div>
              ) : null}
            </div>

            <div className="hidden md:flex flex-col w-full min-w-fit md:w-1/5 ">
              <ControlsSection
                canFileClaim={canFileClaim}
                contract={contract}
                plan={plan}
                protectionPlan={protectionPlan}
                handleMarkAsDelivered={handleMarkAsDelivered}
                handleOpenContactSupportModal={handleOpenContactSupportModal}
                isTimeLimitExceeded={isTimeLimitExceeded}
              />
            </div>
          </div>
        </MainSection>
      </MainPage>
      {dateModal && (
        <ModalBase handleClose={handleCloseDateModal}>
          <SetDateModalContent
            handleClose={handleCloseDateModal}
            onSubmit={updateStatus}
            setSelectedDeliveryDate={setSelectedDeliveryDate}
          />
        </ModalBase>
      )}
      {contactSupportModal && (
        <ModalBase handleClose={handleCloseContactSupportModal}>
          <ContactSupportModalContent
            handleClose={handleCloseContactSupportModal}
            orderNumber={plan.orderNumber}
          />
        </ModalBase>
      )}
    </div>
  );
};
