import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useClaimsSearch } from '../network/api/claims';
import {
  BreadCrumbs,
  Claim,
  ClaimSummaryPhotos,
  ContentLoader,
  HorizontalSeparator,
  MainPage,
  MainSection,
  productConditions,
} from '../ui/components';
import classes from './ClaimSummary.module.css';

interface ParamTypes {
  [claimId: string]: string;
}
const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  month: 'short',
  year: 'numeric',
  day: 'numeric',
};

const formatDate = (date: string | Date) => {
  const dateInstance = date && new Date(date);

  if (dateInstance && dateInstance.toString() !== 'Invalid Date') {
    return new Intl.DateTimeFormat('en-US', DATE_FORMAT_OPTIONS).format(
      new Date(date),
    );
  }

  return '-';
};

const InfoItem = ({
  label,
  info,
  separator = true,
}: {
  label?: string;
  info?: string;
  separator?: boolean;
}) => (
  <div className="flex min-w-0 pt-4 flex-col">
    <div className="b3b mb-1">{label}</div>
    <div
      className={`flex min-w-full max-w-[19rem] min-h-14 b2b mb-4 overflow-y-visible text-ellipsis ${classes.lineClamp}`}
    >
      {info}
    </div>
    {separator && <HorizontalSeparator className="hidden sm:block" />}
  </div>
);

export const ClaimSummary = () => {
  const { data: claims, isFetching } = useClaimsSearch();
  const { claimId } = useParams<ParamTypes>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (isFetching) {
    return <ContentLoader />;
  }

  const claim =
    Array.isArray(claims) && claims.find((c: Claim) => c.id === claimId);

  if (!claim) {
    throw new Error(t('CLAIM_NOT_FOUND') as string);
  }

  const {
    productPurchase,
    createdAt,
    productCondition,
    damageDate,
    damageArea,
    damageLocation,
    damageCause,
    fromPCMI,
    pcmiFailureNotes,
    damageDescription,
  } = claim;

  const { name, merchant } = productPurchase?.product || {};
  const { purchaseDate } = productPurchase?.purchase || {};
  const purchaseDateFormatted = formatDate(purchaseDate);
  const dateOfSubmission = formatDate(createdAt);
  const damageDateFormatted = formatDate(damageDate);
  const productConditionLabel = productConditions.find(
    pc => pc.value === productCondition,
  )?.text;
  return (
    <MainPage>
      <MainSection>
        <div className="flex flex-col md:p-2 md:pl-0 md:pr-2 min-w-fit">
          {/* mobile only back  */}
          <BreadCrumbs
            className="md:hidden"
            onBack={() => {
              navigate(`/claims/${claimId}`);
            }}
            crumbs={[t('claim_status'), t('claim_details')]}
          />
        </div>
        <h3 className="tw-cst-pf s2 sm:h3 text-reguard-indigo mb-4 sm:mb-6">
          {t('claim_details')}
        </h3>
        <div className="flex w-full flex-wrap">
          <div className="flex sm:justify-between md:flex-col w-full mb-0 sm:mb-10">
            <div className="s3b font-normal sm:font-bold md:mb-2">{name}</div>
            <div className="b2 hidden sm:block">
              {t('claim_id')} {claim.pcmiClaimId}
            </div>
          </div>

          <div className="flex flex-col w-full items-center">
            <div className="flex flex-col w-full md:max-w-[37.75rem]">
              <div className="flex w-full mt-4 justify-between sm:hidden">
                <div>
                  <div className="b3b mb-1">{t('claim_id')}</div>
                  <div className="flex min-w-0 max-h-14 b2b">
                    {claim.pcmiClaimId}
                  </div>
                </div>

                <div>
                  <div className="b3b mb-1">{t('merchant')}</div>
                  <div className="flex min-w-0 max-w-[19rem] max-h-14 b2b overflow-hidden text-ellipsis">
                    {merchant?.name}
                  </div>
                </div>
              </div>
              <HorizontalSeparator className="my-4 sm:hidden" />

              <div className="hidden sm:block">
                <InfoItem label="Merchant" info={merchant?.name} />
              </div>
              <InfoItem
                label={t('purchase_date') as string}
                info={purchaseDateFormatted}
              />
              <InfoItem
                label={t('contract_id') as string}
                info={productPurchase?.contract?.pcmiContractNumber}
              />
              <InfoItem
                label={t('date_of_submission') as string}
                info={dateOfSubmission}
              />
              <InfoItem
                label={t('date_of_damage') as string}
                info={damageDateFormatted}
              />
              <InfoItem
                label={t('cause_of_damage') as string}
                info={damageCause.name}
              />
              {!fromPCMI && (
                <>
                  <InfoItem
                    label={t('location_of_damage') as string}
                    info={damageLocation?.name}
                  />
                  <InfoItem
                    label={t('damage_area') as string}
                    info={damageArea?.name}
                  />
                  <InfoItem
                    label={t('product_condition') as string}
                    info={damageDescription}
                  />
                  <InfoItem
                    label={t('product_condition') as string}
                    info={
                      t(productConditionLabel || productCondition) as string
                    }
                  />
                  <div className="b3b mb-4 sm:mb-10 mt-4">
                    {t('photos_title')}
                  </div>
                  <ClaimSummaryPhotos pictures={claim.customerClaimImages} />
                </>
              )}

              {fromPCMI && (
                <>
                  <InfoItem
                    label={t('failure_notes') as string}
                    info={pcmiFailureNotes || '--'}
                  />
                  <InfoItem
                    label={t('product_condition') as string}
                    info={
                      t(productConditionLabel || productCondition) as string
                    }
                  />
                  <InfoItem
                    label={t('photos_title') as string}
                    info={t('no_photos_added') as string}
                    separator={false}
                  />
                </>
              )}

              <div className="flex justify-center w-full mb-36">
                <HorizontalSeparator className="!w-full max-w-[26.75rem]" />
              </div>
            </div>
          </div>
        </div>
      </MainSection>
    </MainPage>
  );
};
