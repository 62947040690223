import { useTranslation } from 'react-i18next';

interface AddNewAddressButtonType {
  openAddressModal: () => void;
}

export const AddNewAddressButton = ({
  openAddressModal,
}: AddNewAddressButtonType) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={() => {
        openAddressModal();
      }}
      type="button"
      className="tw-cst-pf bg-transparent flex b3 text-reguard-wintergreen-shade mt-4 mb-6 cqmd:mt-11 cqsm:ml-24 cqsm:mb-8"
    >
      {t('add_new_address')}
    </button>
  );
};
