import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query/types/react/types';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import useDebounce from './hooks/useDebounce';
import {
  buttonSelectors,
  componentNameSelector,
  navigationLinksSelectors,
  textInputFields,
} from './tests/dataCySelectors';
import { Button, TextField, TokenForm } from '.';

interface VerifyEmailProps {
  useRequestVerifyEmail: (
    options?: Record<string, unknown>,
  ) => UseMutationResult;
  useVerifyEmail: (options?: Record<string, unknown>) => UseMutationResult;
}

const DEBOUNCE_TIMEOUT = 400;

const secureEmailDisplay = (email: string) => {
  const splitEmail = email.split('@');
  const user = splitEmail[0];
  return `${user[0]}*****${user[user.length - 1]}@${splitEmail[1]}`;
};

export function VerifyEmail({
  useRequestVerifyEmail,
  useVerifyEmail,
}: VerifyEmailProps): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [successResponse, setSuccessResponse] = useState<boolean>(false);
  const [checkEmail, setCheckEmail] = useState(null);
  const [tooManyRequestsError, setTooManyRequestsError] = useState('');
  const { mutateAsync: sendEmail, status } = useRequestVerifyEmail();
  const debounced = useDebounce(DEBOUNCE_TIMEOUT);
  const { t } = useTranslation();

  const { customerToken } = useParams();

  const isFetching = status === 'loading';

  const onSendEmail = async (data: any) => {
    const { email } = data;
    try {
      setError('');
      const res: any = await sendEmail({ email, customerToken });

      if (res && res.value && res.email && !res.complete) {
        return setCheckEmail(res.email);
      }

      setEmail(email);
      setSuccessResponse(true);
    } catch (error: any) {
      if (error?.message.indexOf('Too many requests') > -1) {
        setTooManyRequestsError(error?.message);
        return;
      }
    }
  };

  if (successResponse) {
    return (
      <div className="grid m-auto max-w-[18rem] pb-4">
        <VerifyCode
          email={email}
          useRequestVerifyEmail={useRequestVerifyEmail}
          useVerifyEmail={useVerifyEmail}
        />
      </div>
    );
  }

  return (
    <div className="grid m-auto max-w-[18rem] pb-4">
      {checkEmail && (
        <h5 className="mt-2 text-xl">
          <Trans i18nKey="if_matching_account" checkEmail={checkEmail}>
            {{ checkEmail }}
          </Trans>
        </h5>
      )}

      {!checkEmail && (
        <Formik
          initialValues={{ email: '' }}
          onSubmit={onSendEmail}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Please enter email with right format')
              .required('Email is Required'),
          })}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            dirty,
            isValid,
          }) => (
            <form className="grid" onSubmit={handleSubmit}>
              <h4 className="tw-cst-pf text-reguard-indigo mt-[3.875rem] mx-0 mb-8 s1">
                {t('enter_your_email_to_get_started')}
              </h4>
              <span className="b2 mb-[3.125rem]">
                {t('to_set_or_reset_your_password_enter_email')}
              </span>

              <TextField
                name="email"
                data-cy={textInputFields.emailInputField}
                label="Email"
                onChange={e => {
                  setError('');
                  debounced(() => {
                    handleChange(e);
                  });
                }}
                placeholder="john.doe@email.com"
                error={!!error || !!errors?.email}
                errorMessage={errors.email}
                errorExclamationIconVisible
                autoCapitalize="off"
              />
              {error && (
                <div className="error-text text-reguard-error mt-4 mb-[2.375rem]">
                  <p className="c2 mt-2">
                    {t(
                      'Make_sure_that_you_are_entering_the_same_email_that_was_used_when_purchasing_your_Reguard_plan',
                    )}
                  </p>
                </div>
              )}
              <Button
                disabled={isSubmitting || !isValid || !dirty || !!error}
                variant="primary"
                className="mt-4 mb-4 m-auto"
                isfetching={isFetching}
                data-cy={buttonSelectors.sendEmailBtn}
              >
                {t('send_email')}
              </Button>
              <Link
                className="mt-4 mb-[5.18rem] text-center text-reguard-wintergreen cursor-pointer"
                to="/"
                data-cy={navigationLinksSelectors.cancel}
              >
                {t('cancel')}
              </Link>
              {tooManyRequestsError && (
                <div className="text-center">
                  <p className="text-reguard-red">{t('too_many_requests')}</p>

                  <p className="text-reguard-red">
                    {t('please_wait_30_minutes')}
                  </p>
                </div>
              )}
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}

interface VerifyCodeProps extends VerifyEmailProps {
  email: string;
}

function VerifyCode({
  email,
  useRequestVerifyEmail,
  useVerifyEmail,
}: VerifyCodeProps): JSX.Element {
  const [error, setError] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const navigate = useNavigate();
  const { mutateAsync: resendEmail, isLoading: resendingEmail } =
    useRequestVerifyEmail();
  const { mutateAsync: verifyEmail, isLoading: verifyingEmail } =
    useVerifyEmail();
  const isLoading = resendingEmail || verifyingEmail;
  const { t } = useTranslation();

  const { customerToken } = useParams();

  const handleClose = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    navigate('/', { replace: true });
  };

  async function handleResendCode() {
    setError('');
    setCodeSent(false);
    try {
      await resendEmail({ email, customerToken });
      setCodeSent(true);
    } catch (e: any) {
      setError(e.message);
    }
  }

  async function onSubmit(code: string) {
    try {
      const { token }: any = await verifyEmail({
        email,
        verificationCode: code,
        customerToken,
      });
      navigate(`/set-password/${token}`, { replace: true });
    } catch (e: any) {
      setError(e?.message);
    }
  }

  return (
    <>
      <h4
        className="tw-cst-pf text-reguard-indigo mt-[3.875rem] mx-0 mb-8 s1"
        data-cy={componentNameSelector.verifyCodeComponent}
      >
        {t('verify_email')}
      </h4>
      <span className="b2 mb-[3.125rem]">
        {t('please_enter_the_verification_code_we_just_sent_to', {
          value: secureEmailDisplay(email),
        })}
        .
      </span>
      <TokenForm error={error} setError={setError} handleSubmit={onSubmit}>
        <div className="mt-[0.875rem] pt-4 relative w-full flex flex-col items-center">
          {error && <div className="c1 text-reguard-error">{error}</div>}

          {codeSent && (
            <div className="text-reguard-wintergreen-shade b3">
              {t('code_sent')}
            </div>
          )}

          <Button
            disabled={isLoading || !!error}
            variant="secondary"
            className="mt-4 mb-4 m-auto"
            isfetching={isLoading}
            onClick={handleResendCode}
            data-cy={buttonSelectors.resendCodeBtn}
          >
            {t('resend_code')}
          </Button>
          <button
            onClick={handleClose}
            className="mt-4 mb-[5.18rem] text-center text-reguard-wintergreen"
          >
            {t('cancel')}
          </button>
        </div>
      </TokenForm>
    </>
  );
}
