import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';

import FormStepInfoIconSVG from './assets/FormStepInfoIcon.svg';
import type {
  FileClaimDamageDomain,
  StepOneFields,
} from './constants/facFieldsInitialValues';
import classes from './FileClaimStep.module.css';
import {
  SimpleSelectDateForwardRef,
  SimpleSelectForwardRef,
  useDebounce,
} from '.';

export type FileClaimDamageDomainData = FileClaimDamageDomain[];

interface FileClaimStepOneProps {
  damageArea: FileClaimDamageDomainData;
  damageCause: FileClaimDamageDomainData;
  damageLocation: FileClaimDamageDomainData;
  isChatBot?: boolean;
}

const DEBOUNCE_TIMEOUT = 100;

export const FileClaimStepOne: FC<FileClaimStepOneProps> = ({
  damageArea,
  damageCause,
  damageLocation,
  isChatBot,
}: FileClaimStepOneProps) => {
  const [infoTextVisible, setInfoTextVisible] = useState(false);
  const { values, errors, validateField, setFieldValue } =
    useFormikContext<StepOneFields>();
  const debounce = useDebounce(DEBOUNCE_TIMEOUT);
  const { t } = useTranslation();
  return (
    <div
      className={`step-one grid grid-cols-1 gap-x-6 gap-y-3  ${
        isChatBot
          ? ''
          : 'cqsm:gap-y-6 cqsm:grid-cols-2 cqmd:grid-cols-2  sm:gap-y-6 sm:grid-cols-2  md:grid-cols-2 '
      }`}
    >
      <SimpleSelectDateForwardRef
        label={t('date_of_damage') as string}
        labelHtmlFor="dateOfDamage"
        name="dateOfDamage"
        placeholder={t('select') as string}
        className="shrink-0 font-normal"
        error={errors?.dateOfDamage}
        onChange={(date: Date) => {
          if (!date) {
            return;
          }
          setFieldValue('dateOfDamage', dayjs(date).format('YYYY-MM-DD'));
          debounce(() => validateField('dateOfDamage'));
        }}
        selected={
          values.dateOfDamage ? dayjs(values.dateOfDamage).toDate() : undefined
        }
        value={values.dateOfDamage}
      />

      <SimpleSelectForwardRef
        options={damageLocation}
        label={t('location_of_damage') as string}
        name="locationOfDamage"
        className="shrink-0 font-normal"
        error={errors?.locationOfDamage}
        errorExclamationIconVisible={false}
        onChange={opt => {
          setFieldValue('locationOfDamage', opt);
          validateField('dateOfDamage');
          debounce(() => validateField('locationOfDamage'));
        }}
        value={values.locationOfDamage}
      />

      <div className="relative">
        <div className="absolute top-0 right-[0.625rem] bottom-3 h-fit">
          <button
            className={`tw-cst-pf bg-transparent ${
              infoTextVisible ? classes['info-icon-button-inactive'] : ''
            }`}
            disabled={!!errors?.damageArea}
            type="button"
            onClick={() => {
              setInfoTextVisible(prev => !prev);
            }}
          >
            <FormStepInfoIconSVG />
          </button>
        </div>
        <SimpleSelectForwardRef
          options={damageArea}
          label={t('damage_area') as string}
          name="damageArea"
          className="shrink-0 font-normal"
          error={errors?.damageArea}
          errorExclamationIconVisible={false}
          onChange={opt => {
            setFieldValue('damageArea', opt);
            validateField('locationOfDamage');
            debounce(() => validateField('damageArea'));
          }}
          value={values.damageArea}
          infoTextVisible={infoTextVisible}
          infoTextClassName={isChatBot ? '!relative' : ''}
          infoText="Stand facing the item (all damage areas must be identified from this position. Is the damage to your left or to your right?)"
        />
      </div>

      <SimpleSelectForwardRef
        options={damageCause}
        label={t('cause_of_damage') as string}
        name="causeOfDamage"
        className="shrink-0 font-normal"
        error={errors?.causeOfDamage}
        errorExclamationIconVisible={false}
        onChange={opt => {
          setFieldValue('causeOfDamage', opt);
          validateField('damageArea');
          debounce(() => validateField('causeOfDamage'));
        }}
        value={values.causeOfDamage}
      />
    </div>
  );
};
