import { useTranslation } from 'react-i18next';

import Arrow from './assets/RightArrow.svg';
import type { Product } from './models/Product';
import { cardsSelectors } from './tests/dataCySelectors';
import { PreviewImage } from './PreviewImage';
import { TitledCardInfo } from './TitledCardInfo';
import { Card } from './';

export interface ProductCardProps extends Product {
  className?: string;
  fileClaim: () => void;
  daysUntilExpiration: number;
  isExpired: boolean;
  product: Product;
  protectionPlanSKU?: string;
}

const truncatedStyle = 'overflow-hidden whitespace-nowrap text-ellipsis';

export const ProductCard = ({
  fileClaim,
  daysUntilExpiration,
  isExpired,
  product,
  protectionPlanSKU,
  merchant,
  className,
}: ProductCardProps) => {
  const { t } = useTranslation();
  return (
    <Card
      handleClick={fileClaim}
      className={`hover:ring-2 hover:ring-reguard-wintergreen cursor-pointer ring-inset ${className}`}
      data-cy={cardsSelectors.productCard}
    >
      <PreviewImage fallbackType={protectionPlanSKU} />

      <div className="min-w-0 md:min-w-[16.563rem] w-full flex flex-col justify-center">
        <TitledCardInfo title={product.name} className="md:mb-[1.875rem]">
          <p
            className={`mb-2 ${truncatedStyle} text-left text-[0.75rem] leading-[0.875rem] md:text-[1.125rem] md:leading-[1.25rem] text-reguard-indigo-tint`}
          >
            <span className="semibold">
              {t(isExpired ? 'expired' : 'Active ')}
            </span>
            {t('days_left', { daysUntilExpiration })}
          </p>

          <div className="flex text-[0.75rem] leading-[0.875rem] md:text-[1.125rem] md:leading-[1.25rem] text-reguard-indigo-tint text-left">
            <div className="flex flex-col mr-6">
              <p className={`mb-2 ${truncatedStyle}`}>{t('description')}</p>
              <p className={`mb-1 md:mb-2 ${truncatedStyle}`}>
                {t('merchant')}
              </p>
            </div>

            <div className="lex flex-col semibold w-3/6">
              <p className="mb-2 truncate" title={product.description}>
                {product.description}
              </p>
              <p className="mb-1 md:mb-2">{merchant?.name}</p>
            </div>
          </div>
        </TitledCardInfo>
      </div>

      <div className="ml-4 w-4 flex flex-col justify-center items-center">
        <button
          onClick={() => {
            fileClaim();
          }}
        >
          <span className="self-center">
            <Arrow />
          </span>
        </button>
      </div>
    </Card>
  );
};

export default ProductCard;
