import { useEffect, useState } from 'react';

export const useScreenSize = (mobileScreenMaxSize?: number) => {
  const mobileBreakpoint = mobileScreenMaxSize || 768;
  const tabletBreakpoint = 1024;

  const currentWidth = window.innerWidth;
  const [isMobile, setIsMobile] = useState(currentWidth < mobileBreakpoint);
  const [isTabletOrBelow, setIsTabletOrBelow] = useState(
    currentWidth < tabletBreakpoint,
  );

  const onResize = () => {
    setIsMobile(window.innerWidth < mobileBreakpoint);
    setIsTabletOrBelow(window.innerWidth < tabletBreakpoint);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isMobile,
    isTabletOrBelow,
  };
};
