export function PhoneIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6634 15.3344C11.2984 13.9694 10.2694 12.4411 9.58572 10.8906C9.44105 10.5628 9.52622 10.1789 9.77939 9.92577L10.7349 8.97143C11.5177 8.1886 11.5177 7.08143 10.8341 6.39777L9.46439 5.0281C8.55322 4.11693 7.07622 4.11693 6.16505 5.0281L5.40439 5.78877C4.53989 6.65327 4.17939 7.90043 4.41272 9.1371C4.98905 12.1856 6.76005 15.5234 9.61722 18.3806C12.4744 21.2378 15.8122 23.0088 18.8607 23.5851C20.0974 23.8184 21.3446 23.4579 22.2091 22.5934L22.9686 21.8339C23.8797 20.9228 23.8797 19.4458 22.9686 18.5346L21.6001 17.1661C20.9164 16.4824 19.8081 16.4824 19.1256 17.1661L18.0721 18.2208C17.8189 18.4739 17.4351 18.5591 17.1072 18.4144C15.5567 17.7296 14.0284 16.6994 12.6634 15.3344Z"
        stroke="#4B506D"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
