import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import FacRadioStepFourChecked from './assets/FacRadioStepFourChecked.svg';
import FacRadioStepFourUnChecked from './assets/FacRadioStepFourUnChecked.svg';
import LoaderEllipsisSVG from './assets/LoaderEllipsis.svg';
import type { StepFourFields } from './constants/facFieldsInitialValues';
import type { Address } from './models/Address';
import { buttonSelectors, textInputFields } from './tests/dataCySelectors';
import { AddressText } from './AddressText';

import './FileClaimStep.module.css';

interface FileClaimStepFourProps {
  addresses: Address[];
  openAddressModal: () => void;
  openPhoneModal: () => void;
  draftClaim?: Record<string, any>;
  isFetching: boolean;
  phoneNumber?: string;
}

export const FileClaimStepFour: FC<FileClaimStepFourProps> = ({
  addresses,
  openAddressModal,
  openPhoneModal,
  isFetching,
  phoneNumber,
}: FileClaimStepFourProps) => {
  const { values, errors, setFieldValue, handleChange } =
    useFormikContext<StepFourFields>();
  const { t } = useTranslation();

  useEffect(() => {
    if (phoneNumber) {
      setFieldValue('phoneNumber', phoneNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  return (
    <>
      <div className="step-four grid grid-cols-1 gap-x-6 gap-y-6 mb-6">
        <div>
          <div className="semibold text-base mb-3 cqsm:mb-4 cqsm:text-xl">
            {t('your_service_address')}
          </div>
          <div className="font-normal text-cqsm mb-1 cqsm:text-base">
            {t('select_service_address')}
          </div>
        </div>
      </div>

      <div className="mb-12 cqsm:mb-20 cqmd:mb-20">
        {isFetching ? (
          <div className="w-4 h-4">
            <LoaderEllipsisSVG />
          </div>
        ) : (
          addresses.map(address => {
            return (
              <div
                key={address?.id}
                className="address-radio-group flex flex-row items-start justify-start cqsm:items-center cqsm:pl-24 mb-8 last:mb-0"
              >
                <div className="address-radio-group_btn flex">
                  <input
                    className="peer tw-cst-pf"
                    name="address"
                    id={address?.id}
                    type="radio"
                    value={address?.id}
                    onChange={handleChange}
                    defaultChecked={values.address === address.id}
                  />
                  {address?.id && (
                    <label
                      className="cursor-pointer pt-[0.375rem]"
                      htmlFor={address?.id}
                    >
                      {values.address === address.id ? (
                        <div className="w-4 h-4">
                          <FacRadioStepFourChecked />
                        </div>
                      ) : (
                        <div className="w-4 h-4">
                          <FacRadioStepFourUnChecked />
                        </div>
                      )}
                    </label>
                  )}
                </div>

                <div className="address-radio-group_label ml-3">
                  <label htmlFor={address?.id} className="cursor-pointer">
                    <div className="text-lg semibold max-w-xxs">
                      <AddressText {...address} />
                    </div>
                  </label>
                </div>
              </div>
            );
          })
        )}
        {errors.address && (
          <div className="text-cqsm text-reguard-error semibold mt-1">
            {t('please_select_service_address')}
          </div>
        )}

        <button
          onClick={() => {
            openAddressModal();
          }}
          type="button"
          className="tw-cst-pf bg-transparent flex b3 text-reguard-wintergreen-shade mt-4 mb-6 cqmd:mt-11 cqsm:ml-24 cqsm:mb-8"
          data-cy={buttonSelectors.addNewAddressBtn}
        >
          {t('add_new_address')}
        </button>

        <div className="semibold text-base mb-3 cqsm:mb-4 cqsm:text-xl">
          {t('your_contact_information')}
        </div>
        <div className="font-normal text-cqsm mb-1 cqsm:text-base">
          {t('contact_phone_number')}
        </div>

        <div className="flex flex-row justify-between sm:justify-center items-baseline">
          <div className="text-lg semibold sm:mr-10">
            {values.phoneNumber || t('please_add_phone_number')}
          </div>
          <button
            onClick={() => {
              openPhoneModal();
            }}
            type="button"
            data-cy={
              values.phoneNumber
                ? buttonSelectors.editPhoneNumberBtn
                : buttonSelectors.addPhoneNumberBtn
            }
            className="tw-cst-pf bg-transparent flex b3 text-reguard-wintergreen-shade mt-4 mb-6 cqsm:mt-8 cqsm:mb-8 cqmd:mt-11"
          >
            {values.phoneNumber
              ? t('edit_phone_number')
              : '+ ' + t('add_phone_number')}
          </button>

          {isFetching && (
            <div className="w-14 h-14">
              <LoaderEllipsisSVG />
            </div>
          )}

          {values.phoneNumber && (
            <input
              type="hidden"
              value={values.phoneNumber}
              name="phoneNumber"
              data-cy={textInputFields.phoneNumberInputField}
              onChange={handleChange}
            />
          )}
        </div>
        {errors.phoneNumber && (
          <div className="text-cqsm text-reguard-error semibold mt-1">
            {t('add_telephone_number')}
          </div>
        )}
      </div>
    </>
  );
};
