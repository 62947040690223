import type { FC } from 'react';

import { CustomerClaimStatus } from '../../common/constants/claims';
import { ProgressBarStatus } from './ProgressBarStatus';

interface ClaimStatusProgressBarProps {
  status: CustomerClaimStatus;
}
export const ClaimStatusProgressBar: FC<ClaimStatusProgressBarProps> = (
  props: ClaimStatusProgressBarProps,
) => {
  const { status } = props;
  const innerBarStyle = () => {
    switch (status) {
      case CustomerClaimStatus.UnderReview:
        return 'bg-reguard-violet w-1/3';
      case CustomerClaimStatus.InProgress:
      case CustomerClaimStatus.Approved:
        return 'bg-reguard-violet w-2/3';
      case CustomerClaimStatus.Closed:
      default:
        return 'bg-reguard-inactive1 w-full';
    }
  };
  return (
    <div className="py-2 pr-4 max-w-[37.5rem]">
      <div className="bg-reguard-pearl-shade w-full rounded-full h-4">
        <div
          className={`rounded-full h-full ${innerBarStyle()}`}
          data-testid="inner-bar"
        ></div>
      </div>
      <ProgressBarStatus status={status} />
    </div>
  );
};
