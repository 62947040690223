import { useState } from 'react';

import { usePoliciesContext } from '../../context/policies-context';
import { FileClaimModal } from '../FileClaim/FileClaimModal/FileClaimModal';
import { Policies } from './Policies';

export function ActivePolicies() {
  const { purchases } = usePoliciesContext();
  const [showModal, setShowModal] = useState<boolean>(false);

  if (purchases?.active?.length === 0) return;

  return (
    <>
      <Policies
        title="active_policies"
        subtitle="please_note_these_are_all_the_products_potentially_covered_by_your_reguard_plan"
        purchases={purchases.active}
      />
      {showModal && <FileClaimModal handleClose={() => setShowModal(false)} />}
    </>
  );
}
