import { FC, useEffect, useRef, useState } from 'react';

interface ProfileTokenFormProps {
  resetTimer: () => void;
  timer: number;
}

const ResendClock: FC<ProfileTokenFormProps> = ({ timer, resetTimer }) => {
  const [resendTime, setResendTime] = useState(timer);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (resendTime > 0) {
      timerRef.current = setInterval(() => {
        setResendTime(prevTime => prevTime - 1);
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [resendTime]);

  useEffect(() => {
    if (resendTime <= 0) {
      resetTimer();
    }
  }, [resendTime, resetTimer]);

  return (
    <div className="b2 text-reguard-indigo mb-4">
      Resend code in{' '}
      <span className="b1 text-reguard-success">{resendTime}</span> seconds.
    </div>
  );
};

export default ResendClock;
