import type { FC } from 'react';

import { useScreenSize } from './hooks/screen-size';
import type { Claim } from './models/Claim';
import { CardsGrid } from './CardsGrid';
import { ClaimCard } from './ClaimCard';

interface ClaimsContainerProps {
  claims: Claim[];
  onDetails: (id: string) => void;
  onRemoveDraft?: (claimId: string) => void;
  disabled?: boolean;
}
export const ClaimsContainer: FC<ClaimsContainerProps> = (
  props: ClaimsContainerProps,
) => {
  const { isMobile } = useScreenSize(1024);

  return (
    <CardsGrid className="mb-20">
      {props.claims.map(c => (
        <ClaimCard
          disabled={props.disabled}
          isMobile={isMobile}
          claim={c}
          key={c.id}
          onDetails={() => {
            props.onDetails(c.id);
          }}
          onRemoveDraft={props.onRemoveDraft?.bind(null)}
          data-testid="claim-card"
        />
      ))}
    </CardsGrid>
  );
};
