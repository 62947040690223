import { ComponentPropsWithoutRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { buttonSelectors } from './tests/dataCySelectors';
import { ButtonWithArrow } from './ButtonWithArrow';

export const CollapsibleParagraph = (
  props: ComponentPropsWithoutRef<'div'>,
) => {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(false);
  const toggleShow = () => setShowContent(s => !s);
  const contentHeight = showContent ? 'h-fit' : 'h-0';
  return (
    <div {...props}>
      {!showContent && (
        <ButtonWithArrow
          direction="down"
          onClick={toggleShow}
          data-cy={buttonSelectors.seeMoreBtn}
        >
          {t('see_more')}
        </ButtonWithArrow>
      )}

      <div className={`slide-down overflow-hidden ${contentHeight}`}>
        {props.children}
      </div>

      {showContent && (
        <ButtonWithArrow
          direction="up"
          onClick={toggleShow}
          data-cy={buttonSelectors.seeLessBtn}
        >
          {t('see_less')}
        </ButtonWithArrow>
      )}
    </div>
  );
};
