// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
const debounce = (func: (...args: any[]) => void, timeout = 300) => {
  let timer: number;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

export default debounce;
