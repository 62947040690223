import { CustomerClaimSolution, CustomerClaimStatus } from '../main.js';

export const statusDescription = (
  status: CustomerClaimStatus,
  resolutionType?: CustomerClaimSolution,
) => {
  switch (status) {
    case CustomerClaimStatus.UnderReview:
      return 'reviewing_claim';
    case CustomerClaimStatus.Approved:
      return 'claim_approved';
    case CustomerClaimStatus.InProgress:
      switch (resolutionType) {
        case CustomerClaimSolution.Repair:
          return 'in_home_repair';
        case CustomerClaimSolution.Reimbursement:
          return 'reimbursement';
        case CustomerClaimSolution.Replacement:
          return 'replacement';
        case CustomerClaimSolution.CleaningKit:
          return 'cleaning_kit';

        default:
          return '';
      }
    default:
      return '';
  }
};
