import { useCallback, useEffect } from 'react';
import { useIsFetching } from 'react-query';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import i18n, { i18nInit } from '@cortex/locales/i18n/i18n';
import * as Sentry from '@sentry/react';

import ROUTES from './common/constants/routes';
import { getPreferredLanguage } from './common/locales/locales-utils';
import {
  useCreateUser,
  useLogout,
  useMyUser,
  useRequestVerifyEmail,
  useSetUserPassword,
  useVerifyEmail,
} from './network/api/users';
import {
  ErrorPage,
  LoaderGlobal,
  PublicRoutesContainer,
  SetPass,
  Toast,
  Unsubscribe,
  useGoogleAnalytics,
  VerifyEmail,
} from './ui/components';
import { Footer } from './views/shared/Footer';
import { Header } from './views/shared/Header';
import { MainHeader } from './views/shared/MainHeader';
import config from './config';
import {
  ChooseProduct,
  ClaimDetails,
  ClaimSummary,
  FileClaim,
  Home,
  Login,
  MyClaims,
  MyPlans,
  PolicyDetails,
  Profile,
  ProtectionPlanDetail,
  SafeShip,
  SIClaimDetails,
  SIFileClaim,
  SIMyClaims,
  VerifyUserFailed,
  VerifyUserInformation,
} from './views';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

i18nInit();

const LoggedRoutes = () => {
  const navigate = useNavigate();

  return (
    <SentryRoutes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<Home />} />
        <Route path="plans" element={<MyPlans />} />
        <Route path="/plans/products" element={<ChooseProduct />} />
        <Route
          path="protection-plan/:productPurchaseId"
          element={<ProtectionPlanDetail />}
        />
        <Route path="claims" element={<MyClaims />} />
        <Route path="claims/new" element={<FileClaim />} />
        <Route path="claims/:claimId" element={<ClaimDetails />} />
        <Route path="claims/summary/:claimId" element={<ClaimSummary />} />

        <Route path="/safeship/policies" element={<SafeShip />} />
        <Route path="/safeship/details/:policyId" element={<PolicyDetails />} />
        <Route path="/safeship/claims/new" element={<SIFileClaim />} />
        <Route path="/safeship/claims" element={<SIMyClaims />} />
        <Route path="/safeship/claims/:claimId" element={<SIClaimDetails />} />

        <Route path="profile" element={<Profile />} />
        <Route
          path="not-found"
          element={
            <ErrorPage
              callToAction={() => navigate('/')}
              ctaLabel="Back to Home"
            />
          }
        />
        <Route path="*" element={<Navigate to="not-found" />} />
      </Route>
    </SentryRoutes>
  );
};

function App() {
  const { data: userData, isFetching, isLoading, error } = useMyUser();

  const navigate = useNavigate();
  const isGlobalFetching = useIsFetching();
  const { mutateAsync: createUser } = useCreateUser();
  const { mutateAsync: setUserPassword } = useSetUserPassword();
  const { mutate: logout } = useLogout();
  const location = useLocation();
  const match = useMatch('set-password/:token');
  const isLogged = Boolean(userData?.email) && !match;

  const isFirstLoading = isFetching && isLoading;
  useGoogleAnalytics(config.ga.trackingCode);
  const isNotFound = location.pathname !== ROUTES.NOT_FOUND;
  const changeLanguage = async (language: string) => {
    i18n.changeLanguage(language);
  };
  const language = getPreferredLanguage(userData, localStorage);

  useEffect(() => {
    if (language) {
      changeLanguage(language);
    }
  }, [language]); // eslint-disable-line

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  const logoutHandler = () => {
    logout({});
  };

  // FIX useCallback on this because this component was always re-rendering
  // and the state was always restarted. It allows us to use the state inside
  // SetPass component.
  const PublicRoutes = useCallback(() => {
    return (
      <PublicRoutesContainer>
        <Header />
        <div className="bg-white lg:max-w-[37.5rem] lg:mx-auto">
          <SentryRoutes>
            <Route path="/" element={<Outlet />}>
              <Route index element={<Login />} />
              <Route
                path="forgot-password"
                element={
                  <VerifyEmail
                    useVerifyEmail={useVerifyEmail}
                    useRequestVerifyEmail={useRequestVerifyEmail}
                  />
                }
              />
              <Route
                path="set-email/:customerToken"
                element={
                  <VerifyEmail
                    useVerifyEmail={useVerifyEmail}
                    useRequestVerifyEmail={useRequestVerifyEmail}
                  />
                }
              />
              <Route
                path="set-password/:token"
                element={
                  <SetPass
                    createUser={createUser}
                    setUserPassword={setUserPassword}
                    isMerchant={false}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
            <Route path="/safeship" element={<Outlet />}>
              <Route index element={<Login />} />
            </Route>
            <Route
              path={ROUTES.VERIFY_INFORMATION}
              element={<VerifyUserInformation />}
            />
            <Route
              path={ROUTES.VERIFY_INFORMATION_FAILED}
              element={<VerifyUserFailed />}
            />
          </SentryRoutes>
        </div>
      </PublicRoutesContainer>
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  const handleLoggedUserRoutes = () => {
    if (isFirstLoading) return null;

    if (location.pathname === ROUTES.UNSUBSCRIBE) {
      return (
        <SentryRoutes>
          <Route path={ROUTES.UNSUBSCRIBE} element={<Unsubscribe />} />
        </SentryRoutes>
      );
    }

    if (isLogged) {
      return (
        <>
          {isNotFound && (
            <MainHeader userName={userData.name} onLogout={logoutHandler} />
          )}
          <LoggedRoutes />
          {isNotFound && <Footer />}
        </>
      );
    }

    return <PublicRoutes />;
  };

  return (
    <>
      <LoaderGlobal isLoading={isGlobalFetching > 0} />
      {error ? (
        <ErrorPage callToAction={() => navigate('/')} ctaLabel="Back to Home" />
      ) : (
        handleLoggedUserRoutes()
      )}
      <Toast />
    </>
  );
}

export default App;
