import { synapse } from '../../base';
import { OccurrenceData } from './types';

export const postIngestableOrder = async (body: OccurrenceData) => {
  const args = {
    path: '/ingestable/orders',
    method: 'POST',
    body,
  };

  return synapse(args);
};
