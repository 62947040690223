import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressText } from '@cortex/myaccount/ui/components/AddressText';
import { Address } from '@cortex/myaccount/ui/components/models/Address';

import FacRadioStepTwoChecked from '../../../../../../ui/components/assets/FacRadioStepTwoChecked.svg';
import FacRadioStepTwoUnChecked from '../../../../../../ui/components/assets/FacRadioStepTwoUnChecked.svg';

interface ErrorsType {
  address?: string | undefined;
}

interface ValuesType {
  address?: string | undefined;
}

interface AddressBubbleType {
  address: Address;
  handleChange: (e: ChangeEvent<any>) => void;
  values: ValuesType;
  errors: ErrorsType;
}

export const AddressBubble = ({
  address,
  handleChange,
  values,
  errors,
}: AddressBubbleType) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="address-radio-group flex flex-row items-start justify-start cqsm:items-center cqsm:pl-24 mb-8 last:mb-0">
        <div className="address-radio-group_btn flex">
          <input
            className="peer tw-cst-pf"
            name="address"
            id={address?.id}
            type="radio"
            value={address?.id}
            onChange={handleChange}
            defaultChecked={values.address === address?.id}
          />
          {address?.id && (
            <label
              className="cursor-pointer pt-[0.375rem]"
              htmlFor={address?.id}
            >
              {values.address === address?.id ? (
                <div className="w-4 h-4">
                  <FacRadioStepTwoChecked />
                </div>
              ) : (
                <div className="w-4 h-4">
                  <FacRadioStepTwoUnChecked />
                </div>
              )}
            </label>
          )}
        </div>

        <div className="address-radio-group_label ml-3">
          <label htmlFor={address?.id} className="cursor-pointer">
            <div className="text-lg semibold max-w-xxs">
              <AddressText {...address} />
            </div>
          </label>
        </div>
      </div>
      {errors.address && (
        <div className="text-cqsm text-reguard-error semibold mt-1">
          {t('please_select_service_address')}
        </div>
      )}
    </>
  );
};
