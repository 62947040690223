import { useTranslation } from 'react-i18next';

export const YourServiceAddressText = () => {
  const { t } = useTranslation();

  return (
    <div className="step-two grid grid-cols-1 gap-x-6 gap-y-6 mb-6">
      <div className="step-two grid grid-cols-1 gap-x-6 gap-y-6 mb-6">
        <div>
          <div className="semibold text-base mb-3 cqsm:mb-4 cqsm:text-xl">
            {t('your_service_address')}
          </div>
          <div className="font-normal text-cqsm mb-1 cqsm:text-base">
            {t('select_service_address')}
          </div>
        </div>
      </div>
    </div>
  );
};
