import { ActivePolicies } from './components/Policy/ActivePolicies';
import { ExpiredPolicies } from './components/Policy/ExpiredPolicies';
import { PendingPolicies } from './components/Policy/PendingPolicies';
import { PoliciesContextProvider } from './context/policies-context';
import { MainPage } from '../../ui/components';

export function SafeShip() {
  return (
    <PoliciesContextProvider>
      <MainPage title={'' as string}>
        <PendingPolicies />
        <ActivePolicies />
        <ExpiredPolicies />
      </MainPage>
    </PoliciesContextProvider>
  );
}
